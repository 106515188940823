
import React from 'react';
import { CustomBadge } from '../CustomBadge';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface PriorityDropdownProps {
  priority: string;
  projectId: string;
  onProjectUpdated: () => void;
}

const priorityConfig: Record<string, {
  bg: string,
  label: string
}> = {
  'asap': {
    bg: 'bg-red-500 text-white dark:bg-red-600',
    label: 'ASAP'
  },
  'high': {
    bg: 'bg-orange-500 text-white dark:bg-orange-600',
    label: 'High'
  },
  'average': {
    bg: 'bg-blue-500 text-white dark:bg-blue-600',
    label: 'Average'
  },
  'low': {
    bg: 'bg-green-500 text-white dark:bg-green-600',
    label: 'Low'
  }
};

const PriorityDropdown: React.FC<PriorityDropdownProps> = ({ 
  priority, 
  projectId,
  onProjectUpdated 
}) => {
  const [updating, setUpdating] = React.useState(false);

  const updatePriority = async (value: string) => {
    if (updating) return;
    
    setUpdating(true);
    try {
      const { error } = await supabase
        .from('projects')
        .update({ priority: value })
        .eq('id', projectId);

      if (error) {
        throw error;
      }

      toast.success(`Project priority updated`);
      onProjectUpdated();
    } catch (error: any) {
      console.error(`Error updating project priority:`, error);
      toast.error(`Failed to update project priority`);
    } finally {
      setUpdating(false);
    }
  };

  const currentPriority = priorityConfig[priority as keyof typeof priorityConfig] || priorityConfig['average'];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <CustomBadge 
          variant="custom" 
          className={`${currentPriority.bg} cursor-pointer hover:opacity-90 transition-shadow hover:shadow-md`}
        >
          {currentPriority.label}
        </CustomBadge>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="z-50 bg-popover shadow-lg">
        {Object.entries(priorityConfig).map(([key, config]) => (
          <DropdownMenuItem 
            key={key}
            onClick={() => updatePriority(key)}
            className={`${config.bg} my-1 rounded`}
          >
            {config.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PriorityDropdown;
