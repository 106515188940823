
import React from 'react';

interface ContentPreviewProps {
  content: string;
}

const ContentPreview: React.FC<ContentPreviewProps> = ({ content }) => {
  return (
    <div className="border rounded-md p-4 min-h-[300px] max-w-none">
      <div className="prose prose-lg prose-neutral dark:prose-invert max-w-none">
        {content ? (
          <div 
            dangerouslySetInnerHTML={{ __html: content }}
            className="space-y-4" 
          />
        ) : (
          <p className="text-muted-foreground italic">No content to preview</p>
        )}
      </div>
    </div>
  );
};

export default ContentPreview;
