
import React from 'react';
import { Link } from 'react-router-dom';

interface NavLinkProps {
  to: string;
  label: string;
  icon?: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, label, icon }) => {
  return (
    <Link
      to={to}
      className="group relative flex items-center text-sm font-medium text-foreground/80 hover:text-foreground transition-colors"
    >
      {icon && <span className="mr-1.5">{icon}</span>}
      {label}
      <span className="absolute inset-x-0 -bottom-1 h-0.5 bg-blue-400 scale-x-0 group-hover:scale-x-100 transition-transform" />
    </Link>
  );
};

export default NavLink;
