
import React, { useState } from 'react';
import { Bell, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useReminders } from '@/hooks/reminders/use-reminders';
import { CreateReminderPayload, Reminder } from '@/types/reminder';
import { ReminderItem } from '@/components/reminders/ReminderItem';
import { ReminderDialogs } from '@/components/reminders/ReminderDialogs';
import { ReminderNotification } from '@/components/reminders/ReminderNotification';
import ProtectedRoute from '@/components/ProtectedRoute';
import MainLayout from '@/layouts/MainLayout';
import { Helmet } from 'react-helmet-async';

export default function Reminders() {
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editingReminder, setEditingReminder] = useState<Reminder | null>(null);
  const [deletingReminderId, setDeletingReminderId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('pending');
  
  const { 
    reminders, 
    isLoading, 
    createReminder, 
    updateReminder, 
    completeReminder, 
    snoozeReminder, 
    deleteReminder 
  } = useReminders();

  const pendingReminders = reminders.filter(r => r.status === 'pending');
  const completedReminders = reminders.filter(r => r.status === 'completed');
  const snoozedReminders = reminders.filter(r => r.status === 'snoozed');

  const handleCreate = async (data: CreateReminderPayload) => {
    await createReminder(data);
    setIsCreateOpen(false);
  };

  const handleUpdate = async (data: CreateReminderPayload) => {
    if (editingReminder) {
      await updateReminder({
        id: editingReminder.id,
        ...data
      });
      setEditingReminder(null);
    }
  };

  const handleComplete = async (id: string) => {
    await completeReminder(id);
  };

  const handleSnooze = async (id: string, minutes: number) => {
    await snoozeReminder({ id, minutes });
  };

  const handleDelete = async (id: string) => {
    await deleteReminder(id);
  };

  const renderReminders = (remindersList: Reminder[]) => {
    if (isLoading) {
      return <div className="flex justify-center py-8">Loading reminders...</div>;
    }

    if (remindersList.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center py-12 text-center">
          <Bell className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium">No reminders</h3>
          <p className="text-muted-foreground mt-1">
            {activeTab === 'pending' ? "You don't have any pending reminders" : 
             activeTab === 'completed' ? "You haven't completed any reminders yet" : 
             "You don't have any snoozed reminders"}
          </p>
          {activeTab === 'pending' && (
            <Button 
              onClick={() => setIsCreateOpen(true)} 
              className="mt-4"
              variant="outline"
            >
              <Plus className="h-4 w-4 mr-2" />
              Create a reminder
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {remindersList.map(reminder => (
          <ReminderItem
            key={reminder.id}
            reminder={reminder}
            onEdit={setEditingReminder}
            onComplete={handleComplete}
            onDelete={(id) => setDeletingReminderId(id)}
            onSnooze={handleSnooze}
          />
        ))}
      </div>
    );
  };

  return (
    <ProtectedRoute>
      <MainLayout>
        <Helmet>
          <title>Reminders | Personal Dashboard</title>
          <meta name="description" content="Manage your reminders and never miss an important event" />
        </Helmet>
        
        <div className="container mx-auto max-w-4xl py-8 px-4">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h1 className="text-3xl font-bold">Reminders</h1>
              <p className="text-muted-foreground mt-1">Never miss an important event or task</p>
            </div>
            
            <Button onClick={() => setIsCreateOpen(true)}>
              <Plus className="h-4 w-4 mr-2" />
              New Reminder
            </Button>
          </div>
          
          <Tabs defaultValue="pending" value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid grid-cols-3 mb-6">
              <TabsTrigger value="pending" className="relative">
                Pending
                {pendingReminders.length > 0 && (
                  <span className="ml-1 rounded-full bg-primary px-2 py-0.5 text-xs text-white">
                    {pendingReminders.length}
                  </span>
                )}
              </TabsTrigger>
              <TabsTrigger value="snoozed">
                Snoozed
                {snoozedReminders.length > 0 && (
                  <span className="ml-1 rounded-full bg-blue-500 px-2 py-0.5 text-xs text-white">
                    {snoozedReminders.length}
                  </span>
                )}
              </TabsTrigger>
              <TabsTrigger value="completed">
                Completed
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="pending" className="mt-0">
              {renderReminders(pendingReminders)}
            </TabsContent>
            
            <TabsContent value="snoozed" className="mt-0">
              {renderReminders(snoozedReminders)}
            </TabsContent>
            
            <TabsContent value="completed" className="mt-0">
              {renderReminders(completedReminders)}
            </TabsContent>
          </Tabs>
        </div>
        
        <ReminderDialogs
          isCreateOpen={isCreateOpen}
          setIsCreateOpen={setIsCreateOpen}
          editingReminder={editingReminder}
          setEditingReminder={setEditingReminder}
          deletingReminderId={deletingReminderId}
          setDeletingReminderId={setDeletingReminderId}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
        
        <ReminderNotification />
      </MainLayout>
    </ProtectedRoute>
  );
}
