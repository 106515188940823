
import React, { useState } from 'react';
import { Keyword } from '@/types/keyword';
import KeywordListSkeleton from './components/KeywordListSkeleton';
import KeywordListFilters from './components/KeywordListFilters';
import KeywordTable from './components/KeywordTable';
import KeywordEmptyState from './components/KeywordEmptyState';
import KeywordPagination from './components/KeywordPagination';

interface KeywordsListProps {
  keywords: Keyword[];
  isLoading: boolean;
  onDelete: (id: string) => void;
}

const KeywordsList: React.FC<KeywordsListProps> = ({ keywords, isLoading, onDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'pending' | 'created'>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  // Filter and search keywords
  const filteredKeywords = keywords.filter(keyword => {
    const matchesSearch = keyword.keyword.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter === 'all' || keyword.status === statusFilter;
    return matchesSearch && matchesStatus;
  });
  
  // Pagination
  const totalPages = Math.ceil(filteredKeywords.length / itemsPerPage);
  const paginatedKeywords = filteredKeywords.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  
  if (isLoading) {
    return <KeywordListSkeleton />;
  }
  
  return (
    <div className="space-y-4">
      <KeywordListFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      
      {filteredKeywords.length === 0 ? (
        <KeywordEmptyState searchTerm={searchTerm} />
      ) : (
        <>
          <KeywordTable 
            keywords={paginatedKeywords} 
            onDelete={onDelete} 
          />
          
          <KeywordPagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default KeywordsList;
