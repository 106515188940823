import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@/components/theme-provider';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { AuthProvider } from '@/contexts/auth/AuthProvider';

// Pages
import Index from '@/pages/Index';
import Auth from '@/pages/Auth';
import Profile from '@/pages/Profile';
import Dashboard from '@/pages/Dashboard';
import Projects from '@/pages/Projects';
import ProjectDetails from '@/pages/ProjectDetails';
import ProjectCollection from '@/pages/ProjectCollection';
import Resources from '@/pages/Resources';
import ResourceDetails from '@/pages/ResourceDetails';
import Admin from '@/pages/Admin';
import AdminDashboard from '@/pages/AdminDashboard';
import AdminUsers from '@/pages/AdminUsers';
import AdminResources from '@/pages/AdminResources';
import AdminResourceForm from '@/pages/AdminResourceForm';
import AdminCategoryForm from '@/pages/AdminCategoryForm';
import AdminKeywords from '@/pages/AdminKeywords';
import AdminResourcesGenerator from './pages/AdminResourcesGenerator';
import Habits from '@/pages/Habits';
import Prompts from '@/pages/Prompts';
import Reminders from '@/pages/Reminders';
import NotFound from '@/pages/NotFound';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider defaultTheme="light" storageKey="ui-theme">
          <HelmetProvider>
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              
              {/* Project routes */}
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetails />} />
              <Route path="/collections/:collectionId" element={<ProjectCollection />} />
              
              {/* Resources routes */}
              <Route path="/resources" element={<Resources />} />
              <Route path="/resources/:slug" element={<ResourceDetails />} />
              
              {/* Admin routes */}
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/resources" element={<AdminResources />} />
              <Route path="/admin/resources/create" element={<AdminResourceForm />} />
              <Route path="/admin/resources/edit/:id" element={<AdminResourceForm />} />
              <Route path="/admin/resources/categories" element={<AdminResources />} />
              <Route path="/admin/resources/categories/create" element={<AdminCategoryForm />} />
              <Route path="/admin/resources/categories/edit/:id" element={<AdminCategoryForm />} />
              <Route path="/admin/resources/keywords" element={<AdminKeywords />} />
              <Route path="/admin/resources/resources-generator" element={<AdminResourcesGenerator />} />
              
              {/* Other routes */}
              <Route path="/habits" element={<Habits />} />
              <Route path="/prompts" element={<Prompts />} />
              <Route path="/reminders" element={<Reminders />} />
              
              {/* Catch-all */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Toaster />
          </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
