
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from '../layouts/MainLayout';
import Hero from '../components/Hero';
import ResourceCarousel from '../components/home/ResourceCarousel';
import FeatureSection from '../components/home/FeatureSection';
import HowItWorksSection from '../components/home/HowItWorksSection';
import TestimonialsSection from '../components/home/TestimonialsSection';
import FaqSection from '../components/home/FaqSection';
import CtaSection from '../components/home/CtaSection';
import { useDocumentTitle } from '@/hooks/use-document-title';
import { useMetadata } from '@/hooks/use-metadata';
import { useAuth } from '@/contexts/auth';

const Index = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  // Set document title and metadata for SEO
  useDocumentTitle('Home', false);
  useMetadata({
    title: 'Home',
    description: 'Myhacks.org is the ultimate solution for those seeking speed, efficiency, and an elegant interface for managing projects and tasks.',
    ogType: 'website'
  });

  // Redirect logged in users to dashboard
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  // If user is logged in, don't render home page content
  if (user) {
    return null; // Return empty to prevent flash of home page content
  }

  const handleNewProject = () => {
    console.log('Creating new project');
  };

  // Sample projects data
  const projects = [
    {
      id: '1',
      title: 'Redesign Website',
      description: 'Implement new user interface with improved user experience and modern design.',
      tasks: 12,
      completed: 8,
      lastUpdated: '2 hours ago',
      isFavorite: true,
    },
    {
      id: '2',
      title: 'Mobile App Development',
      description: 'Create native apps for iOS and Android platforms with React Native framework.',
      tasks: 24,
      completed: 6,
      lastUpdated: 'Yesterday',
    },
    {
      id: '3',
      title: 'Content Marketing',
      description: 'Develop content strategy and create engaging blog posts for social media platforms.',
      tasks: 8,
      completed: 2,
      lastUpdated: '3 days ago',
    },
  ];

  // Sample tasks data
  const tasks = [
    {
      id: 't1',
      title: 'Update API documentation',
      projectName: 'Redesign Website',
      dueDate: t('tasks.tomorrow'),
      isCompleted: false,
      tags: ['Documentation', 'API'],
      priority: 'high' as const,
    },
    {
      id: 't2',
      title: 'Create wireframes for dashboard',
      projectName: 'Mobile App Development',
      dueDate: t('tasks.today'),
      isCompleted: false,
      tags: ['Design', 'UX'],
      priority: 'medium' as const,
    },
    {
      id: 't3',
      title: 'Review pull requests',
      projectName: 'Redesign Website',
      dueDate: t('tasks.completed'),
      isCompleted: true,
      tags: ['Development', 'Review'],
      priority: 'low' as const,
    },
  ];

  return (
    <MainLayout>
      <Hero />
      
      <ResourceCarousel />
      <FeatureSection />
      <HowItWorksSection />
      <TestimonialsSection />
      <FaqSection />
      <CtaSection />
    </MainLayout>
  );
};

export default Index;
