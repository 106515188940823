
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { CalendarIcon, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';

interface DatePickerFieldProps {
  label: string;
  name: 'due_date';
  form: UseFormReturn<TaskFormValues>;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({ label, name, form }) => {
  const dateValue = form.watch(name);
  
  const clearDate = (e: React.MouseEvent) => {
    e.stopPropagation();
    form.setValue(name, null, { shouldDirty: true });
  };

  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">{label}</label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !dateValue && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateValue ? (
              <span className="flex items-center justify-between w-full">
                {format(dateValue, 'PPP')}
                <X 
                  className="h-4 w-4 opacity-70 hover:opacity-100" 
                  onClick={clearDate}
                />
              </span>
            ) : (
              <span>Select date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={dateValue || undefined}
            onSelect={(date) => form.setValue(name, date, { shouldDirty: true })}
            initialFocus
            className="p-3 pointer-events-auto"
          />
        </PopoverContent>
      </Popover>
      {form.formState.errors[name] && (
        <p className="text-xs text-destructive">
          {form.formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default DatePickerField;
