import { supabase } from '@/integrations/supabase/client';
import { Keyword, KeywordWithResource } from '@/types/keyword';

export const getKeywords = async (): Promise<KeywordWithResource[]> => {
  const { data, error } = await supabase
    .rpc('get_keywords_with_resources');

  if (error) throw new Error(error.message);
  return data as KeywordWithResource[];
};

// Helper function to normalize keywords for comparison
const normalizeKeyword = (keyword: string): string => {
  return keyword.trim().toLowerCase();
};

// Check if a keyword already exists (exact match only)
export const keywordExists = async (keyword: string): Promise<boolean> => {
  // We'll check for exact match after trimming
  const normalizedKeyword = keyword.trim();
  
  const { data, error } = await supabase
    .from('resource_keywords')
    .select('id')
    .eq('keyword', normalizedKeyword)
    .limit(1);
    
  if (error) throw new Error(error.message);
  return data && data.length > 0;
};

export const addKeyword = async (keyword: string): Promise<Keyword> => {
  // First check if keyword already exists (exact match only)
  const trimmedKeyword = keyword.trim();
  
  const { data: existingKeyword, error: searchError } = await supabase
    .from('resource_keywords')
    .select('*')
    .eq('keyword', trimmedKeyword)
    .single();
  
  if (searchError && searchError.code !== 'PGRST116') {
    // PGRST116 is the code for "no rows returned" which is expected if keyword doesn't exist
    throw new Error(searchError.message);
  }
  
  // If keyword already exists, return the existing one
  if (existingKeyword) return existingKeyword as Keyword;
  
  // Otherwise, add the new keyword
  const { data, error } = await supabase
    .from('resource_keywords')
    .insert({ keyword: trimmedKeyword })
    .select()
    .single();

  if (error) throw new Error(error.message);
  return data as Keyword;
};

export const addBulkKeywords = async (keywords: string[]): Promise<{ added: number, duplicates: number }> => {
  if (!keywords.length) return { added: 0, duplicates: 0 };
  
  // Normalize and deduplicate keywords before processing
  const uniqueKeywords = Array.from(new Set(
    keywords.map(keyword => keyword.trim()).filter(k => k !== '')
  ));
  
  let added = 0;
  let duplicates = 0;
  
  // Process keywords in batches to avoid hitting request size limits
  const batchSize = 100;
  for (let i = 0; i < uniqueKeywords.length; i += batchSize) {
    const batch = uniqueKeywords.slice(i, i + batchSize);
    
    // For each keyword in batch, check if it exists exactly as is
    for (const keyword of batch) {
      try {
        // Check if this exact keyword already exists
        const exists = await keywordExists(keyword);
        
        if (exists) {
          duplicates++;
        } else {
          // Add the keyword
          await addKeyword(keyword);
          added++;
        }
      } catch (error) {
        console.error(`Error processing keyword "${keyword}":`, error);
      }
    }
  }
  
  return { added, duplicates };
};

export const deleteKeyword = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('resource_keywords')
    .delete()
    .eq('id', id);

  if (error) throw new Error(error.message);
};

export const linkKeywordToResource = async (keywordId: string, resourceId: string): Promise<void> => {
  // First, add the mapping
  const { error: mappingError } = await supabase
    .from('resource_keyword_mappings')
    .insert({ keyword_id: keywordId, resource_id: resourceId });

  if (mappingError) throw new Error(mappingError.message);
  
  // Then, update the keyword status
  const { error: statusError } = await supabase
    .from('resource_keywords')
    .update({ status: 'created' })
    .eq('id', keywordId);

  if (statusError) throw new Error(statusError.message);
};
