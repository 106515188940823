
import React from 'react';
import { Button } from '@/components/ui/button';

interface KeywordStatusFilterProps {
  statusFilter: 'all' | 'pending' | 'created';
  setStatusFilter: (status: 'all' | 'pending' | 'created') => void;
}

const KeywordStatusFilter: React.FC<KeywordStatusFilterProps> = ({ 
  statusFilter, 
  setStatusFilter 
}) => {
  return (
    <div className="flex gap-2">
      <Button 
        variant={statusFilter === 'all' ? 'default' : 'outline'}
        onClick={() => setStatusFilter('all')}
        size="sm"
      >
        All
      </Button>
      <Button 
        variant={statusFilter === 'pending' ? 'default' : 'outline'}
        onClick={() => setStatusFilter('pending')}
        size="sm"
      >
        Pending
      </Button>
      <Button 
        variant={statusFilter === 'created' ? 'default' : 'outline'}
        onClick={() => setStatusFilter('created')}
        size="sm"
      >
        Created
      </Button>
    </div>
  );
};

export default KeywordStatusFilter;
