
import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/contexts/AuthContext';

export function useUserTags() {
  const { user } = useAuth();
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTags = async () => {
      if (!user) {
        setTags([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        
        // Get all projects for the user to extract unique tags
        const { data: projects, error } = await supabase
          .from('projects')
          .select('tags')
          .eq('user_id', user.id);
        
        if (error) throw error;
        
        // Flatten all tags and remove duplicates
        const allTags = projects
          .flatMap(project => project.tags || [])
          .filter(Boolean);
        
        const uniqueTags = [...new Set(allTags)];
        
        setTags(uniqueTags);
      } catch (error) {
        console.error('Error loading user tags:', error);
      } finally {
        setLoading(false);
      }
    };

    loadTags();
  }, [user]);

  return { tags, loading };
}
