
import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Plus, Loader2, List } from 'lucide-react';
import { CreateTaskPayload } from '@/types/task';
import { Skeleton } from '@/components/ui/skeleton';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { toast } from 'sonner';

interface TaskCreatorProps {
  projectId: string;
  parentTaskId?: string | null;
  onCreateTask: (task: CreateTaskPayload) => Promise<any>;
  onCancel?: () => void;
  className?: string;
}

const TaskCreator: React.FC<TaskCreatorProps> = ({ 
  projectId, 
  parentTaskId = null,
  onCreateTask,
  onCancel,
  className = ''
}) => {
  const [title, setTitle] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim() || isSubmitting) return;
    
    setIsSubmitting(true);
    
    try {
      // Show creating state with a slight delay to prevent flickering
      const creatingTimeout = setTimeout(() => setIsCreating(true), 300);
      
      // Check if there are multiple lines (for bulk creation)
      const titles = title.split('\n').filter(line => line.trim());
      
      if (titles.length > 1) {
        // Create multiple tasks
        let createdCount = 0;
        
        for (const taskTitle of titles) {
          if (taskTitle.trim()) {
            await onCreateTask({
              title: taskTitle.trim(),
              project_id: projectId,
              parent_task_id: parentTaskId,
              status: 'queue'
            });
            createdCount++;
          }
        }
        
        toast.success(`${createdCount} tasks created successfully`);
      } else {
        // Create a single task
        await onCreateTask({
          title: title.trim(),
          project_id: projectId,
          parent_task_id: parentTaskId,
          status: 'queue'
        });
      }
      
      clearTimeout(creatingTimeout);
      setTitle('');
      
      if (onCancel) {
        onCancel();
      }
    } finally {
      setIsSubmitting(false);
      setIsCreating(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow newlines on Shift+Enter
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setTitle(prev => prev + '\n');
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text');
    
    // If the pasted text contains line breaks, prevent default and update the state
    if (pastedText.includes('\n')) {
      e.preventDefault();
      
      // Set the title to the current value plus the pasted text
      setTitle(prev => {
        const cursorPosition = e.currentTarget.selectionStart || 0;
        const endPosition = e.currentTarget.selectionEnd || 0;
        
        return (
          prev.substring(0, cursorPosition) + 
          pastedText + 
          prev.substring(endPosition)
        );
      });
      
      // Show a toast notification about multi-task creation
      const lines = pastedText.split('\n').filter(line => line.trim()).length;
      if (lines > 1) {
        toast.info(`Detected ${lines} tasks. Press Add ${parentTaskId ? 'Subtasks' : 'Task'} to create all.`);
      }
    }
  };

  if (isCreating) {
    return (
      <div className={`flex gap-2 ${className} animate-pulse transition-all duration-300`}>
        <Skeleton className="h-10 flex-1" />
        <Skeleton className="h-10 w-[100px]" />
        {onCancel && <Skeleton className="h-10 w-[80px]" />}
      </div>
    );
  }

  // Count the number of tasks that would be created
  const taskCount = title ? title.split('\n').filter(line => line.trim()).length : 0;
  const hasMultipleTasks = taskCount > 1;

  return (
    <form onSubmit={handleSubmit} className={`flex gap-2 ${className} transition-opacity duration-200 ${isSubmitting ? 'opacity-80' : 'opacity-100'}`}>
      <div className="flex-1 relative">
        <Input
          placeholder={parentTaskId ? "Add subtask... (Paste multiple lines for bulk creation)" : "Add new task... (Paste multiple lines for bulk creation)"}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          disabled={isSubmitting}
          className="flex-1 transition-all duration-200"
        />
        
        {hasMultipleTasks && (
          <div className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-background px-1">
            <span className="text-xs text-muted-foreground flex items-center">
              <List className="h-3 w-3 mr-1" />
              {taskCount} tasks
            </span>
          </div>
        )}
      </div>
      
      <Tooltip>
        <TooltipTrigger asChild>
          <Button 
            type="submit" 
            size="sm" 
            disabled={!title.trim() || isSubmitting}
            className="transition-all duration-200"
          >
            {isSubmitting ? (
              <Loader2 className="h-4 w-4 mr-1 animate-spin" />
            ) : (
              <Plus className="h-4 w-4 mr-1" />
            )}
            {hasMultipleTasks 
              ? `Add ${taskCount} ${parentTaskId ? 'Subtasks' : 'Tasks'}` 
              : (parentTaskId ? 'Add Subtask' : 'Add Task')}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          {hasMultipleTasks 
            ? "Create multiple tasks from each line" 
            : "Paste multiple lines to create several tasks at once"}
        </TooltipContent>
      </Tooltip>
      
      {onCancel && (
        <Button 
          type="button" 
          variant="ghost" 
          size="sm" 
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      )}
    </form>
  );
};

export default TaskCreator;
