
import { supabase } from '@/integrations/supabase/client';

/**
 * Generates a resource from a keyword using OpenAI
 * @param keywordId The ID of the keyword to generate content for
 * @returns Object with success status, resource data (if successful), and error (if failed)
 */
export const generateResourceFromKeyword = async (keywordId: string): Promise<{ success: boolean; resource?: any; error?: string; details?: any }> => {
  try {
    console.log(`Calling generate-resource function with keywordId: ${keywordId}`);
    
    const { data, error } = await supabase.functions.invoke('generate-resource', {
      body: { keywordId }
    });
    
    if (error) {
      console.error('Error from Supabase function:', error);
      return {
        success: false,
        error: `Function error: ${error.message}`,
        details: error
      };
    }
    
    if (!data || !data.success) {
      const errorMessage = data?.error || 'Unknown error in generate-resource function';
      console.error('Function returned error:', errorMessage);
      
      // Handle case where detailed error info is available
      if (data?.details) {
        console.error('Error details:', data.details);
      }
      
      return {
        success: false,
        error: errorMessage,
        details: data?.details || undefined
      };
    }
    
    console.log('Resource generated successfully:', data.resource);
    return data;
  } catch (error: any) {
    console.error('Error generating resource:', error);
    return { 
      success: false, 
      error: error.message || 'An unknown error occurred while generating the resource',
      details: error.details || error
    };
  }
};
