
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/contexts/AuthContext';
import { ResourceFormValues } from './resource-form-types';
import { generateSlug } from '@/utils/slug-utils';
import { toast } from 'sonner';

export const useResourceSubmissions = (
  id: string | undefined,
  language: string,
  selectedCategories: string[],
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isEditing = !!id;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCategoryChange = (categoryId: string) => {
    setSelectedCategories(prev => {
      if (prev.includes(categoryId)) {
        return prev.filter(id => id !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
  };

  const handleGenerateSlug = (title: string) => {
    return generateSlug(title);
  };

  const handleSubmit = async (data: ResourceFormValues) => {
    if (!user) {
      console.error('No authenticated user found');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      let resourceId = id;
      
      // When saving as draft, ensure we have at least a slug
      if (data.status === 'draft' && !data.slug && data.title) {
        data.slug = generateSlug(data.title);
      } else if (data.status === 'draft' && !data.slug) {
        // Generate a temporary slug if both title and slug are empty
        data.slug = `draft-${Date.now()}`;
      }
      
      if (isEditing) {
        // First, get the current resource to preserve multilingual content
        const { data: existingResource, error: fetchError } = await supabase
          .from('resources')
          .select('*')
          .eq('id', id)
          .single();
  
        if (fetchError) throw fetchError;
  
        // Initialize empty objects if they don't exist or aren't objects
        const currentTitle = (typeof existingResource.title === 'object' && existingResource.title) ? 
          existingResource.title : {};
        const currentContent = (typeof existingResource.content === 'object' && existingResource.content) ? 
          existingResource.content : {};
        const currentExcerpt = (typeof existingResource.excerpt === 'object' && existingResource.excerpt) ? 
          existingResource.excerpt : {};
        const currentMetaTitle = (typeof existingResource.meta_title === 'object' && existingResource.meta_title) ? 
          existingResource.meta_title : {};
        const currentMetaDescription = (typeof existingResource.meta_description === 'object' && existingResource.meta_description) ? 
          existingResource.meta_description : {};
  
        // Create the multilingual objects with proper type casting
        const title = { ...currentTitle, [language]: data.title } as Record<string, string>;
        const content = { ...currentContent, [language]: data.content } as Record<string, string>;
        const excerpt = { ...currentExcerpt, [language]: data.excerpt } as Record<string, string>;
        const meta_title = { ...currentMetaTitle, [language]: data.meta_title } as Record<string, string>;
        const meta_description = { ...currentMetaDescription, [language]: data.meta_description } as Record<string, string>;
  
        // Determine if we need to update the published_at timestamp
        const wasPublished = existingResource.status === 'published';
        const isNowPublished = data.status === 'published';
        const published_at = (!wasPublished && isNowPublished) ? new Date().toISOString() : existingResource.published_at;
  
        // Update the resource
        const { error: updateError } = await supabase
          .from('resources')
          .update({
            title,
            slug: data.slug,
            content,
            excerpt,
            meta_title,
            meta_description,
            status: data.status,
            published_at,
          })
          .eq('id', id);
          
        if (updateError) throw updateError;
      } else {
        // Create multilingual objects for new resource with proper type casting
        const title = { [language]: data.title || '' } as Record<string, string>;
        const content = { [language]: data.content || '' } as Record<string, string>;
        const excerpt = { [language]: data.excerpt || '' } as Record<string, string>;
        const meta_title = { [language]: data.meta_title || '' } as Record<string, string>;
        const meta_description = { [language]: data.meta_description || '' } as Record<string, string>;
  
        // Determine published_at timestamp
        const published_at = data.status === 'published' ? new Date().toISOString() : null;
  
        // Create new resource
        const { data: newResource, error: createError } = await supabase
          .from('resources')
          .insert({
            title,
            slug: data.slug,
            content,
            excerpt,
            meta_title,
            meta_description,
            status: data.status,
            published_at,
            author_id: user.id,
          })
          .select('id')
          .single();
  
        if (createError) throw createError;
        
        resourceId = newResource.id;
        toast.success('Resource saved as draft');
      }
  
      // Handle category assignments if we have a resource ID
      if (resourceId) {
        // Use the selectedCategories state to manage category assignments
        const { data: currentMappings } = await supabase
          .from('resource_category_mappings')
          .select('category_id')
          .eq('resource_id', resourceId);
  
        const currentCategoryIds = currentMappings 
          ? currentMappings.map(mapping => mapping.category_id) 
          : [];
  
        // Categories to add (in selected but not in current)
        const categoriesToAdd = selectedCategories.filter(
          categoryId => !currentCategoryIds.includes(categoryId)
        );
  
        // Categories to remove (in current but not in selected)
        const categoriesToRemove = currentCategoryIds.filter(
          categoryId => !selectedCategories.includes(categoryId)
        );
  
        // Add new category mappings
        for (const categoryId of categoriesToAdd) {
          const { error } = await supabase
            .from('resource_category_mappings')
            .insert({ resource_id: resourceId, category_id: categoryId });
            
          if (error) console.error('Error adding category:', error);
        }
  
        // Remove old category mappings
        for (const categoryId of categoriesToRemove) {
          const { error } = await supabase
            .from('resource_category_mappings')
            .delete()
            .eq('resource_id', resourceId)
            .eq('category_id', categoryId);
            
          if (error) console.error('Error removing category:', error);
        }
      }
  
      // Navigate back to the resources list
      navigate('/admin/resources');
    } catch (error: any) {
      console.error('Error saving resource:', error.message);
      toast.error('Error saving resource: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    handleCategoryChange,
    handleGenerateSlug,
    isSubmitting
  };
};
