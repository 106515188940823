
import { ResourceDetail } from '@/types/resource';

// Extract How-To content if exists
export const extractSchemaData = (content: string) => {
  // Extract FAQ content if exists
  const faqRegex = /## FAQ(.+?)(?=^##|\Z)/ms;
  const faqMatch = content.match(faqRegex);
  const faqContent = faqMatch ? faqMatch[0] : '';

  // Extract FAQs as separate items for the schema
  const faqItemsRegex = /### (.+?)\n([\s\S]+?)(?=###|$)/g;
  const faqItems: {question: string, answer: string}[] = [];
  let faqItemMatch;
  
  if (faqContent) {
    const faqText = faqContent;
    while ((faqItemMatch = faqItemsRegex.exec(faqText)) !== null) {
      const question = faqItemMatch[1].replace('?', '').trim() + '?';
      // Clean up the answer by removing empty lines and trimming
      const answer = faqItemMatch[2].trim();
      faqItems.push({ question, answer });
    }
  }

  // Extract steps from how-to content
  const howToRegex = /## How To Section(.+?)(?=^##|\Z)/ms;
  const howToMatch = content.match(howToRegex);
  const howToContent = howToMatch ? howToMatch[0] : '';

  // Extract steps
  const stepsRegex = /### Steps\n([\s\S]+?)(?=###|$)/;
  const stepsMatch = howToContent.match(stepsRegex);
  const stepsText = stepsMatch ? stepsMatch[1] : '';
  
  const stepsArray: string[] = [];
  const stepLineRegex = /\d+\.\s(.+)/g;
  let stepMatch;
  while ((stepMatch = stepLineRegex.exec(stepsText)) !== null) {
    stepsArray.push(stepMatch[1].trim());
  }

  // Extract supplies/tools
  const suppliesRegex = /### What You'll Need\n([\s\S]+?)(?=###|$)/;
  const suppliesMatch = howToContent.match(suppliesRegex);
  const suppliesText = suppliesMatch ? suppliesMatch[1] : '';
  
  const suppliesArray: string[] = [];
  const supplyLineRegex = /-\s(.+)/g;
  let supplyMatch;
  while ((supplyMatch = supplyLineRegex.exec(suppliesText)) !== null) {
    suppliesArray.push(supplyMatch[1].trim());
  }

  return {
    howTo: {
      steps: stepsArray,
      supplies: suppliesArray
    },
    faq: faqItems
  };
};

// Generate HowTo schema
export const generateHowToSchema = (content: string, resource?: ResourceDetail) => {
  const { howTo } = extractSchemaData(content);
  
  if (howTo.steps.length === 0) return null;

  return {
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": resource?.title || "",
    "description": resource?.excerpt || "",
    "step": howTo.steps.map((step, index) => ({
      "@type": "HowToStep",
      "position": index + 1,
      "name": `Step ${index + 1}`,
      "text": step
    })),
    ...(howTo.supplies.length > 0 && {
      "tool": howTo.supplies.map(supply => ({
        "@type": "HowToTool",
        "name": supply
      }))
    })
  };
};

// Generate FAQ schema
export const generateFAQSchema = (content: string) => {
  const { faq } = extractSchemaData(content);
  
  if (faq.length === 0) return null;

  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faq.map(item => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer
      }
    }))
  };
};

// Generate Review schema
export const generateReviewSchema = (resource: ResourceDetail) => {
  if (!resource || !resource.rating) {
    // Default ratings if not provided
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      "name": resource.title,
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "24"
      }
    };
  }
  
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    "name": resource.title,
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": resource.rating.value.toString(),
      "bestRating": resource.rating.max.toString(),
      "worstRating": "1",
      "ratingCount": resource.rating.count.toString()
    }
  };
};
