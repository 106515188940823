
import { useState, useEffect } from 'react';
import { ResourcePreview } from '@/types/resource';
import { usePublicResources } from '@/hooks/resources/use-public-resources';

// Sample resources for initial rendering
const sampleResources: ResourcePreview[] = [
  {
    id: '1',
    title: 'Getting Started with Project Management',
    excerpt: 'Learn the basics of setting up your first project and organizing tasks efficiently.',
    slug: 'getting-started',
    categories: [{ id: '1', name: 'Beginner', slug: 'beginner' }],
    status: 'published',
    created_at: new Date().toISOString()
  },
  {
    id: '2',
    title: 'Advanced Task Management Techniques',
    excerpt: 'Discover how to prioritize and manage complex task dependencies in your projects.',
    slug: 'advanced-task-management',
    categories: [{ id: '2', name: 'Advanced', slug: 'advanced' }],
    status: 'published',
    created_at: new Date().toISOString()
  },
  {
    id: '3',
    title: 'Building Productive Habits',
    excerpt: 'Implement proven strategies to develop and maintain productive daily habits.',
    slug: 'productive-habits',
    categories: [{ id: '3', name: 'Productivity', slug: 'productivity' }],
    status: 'published',
    created_at: new Date().toISOString()
  }
];

/**
 * Hook to manage resource carousel data with optimized loading
 */
export const useResourceCarouselData = (language = 'en') => {
  const [displayData, setDisplayData] = useState<ResourcePreview[]>(sampleResources.slice(0, 3));
  const [isClientSide, setIsClientSide] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Get resource data from API
  const { usePublicResourcesQuery } = usePublicResources(language);
  const { data: resources = [], isLoading, refetch } = usePublicResourcesQuery();

  // Function to fetch resources on demand
  const fetchResources = () => {
    if (!dataLoaded) {
      refetch().then(result => {
        if (result.data && result.data.length > 0) {
          setDisplayData(result.data.slice(0, 3));
          setDataLoaded(true);
        }
      });
    }
  };

  // Handle client-side initialization and hydration
  useEffect(() => {
    setIsClientSide(true);
    
    // Use requestIdleCallback to defer non-critical initialization
    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
      // @ts-ignore - TypeScript doesn't recognize requestIdleCallback
      window.requestIdleCallback(() => {
        // Only update from API data if it's different from sample data
        if (resources.length > 0 && !isLoading && !dataLoaded) {
          setDisplayData(resources.slice(0, 3));
          setDataLoaded(true);
        }
      });
    } else {
      // Fallback for browsers without requestIdleCallback
      setTimeout(() => {
        if (resources.length > 0 && !isLoading && !dataLoaded) {
          setDisplayData(resources.slice(0, 3));
          setDataLoaded(true);
        }
      }, 1000);
    }
  }, [resources, isLoading]);

  return { 
    displayData, 
    isClientSide, 
    dataLoaded, 
    isLoading,
    fetchResources 
  };
};
