
import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Reminder } from '@/types/reminder';

interface ReminderStatusBadgeProps {
  reminder: Reminder;
  isOverdue: boolean;
}

export function ReminderStatusBadge({ reminder, isOverdue }: ReminderStatusBadgeProps) {
  switch(reminder.status) {
    case 'completed':
      return <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">Completed</Badge>;
    case 'snoozed':
      return <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200">Snoozed</Badge>;
    default:
      return isOverdue 
        ? <Badge variant="outline" className="bg-red-50 text-red-700 border-red-200">Overdue</Badge>
        : <Badge variant="outline" className="bg-yellow-50 text-yellow-700 border-yellow-200">Pending</Badge>;
  }
}
