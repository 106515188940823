
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Home,
  LayoutDashboard,
  ListChecks,
  Calendar,
  Settings,
  FileText,
  Tags,
  Key,
  Sparkles
} from 'lucide-react';

export function useSidebarResources() {
  const { t } = useTranslation();
  const { hasRole } = useAuth();
  const isAdmin = hasRole('admin');
  const navigate = useNavigate();
  const location = useLocation();
  const [isResourcesOpen, setIsResourcesOpen] = useState(true);
  
  const isResourcesActive = location.pathname.includes('/resources') || 
                          location.pathname.includes('/admin/resources');
  
  const toggleResourcesOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsResourcesOpen(prev => !prev);
  };
  
  const navigateToPublicResources = () => {
    navigate('/resources');
  };
  
  const navigateToResources = () => {
    navigate('/admin/resources');
  };
  
  const navigateToCategories = () => {
    navigate('/admin/resources/categories');
  };
  
  const navigateToKeywords = () => {
    navigate('/admin/resources/keywords');
  };
  
  const navigateToResourcesGenerator = () => {
    // Update this path to match the route in App.tsx
    navigate('/admin/resources/resources-generator');
  };

  const dashboardMenu = useMemo(() => getDashboardMenu(t), [t]);
  const projectsMenu = useMemo(() => getProjectsMenu(t), [t]);
  const resourcesMenu = useMemo(() => getResourcesMenu(t, isAdmin), [t, isAdmin]);
  const settingsMenu = useMemo(() => getSettingsMenu(t), [t]);

  return {
    dashboardMenu,
    projectsMenu,
    resourcesMenu,
    settingsMenu,
    isResourcesOpen,
    isResourcesActive,
    isAdmin,
    toggleResourcesOpen,
    navigateToResources,
    navigateToCategories,
    navigateToPublicResources,
    navigateToKeywords,
    navigateToResourcesGenerator
  };
}

function getDashboardMenu(t: any) {
  return {
    title: t('Dashboard'),
    href: '/',
    icon: Home,
  };
}

function getProjectsMenu(t: any) {
  return [
    {
      title: t('Projects'),
      href: '/projects',
      icon: LayoutDashboard,
    },
    {
      title: t('Tasks'),
      href: '/tasks',
      icon: ListChecks,
    },
    {
      title: t('Calendar'),
      href: '/calendar',
      icon: Calendar,
    },
  ];
}

export function getResourcesMenu(t: any, isAdmin: boolean) {
  let publicLinks = [
    {
      title: t('Resources'),
      href: '/resources',
      icon: FileText
    }
  ];

  let adminLinks = [];
  
  if (isAdmin) {
    adminLinks = [
      {
        title: t('Resources'),
        href: '/admin/resources',
        icon: FileText
      },
      {
        title: t('Categories'),
        href: '/admin/resources/categories',
        icon: Tags
      },
      {
        title: t('Keywords'),
        href: '/admin/keywords',
        icon: Key
      },
      {
        title: t('Generator'),
        href: '/admin/resources/generator',
        icon: Sparkles
      }
    ];
  }

  return {
    title: t('Resources'),
    icon: FileText,
    links: publicLinks,
    adminLinks: adminLinks,
  };
}

function getSettingsMenu(t: any) {
  return {
    title: t('Settings'),
    href: '/settings',
    icon: Settings,
  };
}
