
import React, { memo } from 'react';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';
import { AppRole } from '@/contexts/auth/types';
import UserRoleBadge from '../UserRoleBadge';

interface UserRoleListProps {
  roles: AppRole[];
  onRemoveRole: (role: AppRole) => Promise<void>;
  onRefreshRoles: () => Promise<void>;
  isRefreshing: boolean;
}

// Using memo to prevent unnecessary re-renders
const UserRoleList: React.FC<UserRoleListProps> = memo(({ 
  roles, 
  onRemoveRole, 
  onRefreshRoles, 
  isRefreshing 
}) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium">Current Roles</h3>
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={onRefreshRoles} 
          disabled={isRefreshing}
          title="Refresh roles"
          aria-label="Refresh roles"
        >
          <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
        </Button>
      </div>
      <div className="flex flex-wrap gap-2 mb-4">
        {roles.length ? (
          roles.map(role => (
            <UserRoleBadge
              key={role}
              role={role}
              onRemove={() => onRemoveRole(role)}
            />
          ))
        ) : (
          <p className="text-sm text-muted-foreground">No roles assigned</p>
        )}
      </div>
    </div>
  );
});

// Add display name for better debugging
UserRoleList.displayName = 'UserRoleList';

export default UserRoleList;
