
/**
 * This component doesn't render anything visible, but ensures that
 * audio files are properly referenced in the build.
 * 
 * The gong sound is located in the public directory and will be
 * played when the focus timer reaches zero.
 */

const AudioFiles = {
  gong: '/gong-sound.mp3'
};

export default AudioFiles;
