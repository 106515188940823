
import React, { useMemo } from 'react';
import { useProjects } from '@/hooks/use-projects';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Project } from '@/components/sidebar/sidebar-data';
import { Button } from '@/components/ui/button';
import { ArrowRight, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getDaysRemaining } from '@/hooks/goals/use-goal-formatting';
import ProjectCard from '@/components/ProjectCard';

interface ProjectsSectionProps {
  title: string;
  status: string; // 'active', 'in_progress', etc.
}

const ProjectsSection = ({ title, status }: ProjectsSectionProps) => {
  const { projects, loading, fetchProjects } = useProjects();
  
  const filteredProjects = useMemo(() => {
    // First filter by status
    const statusFiltered = projects.filter(project => project.status === status);
    
    // Then find projects with today's due date or overdue
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const todayProjects = statusFiltered.filter(project => {
      if (!project.due_date) return false;
      
      const dueDate = new Date(project.due_date);
      dueDate.setHours(0, 0, 0, 0);
      
      return dueDate.getTime() === today.getTime();
    });
    
    const overdueProjects = statusFiltered.filter(project => {
      if (!project.due_date) return false;
      
      const daysRemaining = getDaysRemaining(project.due_date);
      return daysRemaining !== null && daysRemaining < 0;
    });
    
    // Combine and sort them - overdue first, then today's
    return [...overdueProjects, ...todayProjects];
  }, [projects, status]);

  if (loading) {
    return (
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-40">
            <div className="animate-pulse text-muted-foreground">Loading projects...</div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (filteredProjects.length === 0) {
    return null; // Don't show empty sections on dashboard
  }

  return (
    <Card className="shadow-md">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="flex items-center text-xl">
          <Calendar className="mr-2 h-5 w-5" />
          <span>{title}</span>
          <span className="ml-2 text-sm text-muted-foreground">
            ({filteredProjects.length})
          </span>
        </CardTitle>
        <Link to="/projects">
          <Button variant="ghost" size="sm" className="text-xs">
            View All <ArrowRight className="ml-1 h-3 w-3" />
          </Button>
        </Link>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {filteredProjects.slice(0, 3).map((project) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description || ''}
              lastUpdated={project.updated_at || ''}
              priority={(project.priority as 'asap' | 'high' | 'average' | 'low') || 'average'}
              status={project.status || 'active'}
              dueDate={project.due_date ? new Date(project.due_date) : undefined}
              tags={project.tags || []}
              project={project}
              onSuccess={fetchProjects}
              isFavorite={project.is_favorite || false}
            />
          ))}
          
          {filteredProjects.length > 3 && (
            <div className="text-center pt-2">
              <Link to="/projects">
                <Button variant="outline" size="sm">
                  View {filteredProjects.length - 3} more projects
                </Button>
              </Link>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectsSection;
