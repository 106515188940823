
import React from 'react';
import { formatDate } from '@/utils/date-utils';

interface ResourceDateProps {
  publishedDate?: string;
  createdDate: string;
}

const ResourceDate: React.FC<ResourceDateProps> = ({ publishedDate, createdDate }) => {
  // Use published date if available, otherwise fall back to created date
  const dateToShow = publishedDate ? publishedDate : createdDate;
  
  return (
    <span className="text-sm">
      {formatDate(dateToShow)}
    </span>
  );
};

export default ResourceDate;
