
import React, { createContext, useContext, useState, useEffect } from 'react';

interface AppVersionContextType {
  version: string;
  updateVersion: (newVersion: string) => void;
}

const AppVersionContext = createContext<AppVersionContextType | undefined>(undefined);

export const AppVersionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [version, setVersion] = useState<string>('1.0.0');

  useEffect(() => {
    // Load version from localStorage on component mount
    const storedVersion = localStorage.getItem('app_version');
    if (storedVersion) {
      setVersion(storedVersion);
    }
  }, []);

  const updateVersion = (newVersion: string) => {
    setVersion(newVersion);
    localStorage.setItem('app_version', newVersion);
  };

  return (
    <AppVersionContext.Provider value={{ version, updateVersion }}>
      {children}
    </AppVersionContext.Provider>
  );
};

export const useAppVersion = (): AppVersionContextType => {
  const context = useContext(AppVersionContext);
  if (context === undefined) {
    throw new Error('useAppVersion must be used within an AppVersionProvider');
  }
  return context;
};
