
import { User } from '@supabase/supabase-js';
import { 
  updateUserTimezone,
  updateUserProfile,
  removeUserRole
} from '@/contexts/auth/api';
import { AppRole, UserProfile } from '@/contexts/auth/types';

export function useProfileActions(
  user: User | null,
  userRoles: AppRole[],
  userProfile: UserProfile | null,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: string | null) => void,
  setUserProfile: (profile: UserProfile | null) => void,
  setUserRoles: (roles: AppRole[]) => void
) {
  const removeRole = async (userId: string, role: AppRole): Promise<void> => {
    setIsLoading(true);
    try {
      const success = await removeUserRole(userId, role);
      
      if (success && user && user.id === userId) {
        const updatedRoles = userRoles.filter(r => r !== role);
        setUserRoles(updatedRoles);
      }
    } catch (error: any) {
      console.error('Error removing role:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTimezone = async (timezone: string): Promise<void> => {
    if (!user) return;

    try {
      await updateUserTimezone(user.id, timezone);
      if (userProfile) {
        setUserProfile({
          ...userProfile,
          timezone
        });
      }
    } catch (error: any) {
      console.error('Error updating timezone:', error);
    }
  };

  const updateProfile = async (data: Partial<UserProfile>): Promise<void> => {
    if (!user || !userProfile) return;

    setIsLoading(true);
    try {
      await updateUserProfile(user.id, data);
      setUserProfile({
        ...userProfile,
        ...data
      });
    } catch (error: any) {
      console.error('Error updating profile:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    removeRole,
    updateTimezone,
    updateProfile
  };
}
