
import { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { Project } from '@/components/sidebar/sidebar-data';
import { useAuth } from '@/contexts/auth';
import { Json } from '@/integrations/supabase/types';
import { Goal } from '@/lib/utils';

export interface CollectionDetails {
  id: string;
  name: string;
  icon: React.ReactNode;
  description: string;
}

export function useProjectCollection(collectionId: string | undefined) {
  const { user } = useAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const isMountedRef = useRef(true);
  const fetchInProgressRef = useRef(false);
  
  const fetchProjects = useCallback(async () => {
    if (!user || !collectionId || fetchInProgressRef.current) return;
    
    try {
      fetchInProgressRef.current = true;
      
      if (isMountedRef.current) {
        setLoading(true);
      }
      
      // Fetch projects for this collection
      const { data: projectsData, error: projectsError } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id)
        .eq('collection_id', collectionId)
        .order('created_at', { ascending: false });
      
      if (projectsError) throw projectsError;
      
      // Fetch all goals for projects in this collection
      let goalsByProject: Record<string, Goal[]> = {};
      
      if (projectsData && projectsData.length > 0) {
        const projectIds = projectsData.map(p => p.id);
        
        const { data: goalsData, error: goalsError } = await supabase
          .from('project_goals')
          .select('*')
          .eq('user_id', user.id)
          .in('project_id', projectIds)
          .order('due_date', { ascending: true });
        
        if (goalsError) {
          console.error('Error fetching goals:', goalsError);
        } else if (goalsData) {
          // Group goals by project_id
          goalsByProject = goalsData.reduce((acc, goal) => {
            if (!acc[goal.project_id]) {
              acc[goal.project_id] = [];
            }
            acc[goal.project_id].push(goal);
            return acc;
          }, {} as Record<string, Goal[]>);
        }
      }
      
      // Transform data to match Project interface
      const transformedProjects = projectsData?.map(project => {
        // Safely cast the priority to the required type
        const priority = ['asap', 'high', 'average', 'low'].includes(project.priority) 
          ? project.priority as 'asap' | 'high' | 'average' | 'low'
          : 'average';
        
        return {
          ...project,
          priority,
          links: Array.isArray(project.links) 
            ? project.links as { url: string; title: string }[]
            : null,
          tags: Array.isArray(project.tags) ? project.tags : null,
          goals: goalsByProject[project.id] || []
        } as Project;
      }) || [];
      
      if (isMountedRef.current) {
        setProjects(transformedProjects);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      if (isMountedRef.current) {
        setLoading(false);
      }
    } finally {
      fetchInProgressRef.current = false;
    }
  }, [collectionId, user]);
  
  useEffect(() => {
    isMountedRef.current = true;
    
    if (user && collectionId) {
      fetchProjects();
    }
    
    return () => {
      isMountedRef.current = false;
    };
  }, [collectionId, user, fetchProjects]);
  
  return {
    projects,
    loading,
    fetchProjects
  };
}
