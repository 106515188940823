
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { CreateHabitPayload, Habit, UpdateHabitPayload } from '@/types/habit';

/**
 * Fetch habits for a user from the database
 */
export async function fetchHabits(userId: string | undefined): Promise<Habit[]> {
  if (!userId) return [];
  
  try {
    const { data, error } = await supabase
      .from('habits')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    // Convert database response to Habit type
    const habits = data.map(item => ({
      ...item,
      links: item.links as { url: string; title: string }[] | null
    }));
    
    return habits as Habit[];
  } catch (error) {
    console.error('Error fetching habits:', error);
    toast.error('Failed to load habits');
    return [];
  }
}

/**
 * Create a new habit in the database
 */
export async function createHabitInDb(habit: CreateHabitPayload, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    const { error } = await supabase
      .from('habits')
      .insert({ ...habit, user_id: userId });

    if (error) throw error;
    
    toast.success('Habit created successfully');
    return true;
  } catch (error) {
    console.error('Error creating habit:', error);
    toast.error('Failed to create habit');
    return false;
  }
}

/**
 * Update an existing habit in the database
 */
export async function updateHabitInDb(habit: UpdateHabitPayload, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    const { error } = await supabase
      .from('habits')
      .update(habit)
      .eq('id', habit.id)
      .eq('user_id', userId);

    if (error) throw error;
    
    toast.success('Habit updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating habit:', error);
    toast.error('Failed to update habit');
    return false;
  }
}

/**
 * Delete a habit from the database
 */
export async function deleteHabitFromDb(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    const { error } = await supabase
      .from('habits')
      .delete()
      .eq('id', habitId)
      .eq('user_id', userId);

    if (error) throw error;
    
    toast.success('Habit deleted successfully');
    return true;
  } catch (error) {
    console.error('Error deleting habit:', error);
    toast.error('Failed to delete habit');
    return false;
  }
}
