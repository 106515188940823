
import React from 'react';
import { Button } from '@/components/ui/button';
import { useDoneForToday } from '@/components/projects/card/useDoneForToday';
import { Project } from '@/components/sidebar/sidebar-data';
import { CheckCircle, Calendar } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { 
  getRecurrenceLabel, 
  getRecurrenceOptions, 
  getPeriodOptions, 
  getDayOfWeekOptions 
} from './dropdowns/recurrence/RecurrenceUtils';
import { CustomRecurrence } from '@/utils/recurrence';
import { Json } from '@/integrations/supabase/types';

interface ProjectDoneForTodayProps {
  project: Project;
  onSuccess?: () => void;
}

const ProjectDoneForToday: React.FC<ProjectDoneForTodayProps> = ({
  project,
  onSuccess
}) => {
  const { t } = useTranslation();
  const { handleDoneForToday, isUpdating } = useDoneForToday(project, onSuccess);

  // Only show the button for active projects or recurring projects
  if (project.status === 'completed' && project.recurrence === 'none') {
    return null;
  }

  const isRecurring = project.recurrence !== 'none' && project.recurrence !== null && project.recurrence !== undefined;
  
  // Setup options for recurrence label (if needed)
  const recurrenceOptions = getRecurrenceOptions(t);
  const periodOptions = getPeriodOptions(t);
  const dayOfWeekOptions = getDayOfWeekOptions(t);

  // Function to safely cast custom_recurrence to CustomRecurrence
  const safeCustomRecurrence = (project.recurrence === 'custom' && project.custom_recurrence) ? 
    (typeof project.custom_recurrence === 'object' && 
     'period' in project.custom_recurrence && 
     'every' in project.custom_recurrence) ? 
      project.custom_recurrence : null : null;
  
  // Get a readable recurrence label
  const recurrenceLabel = isRecurring && project.recurrence === 'custom' && safeCustomRecurrence 
    ? getRecurrenceLabel(project.recurrence, safeCustomRecurrence, recurrenceOptions, dayOfWeekOptions, periodOptions, t)
    : '';
  
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={handleDoneForToday}
            disabled={isUpdating}
            className="w-full sm:w-auto"
            size="sm"
          >
            {isRecurring ? (
              <>
                <Calendar className="mr-1 h-4 w-4" />
                {isUpdating ? 'Updating...' : 'Schedule Next'}
              </>
            ) : (
              <>
                <CheckCircle className="mr-1 h-4 w-4" />
                {isUpdating ? 'Updating...' : 'Done for Today'}
              </>
            )}
          </Button>
        </TooltipTrigger>
        {isRecurring && (
          <TooltipContent>
            <p>
              {project.recurrence === 'custom' 
                ? recurrenceLabel 
                : t(`projects.recurrence.${project.recurrence}`, project.recurrence)}
            </p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default ProjectDoneForToday;
