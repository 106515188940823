
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

/**
 * Delete a user and all their associated data
 * This is an admin-only function that completely removes a user account and all related data
 */
export const deleteUser = async (userId: string): Promise<boolean> => {
  try {
    // First verify this isn't the super admin account
    const { data: userData, error: userError } = await supabase
      .from('profiles')
      .select('email')
      .eq('id', userId)
      .single();
    
    if (userError) throw userError;
    
    // Block deletion of super admin account
    if (userData?.email === 'davidezanon5@gmail.com') {
      toast.error("Cannot delete the primary administrator account");
      return false;
    }

    // Use a Supabase function to delete the user account and all associated data
    // This ensures the deletion happens atomically in a transaction
    const { error: deleteError } = await supabase
      .rpc('delete_user_account', { _user_id: userId });
    
    if (deleteError) throw deleteError;
    
    toast.success("User account deleted successfully");
    return true;
  } catch (error: any) {
    console.error('Error deleting user account:', error);
    toast.error(`Failed to delete user account: ${error.message}`);
    return false;
  }
};
