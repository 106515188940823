
import React, { useEffect, useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import { useTheme } from '@/components/theme-provider';
import { useIsMobile } from '@/hooks/use-mobile';
import { trackEvent } from '@/hooks/use-analytics';
import { useNavigate } from 'react-router-dom';

/**
 * Hero section component displayed on the homepage.
 * 
 * Features:
 * - Responsive design with tailored styles for mobile and desktop
 * - Animated entrance effects for key elements
 * - Theme-aware styling that adapts to light/dark mode
 * - Gradient background effects
 * - Call-to-action buttons
 * - Improved contrast for accessibility
 * - Analytics tracking for button clicks
 */
const Hero: React.FC = () => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { theme } = useTheme();
  const isLightMode = theme === 'light';
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  
  /**
   * Sets up intersection observer for fade-in animation
   * when the title element comes into view
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );
    
    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    
    return () => {
      if (titleRef.current) observer.unobserve(titleRef.current);
    };
  }, []);

  // Track button clicks for analytics
  const handleStartNowClick = () => {
    trackEvent('hero_cta_click', { button: 'start_now' });
    navigate('/auth');
  };

  const handleLearnMoreClick = () => {
    trackEvent('hero_cta_click', { button: 'learn_more' });
    
    // Scroll to the Key Features section
    const featuresSection = document.getElementById('key-features-section');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative pt-24 md:pt-32 pb-16 md:pb-20 px-5 md:px-6 overflow-hidden">
      {/* Background gradient elements - adjusted for light/dark mode with improved contrast */}
      <div className={`absolute top-20 left-0 w-72 sm:w-96 h-72 sm:h-96 ${isLightMode ? 'bg-indigo-600/15' : 'bg-blue-500/30'} rounded-full filter blur-3xl opacity-20`} />
      <div className={`absolute bottom-0 right-6 sm:right-10 w-64 sm:w-80 h-64 sm:h-80 ${isLightMode ? 'bg-purple-600/15' : 'bg-indigo-500/30'} rounded-full filter blur-3xl opacity-20`} />
      
      <div className="max-w-6xl mx-auto relative z-10">
        <div className="text-center space-y-6 md:space-y-8">
          {/* Announcement badge with improved contrast */}
          <div className="inline-block">
            <div className={`${isLightMode ? 'bg-indigo-100 text-indigo-900' : 'bg-blue-900/60 text-blue-50'} px-3 py-1 rounded-full text-xs font-medium tracking-wide mb-5 md:mb-6 animate-fade-in`}>
              THE MOST INNOVATIVE PLATFORM FOR YOUR PROJECTS
            </div>
          </div>
          
          {/* Main heading with animation and improved contrast */}
          <h1 
            ref={titleRef}
            className={`text-3xl sm:text-4xl md:text-6xl lg:text-7xl font-bold tracking-tight ${isLightMode ? 'text-gray-900' : 'text-white'} opacity-0`}
            style={{animationDelay: '0.2s', animationFillMode: 'forwards'}}
          >
            Manage your projects <br className="hidden sm:block" />
            style <span className={isLightMode ? 'text-indigo-700' : 'text-blue-200'}>hacker</span>
          </h1>
          
          {/* Description paragraph with animation and improved contrast */}
          <p 
            className={`${isLightMode ? 'text-gray-700' : 'text-gray-200'} text-base md:text-lg lg:text-xl max-w-3xl mx-auto opacity-0 animate-fade-in px-2`}
            style={{animationDelay: '0.4s', animationFillMode: 'forwards'}}
          >
            Myhacks.org is the ultimate solution for those seeking speed, efficiency, and an elegant interface for managing projects and tasks.
          </p>
          
          {/* Call-to-action buttons with animation and improved contrast */}
          <div 
            className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-4 pt-2 sm:pt-4 opacity-0 animate-fade-in"
            style={{animationDelay: '0.6s', animationFillMode: 'forwards'}}
          >
            <button 
              onClick={handleStartNowClick}
              className={`group px-5 sm:px-6 py-3 ${isLightMode ? 'bg-indigo-700 hover:bg-indigo-800' : 'bg-blue-700 hover:bg-blue-800'} text-white rounded-md font-medium transition-all focus-ring flex items-center gap-2 w-full sm:w-auto justify-center`}
            >
              Start Now
              <ArrowRight className="h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </button>
            <button 
              onClick={handleLearnMoreClick}
              className={`px-5 sm:px-6 py-3 ${isLightMode ? 'bg-white/90 hover:bg-white border border-gray-300 text-gray-900' : 'glass hover:bg-white/15 text-gray-100'} rounded-md font-medium transition-all focus-ring w-full sm:w-auto`}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
