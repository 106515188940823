
import { useResourcesQuery, useResourceBySlugQuery } from './resources/use-resources-query';
import { useCategoriesQuery } from './resources/use-categories-query';
import { useResourceMutations } from './resources/use-resource-mutations';
import { useCategoryMutations } from './resources/use-category-mutations';
import { useResourceCategoryMappings } from './resources/use-resource-category-mappings';

export const useResources = (language = 'en') => {
  const { 
    useCreateResourceMutation,
    useUpdateResourceMutation,
    useDeleteResourceMutation 
  } = useResourceMutations();

  const { 
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation 
  } = useCategoryMutations();

  const {
    useAddCategoryToResourceMutation,
    useRemoveCategoryFromResourceMutation
  } = useResourceCategoryMappings();

  return {
    useResourcesQuery: (status?: any, categoryId?: string) => 
      useResourcesQuery(status, categoryId, language),
    useResourceBySlugQuery,
    useCategoriesQuery,
    useCreateResourceMutation,
    useUpdateResourceMutation,
    useDeleteResourceMutation,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useAddCategoryToResourceMutation,
    useRemoveCategoryFromResourceMutation
  };
};
