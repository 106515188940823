
import React, { useState, useRef, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface EditableContentProps {
  content: string | null;
  onUpdate?: (newContent: string) => Promise<void>;
  placeholder?: string;
  inputClassName?: string;
  label?: string;
  editButtonText?: string;
  saveButtonText?: string;
  cancelButtonText?: string;
  type?: 'text' | 'textarea' | 'markdown';
  
  projectId?: string;
  fieldName?: string;
  tableName?: 'projects' | 'tasks' | 'project_goals' | 'habits' | 'notes';
  placeholderText?: string;
  emptyText?: string;
  onContentUpdated?: () => void;
}

const EditableContent: React.FC<EditableContentProps> = ({
  content,
  onUpdate,
  placeholder = 'Click to add content',
  inputClassName,
  label,
  editButtonText = 'Edit',
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
  type = 'text',
  
  projectId,
  fieldName,
  tableName,
  placeholderText,
  emptyText,
  onContentUpdated,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content || '');
  const [isUpdating, setIsUpdating] = useState(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setEditedContent(content || '');
  }, [content]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedContent(content || '');
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (editedContent === content) {
      setIsEditing(false);
      return;
    }

    setIsUpdating(true);
    
    try {
      if (onUpdate) {
        await onUpdate(editedContent);
      }
      else if (projectId && fieldName && tableName) {
        const { error } = await supabase
          .from(tableName)
          .update({ [fieldName]: editedContent })
          .eq('id', projectId);
          
        if (error) throw error;
        
        if (onContentUpdated) {
          onContentUpdated();
        }
      } 
      
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update content:', error);
      toast.error('Failed to update content');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleCancel();
    } else if (e.key === 'Enter' && !e.shiftKey && type !== 'textarea') {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div className="relative group">
      {label && <div className="text-sm font-medium mb-1.5">{label}</div>}
      
      {isEditing ? (
        <div className="space-y-2">
          {type === 'textarea' ? (
            <textarea
              ref={inputRef as React.RefObject<HTMLTextAreaElement>}
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              onKeyDown={handleKeyDown}
              className={cn(
                "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50",
                inputClassName
              )}
              placeholder={placeholderText || placeholder}
              rows={3}
            />
          ) : (
            <input
              ref={inputRef as React.RefObject<HTMLInputElement>}
              type="text"
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              onKeyDown={handleKeyDown}
              className={cn(
                "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50",
                inputClassName
              )}
              placeholder={placeholderText || placeholder}
            />
          )}
          
          <div className="flex space-x-2 mt-2">
            <button
              onClick={handleSave}
              disabled={isUpdating}
              className="text-sm bg-primary text-primary-foreground px-3 py-1 rounded-md hover:bg-primary/90 
                transition-colors flex items-center gap-1.5"
            >
              {isUpdating && <Loader2 className="h-3 w-3 animate-spin" />}
              {saveButtonText}
            </button>
            <button
              onClick={handleCancel}
              className="text-sm bg-secondary text-secondary-foreground px-3 py-1 rounded-md 
                hover:bg-secondary/90 transition-colors"
            >
              {cancelButtonText}
            </button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div
            onClick={handleEdit}
            className={cn(
              "cursor-pointer hover:bg-muted/50 rounded px-2 py-1 -ml-2",
              !content && "text-muted-foreground italic"
            )}
          >
            {content || (emptyText || placeholder)}
          </div>
          <button
            onClick={handleEdit}
            className="absolute right-0 top-0 opacity-0 group-hover:opacity-100 
              text-xs text-muted-foreground hover:text-foreground transition-opacity"
          >
            {editButtonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default EditableContent;
