
import React from 'react';
import { useParams } from 'react-router-dom';
import ResourceForm from '@/components/admin/resources/ResourceForm';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useResourceForm } from '@/hooks/resources/use-resource-form';
import { useMetadata } from '@/hooks/use-metadata';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';

const AdminResourceForm: React.FC = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);
  
  const title = isEditing ? 'Edit Resource' : 'Create Resource';
  useMetadata({
    title,
    description: isEditing 
      ? 'Edit an existing learning resource.' 
      : 'Create a new learning resource for your platform.',
  });
  
  const {
    defaultValues,
    handleSubmit,
    handleCategoryChange,
    handleGenerateSlug,
    selectedCategories,
    categories,
    isSubmitting,
    language,
    loading,
    error
  } = useResourceForm();

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <h1 className="text-2xl font-bold mb-6">{title}</h1>
          
          {loading && (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="w-6 h-6 animate-spin mr-2" />
              <span>Loading resource data...</span>
            </div>
          )}
          
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {!loading && !error && (
            <ResourceForm
              defaultValues={defaultValues}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              onGenerateSlug={handleGenerateSlug}
              selectedCategories={selectedCategories}
              onCategoryChange={handleCategoryChange}
              categories={categories}
              language={language}
            />
          )}
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminResourceForm;
