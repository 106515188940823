
import React from 'react';
import { Button } from '@/components/ui/button';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';

interface HtmlTemplateAccordionProps {
  htmlSamples: Record<string, string>;
  onInsertTemplate: (template: string) => void;
}

const HtmlTemplateAccordion: React.FC<HtmlTemplateAccordionProps> = ({ 
  htmlSamples, 
  onInsertTemplate 
}) => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="html-help">
        <AccordionTrigger>HTML Templates</AccordionTrigger>
        <AccordionContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.headers)}
            >
              Insert Headers
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.emphasis)}
            >
              Insert Emphasis
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.lists)}
            >
              Insert Lists
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.links)}
            >
              Insert Links
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.tables)}
            >
              Insert Table
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.custom)}
            >
              Insert Custom Block
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default HtmlTemplateAccordion;
