
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { supabase } from '@/integrations/supabase/client';
import { useResources } from '@/hooks/use-resources';
import { convertJsonToStringRecord } from '@/utils/json-utils';
import { generateSlug } from '@/utils/slug-utils';
import { useAuth } from '@/contexts/AuthContext';

export interface CategoryFormValues {
  name: string;
  slug: string;
  description: string;
}

export const useCategoryForm = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const isEditing = !!id;
  const language = i18n.language || 'en';
  const [availableLanguages] = useState(['en', 'it']);
  const [defaultValues, setDefaultValues] = useState<CategoryFormValues>({
    name: '',
    slug: '',
    description: '',
  });

  const { 
    useCreateCategoryMutation, 
    useUpdateCategoryMutation
  } = useResources();
  
  const createMutation = useCreateCategoryMutation();
  const updateMutation = useUpdateCategoryMutation();
  
  useEffect(() => {
    const fetchCategory = async () => {
      if (id) {
        try {
          const { data: category, error } = await supabase
            .from('resource_categories')
            .select('*')
            .eq('id', id)
            .single();

          if (error) throw error;

          // Convert JSON fields to string records and extract current language value
          const nameRecord = convertJsonToStringRecord(category.name);
          const descriptionRecord = convertJsonToStringRecord(category.description);

          setDefaultValues({
            name: nameRecord[language] || '',
            slug: category.slug,
            description: descriptionRecord[language] || '',
          });
        } catch (error) {
          console.error('Error fetching category:', error);
        }
      }
    };

    fetchCategory();
  }, [id, language]);

  const handleSubmit = async (data: CategoryFormValues) => {
    if (!user) {
      console.error('No authenticated user found');
      return;
    }

    try {
      if (isEditing) {
        // First, get the current category to preserve multilingual content
        const { data: existingCategory, error: fetchError } = await supabase
          .from('resource_categories')
          .select('*')
          .eq('id', id)
          .single();
  
        if (fetchError) throw fetchError;
  
        // Initialize empty objects if they don't exist or aren't objects
        const currentName = (typeof existingCategory.name === 'object' && existingCategory.name && !Array.isArray(existingCategory.name)) ? 
          existingCategory.name : {};
        const currentDescription = (typeof existingCategory.description === 'object' && existingCategory.description && !Array.isArray(existingCategory.description)) ? 
          existingCategory.description : {};
  
        // Safely create the multilingual objects
        const name = { ...currentName, [language]: data.name } as Record<string, string>;
        const description = { ...currentDescription, [language]: data.description } as Record<string, string>;
  
        // Update the category
        await updateMutation.mutateAsync({
          id,
          category: {
            name,
            slug: data.slug,
            description,
          },
        });
  
        navigate('/admin/resources/categories');
      } else {
        // Create new category
        const name = { [language]: data.name } as Record<string, string>;
        const description = { [language]: data.description } as Record<string, string>;
  
        await createMutation.mutateAsync({
          name,
          slug: data.slug,
          description,
        });
  
        navigate('/admin/resources/categories');
      }
    } catch (error: any) {
      console.error('Error saving category:', error.message);
    }
  };

  const handleGenerateSlug = (title: string) => {
    return generateSlug(title);
  };

  return {
    isEditing,
    defaultValues,
    handleSubmit,
    handleGenerateSlug,
    availableLanguages,
    isSubmitting: createMutation.isPending || updateMutation.isPending
  };
};
