
import React from 'react';
import { Plus, Calendar } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface HabitsEmptyStateProps {
  isLoading: boolean;
  onCreateNew: () => void;
}

export function HabitsEmptyState({ isLoading, onCreateNew }: HabitsEmptyStateProps) {
  if (isLoading) {
    return (
      <div className="bg-card/60 rounded-lg p-8 text-center">
        <p className="text-muted-foreground animate-pulse">Loading habits...</p>
      </div>
    );
  }

  return (
    <div className="bg-card/60 border rounded-lg p-8 text-center">
      <Calendar className="h-16 w-16 mx-auto mb-4 text-primary/40" />
      <p className="text-muted-foreground mb-4">
        You haven't created any habits yet.
      </p>
      <Button 
        variant="outline" 
        onClick={onCreateNew}
        className="mx-auto"
      >
        <Plus className="h-4 w-4 mr-2" />
        Create Habit
      </Button>
    </div>
  );
}
