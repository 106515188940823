
import React from 'react';
import { 
  FormField, 
  FormItem, 
  FormLabel, 
  FormControl, 
  FormDescription, 
  FormMessage 
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { UseFormReturn } from 'react-hook-form';
import { OpenAIConfig } from '@/services/resources/openai-config-service';

interface AuthorProfileTabProps {
  form: UseFormReturn<OpenAIConfig>;
  defaultAuthorProfile: string;
}

const AuthorProfileTab: React.FC<AuthorProfileTabProps> = ({ form, defaultAuthorProfile }) => {
  return (
    <FormField
      control={form.control}
      name="author_profile"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Author Profile</FormLabel>
          <FormControl>
            <Textarea
              placeholder="Define the personality and tone of the AI author..."
              className="min-h-[300px] font-mono text-sm"
              {...field}
              value={field.value || defaultAuthorProfile}
            />
          </FormControl>
          <FormDescription>
            <p>Define the personality, expertise level, and writing style that will be used when generating content.</p>
            <div className="p-3 bg-blue-50 border border-blue-200 rounded-md mt-2">
              <h4 className="font-medium text-sm mb-1 text-blue-800">💡 Tips</h4>
              <ul className="text-xs list-disc pl-4 text-blue-700 space-y-1">
                <li>Define the expertise level (beginner, expert, etc.)</li>
                <li>Specify the tone (formal, conversational, technical)</li>
                <li>Mention any special writing styles or approaches</li>
                <li>Include instructions on how to address the reader</li>
              </ul>
            </div>
            <p className="mt-2 text-xs">Use <code>{'{keyword}'}</code> as a placeholder for the keyword value in your author profile.</p>
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default AuthorProfileTab;
