
import { DayOfWeekOption, PeriodOption, RecurrenceOption } from '../recurrence-types';
import { TranslationFunction } from './recurrence-types';

export const getRecurrenceOptions = (t: TranslationFunction): RecurrenceOption[] => [
  { value: 'none', label: t('projects.recurrence.none', 'None') },
  { value: 'daily', label: t('projects.recurrence.daily', 'Daily') },
  { value: 'weekdays', label: t('projects.recurrence.weekdays', 'Weekdays') },
  { value: 'weekends', label: t('projects.recurrence.weekends', 'Weekends') },
  { value: 'weekly', label: t('projects.recurrence.weekly', 'Weekly') },
  { value: 'monthly', label: t('projects.recurrence.monthly', 'Monthly') },
  { value: 'yearly', label: t('projects.recurrence.yearly', 'Yearly') },
  { value: 'custom', label: t('projects.recurrence.custom', 'Custom') }
];

export const getPeriodOptions = (t: TranslationFunction): PeriodOption[] => [
  { value: 'days', label: t('projects.recurrence.days', 'Days') },
  { value: 'weeks', label: t('projects.recurrence.periodWeeks', 'Weeks') },
  { value: 'months', label: t('projects.recurrence.periodMonths', 'Months') },
  { value: 'years', label: t('projects.recurrence.periodYears', 'Years') }
];

export const getDayOfWeekOptions = (t: TranslationFunction): DayOfWeekOption[] => [
  { value: 'monday', label: t('days.monday', 'Monday') },
  { value: 'tuesday', label: t('days.tuesday', 'Tuesday') },
  { value: 'wednesday', label: t('days.wednesday', 'Wednesday') },
  { value: 'thursday', label: t('days.thursday', 'Thursday') },
  { value: 'friday', label: t('days.friday', 'Friday') },
  { value: 'saturday', label: t('days.saturday', 'Saturday') },
  { value: 'sunday', label: t('days.sunday', 'Sunday') }
];
