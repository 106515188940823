
import React from 'react';
import { Task, CreateTaskPayload, UpdateTaskPayload } from '@/types/task';
import TaskItem from './TaskItem';

interface TaskListProps {
  tasks: Task[];
  onUpdateTask: (task: UpdateTaskPayload) => Promise<boolean>;
  onDeleteTask: (taskId: string) => Promise<boolean>;
  onToggleExpansion: (taskId: string) => void;
  onCreateSubtask: (task: CreateTaskPayload) => Promise<Task | null>;
  projectId: string;
}

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  onUpdateTask,
  onDeleteTask,
  onToggleExpansion,
  onCreateSubtask,
  projectId
}) => {
  if (tasks.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        No tasks found. Add a task to get started.
      </div>
    );
  }
  
  // Sort tasks by priority, status, and completion
  const sortTasks = (tasksList: Task[]): Task[] => {
    // Priority order: asap > high > average > low
    const priorityOrder: Record<string, number> = {
      'asap': 0,
      'high': 1,
      'average': 2,
      'low': 3
    };
    
    return [...tasksList].sort((a, b) => {
      // First put completed tasks at the bottom
      if (a.status === 'completed' && b.status !== 'completed') return 1;
      if (a.status !== 'completed' && b.status === 'completed') return -1;
      
      // If both are completed or both are not completed, sort by status
      if (a.status !== b.status) {
        // Status order: active > in_progress > queue
        const statusOrder: Record<string, number> = {
          'active': 0,
          'in_progress': 1,
          'queue': 2
        };
        return statusOrder[a.status] - statusOrder[b.status];
      }
      
      // Then sort by priority
      const priorityDiff = priorityOrder[a.priority] - priorityOrder[b.priority];
      if (priorityDiff !== 0) return priorityDiff;
      
      // Finally sort by position
      return a.position - b.position;
    }).map(task => {
      // Also sort subtasks if they exist
      if (task.subtasks && task.subtasks.length > 0) {
        return {
          ...task,
          subtasks: sortTasks(task.subtasks)
        };
      }
      return task;
    });
  };
  
  const sortedTasks = sortTasks(tasks);
  
  return (
    <div className="space-y-1">
      {sortedTasks.map(task => (
        <TaskItem
          key={task.id}
          task={task}
          level={0}
          onUpdateTask={onUpdateTask}
          onDeleteTask={onDeleteTask}
          onToggleExpansion={onToggleExpansion}
          onCreateSubtask={onCreateSubtask}
          projectId={projectId}
        />
      ))}
    </div>
  );
};

export default TaskList;
