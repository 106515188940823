
import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface HabitsHeaderProps {
  onCreateNew: () => void;
  showAllDays: boolean;
  onToggleShowAllDays: () => void;
}

export function HabitsHeader({
  onCreateNew,
  showAllDays,
  onToggleShowAllDays
}: HabitsHeaderProps) {
  return (
    <div className="flex items-center justify-between mb-6">
      <div className="flex items-center">
        <h1 className="text-3xl font-bold">My Habits</h1>
      </div>
      <div className="flex items-center gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={onToggleShowAllDays}
                className={showAllDays ? "bg-primary/10" : ""}
              >
                {showAllDays ? "Show Today's Habits" : "Show All Habits"}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                {showAllDays 
                  ? "Only show habits scheduled for today's day of the week" 
                  : "Show all habits, regardless of scheduled day"
                }
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Button onClick={onCreateNew}>
          <Plus className="h-4 w-4 mr-2" />
          New Habit
        </Button>
      </div>
    </div>
  );
}
