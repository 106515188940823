
import { supabase } from '@/integrations/supabase/client';

export interface OpenAIConfig {
  id?: string;
  model: string;
  temperature: number;
  max_tokens: number;
  resource_template: string | null;
  author_profile: string | null;
}

export async function getOpenAIConfig(): Promise<OpenAIConfig> {
  try {
    const { data, error } = await supabase.rpc('get_openai_config');

    if (error) throw error;
    
    // If no config exists yet, return default values
    if (!data || data.length === 0) {
      return {
        model: 'gpt-4o',
        temperature: 0.7,
        max_tokens: 2048,
        resource_template: null,
        author_profile: null
      };
    }

    // Add author_profile if missing from data
    if (data[0] && !data[0].author_profile) {
      data[0].author_profile = null;
    }

    return data[0];
  } catch (error: any) {
    console.error('Error fetching OpenAI config:', error);
    throw error;
  }
}

export async function saveOpenAIConfig(config: OpenAIConfig): Promise<void> {
  try {
    // Check if we already have a config
    const { data: existingConfig } = await supabase
      .from('openai_config')
      .select('id')
      .limit(1);

    if (existingConfig && existingConfig.length > 0) {
      // Update existing config
      const { error } = await supabase
        .from('openai_config')
        .update({
          model: config.model,
          temperature: config.temperature,
          max_tokens: config.max_tokens,
          resource_template: config.resource_template,
          author_profile: config.author_profile
        })
        .eq('id', existingConfig[0].id);

      if (error) throw error;
    } else {
      // Insert new config
      const { error } = await supabase
        .from('openai_config')
        .insert({
          model: config.model,
          temperature: config.temperature,
          max_tokens: config.max_tokens,
          resource_template: config.resource_template,
          author_profile: config.author_profile
        });

      if (error) throw error;
    }
  } catch (error: any) {
    console.error('Error saving OpenAI config:', error);
    throw error;
  }
}
