
import React, { useState } from 'react';
import { Task } from '@/types/task';
import { AlertCircle, Clock, ChevronDown, ChevronUp } from 'lucide-react';

interface FocusTaskDetailsProps {
  task: Task;
}

const FocusTaskDetails: React.FC<FocusTaskDetailsProps> = ({ task }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  
  // Check if the description needs to be truncated
  const needsShowMore = task.description && (
    task.description.split('\n').length > 2 || 
    task.description.length > 150
  );
  
  // Get truncated description
  const getTruncatedDescription = () => {
    if (!task.description) return '';
    
    const lines = task.description.split('\n');
    if (lines.length > 2) {
      return lines.slice(0, 2).join('\n') + '...';
    }
    
    return task.description.length > 150 
      ? task.description.substring(0, 150) + '...' 
      : task.description;
  };
  
  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-semibold">{task.title}</h2>
      
      {task.description && (
        <div className="relative">
          <p className="text-muted-foreground whitespace-pre-line">
            {showFullDescription || !needsShowMore 
              ? task.description 
              : getTruncatedDescription()}
          </p>
          
          {needsShowMore && (
            <button 
              onClick={() => setShowFullDescription(!showFullDescription)}
              className="text-primary flex items-center text-sm mt-1 hover:underline"
            >
              {showFullDescription ? (
                <>Show less <ChevronUp className="h-4 w-4 ml-1" /></>
              ) : (
                <>Show more <ChevronDown className="h-4 w-4 ml-1" /></>
              )}
            </button>
          )}
        </div>
      )}
      
      {task.notes && (
        <div className="flex items-start gap-2 text-sm bg-muted/20 p-3 rounded-md">
          <AlertCircle className="h-4 w-4 mt-0.5 flex-shrink-0" />
          <p>{task.notes}</p>
        </div>
      )}
      
      <div className="flex items-center gap-2 text-sm text-muted-foreground">
        <Clock className="h-4 w-4" />
        <span>Estimated time: {task.estimated_minutes} minutes</span>
      </div>
    </div>
  );
};

export default FocusTaskDetails;
