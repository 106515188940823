
import React from 'react';
import { useForm } from 'react-hook-form';
import { Save } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '@/components/ui/form';
import { toast } from '@/hooks/use-toast';

export interface CategoryFormValues {
  name: string;
  slug: string;
  description: string;
}

interface CategoryFormProps {
  defaultValues: CategoryFormValues;
  onSubmit: (data: CategoryFormValues) => void;
  isSubmitting: boolean;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ 
  defaultValues, 
  onSubmit, 
  isSubmitting 
}) => {
  const form = useForm<CategoryFormValues>({
    defaultValues
  });

  const handleGenerateSlug = () => {
    const name = form.getValues('name');
    if (!name) {
      toast({
        title: "Cannot generate slug",
        description: "Please enter a name first",
        variant: "destructive"
      });
      return;
    }
    
    const slug = name
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .trim();
    
    form.setValue('slug', slug);
  };

  const handleFormSubmit = async (data: CategoryFormValues) => {
    if (!data.slug) {
      toast({
        title: "Slug required",
        description: "Please generate or enter a slug",
        variant: "destructive" 
      });
      return;
    }
    
    try {
      await onSubmit(data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-6" onSubmit={form.handleSubmit(handleFormSubmit)}>
        <div className="grid gap-4 md:grid-cols-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex gap-2 items-end">
            <FormField
              control={form.control}
              name="slug"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Slug</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button 
              type="button" 
              variant="outline"
              onClick={handleGenerateSlug}
              className="mb-0.5"
            >
              Generate
            </Button>
          </div>
        </div>

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end">
          <Button 
            type="submit" 
            disabled={isSubmitting}
          >
            <Save className="h-4 w-4 mr-2" />
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CategoryForm;
