
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronRight, FileText } from 'lucide-react';
import { 
  SidebarMenuSubItem, 
  SidebarMenuSubButton 
} from '@/components/ui/sidebar';
import { Project } from './sidebar-data';

interface SidebarCollectionItemProps {
  id: string;
  name: string;
  path: string;
  isActive: boolean;
  isExpanded: boolean;
  toggleCollection: (id: string, event?: React.MouseEvent) => void;
  projects: Project[];
  navigateToProject: (id: string, title: string) => void;
}

export function SidebarCollectionItem({
  id,
  name,
  path,
  isActive,
  isExpanded,
  toggleCollection,
  projects,
  navigateToProject
}: SidebarCollectionItemProps) {
  const navigate = useNavigate();
  
  return (
    <SidebarMenuSubItem key={id}>
      <SidebarMenuSubButton
        onClick={() => {
          navigate(path);
        }}
        isActive={isActive}
        className="w-full justify-between pl-6 py-2.5 min-h-[2.25rem]"
      >
        <span className="line-clamp-2 mr-2 text-sm">{name}</span>
        {projects.length > 0 && (
          isExpanded ? (
            <ChevronDown 
              className="h-3 w-3 flex-shrink-0" 
              onClick={(e) => toggleCollection(id, e)} 
            />
          ) : (
            <ChevronRight 
              className="h-3 w-3 flex-shrink-0" 
              onClick={(e) => toggleCollection(id, e)} 
            />
          )
        )}
      </SidebarMenuSubButton>
      
      {/* Projects under this collection */}
      {isExpanded && projects.map((project) => (
        <SidebarMenuSubButton
          key={project.id}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            navigateToProject(project.id, project.title);
          }}
          className="pl-10 py-2 text-xs flex items-center gap-1.5 min-h-[1.75rem]"
        >
          <FileText className="h-3 w-3 flex-shrink-0" />
          <span className="line-clamp-2">{project.title}</span>
        </SidebarMenuSubButton>
      ))}
    </SidebarMenuSubItem>
  );
}
