
import React from 'react';
import { Habit } from '@/types/habit';
import { HabitTitle } from '../edit/HabitTitle';
import { HabitLastCompleted } from './HabitLastCompleted';
import { HabitStreak } from './HabitStreak';
import { HabitTags } from './HabitTags';
import { HabitDaysOfWeek } from './HabitDaysOfWeek';

interface HabitContentSectionProps {
  habit: Habit;
  isCompleted: boolean;
  onEditTitle: (newTitle: string) => Promise<void>;
}

export function HabitContentSection({ habit, isCompleted, onEditTitle }: HabitContentSectionProps) {
  return (
    <div className="flex-1 min-w-0">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <HabitTitle 
          title={habit.title} 
          isCompleted={isCompleted} 
          habitId={habit.id}
          onEditTitle={onEditTitle}
        />
      </div>
      
      <HabitLastCompleted lastCompleted={habit.last_completed} />
      <HabitStreak streakCount={habit.streak_count} />
      <HabitTags tags={habit.tags} />
      <HabitDaysOfWeek days={habit.days_of_week} />
    </div>
  );
}
