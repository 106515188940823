
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook to track page views in Google Analytics
 * for single-page applications (SPAs).
 * 
 * This hook tracks page views when the route changes
 * by sending events to Google Analytics.
 */
export const useAnalytics = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
        send_to: 'G-VTH5NHS6QG'
      });
    }
  }, [location]);
};

/**
 * Track custom events in Google Analytics
 * 
 * @param eventName - The name of the event to track
 * @param eventParams - Additional parameters to include with the event
 */
export const trackEvent = (eventName: string, eventParams?: Record<string, any>) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', eventName, eventParams);
  }
};

// Declare gtag for TypeScript
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
