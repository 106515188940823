
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';

export function useSidebarPrompts() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPromptsOpen, setIsPromptsOpen] = useState(false);
  
  const isPromptsActive = location.pathname.startsWith('/prompts');

  const togglePromptsOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsPromptsOpen(!isPromptsOpen);
  };

  const navigateToPrompts = () => {
    navigate('/prompts');
  };

  return {
    isPromptsOpen,
    setIsPromptsOpen,
    isPromptsActive,
    togglePromptsOpen,
    navigateToPrompts
  };
}
