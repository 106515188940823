
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface CollectionHeaderProps {
  icon: React.ReactNode;
  name: string;
  description: string;
}

const CollectionHeader: React.FC<CollectionHeaderProps> = ({ 
  icon, name, description 
}) => {
  const navigate = useNavigate();
  
  return (
    <div className="mb-6 md:mb-8">
      <div className="flex items-center gap-3 mb-1">
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={() => navigate('/projects')}
          className="mr-2 -ml-2"
        >
          <ArrowLeft className="mr-1 h-4 w-4" />
          Back
        </Button>
      </div>
      
      <div className="flex items-center gap-3">
        <div className="h-10 w-10 flex items-center justify-center rounded-md bg-blue-500/20 text-blue-400">
          {icon}
        </div>
        <div>
          <h1 className="text-2xl md:text-3xl font-bold">{name}</h1>
          <p className="text-muted-foreground">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CollectionHeader;
