
import React from 'react';
import { ExternalLink } from 'lucide-react';
import { LinkItem } from '@/components/projects/form-fields/links/types';
import { Badge } from '@/components/ui/badge';

interface TaskLinksProps {
  links?: LinkItem[];
}

const TaskLinks: React.FC<TaskLinksProps> = ({ links }) => {
  if (!links || links.length === 0) {
    return null;
  }
  
  return (
    <div className="flex flex-wrap gap-1 mt-1">
      {links.map((link, index) => (
        <a 
          key={`${link.url}-${index}`}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline"
        >
          <Badge 
            variant="outline" 
            className="flex items-center gap-1 px-2 py-0.5 text-xs text-muted-foreground hover:bg-muted/50 transition-colors cursor-pointer"
          >
            <ExternalLink className="h-3 w-3" />
            {link.title || 'Link'}
          </Badge>
        </a>
      ))}
    </div>
  );
};

export default TaskLinks;
