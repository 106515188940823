
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { AlertTriangle } from 'lucide-react';

const ResourceNotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center p-12 space-y-4">
      <div className="rounded-full bg-amber-100 p-4 mb-2">
        <AlertTriangle className="h-8 w-8 text-amber-600" />
      </div>
      
      <h2 className="text-2xl font-medium">Resource not found</h2>
      
      <p className="text-muted-foreground mt-2 max-w-md">
        The resource you are looking for does not exist or has been removed.
        Please check the URL or return to the resources page.
      </p>
      
      <Button asChild className="mt-4">
        <Link to="/resources">Back to resources</Link>
      </Button>
    </div>
  );
};

export default ResourceNotFound;
