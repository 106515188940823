
import React from 'react';
import { Keyword } from '@/types/keyword';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Info } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface KeywordErrorDetailsProps {
  keyword: Keyword;
}

const KeywordErrorDetails: React.FC<KeywordErrorDetailsProps> = ({ keyword }) => {
  if (keyword.generation_status !== 'failed' || !keyword.generation_metadata) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="flex items-center gap-1">
          <Info className="h-3 w-3" />
          Details
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-96 max-h-96 overflow-auto">
        <div className="p-2">
          <h4 className="font-bold text-sm mb-1">Error Details</h4>
          <p className="text-xs text-red-500 mb-2">
            {keyword.generation_metadata.error || 'Unknown error'}
          </p>
          
          {keyword.generation_metadata.details && (
            <>
              <h4 className="font-bold text-sm mb-1">Technical Details</h4>
              <div className="mb-4">
                {keyword.generation_metadata.details.message && (
                  <Alert variant="destructive" className="mb-2">
                    <AlertDescription>
                      {keyword.generation_metadata.details.message}
                    </AlertDescription>
                  </Alert>
                )}
                
                <pre className="text-xs p-2 whitespace-pre-wrap bg-gray-100 rounded">
                  {JSON.stringify(keyword.generation_metadata.details, null, 2)}
                </pre>
              </div>
              
              <h4 className="font-bold text-sm mb-1">Troubleshooting</h4>
              <ul className="text-xs list-disc pl-4">
                <li className="mb-1">Check that your OpenAI API key is valid and has sufficient credits</li>
                <li className="mb-1">Verify your prompt template requires <strong>ALL</strong> fields: title, content, excerpt, meta_title, meta_description</li>
                <li className="mb-1">Ensure your template instructs the AI to output valid JSON format</li>
                <li className="mb-1">Make sure the keyword is specific enough for content generation</li>
                <li className="mb-1">Try setting temperature to 0.3-0.5 for more consistent outputs</li>
                <li className="mb-1">Increase max tokens if content is being cut off</li>
              </ul>
            </>
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default KeywordErrorDetails;
