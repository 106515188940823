
import React from 'react';
import { Link } from 'react-router-dom';
import { Code } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/components/theme-provider';
import { trackEvent } from '@/hooks/use-analytics';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
  monochrome?: boolean;
  asLink?: boolean;
  className?: string;
}

const Logo = ({ size = 'md', monochrome = false, asLink = true, className }: LogoProps) => {
  const sizes = {
    sm: { icon: 'h-5 w-5', text: 'text-lg' },
    md: { icon: 'h-6 w-6', text: 'text-xl' },
    lg: { icon: 'h-8 w-8', text: 'text-2xl' },
  };
  
  const { theme } = useTheme();
  const isLightMode = theme === 'light';

  const handleLogoClick = () => {
    trackEvent('logo_click', { location: window.location.pathname });
  };

  const logoContent = (
    <div className={cn('flex items-center space-x-2 hover:opacity-80 transition-opacity', className)}>
      <Code className={cn(sizes[size].icon, 'text-primary')} />
      <span className={cn('font-mono font-bold tracking-tight', sizes[size].text)}>
        myhacks<span className={monochrome ? 'text-foreground' : isLightMode ? 'text-blue-700' : 'text-blue-200'}>.org</span>
      </span>
    </div>
  );

  if (asLink) {
    return (
      <Link to="/" onClick={handleLogoClick} aria-label="MyHacks.org Homepage">
        {logoContent}
      </Link>
    );
  }

  return logoContent;
};

export default Logo;
