
import React from 'react';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { useTranslation } from 'react-i18next';

interface RecurrenceOptionsProps {
  recurrence: string | null;
  updateRecurrence: (value: string, customSettings?: any) => void;
}

const RecurrenceOptions: React.FC<RecurrenceOptionsProps> = ({ 
  recurrence,
  updateRecurrence 
}) => {
  const { t } = useTranslation();
  
  const handleItemClick = (value: string) => {
    if (value === 'custom') return; // Custom is handled separately
    updateRecurrence(value === 'none' ? null : value);
  };

  const options = [
    { value: 'none', label: t('projects.recurrence.none', 'None') },
    { value: 'daily', label: t('projects.recurrence.daily', 'Daily') },
    { value: 'weekdays', label: t('projects.recurrence.weekdays', 'Weekdays') },
    { value: 'weekends', label: t('projects.recurrence.weekends', 'Weekends') },
    { value: 'weekly', label: t('projects.recurrence.weekly', 'Weekly') },
    { value: 'monthly', label: t('projects.recurrence.monthly', 'Monthly') },
    { value: 'yearly', label: t('projects.recurrence.yearly', 'Yearly') }
  ];

  return (
    <>
      {options.map(option => (
        <DropdownMenuItem
          key={option.value}
          onClick={() => handleItemClick(option.value)}
          className={recurrence === option.value ? "bg-accent/50" : ""}
        >
          {option.label}
        </DropdownMenuItem>
      ))}
    </>
  );
};

export default RecurrenceOptions;
