
import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar } from 'lucide-react';
import { ResourcePreview } from '@/types/resource';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { formatDate } from '@/utils/date-utils';

interface ResourceCardProps {
  resource: ResourcePreview;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ resource }) => {
  const { title, slug, excerpt, categories, published_at } = resource;
  
  return (
    <Link to={`/resources/${slug}`} className="block h-full">
      <Card className="h-full flex flex-col transition-all duration-200 hover:shadow-md">
        <CardContent className="pt-6 flex-grow">
          <h3 className="text-lg font-semibold mb-2 line-clamp-2">{title}</h3>
          
          {excerpt && (
            <p className="text-muted-foreground text-sm mb-4 line-clamp-3">
              {excerpt}
            </p>
          )}
          
          <div className="flex flex-wrap gap-1 mt-2">
            {categories.map((category) => (
              <Badge key={category.id} variant="outline" className="text-xs">
                {category.name}
              </Badge>
            ))}
          </div>
        </CardContent>
        
        <CardFooter className="border-t pt-4 text-xs text-muted-foreground">
          <div className="flex items-center">
            <Calendar className="h-3 w-3 mr-1" />
            {published_at ? formatDate(published_at) : 'Recently published'}
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default ResourceCard;
