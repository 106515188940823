
import React from 'react';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';

interface UsersTableEmptyProps {
  onRefresh?: () => void;
  isLoading?: boolean;
}

const UsersTableEmpty: React.FC<UsersTableEmptyProps> = ({ onRefresh, isLoading }) => {
  return (
    <div className="text-center py-8">
      <p className="text-muted-foreground">No users found.</p>
      {onRefresh && (
        <Button 
          variant="outline" 
          size="sm" 
          className="mt-4" 
          onClick={onRefresh}
          disabled={isLoading}
        >
          <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
          Refresh
        </Button>
      )}
    </div>
  );
};

export default UsersTableEmpty;
