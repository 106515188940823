
import { Json } from '@/integrations/supabase/types';

export type ResourceStatus = 'draft' | 'published' | 'archived';

export interface Category {
  id: string;
  name: Record<string, string>;
  slug: string;
  description?: Record<string, string>;
}

export interface Resource {
  id: string;
  title: Record<string, string>;
  slug: string;
  content: Record<string, string>;
  excerpt?: Record<string, string>;
  meta_title?: Record<string, string>;
  meta_description?: Record<string, string>;
  status: ResourceStatus;
  author_id: string;
  published_at?: string;
  created_at: string;
  updated_at: string;
  categories?: Category[];
}

export interface ResourcePreview {
  id: string;
  title: string;
  slug: string;
  excerpt?: string;
  status: ResourceStatus;
  published_at?: string;
  created_at: string;
  categories: {
    id: string;
    name: string;
    slug: string;
  }[];
}

export interface ResourceDetail {
  id: string;
  title: string;
  slug: string;
  content: string;
  excerpt?: string;
  meta_title?: string;
  meta_description?: string;
  published_at?: string;
  categories: {
    id: string;
    name: string;
    slug: string;
  }[];
  rating?: {
    value: number;
    count: number;
    max: number;
  };
}

// Type conversion utility functions for Supabase JSON handling
export const convertToResourcePreview = (data: any): ResourcePreview => {
  return {
    id: data.id,
    title: data.title || '',
    slug: data.slug || '',
    excerpt: data.excerpt || '',
    status: data.status || 'draft',
    published_at: data.published_at,
    created_at: data.created_at,
    categories: Array.isArray(data.categories) 
      ? data.categories 
      : []
  };
};

export const convertToCategory = (data: any): Category => {
  // Convert Json objects to Record<string, string> by ensuring string values
  const convertJsonToStringRecord = (jsonObj: Json | null): Record<string, string> => {
    if (!jsonObj || typeof jsonObj !== 'object') return {};
    
    const result: Record<string, string> = {};
    Object.entries(jsonObj).forEach(([key, value]) => {
      // Ensure value is a string
      result[key] = String(value || '');
    });
    return result;
  };

  return {
    id: data.id,
    name: convertJsonToStringRecord(data.name),
    slug: data.slug,
    description: convertJsonToStringRecord(data.description)
  };
};

export const convertToResource = (data: any): Resource => {
  // Convert Json objects to Record<string, string> by ensuring string values
  const convertJsonToStringRecord = (jsonObj: Json | null): Record<string, string> => {
    if (!jsonObj || typeof jsonObj !== 'object') return {};
    
    const result: Record<string, string> = {};
    Object.entries(jsonObj).forEach(([key, value]) => {
      // Ensure value is a string
      result[key] = String(value || '');
    });
    return result;
  };

  return {
    id: data.id,
    title: convertJsonToStringRecord(data.title),
    slug: data.slug,
    content: convertJsonToStringRecord(data.content),
    excerpt: convertJsonToStringRecord(data.excerpt),
    meta_title: convertJsonToStringRecord(data.meta_title),
    meta_description: convertJsonToStringRecord(data.meta_description),
    status: data.status,
    author_id: data.author_id,
    published_at: data.published_at,
    created_at: data.created_at,
    updated_at: data.updated_at
  };
};
