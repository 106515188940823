
import React from 'react';
import KeywordSearchBar from './KeywordSearchBar';
import KeywordStatusFilter from './KeywordStatusFilter';

interface KeywordListFiltersProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  statusFilter: 'all' | 'pending' | 'created';
  setStatusFilter: (status: 'all' | 'pending' | 'created') => void;
}

const KeywordListFilters: React.FC<KeywordListFiltersProps> = ({
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter
}) => {
  return (
    <div className="flex flex-col sm:flex-row gap-4 justify-between">
      <KeywordSearchBar 
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm} 
      />
      <KeywordStatusFilter 
        statusFilter={statusFilter} 
        setStatusFilter={setStatusFilter} 
      />
    </div>
  );
};

export default KeywordListFilters;
