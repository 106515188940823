
import React from 'react';
import { Input } from '@/components/ui/input';
import { UseFormReturn } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';

interface TextFieldProps {
  label?: string;
  name: keyof TaskFormValues;
  form: UseFormReturn<TaskFormValues>;
  placeholder: string;
  type?: string;
  className?: string;
  min?: number;
}

const TextField: React.FC<TextFieldProps> = ({ 
  label, 
  name, 
  form, 
  placeholder,
  type = 'text',
  className = '',
  min
}) => {
  return (
    <div>
      {label && <label className="text-sm font-medium mb-1 block">{label}</label>}
      <Input
        type={type}
        placeholder={placeholder}
        {...form.register(name)}
        className={className}
        min={min}
      />
      {form.formState.errors[name] && (
        <p className="text-xs text-destructive mt-1">
          {form.formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default TextField;
