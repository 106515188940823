
/**
 * Generate a URL-friendly slug from a string
 * Ensures it follows the pattern required by the database: ^[a-z0-9]+(-[a-z0-9]+)*$
 * @param title - The string to generate a slug from
 * @returns A URL-friendly slug
 */
export const generateSlug = (title: string) => {
  if (!title) return '';
  
  // Convert to lowercase and remove special characters
  let slug = title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')     // Remove special chars
    .replace(/\s+/g, '-')         // Replace spaces with hyphens
    .replace(/-+/g, '-')          // Replace multiple hyphens with single hyphen
    .replace(/^-+|-+$/g, '')      // Remove leading/trailing hyphens
    .trim();
  
  // Ensure slug starts with a letter or number
  if (!/^[a-z0-9]/.test(slug)) {
    return `resource-${slug}`;
  }
  
  // If slug is empty after processing, return a default
  return slug || 'resource';
};

/**
 * Validates if a slug matches the required database format
 * Pattern: ^[a-z0-9]+(-[a-z0-9]+)*$
 */
export const validateSlug = (slug: string): boolean => {
  const slugPattern = /^[a-z0-9]+(-[a-z0-9]+)*$/;
  return slugPattern.test(slug);
};
