import React from 'react';
import { Badge } from '@/components/ui/badge';

interface KeywordStatusBadgeProps {
  status?: 'pending' | 'created';
  generation_status?: 'pending' | 'generating' | 'completed' | 'failed';
}

const KeywordStatusBadge: React.FC<KeywordStatusBadgeProps> = ({ 
  status, 
  generation_status 
}) => {
  // Show generation status if it's generating or failed
  if (generation_status === 'generating') {
    return <Badge variant="outline" className="bg-blue-50 text-blue-800">Generating...</Badge>;
  }
  
  if (generation_status === 'failed') {
    return <Badge variant="destructive">Failed</Badge>;
  }
  
  if (generation_status === 'completed') {
    return <Badge variant="outline" className="bg-green-50 text-green-800">Completed</Badge>;
  }
  
  // Otherwise show the regular status
  if (status === 'created') {
    return <Badge>Created</Badge>;
  }
  
  return <Badge variant="outline">Pending</Badge>;
};

export default KeywordStatusBadge;
