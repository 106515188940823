
import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from '@/components/ui/card';
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Tag, Shield } from 'lucide-react';
import { useAppVersion } from '@/contexts/AppVersionContext';

// Form schema for version update
const versionSchema = z.object({
  version: z.string().min(1, "Version cannot be empty")
});

type VersionFormValues = z.infer<typeof versionSchema>;

const VersionManager: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { version, updateVersion } = useAppVersion();
  
  const form = useForm<VersionFormValues>({
    resolver: zodResolver(versionSchema),
    defaultValues: {
      version: version,
    },
  });

  const onSubmit = async (data: VersionFormValues) => {
    setIsUpdating(true);
    try {
      updateVersion(data.version);
      toast.success('Version updated successfully');
    } catch (error) {
      toast.error('Failed to update version');
      console.error('Error updating version:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Tag className="h-5 w-5" />
          Version Management
        </CardTitle>
        <CardDescription>
          Update the application version displayed to users
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4 flex items-center gap-2 text-sm">
          <Shield className="h-4 w-4 text-amber-500" />
          <span>Current version: <strong>{version}</strong></span>
        </div>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="version"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Version</FormLabel>
                  <FormControl>
                    <Input placeholder="e.g. 1.0.0" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button 
              type="submit" 
              disabled={isUpdating}
              className="w-full sm:w-auto"
            >
              {isUpdating ? 'Updating...' : 'Update Version'}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default VersionManager;
