
/**
 * Determine if a task should recur based on its recurrence settings
 */
export function shouldTaskRecur(task: any): boolean {
  return (
    task.recurrence !== null && 
    task.recurrence !== 'none' && 
    task.status === 'completed'
  );
}

/**
 * Create a new instance of a recurring task
 */
export function createRecurringTaskInstance(task: any, nextDueDate: Date): any {
  // Create a new task based on the completed recurring task
  return {
    title: task.title,
    description: task.description,
    status: 'active',
    priority: task.priority,
    position: task.position,
    estimated_minutes: task.estimated_minutes,
    parent_task_id: task.parent_task_id,
    project_id: task.project_id,
    user_id: task.user_id,
    due_date: nextDueDate.toISOString(),
    recurrence: task.recurrence,
    custom_recurrence: task.custom_recurrence,
    links: task.links
  };
}
