
import React from 'react';
import { CheckCircle, Edit, MoreVertical, Clock, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Reminder } from '@/types/reminder';

interface ReminderActionsProps {
  reminder: Reminder;
  onEdit: (reminder: Reminder) => void;
  onComplete: (id: string) => void;
  onDelete: (id: string) => void;
  onSnooze: (id: string, minutes: number) => void;
}

export function ReminderActions({ 
  reminder, 
  onEdit, 
  onComplete, 
  onDelete, 
  onSnooze 
}: ReminderActionsProps) {
  return (
    <div className="flex items-center space-x-1">
      {reminder.status !== 'completed' && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onComplete(reminder.id)}
          title="Mark as complete"
        >
          <CheckCircle className="h-5 w-5 text-green-600" />
        </Button>
      )}
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreVertical className="h-5 w-5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => onEdit(reminder)}>
            <Edit className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          
          {reminder.status !== 'completed' && (
            <>
              <DropdownMenuItem onClick={() => onComplete(reminder.id)}>
                <CheckCircle className="mr-2 h-4 w-4" />
                Mark as completed
              </DropdownMenuItem>
              
              <DropdownMenuItem onClick={() => onSnooze(reminder.id, 15)}>
                <Clock className="mr-2 h-4 w-4" />
                Snooze 15 minutes
              </DropdownMenuItem>
              
              <DropdownMenuItem onClick={() => onSnooze(reminder.id, 60)}>
                <Clock className="mr-2 h-4 w-4" />
                Snooze 1 hour
              </DropdownMenuItem>
            </>
          )}
          
          <DropdownMenuItem 
            onClick={() => onDelete(reminder.id)}
            className="text-red-600 focus:text-red-600"
          >
            <Trash2 className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
