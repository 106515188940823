
import { Json } from '@/integrations/supabase/types';

/**
 * Helper function to convert Json to string records
 * @param jsonObj - The JSON object to convert
 * @returns A record with string values
 */
export const convertJsonToStringRecord = (jsonObj: Json | null): Record<string, string> => {
  if (!jsonObj || typeof jsonObj !== 'object' || Array.isArray(jsonObj)) return {};
  
  const result: Record<string, string> = {};
  
  // Safely cast jsonObj as Record<string, Json>
  const safeJsonObj = jsonObj as Record<string, Json>;
  
  Object.entries(safeJsonObj).forEach(([key, value]) => {
    // Ensure value is a string
    result[key] = value !== null ? String(value) : '';
  });
  
  return result;
};
