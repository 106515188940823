
import React from 'react';
import { Task, TaskStatus } from '@/types/task';

/**
 * Filter tasks based on search term and active tab
 */
export const filterTasks = (
  taskList: Task[], 
  searchTerm: string, 
  selectedTab: string
): Task[] => {
  return taskList
    .filter(task => {
      const matchesSearch = !searchTerm || 
        task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (task.description && task.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (task.notes && task.notes.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesTab = selectedTab === 'all' || 
        selectedTab === task.status;

      return matchesSearch && matchesTab;
    })
    .map(task => {
      // Conserviamo le sottattività originali quando il task principale corrisponde ai criteri
      if (task.subtasks && task.subtasks.length > 0) {
        // Per le sottattività, applichiamo solo il filtro di ricerca, non quello del tab
        const filteredSubtasks = task.subtasks.filter(subtask => 
          !searchTerm || 
          subtask.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (subtask.description && subtask.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (subtask.notes && subtask.notes.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        
        return {
          ...task,
          subtasks: filteredSubtasks
        };
      }
      return task;
    });
};

/**
 * Count tasks by status
 */
export const countTasksByStatus = (tasks: Task[]) => {
  return {
    all: tasks.length,
    active: tasks.filter(t => t.status === 'active').length,
    in_progress: tasks.filter(t => t.status === 'in_progress').length,
    queue: tasks.filter(t => t.status === 'queue').length,
    blocked: tasks.filter(t => t.status === 'blocked').length,
    completed: tasks.filter(t => t.status === 'completed').length,
  };
};

/**
 * Task tab configuration 
 */
export const getStatusTabs = (taskCounts: Record<string, number>) => {
  return [
    { value: 'in_progress', label: `In Progress (${taskCounts.in_progress})`, icon: 'ArrowRight' },
    { value: 'active', label: `Active (${taskCounts.active})`, icon: 'AlertTriangle' },
    { value: 'queue', label: `Queue (${taskCounts.queue})`, icon: 'Clock' },
    { value: 'blocked', label: `Blocked (${taskCounts.blocked})`, icon: 'Ban' },
    { value: 'completed', label: `Completed (${taskCounts.completed})`, icon: 'Check' },
    { value: 'all', label: `All (${taskCounts.all})`, icon: 'List' },
  ];
};
