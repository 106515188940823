
import { useState, useEffect, useCallback } from 'react';
import { getResourceRating, ResourceRating } from '@/services/resources/review-service';

export const useResourceRating = (resourceId: string) => {
  const [rating, setRating] = useState<ResourceRating | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchRating = useCallback(async () => {
    if (!resourceId) return;
    
    setIsLoading(true);
    try {
      const ratingData = await getResourceRating(resourceId);
      setRating(ratingData);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch rating'));
    } finally {
      setIsLoading(false);
    }
  }, [resourceId]);

  useEffect(() => {
    fetchRating();
  }, [fetchRating]);

  return {
    rating,
    isLoading,
    error,
    refetchRating: fetchRating
  };
};
