
import React, { useState } from 'react';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useHabits } from '@/hooks/habits/use-habits';
import { CreateHabitPayload, Habit, TimeOfDay } from '@/types/habit';
import { HabitsHeader } from '@/components/habits/HabitsHeader';
import { HabitsContent } from '@/components/habits/HabitsContent';
import { HabitDialogs } from '@/components/habits/HabitDialogs';
import { HabitCompletionCelebration } from '@/components/habits/HabitCompletionCelebration';
import { useHabitDayFilter } from '@/hooks/habits/use-habit-day-filter';
import { useTimeOfDayNavigation } from '@/hooks/habits/use-time-of-day-navigation';

export default function Habits() {
  const { 
    habits, 
    isLoading, 
    createHabit, 
    updateHabit, 
    deleteHabit, 
    toggleHabitCompletion,
    celebration,
    hideCelebration
  } = useHabits();
  
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editingHabit, setEditingHabit] = useState<Habit | null>(null);
  
  const { showAllDays, setShowAllDays, filterHabitsByDay } = useHabitDayFilter();
  
  const { 
    timeOfDaySectionRefs,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
    currentTimeOfDay 
  } = useTimeOfDayNavigation(isLoading, habits.length);

  const handleCreate = async (data: CreateHabitPayload) => {
    const success = await createHabit(data);
    if (success) {
      setIsCreateOpen(false);
    }
  };

  const handleUpdate = async (data: CreateHabitPayload) => {
    if (!editingHabit) return;
    const success = await updateHabit({ ...data, id: editingHabit.id });
    if (success) {
      setEditingHabit(null);
    }
  };

  const handleDelete = async (habitId: string) => {
    await deleteHabit(habitId);
  };

  const handleToggleComplete = async (habitId: string, completed: boolean) => {
    await toggleHabitCompletion(habitId, completed);
  };

  const handleUpdateField = async (habitId: string, field: string, value: any) => {
    const habitToUpdate = habits.find(h => h.id === habitId);
    if (!habitToUpdate) return;
    
    // Create update payload with only the field being updated
    const updatePayload = {
      id: habitId,
      [field]: value
    };
    
    await updateHabit(updatePayload);
  };

  const habitsByTimeOfDay: Record<TimeOfDay, Habit[]> = {
    morning: filterHabitsByDay(habits.filter(habit => habit.time_of_day === 'morning')),
    afternoon: filterHabitsByDay(habits.filter(habit => habit.time_of_day === 'afternoon')),
    evening: filterHabitsByDay(habits.filter(habit => habit.time_of_day === 'evening')),
    night: filterHabitsByDay(habits.filter(habit => habit.time_of_day === 'night'))
  };

  const handleAddHabitForTime = (timeOfDay: TimeOfDay) => {
    setSelectedTimeOfDay(timeOfDay);
    setIsCreateOpen(true);
  };

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="container max-w-4xl mx-auto py-6">
          <HabitsHeader 
            onCreateNew={() => setIsCreateOpen(true)}
            showAllDays={showAllDays}
            onToggleShowAllDays={() => setShowAllDays(!showAllDays)}
          />

          <HabitsContent
            isLoading={isLoading}
            habits={habits}
            habitsByTimeOfDay={habitsByTimeOfDay}
            timeOfDaySectionRefs={timeOfDaySectionRefs}
            currentTimeOfDay={currentTimeOfDay}
            onAddHabit={handleAddHabitForTime}
            onCreateNew={() => setIsCreateOpen(true)}
            onToggleComplete={handleToggleComplete}
            onEdit={setEditingHabit}
            onDelete={handleDelete}
            onUpdateField={handleUpdateField}
          />

          <HabitDialogs
            isCreateOpen={isCreateOpen}
            setIsCreateOpen={setIsCreateOpen}
            editingHabit={editingHabit}
            setEditingHabit={setEditingHabit}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            selectedTimeOfDay={selectedTimeOfDay}
          />
          
          <HabitCompletionCelebration
            isVisible={celebration.visible}
            message={celebration.message}
            onClose={hideCelebration}
          />
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
}
