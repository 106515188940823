
import React, { useEffect } from 'react';
import { useAuth } from '@/contexts/auth';
import { SidebarProvider, useSidebar } from '@/components/ui/sidebar';
import { GlobalSidebar } from '@/components/sidebar/GlobalSidebar';
import Navbar from '../components/Navbar';
import { toast } from 'sonner';
import { SIDEBAR_KEYBOARD_SHORTCUT } from '@/components/ui/sidebar/sidebar-context';
import { useAnalytics } from '@/hooks/use-analytics';

// KeyboardShortcutInfo component to display toast when layout mounts
const KeyboardShortcutInfo = () => {
  useEffect(() => {
    // Show toast with keyboard shortcut info when component mounts
    const shortcutKey = SIDEBAR_KEYBOARD_SHORTCUT.toUpperCase();
    toast.info(
      `Tip: Use Ctrl+${shortcutKey} or ⌘+${shortcutKey} to toggle the sidebar`,
      {
        duration: 4000,
        position: 'bottom-center',
      }
    );
  }, []);
  
  return null;
};

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { user } = useAuth();
  
  // Initialize analytics tracking
  useAnalytics();

  return (
    <div className="min-h-screen bg-background text-foreground flex flex-col">
      {/* Updated gradient overlay for better contrast in both light and dark modes */}
      <div className="fixed inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-800/20 via-gray-900/20 to-background -z-10 dark:from-blue-900/30 dark:via-gray-900/25" />
      
      {/* Fixed height navbar at the top */}
      <header className="sticky top-0 z-50 h-[var(--navbar-height)] w-full">
        <Navbar />
      </header>
      
      {/* Main content area */}
      <div className="flex flex-1 w-full">
        {user ? (
          <SidebarProvider defaultOpen={true}>
            {/* Sidebar with fixed height calculation - only shown to logged in users */}
            <GlobalSidebar />
            <KeyboardShortcutInfo />
            
            {/* Main content with responsive padding */}
            <main className="flex-1 relative z-10 p-4 md:p-6 overflow-x-hidden">
              {children}
            </main>
          </SidebarProvider>
        ) : (
          /* Main content without sidebar for non-logged in users */
          <main className="flex-1 relative z-10 p-4 md:p-6 overflow-x-hidden w-full">
            {children}
          </main>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
