
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, LayoutDashboard } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth';
import NavLink from './NavLink';
import UserMenu from './UserMenu';
import { ThemeToggle } from '../ThemeToggle';

interface DesktopNavProps {
  onSignOut: () => Promise<void>;
}

const DesktopNav: React.FC<DesktopNavProps> = ({ onSignOut }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <nav className="hidden md:flex items-center justify-end space-x-6 flex-1">
      {user ? (
        // Navigation links for logged in users
        <NavLink to="/dashboard" label="Your Dashboard" icon={<LayoutDashboard className="h-4 w-4" />} />
      ) : null}
      <NavLink to="/resources" label="Productivity Resources" icon={<FileText className="h-4 w-4" />} />
      
      {/* Theme toggle integrated into the desktop navigation */}
      <div className="ml-2">
        <ThemeToggle />
      </div>
      
      {user ? (
        <UserMenu onSignOut={onSignOut} />
      ) : (
        <Button 
          onClick={() => navigate('/auth')} 
          className="ml-4 px-4 py-2 bg-primary text-primary-foreground hover:bg-primary/90 dark:bg-primary dark:text-primary-foreground dark:hover:bg-primary/90 rounded-md text-sm font-medium transition-all focus-ring"
        >
          Sign In to Your Account
        </Button>
      )}
    </nav>
  );
};

export default DesktopNav;
