
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Prompt, CreatePromptPayload } from '@/types/prompt';
import { TagsInput } from './TagsInput';

interface PromptFormProps {
  onSubmit: (data: Omit<CreatePromptPayload, 'user_id'>) => Promise<void>;
  defaultValues?: Partial<Prompt>;
  isSubmitting?: boolean;
}

export function PromptForm({ onSubmit, defaultValues, isSubmitting = false }: PromptFormProps) {
  const form = useForm<Omit<CreatePromptPayload, 'user_id'>>({
    defaultValues: {
      title: defaultValues?.title || '',
      content: defaultValues?.content || '',
      tags: defaultValues?.tags || []
    }
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Enter prompt title" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Prompt Content</FormLabel>
              <FormControl>
                <Textarea 
                  placeholder="Enter your prompt content here..."
                  className="min-h-[200px]"
                  {...field} 
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <TagsInput 
                  value={field.value || []} 
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full" disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save Prompt'}
        </Button>
      </form>
    </Form>
  );
}
