
import React from 'react';
import { Flame } from 'lucide-react';
import { motion } from 'framer-motion';
import { pulseAnimation } from '@/utils/animations';

interface HabitStreakProps {
  streakCount: number;
}

export function HabitStreak({ streakCount }: HabitStreakProps) {
  if (streakCount <= 0) return null;
  
  return (
    <div className="flex items-center mt-1 text-amber-600 dark:text-amber-400">
      <motion.div
        initial="initial"
        animate="pulse"
        variants={pulseAnimation}
      >
        <Flame className="h-4 w-4 mr-1 text-amber-500" />
      </motion.div>
      <span className="text-xs font-medium">
        {streakCount} {streakCount === 1 ? 'day' : 'days'} streak
      </span>
    </div>
  );
}
