
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { PromptsHeader } from '@/components/prompts/PromptsHeader';
import { PromptCard } from '@/components/prompts/PromptCard';
import { PromptsDialogs } from '@/components/prompts/PromptsDialogs';
import { TagsFilter } from '@/components/prompts/TagsFilter';
import { EmptyState } from '@/components/prompts/EmptyState';
import { usePrompts } from '@/hooks/prompts/use-prompts';
import { Prompt, CreatePromptPayload, UpdatePromptPayload } from '@/types/prompt';

export default function Prompts() {
  const {
    prompts,
    tags,
    isLoading,
    activeTag,
    setActiveTag,
    handleCreatePrompt,
    handleUpdatePrompt,
    handleDeletePrompt,
    copyPromptToClipboard,
    isCreating,
    isUpdating,
    isDeleting
  } = usePrompts();

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null);
  const [deletingPromptId, setDeletingPromptId] = useState<string | null>(null);

  const openCreateDialog = () => {
    setIsCreateOpen(true);
  };

  return (
    <ProtectedRoute>
      <MainLayout>
        <Helmet>
          <title>AI Prompts | Life Balance</title>
          <meta name="description" content="Manage your collection of AI prompts for quick reuse" />
        </Helmet>

        <div className="container py-6">
          <PromptsHeader onCreatePrompt={openCreateDialog} />
          
          <TagsFilter 
            tags={tags} 
            activeTag={activeTag} 
            onSelectTag={setActiveTag} 
          />
          
          {prompts.length === 0 && !isLoading && !activeTag ? (
            <EmptyState onCreatePrompt={openCreateDialog} />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {prompts.map(prompt => (
                <PromptCard
                  key={prompt.id}
                  prompt={prompt}
                  onEdit={setEditingPrompt}
                  onDelete={setDeletingPromptId}
                  onCopy={copyPromptToClipboard}
                />
              ))}
              
              {isLoading && (
                Array(3).fill(0).map((_, i) => (
                  <div key={i} className="h-[240px] rounded-lg border border-border bg-card animate-pulse" />
                ))
              )}
              
              {prompts.length === 0 && !isLoading && activeTag && (
                <div className="col-span-full text-center py-8">
                  <p className="text-muted-foreground">No prompts found with the selected tag.</p>
                  <button 
                    className="text-primary hover:underline mt-2"
                    onClick={() => setActiveTag(null)}
                  >
                    Clear filter
                  </button>
                </div>
              )}
            </div>
          )}
          
          <PromptsDialogs
            isCreateOpen={isCreateOpen}
            setIsCreateOpen={setIsCreateOpen}
            editingPrompt={editingPrompt}
            setEditingPrompt={setEditingPrompt}
            deletingPromptId={deletingPromptId}
            setDeletingPromptId={setDeletingPromptId}
            handleCreate={handleCreatePrompt}
            handleUpdate={handleUpdatePrompt}
            handleDelete={handleDeletePrompt}
            isCreating={isCreating}
            isUpdating={isUpdating}
          />
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
}
