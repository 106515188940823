
import React from 'react';
import {
  Table,
  TableBody,
} from '@/components/ui/table';
import { Keyword } from '@/types/keyword';
import KeywordTableHeader from './KeywordTableHeader';
import KeywordTableRow from './KeywordTableRow';

interface KeywordTableProps {
  keywords: Keyword[];
  onDelete: (id: string) => void;
}

const KeywordTable: React.FC<KeywordTableProps> = ({ keywords, onDelete }) => {
  return (
    <div className="rounded-md border">
      <Table>
        <KeywordTableHeader />
        <TableBody>
          {keywords.map((keyword) => (
            <KeywordTableRow 
              key={keyword.id} 
              keyword={keyword} 
              onDelete={onDelete} 
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default KeywordTable;
