
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import LoginForm from '@/components/auth/LoginForm';
import SignupForm from '@/components/auth/SignupForm';
import { useAuth } from '@/contexts/auth';
import MainLayout from '@/layouts/MainLayout';
import { useMetadata } from '@/hooks/use-metadata';

const Auth: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('login');
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Update metadata for improved SEO
  useMetadata({
    title: activeTab === 'login' ? 'Log In' : 'Sign Up',
    description: 'Access your MyHacks account to manage projects, habits and improve your productivity',
    ogType: 'website'
  });

  // Get the previous location or default to home
  const from = location.state?.from?.pathname || '/';

  // Redirect if already authenticated
  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  return (
    <MainLayout>
      <div className="container max-w-md mx-auto pt-32 pb-16 px-4">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="login">Login</TabsTrigger>
            <TabsTrigger value="signup">Sign Up</TabsTrigger>
          </TabsList>
          <div className="mt-6">
            <TabsContent value="login">
              <Card>
                <CardHeader>
                  <CardTitle>Login</CardTitle>
                  <CardDescription>
                    Don't have an account?{' '}
                    <button 
                      onClick={() => setActiveTab('signup')}
                      className="text-primary hover:underline"
                    >
                      Sign Up
                    </button>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <LoginForm />
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="signup">
              <Card>
                <CardHeader>
                  <CardTitle>Sign Up</CardTitle>
                  <CardDescription>
                    Already have an account?{' '}
                    <button 
                      onClick={() => setActiveTab('login')}
                      className="text-primary hover:underline"
                    >
                      Login
                    </button>
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <SignupForm />
                </CardContent>
              </Card>
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </MainLayout>
  );
};

export default Auth;
