
import React from 'react';
import { formatRelativeTime } from '@/utils/date-utils';

interface HabitLastCompletedProps {
  lastCompleted: string | null;
}

export function HabitLastCompleted({ lastCompleted }: HabitLastCompletedProps) {
  if (!lastCompleted) return null;
  
  return (
    <p className="text-xs text-muted-foreground mt-1">
      Last completed: {formatRelativeTime(lastCompleted)}
    </p>
  );
}
