
import React from 'react';
import { Badge } from '@/components/ui/badge';

interface HabitDaysOfWeekProps {
  days: string[] | null;
}

export function HabitDaysOfWeek({ days }: HabitDaysOfWeekProps) {
  if (!days || days.length === 0) return null;

  // Format day of week for display
  const formatDayName = (day: string) => {
    return day.charAt(0).toUpperCase() + day.slice(1, 3);
  };
  
  return (
    <div className="flex flex-wrap mt-2 gap-1">
      {days.map(day => (
        <Badge key={day} variant="secondary" className="text-xs">
          {formatDayName(day)}
        </Badge>
      ))}
    </div>
  );
}
