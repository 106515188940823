
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TaskFormValues } from './task-editor-schema';
import TextField from './fields/TextField';
import TextareaField from './fields/TextareaField';
import SelectField from './fields/SelectField';
import LinksField from './fields/LinksField';
import DatePickerField from './fields/DatePickerField';
import RecurrenceField from './fields/RecurrenceField';

const STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'queue', label: 'In Queue' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'completed', label: 'Completed' }
];

const PRIORITY_OPTIONS = [
  { value: 'asap', label: 'ASAP' },
  { value: 'high', label: 'High' },
  { value: 'average', label: 'Average' },
  { value: 'low', label: 'Low' }
];

const TaskFormFields = () => {
  const form = useFormContext<TaskFormValues>();

  return (
    <div className="space-y-4">
      <TextField
        name="title"
        form={form}
        placeholder="Task title"
        className="font-medium"
      />
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <SelectField
          label="Status"
          name="status"
          form={form}
          options={STATUS_OPTIONS}
        />
        
        <SelectField
          label="Priority"
          name="priority"
          form={form}
          options={PRIORITY_OPTIONS}
        />
      </div>
      
      <TextareaField
        label="Description (Optional)"
        name="description"
        form={form}
        placeholder="Task description"
      />
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <TextField
          label="Estimated Time (minutes)"
          name="estimated_minutes"
          form={form}
          placeholder="Minutes"
          type="number"
          min={0}
        />
        
        <DatePickerField
          label="Due Date"
          name="due_date"
          form={form}
        />
      </div>
      
      <RecurrenceField />
      
      <LinksField form={form} />
    </div>
  );
};

export default TaskFormFields;
