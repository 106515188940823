
export const DEFAULT_TEMPLATE = `Generate a comprehensive resource about {keyword}.

Your response MUST be in the following JSON format with these exact keys - all fields are required:
{
  "title": "An engaging title about {keyword}",
  "content": "Detailed, well-structured content in HTML format about {keyword}. Use proper HTML tags (h1, h2, h3, p, ul, ol, li, a, strong, em, etc.)",
  "excerpt": "A brief summary of the content, about 2-3 sentences",
  "meta_title": "SEO-optimized title for {keyword}",
  "meta_description": "SEO-optimized description for {keyword}, under 160 characters"
}

For the 'content' field, write HTML content with proper semantic structure: 
- Use h2, h3, h4 for headings
- Use p tags for paragraphs
- Use ul/ol and li tags for lists
- Use strong and em for emphasis
- Include proper anchor tags for links
- Use tables where appropriate
- Structure content in a logical way with proper HTML sections

Ensure all fields are filled with high-quality content. Do not omit any fields in your response.`;

export const DEFAULT_AUTHOR_PROFILE = `You are an expert content writer with deep knowledge in the field of {keyword}. 
Write in a professional tone with clear explanations and practical insights about {keyword}.
Use an authoritative voice while remaining accessible to both beginners and experts.
Include relevant examples and explanations that demonstrate your expertise on {keyword}.

Write HTML content that includes:
- Proper headings (h2, h3) for structure
- Well-formatted paragraphs
- Lists where appropriate
- Bold and italic text for emphasis
- Anchor links to relevant resources
- Tables for comparing information when useful
- Clear section organization

IMPORTANT: Your output MUST be valid JSON as described in the instructions, with the content field containing proper HTML.`;
