
import React from 'react';
import { Textarea } from '@/components/ui/textarea';
import { UseFormReturn } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';

interface TextareaFieldProps {
  label: string;
  name: keyof TaskFormValues;
  form: UseFormReturn<TaskFormValues>;
  placeholder: string;
  rows?: number;
}

const TextareaField: React.FC<TextareaFieldProps> = ({ 
  label, 
  name, 
  form, 
  placeholder,
  rows = 2
}) => {
  return (
    <div>
      <label className="text-sm font-medium mb-1 block">{label}</label>
      <Textarea
        placeholder={placeholder}
        {...form.register(name)}
        rows={rows}
      />
    </div>
  );
};

export default TextareaField;
