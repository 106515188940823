
import { useState } from 'react';
import { Task, UpdateTaskPayload, CreateTaskPayload, TaskPriority, TaskStatus } from '@/types/task';

export const useTaskItemHandlers = (
  task: Task,
  onUpdateTask: (task: UpdateTaskPayload) => Promise<boolean>,
  onDeleteTask: (taskId: string) => Promise<boolean>,
  onToggleExpansion: (taskId: string) => void
) => {
  const [showAddSubtask, setShowAddSubtask] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const isCompleted = task.status === 'completed';
  
  const handleToggleStatus = async () => {
    // Ricordiamo la posizione dello scroll prima dell'aggiornamento
    const scrollPosition = window.scrollY;
    
    await onUpdateTask({
      id: task.id,
      status: isCompleted ? 'active' : 'completed'
    });
    
    // Ripristiniamo la posizione dello scroll dopo l'aggiornamento
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };
  
  const handleStatusChange = async (newStatus: TaskStatus) => {
    // Ricordiamo la posizione dello scroll prima dell'aggiornamento
    const scrollPosition = window.scrollY;
    
    await onUpdateTask({
      id: task.id,
      status: newStatus
    });
    
    // Ripristiniamo la posizione dello scroll dopo l'aggiornamento
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };
  
  const handleDelete = async () => {
    // Ricordiamo la posizione dello scroll prima dell'eliminazione
    const scrollPosition = window.scrollY;
    
    await onDeleteTask(task.id);
    
    // Ripristiniamo la posizione dello scroll dopo l'eliminazione
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };

  const handleTitleUpdate = async (newTitle: string) => {
    // Ricordiamo la posizione dello scroll prima dell'aggiornamento
    const scrollPosition = window.scrollY;
    
    await onUpdateTask({
      id: task.id,
      title: newTitle
    });
    
    // Ripristiniamo la posizione dello scroll dopo l'aggiornamento
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };

  const handlePriorityUpdate = async (newPriority: TaskPriority) => {
    // Ricordiamo la posizione dello scroll prima dell'aggiornamento
    const scrollPosition = window.scrollY;
    
    await onUpdateTask({
      id: task.id,
      priority: newPriority
    });
    
    // Ripristiniamo la posizione dello scroll dopo l'aggiornamento
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };

  const handleEstimatedTimeUpdate = async (minutes: number | null) => {
    // Ricordiamo la posizione dello scroll prima dell'aggiornamento
    const scrollPosition = window.scrollY;
    
    await onUpdateTask({
      id: task.id,
      estimated_minutes: minutes
    });
    
    // Ripristiniamo la posizione dello scroll dopo l'aggiornamento
    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
  };

  const toggleAddSubtask = () => {
    setShowAddSubtask(!showAddSubtask);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return {
    showAddSubtask,
    isEditing,
    isCompleted,
    handleToggleStatus,
    handleStatusChange,
    handleDelete,
    handleTitleUpdate,
    handlePriorityUpdate,
    handleEstimatedTimeUpdate,
    toggleAddSubtask,
    toggleEditing,
    setShowAddSubtask,
    setIsEditing
  };
};
