
import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

interface PromptsHeaderProps {
  onCreatePrompt: () => void;
}

export function PromptsHeader({ onCreatePrompt }: PromptsHeaderProps) {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
      <div>
        <h1 className="text-2xl font-bold tracking-tight">AI Prompts</h1>
        <p className="text-muted-foreground mt-1">
          Create and manage your collection of AI prompts
        </p>
      </div>
      <Button onClick={onCreatePrompt}>
        <Plus className="h-4 w-4 mr-2" />
        New Prompt
      </Button>
    </div>
  );
}
