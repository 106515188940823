
import { formatRelativeTime } from '@/utils/date-utils';

// Calculate days remaining until goal due date
export const getDaysRemaining = (dueDate: string | null): number | null => {
  if (!dueDate) return null;
  
  const now = new Date();
  const due = new Date(dueDate);
  const diffTime = due.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return diffDays;
};

// Format due date for display
export const formatDueDate = (dueDate: string | null, short: boolean = false): string => {
  if (!dueDate) return 'No due date';
  
  const days = getDaysRemaining(dueDate);
  
  if (days === null) return 'Invalid date';
  
  if (short) {
    if (days < 0) return `${Math.abs(days)}d ago`;
    if (days === 0) return 'Today';
    if (days === 1) return 'Tomorrow';
    return `${days}d`;
  }
  
  if (days < 0) {
    return `Expired ${Math.abs(days)} day${Math.abs(days) === 1 ? '' : 's'} ago`;
  }
  if (days === 0) return 'Due today';
  if (days === 1) return 'Due tomorrow';
  return `Due in ${days} day${days === 1 ? '' : 's'}`;
};

// Get status color based on days remaining
export const getStatusColor = (dueDate: string | null, completed: boolean): string => {
  if (completed) return 'text-green-500';
  if (!dueDate) return 'text-blue-500';
  
  const days = getDaysRemaining(dueDate);
  
  if (days === null) return 'text-gray-500';
  if (days < 0) return 'text-red-500';
  if (days <= 3) return 'text-orange-500';
  if (days <= 7) return 'text-yellow-500';
  return 'text-blue-500';
};
