
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';
import { UserProfile, AppRole } from '@/contexts/auth/types';
import UsersTableContent from './table/UsersTableContent';
import UsersTableEmpty from './table/UsersTableEmpty';
import UserRoleDialog from './dialogs/UserRoleDialog';
import UserProfileDialog from './dialogs/UserProfileDialog';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UsersTableProps {
  users: UserWithRoles[];
  onRemoveRole: (userId: string, role: AppRole) => Promise<void>;
  onAddRole: (userId: string, role: AppRole) => Promise<void>;
  onUpdateProfile: (userId: string, data: Partial<UserProfile>) => Promise<void>;
  onDeleteUser: (userId: string) => Promise<void>;
  onRefresh?: () => void;
  isLoading?: boolean;
}

const UsersTable: React.FC<UsersTableProps> = ({ 
  users, 
  onRemoveRole, 
  onAddRole,
  onUpdateProfile,
  onDeleteUser,
  onRefresh, 
  isLoading 
}) => {
  const [selectedUser, setSelectedUser] = useState<UserWithRoles | null>(null);
  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);

  const handleOpenRoleDialog = (user: UserWithRoles) => {
    setSelectedUser(user);
    setIsRoleDialogOpen(true);
  };

  const handleOpenProfileDialog = (user: UserWithRoles) => {
    setSelectedUser(user);
    setIsProfileDialogOpen(true);
  };

  // Handle dialog close and refresh
  const handleRoleDialogChange = (open: boolean) => {
    setIsRoleDialogOpen(open);
    // Only refresh when the dialog is closing and we have a refresh function
    if (!open && onRefresh && !isLoading) {
      onRefresh();
    }
  };

  if (!users.length) {
    return <UsersTableEmpty onRefresh={onRefresh} isLoading={isLoading} />;
  }

  return (
    <div className="space-y-4">
      {onRefresh && (
        <div className="flex justify-end">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={onRefresh}
            disabled={isLoading}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
      )}
      
      <UsersTableContent 
        users={users}
        onOpenRoleDialog={handleOpenRoleDialog}
        onOpenProfileDialog={handleOpenProfileDialog}
        onRemoveRole={onRemoveRole}
        onDeleteUser={onDeleteUser}
      />

      {/* Dialogs */}
      <UserRoleDialog 
        isOpen={isRoleDialogOpen}
        onOpenChange={handleRoleDialogChange}
        selectedUser={selectedUser}
        onAddRole={onAddRole}
        onRemoveRole={onRemoveRole}
      />

      <UserProfileDialog
        isOpen={isProfileDialogOpen}
        onOpenChange={setIsProfileDialogOpen}
        selectedUser={selectedUser}
        onUpdateProfile={onUpdateProfile}
      />
    </div>
  );
};

export default UsersTable;
