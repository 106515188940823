
import React from 'react';
import { Focus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import FocusModeSheet from '@/components/project-details/tasks/focus-mode/FocusModeSheet';
import { Habit } from '@/types/habit';

interface HabitFocusModeProps {
  habit: Habit;
}

export function HabitFocusMode({ habit }: HabitFocusModeProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  
  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0"
        onClick={() => setIsOpen(true)}
        title="Focus Mode"
        disabled={!habit.estimated_minutes}
      >
        <Focus className="h-4 w-4" />
      </Button>
      
      {habit.estimated_minutes && (
        <FocusModeSheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          task={{
            ...habit,
            title: habit.title,
            description: habit.description || undefined,
            notes: undefined,
            estimated_minutes: habit.estimated_minutes,
            auto_increment_time: habit.auto_increment_time
          } as any}
        />
      )}
    </>
  );
}
