
import React from 'react';
import { Keyword } from '@/types/keyword';
import KeywordStatusBadge from './components/KeywordStatusBadge';
import GenerateResourceButton from './components/GenerateResourceButton';
import KeywordErrorDetails from './components/KeywordErrorDetails';
import KeywordActionsMenu from './components/KeywordActionsMenu';
import ResourceLinkButton from './components/ResourceLinkButton';
import PreviewResourceButton from './components/PreviewResourceButton';

interface KeywordActionsProps {
  keyword: Keyword;
  onDelete?: (id: string) => void;
  showResource?: boolean;
  showDelete?: boolean;
}

const KeywordActions: React.FC<KeywordActionsProps> = ({
  keyword,
  onDelete,
  showResource = true,
  showDelete = true,
}) => {
  const canGenerate = keyword.status === 'pending' || (keyword.generation_status === 'failed');
  const hasError = keyword.generation_status === 'failed';
  const hasResource = !!keyword.resource_id;

  return (
    <div className="flex items-center gap-2">
      <KeywordStatusBadge 
        status={keyword.status} 
        generation_status={keyword.generation_status} 
      />
      
      {canGenerate && (
        <>
          <PreviewResourceButton 
            keyword={keyword}
            disabled={!canGenerate}
          />
          
          <GenerateResourceButton 
            keyword={keyword}
            disabled={!canGenerate}
          />
        </>
      )}
      
      {hasError && <KeywordErrorDetails keyword={keyword} />}
      
      {showResource && hasResource && (
        <ResourceLinkButton keyword={keyword} />
      )}
      
      {showDelete && (
        <KeywordActionsMenu
          keyword={keyword}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

export default KeywordActions;
