
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { incrementStreak, decrementStreak } from './use-habit-streaks';

/**
 * Incrementa il tempo stimato dell'abitudine dell'1%
 */
async function incrementEstimatedTime(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    // Ottieni l'abitudine completa per verificare se l'incremento automatico è abilitato
    const { data: habitData, error: fetchError } = await supabase
      .from('habits')
      .select('estimated_minutes, auto_increment_time')
      .eq('id', habitId)
      .eq('user_id', userId)
      .single();
      
    if (fetchError) throw fetchError;
    
    // Se l'incremento automatico non è abilitato o non ci sono minuti stimati, non fare nulla
    if (!habitData || 
        habitData.estimated_minutes === null || 
        !habitData.auto_increment_time) {
      return true;
    }
    
    // Calcola il nuovo valore con l'incremento dell'1%
    const currentMinutes = habitData.estimated_minutes;
    // Aggiungi 1% mantenendo due decimali per precisione (non arrotondare)
    const newMinutes = parseFloat((currentMinutes * 1.01).toFixed(2));
    
    console.log(`Incrementing habit time from ${currentMinutes} to ${newMinutes} minutes (1%)`);
    
    // Aggiorna solo se c'è un cambiamento
    if (newMinutes > currentMinutes) {
      const { error: updateError } = await supabase
        .from('habits')
        .update({ estimated_minutes: newMinutes })
        .eq('id', habitId)
        .eq('user_id', userId);
        
      if (updateError) throw updateError;
      
      console.log(`Tempo stimato incrementato da ${currentMinutes} a ${newMinutes} minuti (1%)`);
    }
    
    return true;
  } catch (error) {
    console.error('Errore nell\'incrementare il tempo stimato:', error);
    return false;
  }
}

/**
 * Mark a habit as completed
 */
export async function completeHabit(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    const now = new Date().toISOString();
    
    // Increment the streak count
    const streakSuccess = await incrementStreak(habitId, userId);
    if (!streakSuccess) {
      throw new Error('Failed to update streak count');
    }
    
    // Incrementa il tempo stimato dell'1%
    await incrementEstimatedTime(habitId, userId);
    
    // Mark habit as completed
    const { error } = await supabase
      .from('habits')
      .update({
        last_completed: now
      })
      .eq('id', habitId)
      .eq('user_id', userId);
      
    if (error) throw error;
    
    // Create habit log entry
    const { error: logError } = await supabase
      .from('habit_logs')
      .insert({
        habit_id: habitId,
        user_id: userId,
        completed_at: now
      });
      
    if (logError) throw logError;
    
    toast.success('Habit marked as completed');
    return true;
  } catch (error) {
    console.error('Error completing habit:', error);
    toast.error('Failed to update habit completion status');
    return false;
  }
}

/**
 * Mark a habit as not completed
 */
export async function uncompleteHabit(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    // Decrement the streak count
    const streakSuccess = await decrementStreak(habitId, userId);
    if (!streakSuccess) {
      throw new Error('Failed to update streak count');
    }
    
    // Mark habit as not completed - remove the most recent log and update the habit
    const { data: logs, error: fetchError } = await supabase
      .from('habit_logs')
      .select('*')
      .eq('habit_id', habitId)
      .eq('user_id', userId)
      .order('completed_at', { ascending: false })
      .limit(1);
      
    if (fetchError) throw fetchError;
    
    if (logs && logs.length > 0) {
      const { error: deleteError } = await supabase
        .from('habit_logs')
        .delete()
        .eq('id', logs[0].id)
        .eq('user_id', userId);
        
      if (deleteError) throw deleteError;
    }
    
    // Update the habit record
    const { data: previousLogs, error: prevLogsError } = await supabase
      .from('habit_logs')
      .select('*')
      .eq('habit_id', habitId)
      .eq('user_id', userId)
      .order('completed_at', { ascending: false })
      .limit(1);
      
    if (prevLogsError) throw prevLogsError;
    
    const lastCompleted = previousLogs && previousLogs.length > 0 
      ? previousLogs[0].completed_at 
      : null;
      
    const { error: updateError } = await supabase
      .from('habits')
      .update({
        last_completed: lastCompleted
      })
      .eq('id', habitId)
      .eq('user_id', userId);
      
    if (updateError) throw updateError;
    
    toast.success('Habit marked as not completed');
    return true;
  } catch (error) {
    console.error('Error uncompleting habit:', error);
    toast.error('Failed to update habit completion status');
    return false;
  }
}

/**
 * Toggle a habit's completion status
 */
export async function toggleHabitCompletion(
  habitId: string, 
  completed: boolean, 
  userId: string | undefined
): Promise<boolean> {
  if (completed) {
    return completeHabit(habitId, userId);
  } else {
    return uncompleteHabit(habitId, userId);
  }
}
