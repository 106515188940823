
import { supabase } from '@/integrations/supabase/client';
import { 
  Category,
  convertToCategory
} from '@/types/resource';

export const getCategories = async (): Promise<Category[]> => {
  const { data, error } = await supabase
    .from('resource_categories')
    .select('*');

  if (error) throw new Error(error.message);
  return (data || []).map(item => convertToCategory(item));
};
