
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../project-form-schema';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { InfoIcon } from 'lucide-react';

interface AIFieldProps {
  form: UseFormReturn<ProjectFormValues>;
}

const AIField: React.FC<AIFieldProps> = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="ai"
      render={({ field }) => (
        <FormItem>
          <div className="flex items-center gap-2">
            <FormLabel className="font-medium">AI Notes</FormLabel>
            <span className="text-xs text-muted-foreground flex items-center">
              <InfoIcon className="h-3.5 w-3.5 mr-1" />
              AI notes can be added in more detail from the project details page
            </span>
          </div>
          <FormControl>
            <Textarea
              placeholder="Add AI notes for this project..."
              className="resize-y min-h-[100px]"
              {...field}
              value={field.value || ''}
              onChange={(e) => {
                // For compatibility with the multi-note system,
                // we'll store simple text as a single note
                const newValue = e.target.value;
                field.onChange(newValue);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default AIField;
