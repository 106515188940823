
import { useState, useEffect } from 'react'

export function useTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState<boolean>(false)

  useEffect(() => {
    // Primary detection - checking if pointer events are coarse
    const hasTouchScreen = (
      'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0
    ) || (
      'msMaxTouchPoints' in navigator && (navigator as any).msMaxTouchPoints > 0
    )

    // Secondary detection - checking if matchMedia supports any touch capability
    const mediaQuery = window.matchMedia('(pointer: coarse)')
    
    // Set state based on whether device is touch-capable
    setIsTouchDevice(hasTouchScreen || mediaQuery.matches)
    
    // Set up listener for changes (e.g., device orientation change)
    const handleMediaChange = (e: MediaQueryListEvent) => {
      setIsTouchDevice(hasTouchScreen || e.matches)
    }
    
    mediaQuery.addEventListener('change', handleMediaChange)
    
    return () => mediaQuery.removeEventListener('change', handleMediaChange)
  }, [])

  return isTouchDevice
}
