
import { QueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ResourceGenerationError } from './types';

/**
 * Handler for resource generation errors
 */
export const handleResourceGenerationError = (
  error: ResourceGenerationError, 
  queryClient: QueryClient
) => {
  // Invalidate keywords query to refresh the list with updated status
  queryClient.invalidateQueries({
    queryKey: ['keywords']
  });
  
  // Show error message with details if available
  let errorMessage = error.message || 'An error occurred while generating the resource';
  
  // Enhance error messages for common issues
  if (errorMessage.includes('Failed to send a request to the Edge Function')) {
    errorMessage = 'Failed to connect to the resource generation service. Please check that the Edge Function is deployed and all environment variables are set correctly.';
  } else if (errorMessage.includes('Invalid response format')) {
    errorMessage = 'The AI generated an invalid response format. Please try again with a different keyword or check the OpenAI configuration.';
  } else if (errorMessage.includes('slug_format')) {
    errorMessage = 'The generated title contained characters that cannot be used in a URL. Please try again with a simpler keyword.';
  }
  
  toast.error('Resource generation failed', {
    description: errorMessage
  });
  
  // Log detailed error information to console for debugging
  console.error('Resource generation error:', error);
  if (error.details) {
    console.error('Error details:', error.details);
  }
};
