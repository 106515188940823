
import { useState, useCallback } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { AppRole, UserProfile } from '@/contexts/auth/types';
import { toast } from 'sonner';
import { UserWithRoles } from '../types/user-types';

// Helper function to validate if a string is a valid AppRole
const isValidAppRole = (role: string): role is AppRole => {
  return ['user', 'admin', 'premium'].includes(role);
};

export function useUsersData() {
  const [users, setUsers] = useState<UserWithRoles[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Get all users directly from auth.users via profiles
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('*');

      if (profilesError) throw profilesError;

      // For each profile, fetch their roles directly from the database
      const usersWithRoles = await Promise.all(
        profiles.map(async (profile) => {
          try {
            // Call the database function correctly with '_user_id' parameter
            const { data: rolesData, error: rolesError } = await supabase
              .rpc('get_user_roles', { _user_id: profile.id });
              
            if (rolesError) {
              console.error(`Error fetching roles for user ${profile.id}:`, rolesError);
              throw rolesError;
            }
            
            // Only include valid app roles that actually exist in the database
            // Don't add default roles if none found - let the database handle defaults
            const validRoles: AppRole[] = Array.isArray(rolesData)
              ? rolesData.filter(isValidAppRole)
              : [];
            
            return {
              id: profile.id,
              email: profile.email,
              profile: {
                email: profile.email,
                timezone: profile.timezone,
                first_name: profile.first_name,
                last_name: profile.last_name
              },
              roles: validRoles
            };
          } catch (userError) {
            console.error(`Error processing user ${profile.id}:`, userError);
            // If there's an error fetching roles, don't assign default roles
            // Let the UI handle the display of the error state
            return {
              id: profile.id,
              email: profile.email,
              profile: {
                email: profile.email,
                timezone: profile.timezone,
                first_name: profile.first_name,
                last_name: profile.last_name
              },
              roles: []
            };
          }
        })
      );

      console.log('Fetched users with roles:', usersWithRoles);
      setUsers(usersWithRoles);
    } catch (err: any) {
      console.error('Error fetching users:', err);
      setError(err.message);
      toast.error('Failed to load users');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    users,
    setUsers,
    isLoading,
    setIsLoading,
    error,
    setError,
    fetchUsers
  };
}
