
import { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { Project } from '@/components/sidebar/sidebar-data';
import { useAuth } from '@/contexts/auth';
import { Json } from '@/integrations/supabase/types';
import { Goal } from '@/lib/utils';

export function useProjects() {
  const { user } = useAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const fetchInProgressRef = useRef(false);
  
  const fetchProjects = useCallback(async () => {
    if (!user) return;
    
    // Prevent concurrent fetches
    if (fetchInProgressRef.current) {
      console.log('Fetch already in progress, skipping...');
      return;
    }
    
    try {
      fetchInProgressRef.current = true;
      setLoading(true);
      
      // Fetch all projects for the user
      const { data: projectsData, error: projectsError } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });
      
      if (projectsError) throw projectsError;
      
      // Fetch all goals for the user to join with projects
      const { data: goalsData, error: goalsError } = await supabase
        .from('project_goals')
        .select('*')
        .eq('user_id', user.id)
        .order('due_date', { ascending: true });
      
      if (goalsError) {
        console.error('Error fetching goals:', goalsError);
        // Continue without goals
      }
      
      // Group goals by project_id
      const goalsByProject: Record<string, Goal[]> = {};
      if (goalsData) {
        goalsData.forEach(goal => {
          if (!goalsByProject[goal.project_id]) {
            goalsByProject[goal.project_id] = [];
          }
          goalsByProject[goal.project_id].push(goal);
        });
      }
      
      // Transform data to match Project interface and add goals
      const transformedProjects = projectsData?.map(project => {
        // Safely cast the priority to the required type
        const priority = ['asap', 'high', 'average', 'low'].includes(project.priority) 
          ? project.priority as 'asap' | 'high' | 'average' | 'low'
          : 'average';
        
        return {
          ...project,
          priority,
          links: Array.isArray(project.links) 
            ? project.links as { url: string; title: string }[]
            : null,
          tags: Array.isArray(project.tags) ? project.tags : null,
          goals: goalsByProject[project.id] || []
        } as Project;
      }) || [];
      
      setProjects(transformedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
      fetchInProgressRef.current = false;
    }
  }, [user]);

  useEffect(() => {
    let isMounted = true;
    
    if (user && isMounted && !fetchInProgressRef.current) {
      fetchProjects();
    }
    
    return () => {
      isMounted = false;
    };
  }, [user, fetchProjects]);

  return {
    projects,
    loading,
    fetchProjects
  };
}
