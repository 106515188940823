
import React from 'react';
import {
  TableHeader,
  TableRow,
  TableHead,
} from '@/components/ui/table';

const KeywordTableHeader: React.FC = () => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>Keyword</TableHead>
        <TableHead>Created</TableHead>
        <TableHead className="text-right">Actions</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default KeywordTableHeader;
