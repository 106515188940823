
import { useState, useCallback, useMemo } from 'react';
import { AppRole } from '@/contexts/auth/types';
import { toast } from 'sonner';

interface UseRoleManagerProps {
  userId: string;
  userRoles: AppRole[];
  onAddRole: (userId: string, role: AppRole) => Promise<void>;
  onRemoveRole: (userId: string, role: AppRole) => Promise<void>;
  onClose: () => void;
}

export function useRoleManager({
  userId,
  userRoles,
  onAddRole,
  onRemoveRole,
  onClose
}: UseRoleManagerProps) {
  const [isAddingRole, setIsAddingRole] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<AppRole | ''>('');
  
  // All available roles
  const allRoles: AppRole[] = useMemo(() => ['user', 'admin', 'premium'], []);
  
  // Currently assigned roles
  const currentRoles = useMemo(() => userRoles || [], [userRoles]);
  
  // Roles that can be added (not already assigned)
  const unusedRoles = useMemo(() => 
    allRoles.filter(role => !currentRoles.includes(role)),
  [allRoles, currentRoles]);
  
  // Function to add a role to the user
  const handleAddRole = useCallback(async () => {
    if (!selectedRole) {
      toast.error('Please select a role to add');
      return;
    }
    
    setIsAddingRole(true);
    try {
      await onAddRole(userId, selectedRole as AppRole);
      setSelectedRole('');
      toast.success(`Role ${selectedRole} added successfully`);
    } catch (error: any) {
      console.error('Error adding role:', error);
      toast.error(`Failed to add role: ${error.message || 'Unknown error'}`);
    } finally {
      setIsAddingRole(false);
    }
  }, [selectedRole, userId, onAddRole]);
  
  // Function to remove a role from the user
  const handleRemoveRole = useCallback(async (role: AppRole) => {
    try {
      await onRemoveRole(userId, role);
      toast.success(`Role ${role} removed successfully`);
    } catch (error: any) {
      console.error('Error removing role:', error);
      toast.error(`Failed to remove role: ${error.message || 'Unknown error'}`);
    }
  }, [userId, onRemoveRole]);
  
  // Function to refresh roles (simulated here - would call a refetch in real app)
  const handleRefreshRoles = useCallback(async () => {
    setIsRefreshing(true);
    try {
      // Add a small delay to prevent too frequent refreshes
      await new Promise(resolve => setTimeout(resolve, 500));
      // In a real app, you would fetch the updated roles here
      toast.success('Roles refreshed');
    } catch (error: any) {
      console.error('Error refreshing roles:', error);
      toast.error(`Failed to refresh roles: ${error.message || 'Unknown error'}`);
    } finally {
      setIsRefreshing(false);
    }
  }, []);
  
  return {
    isAddingRole,
    isRefreshing,
    isLoading,
    selectedRole,
    setSelectedRole,
    currentRoles,
    unusedRoles,
    handleAddRole,
    handleRemoveRole,
    handleRefreshRoles: handleRefreshRoles,
    refreshRoles: handleRefreshRoles,
    onClose
  };
}
