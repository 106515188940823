
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface StatusSwitchDialogProps {
  isOpen: boolean;
  onClose: () => void;
  currentStatus: string;
  onStatusChange: (status: string) => void;
}

const statuses = [
  { value: 'active', label: 'Active', color: 'text-green-500' },
  { value: 'in_progress', label: 'In Progress', color: 'text-blue-500' },
  { value: 'queue', label: 'Queue', color: 'text-orange-500' },
  { value: 'blocked', label: 'Blocked', color: 'text-red-700' },
  { value: 'completed', label: 'Completed', color: 'text-gray-500' }
];

export const StatusSwitchDialog: React.FC<StatusSwitchDialogProps> = ({
  isOpen,
  onClose,
  currentStatus,
  onStatusChange
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Change Project Status</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4 py-4">
          {statuses.map((status) => (
            <Button
              key={status.value}
              variant={currentStatus === status.value ? "default" : "outline"}
              className={`justify-center ${status.color}`}
              onClick={() => onStatusChange(status.value)}
            >
              {status.label}
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
