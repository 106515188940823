
import React from 'react';
import { FormField, FormItem, FormLabel } from '@/components/ui/form';
import { UseFormReturn } from 'react-hook-form';
import { CreateHabitPayload } from '@/types/habit';
import { CheckIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DaysOfWeekFieldProps {
  form: UseFormReturn<CreateHabitPayload>;
}

const daysOfWeek = [
  { value: 'monday', label: 'Mon' },
  { value: 'tuesday', label: 'Tue' },
  { value: 'wednesday', label: 'Wed' },
  { value: 'thursday', label: 'Thu' },
  { value: 'friday', label: 'Fri' },
  { value: 'saturday', label: 'Sat' },
  { value: 'sunday', label: 'Sun' }
];

const DaysOfWeekField: React.FC<DaysOfWeekFieldProps> = ({ form }) => {
  const selectedDays = form.watch('days_of_week') || [];

  const toggleDay = (day: string) => {
    const currentDays = [...selectedDays];
    
    if (currentDays.includes(day)) {
      form.setValue('days_of_week', currentDays.filter(d => d !== day), { shouldValidate: true, shouldDirty: true });
    } else {
      form.setValue('days_of_week', [...currentDays, day], { shouldValidate: true, shouldDirty: true });
    }
  };

  return (
    <FormField
      control={form.control}
      name="days_of_week"
      render={() => (
        <FormItem>
          <FormLabel>Days of Week</FormLabel>
          <div className="flex flex-wrap gap-2">
            {daysOfWeek.map((day) => (
              <button
                key={day.value}
                type="button"
                onClick={() => toggleDay(day.value)}
                className={cn(
                  "h-10 w-10 rounded-full flex items-center justify-center border text-sm",
                  selectedDays.includes(day.value)
                    ? "bg-primary text-primary-foreground border-primary"
                    : "bg-background border-input hover:bg-accent hover:text-accent-foreground"
                )}
              >
                {day.label}
                {selectedDays.includes(day.value) && (
                  <span className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3">
                    <CheckIcon className="h-3 w-3" />
                  </span>
                )}
              </button>
            ))}
          </div>
        </FormItem>
      )}
    />
  );
};

export default DaysOfWeekField;
