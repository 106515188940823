
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

/**
 * Increment a habit's streak count
 */
export async function incrementStreak(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    // Get current habit data to read the streak_count
    const { data: habitData, error: habitError } = await supabase
      .from('habits')
      .select('streak_count')
      .eq('id', habitId)
      .eq('user_id', userId)
      .single();
      
    if (habitError) throw habitError;
    
    // Calculate the new streak count
    const currentStreak = habitData.streak_count || 0;
    const newStreak = currentStreak + 1;
    
    // Update the streak count
    const { error } = await supabase
      .from('habits')
      .update({
        streak_count: newStreak
      })
      .eq('id', habitId)
      .eq('user_id', userId);
      
    if (error) throw error;
    
    return true;
  } catch (error) {
    console.error('Error incrementing streak:', error);
    return false;
  }
}

/**
 * Decrement a habit's streak count
 */
export async function decrementStreak(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    // Get current habit data to read the streak_count
    const { data: habitData, error: habitError } = await supabase
      .from('habits')
      .select('streak_count')
      .eq('id', habitId)
      .eq('user_id', userId)
      .single();
      
    if (habitError) throw habitError;
    
    // Calculate the new streak count, ensuring it doesn't go below zero
    const currentStreak = habitData.streak_count || 0;
    const newStreak = Math.max(0, currentStreak - 1);
    
    // Update the streak count
    const { error } = await supabase
      .from('habits')
      .update({
        streak_count: newStreak
      })
      .eq('id', habitId)
      .eq('user_id', userId);
      
    if (error) throw error;
    
    return true;
  } catch (error) {
    console.error('Error decrementing streak:', error);
    return false;
  }
}

/**
 * Reset a habit's streak count to zero
 */
export async function resetStreak(habitId: string, userId: string | undefined): Promise<boolean> {
  if (!userId) return false;
  
  try {
    const { error } = await supabase
      .from('habits')
      .update({
        streak_count: 0
      })
      .eq('id', habitId)
      .eq('user_id', userId);
      
    if (error) throw error;
    
    return true;
  } catch (error) {
    console.error('Error resetting streak:', error);
    return false;
  }
}

/**
 * Get the current streak count for a habit
 */
export async function getStreakCount(habitId: string, userId: string | undefined): Promise<number> {
  if (!userId) return 0;
  
  try {
    const { data, error } = await supabase
      .from('habits')
      .select('streak_count')
      .eq('id', habitId)
      .eq('user_id', userId)
      .single();
      
    if (error) throw error;
    
    return data.streak_count || 0;
  } catch (error) {
    console.error('Error getting streak count:', error);
    return 0;
  }
}
