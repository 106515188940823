
import React, { useEffect, useState } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useAuth } from '@/contexts/AuthContext';
import { getBrowserTimezone } from '@/utils/date-utils';

// Common timezones
const popularTimezones = [
  { value: 'UTC', label: 'UTC (Coordinated Universal Time)' },
  { value: 'America/New_York', label: 'Eastern Time (ET)' },
  { value: 'America/Chicago', label: 'Central Time (CT)' },
  { value: 'America/Denver', label: 'Mountain Time (MT)' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
  { value: 'Europe/London', label: 'London (GMT)' },
  { value: 'Europe/Paris', label: 'Central European Time (CET)' },
  { value: 'Europe/Moscow', label: 'Moscow Time (MSK)' },
  { value: 'Asia/Tokyo', label: 'Japan Standard Time (JST)' },
  { value: 'Asia/Shanghai', label: 'China Standard Time (CST)' },
  { value: 'Australia/Sydney', label: 'Australian Eastern Time (AET)' },
];

// Additional common timezones to ensure good coverage without relying on Intl.supportedValuesOf
const additionalTimezones = [
  { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time (HAT)' },
  { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
  { value: 'America/Phoenix', label: 'Mountain Standard Time (MST)' },
  { value: 'America/Monterrey', label: 'Central Time (Mexico)' },
  { value: 'America/Toronto', label: 'Eastern Time (Canada)' },
  { value: 'America/Halifax', label: 'Atlantic Time (AT)' },
  { value: 'America/St_Johns', label: 'Newfoundland Time (NT)' },
  { value: 'America/Sao_Paulo', label: 'Brasilia Time (BRT)' },
  { value: 'Atlantic/Azores', label: 'Azores Time (AZOT)' },
  { value: 'Europe/Dublin', label: 'Irish Standard Time (IST)' },
  { value: 'Europe/Amsterdam', label: 'Central European Time (Netherlands)' },
  { value: 'Europe/Berlin', label: 'Central European Time (Germany)' },
  { value: 'Europe/Rome', label: 'Central European Time (Italy)' },
  { value: 'Europe/Madrid', label: 'Central European Time (Spain)' },
  { value: 'Europe/Athens', label: 'Eastern European Time (EET)' },
  { value: 'Europe/Istanbul', label: 'Turkey Time (TRT)' },
  { value: 'Asia/Jerusalem', label: 'Israel Standard Time (IST)' },
  { value: 'Asia/Dubai', label: 'Gulf Standard Time (GST)' },
  { value: 'Asia/Kolkata', label: 'India Standard Time (IST)' },
  { value: 'Asia/Bangkok', label: 'Indochina Time (ICT)' },
  { value: 'Asia/Singapore', label: 'Singapore Time (SGT)' },
  { value: 'Asia/Hong_Kong', label: 'Hong Kong Time (HKT)' },
  { value: 'Asia/Seoul', label: 'Korea Standard Time (KST)' },
  { value: 'Australia/Perth', label: 'Australian Western Time (AWST)' },
  { value: 'Australia/Adelaide', label: 'Australian Central Time (ACST)' },
  { value: 'Australia/Brisbane', label: 'Australian Eastern Time (AEST)' },
  { value: 'Pacific/Auckland', label: 'New Zealand Standard Time (NZST)' },
  { value: 'Pacific/Fiji', label: 'Fiji Time (FJT)' },
];

// Get all IANA timezones
const getTimezones = (): { value: string; label: string }[] => {
  // Start with our predefined lists
  const allTimezones = [...popularTimezones, ...additionalTimezones];
  
  // Deduplicate based on value
  const uniqueTimezones = allTimezones.filter((tz, index, self) =>
    index === self.findIndex(t => t.value === tz.value)
  );
  
  return uniqueTimezones.sort((a, b) => a.label.localeCompare(b.label));
};

interface TimezoneSelectProps {
  currentTimezone?: string;
  onChange?: (timezone: string) => void;
  disabled?: boolean;
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ 
  currentTimezone,
  onChange,
  disabled = false
}) => {
  const { userProfile, updateTimezone } = useAuth();
  const [open, setOpen] = useState(false);
  const [timezones] = useState(getTimezones());
  const [selectedTimezone, setSelectedTimezone] = useState(currentTimezone || userProfile?.timezone || getBrowserTimezone());
  
  useEffect(() => {
    // Update the selected timezone when the profile is loaded or props change
    if (currentTimezone) {
      setSelectedTimezone(currentTimezone);
    } else if (userProfile?.timezone) {
      setSelectedTimezone(userProfile.timezone);
    }
  }, [userProfile, currentTimezone]);
  
  const handleSelectTimezone = async (value: string) => {
    setSelectedTimezone(value);
    setOpen(false);

    if (onChange) {
      onChange(value);
    } else {
      // Only call updateTimezone if onChange isn't provided (default behavior)
      await updateTimezone(value);
    }
  };
  
  const selectedTimezoneName = timezones.find(tz => tz.value === selectedTimezone)?.label || selectedTimezone;
  
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between w-full"
          disabled={disabled}
        >
          {selectedTimezoneName}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder="Search timezone..." />
          <CommandEmpty>No timezone found</CommandEmpty>
          <CommandGroup className="max-h-[300px] overflow-auto">
            {timezones.map((tz) => (
              <CommandItem
                key={tz.value}
                value={tz.value}
                onSelect={() => handleSelectTimezone(tz.value)}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selectedTimezone === tz.value ? "opacity-100" : "opacity-0"
                  )}
                />
                {tz.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default TimezoneSelect;
