
import React from 'react';
import ResourceCard from './ResourceCard';
import { ResourcePreview } from '@/types/resource';
import { Skeleton } from '@/components/ui/skeleton';

interface ResourceListProps {
  resources: ResourcePreview[];
  isLoading: boolean;
}

const ResourceList: React.FC<ResourceListProps> = ({ resources, isLoading }) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="flex flex-col space-y-3">
            <Skeleton className="h-[200px] rounded-lg" />
          </div>
        ))}
      </div>
    );
  }

  if (resources.length === 0) {
    return (
      <div className="text-center p-12">
        <h3 className="text-lg font-medium mb-2">No resources found</h3>
        <p className="text-muted-foreground">
          Check back later for new content
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {resources.map((resource) => (
        <ResourceCard key={resource.id} resource={resource} />
      ))}
    </div>
  );
};

export default ResourceList;
