
import React, { useState } from 'react';
import { Clock, Plus } from 'lucide-react';
import { Input } from '@/components/ui/input';

interface EstimatedTimeInputProps {
  minutes?: number | null;
  onUpdate?: (minutes: number | null) => Promise<void>;
  autoIncrement?: boolean;
}

const EstimatedTimeInput: React.FC<EstimatedTimeInputProps> = ({
  minutes,
  onUpdate,
  autoIncrement = false
}) => {
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [timeValue, setTimeValue] = useState(minutes?.toString() || '');

  const handleSaveTime = async () => {
    if (onUpdate) {
      const minutes = timeValue.trim() === '' ? null : parseFloat(timeValue);
      
      if (timeValue.trim() !== '' && (isNaN(minutes!) || minutes! < 1)) {
        return; // Invalid input
      }
      
      await onUpdate(minutes);
      setIsEditingTime(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveTime();
    } else if (e.key === 'Escape') {
      setIsEditingTime(false);
      setTimeValue(minutes?.toString() || '');
    }
  };

  const formatTime = (minutes: number | null | undefined) => {
    if (!minutes) return null;
    
    if (autoIncrement) {
      // Show minutes and seconds for auto-incrementing tasks
      const mins = Math.floor(minutes);
      const secs = Math.round((minutes - mins) * 60);
      return `${mins}:${secs.toString().padStart(2, '0')} min`;
    } else {
      // Show only minutes for regular tasks
      return `${minutes} min`;
    }
  };

  if (isEditingTime) {
    return (
      <div className="flex items-center gap-1">
        <Clock className="h-3 w-3 flex-shrink-0 text-muted-foreground" />
        <Input
          type="number"
          min="1"
          step="0.1"
          className="h-6 w-16 text-xs px-1 py-0"
          value={timeValue}
          onChange={(e) => setTimeValue(e.target.value)}
          onBlur={handleSaveTime}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <span className="text-xs text-muted-foreground">min</span>
      </div>
    );
  }
  
  if (minutes) {
    return (
      <div 
        className="flex items-center text-muted-foreground cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors" 
        onClick={() => onUpdate && setIsEditingTime(true)}
        title="Click to edit estimated time"
      >
        <Clock className="h-3 w-3 mr-1" />
        {formatTime(minutes)}
      </div>
    );
  }
  
  // Sempre visibile, anche quando non c'è un valore
  return (
    <div 
      className="flex items-center gap-1 text-muted-foreground cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors"
      onClick={() => onUpdate && setIsEditingTime(true)}
      title="Add estimated time"
    >
      <Clock className="h-3 w-3" />
      {onUpdate && <Plus className="h-3 w-3" />}
    </div>
  );
};

export default EstimatedTimeInput;
