
import React from 'react';
import { Habit, TimeOfDay } from '@/types/habit';
import { HabitsEmptyState } from '@/components/habits/HabitsEmptyState';
import { HabitsSections } from '@/components/habits/HabitsSections';

interface HabitsContentProps {
  isLoading: boolean;
  habits: Habit[];
  habitsByTimeOfDay: Record<TimeOfDay, Habit[]>;
  timeOfDaySectionRefs: React.MutableRefObject<Record<TimeOfDay, React.RefObject<HTMLDivElement>>>;
  currentTimeOfDay: TimeOfDay;
  onAddHabit: (timeOfDay: TimeOfDay) => void;
  onCreateNew: () => void;
  onToggleComplete: (habitId: string, completed: boolean) => Promise<void>;
  onEdit: (habit: Habit) => void;
  onDelete: (habitId: string) => Promise<void>;
  onUpdateField: (habitId: string, field: string, value: any) => Promise<void>;
}

export const HabitsContent: React.FC<HabitsContentProps> = ({
  isLoading,
  habits,
  habitsByTimeOfDay,
  timeOfDaySectionRefs,
  currentTimeOfDay,
  onAddHabit,
  onCreateNew,
  onToggleComplete,
  onEdit,
  onDelete,
  onUpdateField
}) => {
  return (
    <>
      {isLoading || habits.length === 0 ? (
        <HabitsEmptyState 
          isLoading={isLoading}
          onCreateNew={onCreateNew}
        />
      ) : (
        <HabitsSections
          habitsByTimeOfDay={habitsByTimeOfDay}
          timeOfDaySectionRefs={timeOfDaySectionRefs}
          currentTimeOfDay={currentTimeOfDay}
          onAddHabit={onAddHabit}
          onToggleComplete={onToggleComplete}
          onEdit={onEdit}
          onDelete={onDelete}
          onUpdateField={onUpdateField}
        />
      )}
    </>
  );
};
