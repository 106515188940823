
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { useKeywords } from '@/hooks/use-keywords';
import { Badge } from '@/components/ui/badge';
import { X } from 'lucide-react';

const KeywordBulkAdd: React.FC = () => {
  const [input, setInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewKeywords, setPreviewKeywords] = useState<string[]>([]);
  const { useAddBulkKeywordsMutation } = useKeywords();
  const addBulkKeywords = useAddBulkKeywordsMutation();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    
    // Update preview keywords
    if (e.target.value.trim()) {
      // Parse keywords - split by newline or comma, then normalize
      const rawKeywords = e.target.value
        .split(/[\n,]/)
        .map(k => k.trim())
        .filter(k => k.length > 0);
      
      // Simply deduplicate exact matches (not case-insensitive)
      const uniqueKeywords = Array.from(new Set(rawKeywords));
      setPreviewKeywords(uniqueKeywords);
    } else {
      setPreviewKeywords([]);
    }
  };

  const removeKeyword = (keywordToRemove: string) => {
    const updatedPreview = previewKeywords.filter(
      k => k !== keywordToRemove // Now using exact match
    );
    setPreviewKeywords(updatedPreview);
    
    // Reconstruct the textarea input
    setInput(updatedPreview.join('\n'));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (previewKeywords.length === 0) return;
    
    setIsSubmitting(true);
    
    try {
      await addBulkKeywords.mutateAsync(previewKeywords);
      setInput(''); // Clear input on success
      setPreviewKeywords([]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="keywords">Add Multiple Keywords</Label>
          <Textarea
            id="keywords"
            placeholder="Enter keywords (one per line or comma-separated)"
            className="min-h-[150px]"
            value={input}
            onChange={handleChange}
          />
          <p className="text-sm text-muted-foreground">
            Enter keywords separated by commas or new lines. Only exact duplicates will be automatically ignored.
          </p>
        </div>
        
        {previewKeywords.length > 0 && (
          <div className="space-y-2">
            <Label>Keywords to add ({previewKeywords.length})</Label>
            <div className="flex flex-wrap gap-2 p-2 border rounded-md bg-muted/20 min-h-[60px]">
              {previewKeywords.map((keyword, index) => (
                <Badge 
                  key={`${keyword}-${index}`} 
                  variant="secondary"
                  className="flex items-center gap-1 px-3 py-1"
                >
                  {keyword}
                  <X 
                    className="h-3 w-3 cursor-pointer" 
                    onClick={() => removeKeyword(keyword)}
                  />
                </Badge>
              ))}
            </div>
          </div>
        )}
        
        <Button 
          type="submit" 
          disabled={previewKeywords.length === 0 || isSubmitting}
          className="w-full"
        >
          {isSubmitting ? 'Adding Keywords...' : `Add ${previewKeywords.length} Keywords`}
        </Button>
      </form>
    </div>
  );
};

export default KeywordBulkAdd;
