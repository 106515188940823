
import React from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import ProjectCard from '@/components/ProjectCard';
import NewProjectButton from '@/components/NewProjectButton';

interface ProjectGridProps {
  projects: Project[];
  collectionId?: string;
  onSuccess: () => void;
}

const ProjectGrid: React.FC<ProjectGridProps> = ({ 
  projects, 
  collectionId, 
  onSuccess 
}) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:gap-6 w-full max-w-3xl mx-auto">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          title={project.title}
          description={project.description || ''}
          lastUpdated={project.updated_at || ''}
          priority={(project.priority as 'asap' | 'high' | 'average' | 'low') || 'average'}
          status={project.status || 'active'}
          dueDate={project.due_date ? new Date(project.due_date) : undefined}
          tags={project.tags || []}
          project={project}
          onSuccess={onSuccess}
          isFavorite={project.is_favorite || false}
        />
      ))}
      
      {collectionId && (
        <div className="h-full">
          <NewProjectButton 
            collectionId={collectionId}
            onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectGrid;
