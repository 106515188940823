
import { supabase } from '@/integrations/supabase/client';
import { Prompt } from '@/types/prompt';

export async function fetchPrompts(userId: string): Promise<Prompt[]> {
  const { data, error } = await supabase
    .from('prompts')
    .select('*')
    .eq('user_id', userId)
    .order('updated_at', { ascending: false });

  if (error) {
    console.error('Error fetching prompts:', error);
    throw error;
  }

  return data as Prompt[];
}

export async function fetchPromptsByTag(userId: string, tag: string): Promise<Prompt[]> {
  const { data, error } = await supabase
    .from('prompts')
    .select('*')
    .eq('user_id', userId)
    .contains('tags', [tag])
    .order('updated_at', { ascending: false });

  if (error) {
    console.error('Error fetching prompts by tag:', error);
    throw error;
  }

  return data as Prompt[];
}

export async function fetchUserPromptTags(userId: string): Promise<string[]> {
  const { data, error } = await supabase
    .from('prompts')
    .select('tags')
    .eq('user_id', userId);

  if (error) {
    console.error('Error fetching user prompt tags:', error);
    throw error;
  }

  // Extract unique tags from all prompts
  const allTags = data.flatMap(item => item.tags || []);
  const uniqueTags = [...new Set(allTags)];
  
  return uniqueTags;
}
