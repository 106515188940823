
import React, { useState, useRef, useEffect } from 'react';
import { FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { X, ChevronDown } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../project-form-schema';
import { useUserTags } from '@/hooks/use-user-tags';
import { cn } from '@/lib/utils';

interface TagsFieldProps {
  form: UseFormReturn<ProjectFormValues>;
}

const TagsField: React.FC<TagsFieldProps> = ({ form }) => {
  const [newTag, setNewTag] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const { tags: userTags, loading: loadingTags } = useUserTags();
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  // Filter suggestions based on current input
  const filteredSuggestions = userTags.filter(tag => 
    tag.toLowerCase().includes(newTag.toLowerCase()) && 
    !form.watch('tags')?.includes(tag)
  );

  // Handle clicking outside suggestions to close them
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(event.target as Node) &&
        !inputRef.current?.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleAddTag = () => {
    if (!newTag.trim()) return;
    
    const currentTags = form.getValues('tags') || [];
    if (!currentTags.includes(newTag.trim())) {
      form.setValue('tags', [...currentTags, newTag.trim()]);
    }
    setNewTag('');
    setShowSuggestions(false);
    setSelectedSuggestionIndex(-1);
  };

  const handleRemoveTag = (tagToRemove: string) => {
    const currentTags = form.getValues('tags') || [];
    form.setValue('tags', currentTags.filter(tag => tag !== tagToRemove));
  };

  const handleSelectSuggestion = (tag: string) => {
    const currentTags = form.getValues('tags') || [];
    if (!currentTags.includes(tag)) {
      form.setValue('tags', [...currentTags, tag]);
    }
    setNewTag('');
    setShowSuggestions(false);
    setSelectedSuggestionIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Handle keyboard navigation for suggestions
    if (showSuggestions && filteredSuggestions.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedSuggestionIndex(prev => 
            prev < filteredSuggestions.length - 1 ? prev + 1 : prev
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedSuggestionIndex(prev => prev > 0 ? prev - 1 : 0);
          break;
        case 'Enter':
          e.preventDefault();
          if (selectedSuggestionIndex >= 0) {
            handleSelectSuggestion(filteredSuggestions[selectedSuggestionIndex]);
          } else {
            handleAddTag();
          }
          break;
        case 'Escape':
          e.preventDefault();
          setShowSuggestions(false);
          setSelectedSuggestionIndex(-1);
          break;
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag();
    }
  };

  return (
    <FormField
      control={form.control}
      name="tags"
      render={() => (
        <FormItem className="relative">
          <FormLabel>Tags</FormLabel>
          <div className="flex flex-wrap gap-2 mb-2">
            {form.watch('tags')?.map((tag) => (
              <Badge 
                key={tag} 
                variant="secondary"
                className="flex items-center gap-1 px-3 py-1"
              >
                {tag}
                <X 
                  className="h-3 w-3 cursor-pointer" 
                  onClick={() => handleRemoveTag(tag)} 
                />
              </Badge>
            ))}
          </div>
          <div className="flex gap-2 relative">
            <div className="flex-1 relative">
              <Input
                ref={inputRef}
                placeholder="Add a tag..."
                value={newTag}
                onChange={(e) => {
                  setNewTag(e.target.value);
                  setShowSuggestions(true);
                  setSelectedSuggestionIndex(-1);
                }}
                onFocus={() => setShowSuggestions(true)}
                onKeyDown={handleKeyDown}
                className="pr-8"
              />
              {userTags.length > 0 && (
                <button 
                  type="button"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-muted-foreground"
                  onClick={() => setShowSuggestions(!showSuggestions)}
                >
                  <ChevronDown className="h-4 w-4" />
                </button>
              )}
              
              {showSuggestions && filteredSuggestions.length > 0 && (
                <div 
                  ref={suggestionsRef}
                  className="absolute top-full left-0 w-full z-10 mt-1 py-1 bg-background border rounded-md shadow-md max-h-60 overflow-y-auto"
                >
                  {filteredSuggestions.map((tag, index) => (
                    <div
                      key={tag}
                      className={cn(
                        "px-3 py-2 cursor-pointer hover:bg-accent/50",
                        selectedSuggestionIndex === index && "bg-accent"
                      )}
                      onClick={() => handleSelectSuggestion(tag)}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Button 
              type="button" 
              variant="outline" 
              onClick={handleAddTag}
            >
              Add
            </Button>
          </div>
        </FormItem>
      )}
    />
  );
};

export default TagsField;
