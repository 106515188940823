
import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Tag } from 'lucide-react';

interface ProjectCardContentProps {
  description: string;
  tags: string[];
}

const ProjectCardContent: React.FC<ProjectCardContentProps> = ({
  tags
}) => {
  return (
    <>
      {/* Tags */}
      {tags && tags.length > 0 && (
        <div className="flex flex-wrap gap-1.5">
          {tags.map(tag => (
            <Badge key={tag} variant="secondary" className="flex items-center gap-1 text-xs bg-white/5">
              <Tag className="h-3 w-3" />
              {tag}
            </Badge>
          ))}
        </div>
      )}
    </>
  );
};

export default ProjectCardContent;
