
import { useState } from 'react';
import { fetchUserProfile } from '@/contexts/auth/api';
import { UserProfile } from '@/contexts/auth/types';
import { User } from '@supabase/supabase-js';

export function useAuthProfile(user: User | null) {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  
  // Initialize user profile
  if (user && !userProfile) {
    fetchUserProfile(user.id).then(profile => setUserProfile(profile));
  }

  return {
    userProfile,
    setUserProfile
  };
}
