
import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { LinksFormProps } from './types';

const LinkInputForm: React.FC<LinksFormProps> = ({ form }) => {
  const [newLinkUrl, setNewLinkUrl] = useState('');
  const [newLinkTitle, setNewLinkTitle] = useState('');

  const handleAddLink = () => {
    if (!newLinkUrl.trim()) return;
    
    try {
      // Validate URL format
      new URL(newLinkUrl);
      
      const currentLinks = form.getValues('links') || [];
      form.setValue('links', [
        ...currentLinks, 
        { 
          url: newLinkUrl.trim(), 
          title: newLinkTitle.trim() || newLinkUrl.trim()
        }
      ]);
      setNewLinkUrl('');
      setNewLinkTitle('');
    } catch (e) {
      toast.error('Please enter a valid URL');
    }
  };

  return (
    <div className="space-y-2">
      <Input
        placeholder="Link Title (optional)"
        value={newLinkTitle}
        onChange={(e) => setNewLinkTitle(e.target.value)}
      />
      <div className="flex gap-2">
        <Input
          placeholder="URL (https://...)"
          value={newLinkUrl}
          onChange={(e) => setNewLinkUrl(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddLink();
            }
          }}
        />
        <Button 
          type="button" 
          variant="outline" 
          onClick={handleAddLink}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default LinkInputForm;
