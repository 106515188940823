
import { useQuery } from '@tanstack/react-query';
import { getCategories } from '@/services/resources';

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ['resourceCategories'],
    queryFn: getCategories,
  });
};
