
import React, { useState } from 'react';
import { Habit } from '@/types/habit';
import { HabitTime } from '../edit/HabitTime';
import { HabitDescription } from '../dialogs/HabitDescription';
import { HabitFocusMode } from '../HabitFocusMode';
import { HabitDeleteDialog } from '../dialogs/HabitDeleteDialog';
import { Bell, Edit, PlusCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { HabitReminderDialog } from '../dialogs/HabitReminderDialog';
import { useReminders } from '@/hooks/reminders/use-reminders';
import { cn } from '@/lib/utils';
import { useIsMobile } from '@/hooks/use-mobile';

interface HabitActionsSectionProps {
  habit: Habit;
  onEdit: () => void;
  onDelete: (habitId: string) => Promise<void>;
  onUpdateField?: (habitId: string, field: string, value: any) => Promise<void>;
}

export function HabitActionsSection({ 
  habit, 
  onEdit, 
  onDelete,
  onUpdateField 
}: HabitActionsSectionProps) {
  const [isReminderDialogOpen, setIsReminderDialogOpen] = useState(false);
  const { reminders } = useReminders();
  const isMobile = useIsMobile();
  
  // Check if there are reminders associated with this habit
  const habitReminders = reminders.filter(
    reminder => reminder.title.includes(habit.title) || 
                (reminder.description && reminder.description.includes(habit.title))
  );
  
  const hasReminders = habitReminders.length > 0;

  const handleTimeEdit = async (minutes: number) => {
    if (onUpdateField) {
      await onUpdateField(habit.id, 'estimated_minutes', minutes);
    } else {
      // In a real implementation, we would update the time via API call here
      console.log(`Time changed from "${habit.estimated_minutes}" to "${minutes}"`);
    }
  };

  const handleDescriptionEdit = async (newDescription: string) => {
    if (onUpdateField) {
      await onUpdateField(habit.id, 'description', newDescription);
    } else {
      // In a real implementation, we would update the description via API call here
      console.log(`Description updated to "${newDescription}"`);
    }
  };
  
  // For mobile, we'll show only essential actions
  if (isMobile) {
    return (
      <div className="flex items-center gap-2">
        <HabitTime 
          minutes={habit.estimated_minutes} 
          onEditTime={handleTimeEdit}
          autoIncrement={habit.auto_increment_time}
        />
        
        <Button
          variant="ghost"
          size="sm"
          className="h-9 w-9 p-0"
          onClick={onEdit}
          title="Edit Habit"
        >
          <Edit className="h-4 w-4" />
        </Button>

        <HabitDeleteDialog 
          title={habit.title}
          habitId={habit.id}
          onDelete={onDelete}
        />
      </div>
    );
  }
  
  // For desktop, show all actions
  return (
    <div className="flex items-center gap-1 flex-wrap">
      <HabitTime 
        minutes={habit.estimated_minutes} 
        onEditTime={handleTimeEdit}
        autoIncrement={habit.auto_increment_time}
      />
      
      <HabitDescription 
        title={habit.title} 
        description={habit.description}
        onUpdateDescription={handleDescriptionEdit}
      />
      
      <HabitFocusMode habit={habit} />
      
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0"
        onClick={() => setIsReminderDialogOpen(true)}
        title="Add Reminder"
      >
        <Bell 
          className={cn(
            "h-4 w-4",
            hasReminders ? "text-blue-500 dark:text-blue-400" : ""
          )} 
        />
      </Button>
      
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0"
        onClick={onEdit}
        title="Edit Habit"
      >
        <Edit className="h-4 w-4" />
      </Button>

      <HabitDeleteDialog 
        title={habit.title}
        habitId={habit.id}
        onDelete={onDelete}
      />
      
      <HabitReminderDialog
        open={isReminderDialogOpen}
        onOpenChange={setIsReminderDialogOpen}
        habitTitle={habit.title}
      />
    </div>
  );
}
