
import React, { useState } from 'react';
import { AppRole, UserProfile } from '@/contexts/auth/types';
import UserRoleList from './role-management/UserRoleList';
import UserRoleForm from './role-management/UserRoleForm';
import { useRoleManager } from './role-management/useRoleManager';
import { Separator } from '@/components/ui/separator'; 
import RoleManagementSkeleton from './role-management/RoleManagementSkeleton';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UserRoleManagerProps {
  user: UserWithRoles;
  onAddRole: (userId: string, role: AppRole) => Promise<void>;
  onRemoveRole: (userId: string, role: AppRole) => Promise<void>;
  onClose: () => void;
}

const UserRoleManager: React.FC<UserRoleManagerProps> = ({ 
  user, 
  onAddRole, 
  onRemoveRole, 
  onClose 
}) => {
  const {
    currentRoles,
    isLoading,
    isRefreshing,
    refreshRoles,
    handleAddRole,
    handleRemoveRole,
    unusedRoles,
    selectedRole,
    setSelectedRole,
    isAddingRole
  } = useRoleManager({
    userId: user.id,
    userRoles: user.roles,
    onAddRole,
    onRemoveRole,
    onClose
  });

  if (isLoading && !currentRoles.length) {
    return <RoleManagementSkeleton />;
  }

  return (
    <div className="space-y-4 py-2">
      <div className="text-sm text-muted-foreground mb-2">
        Manage roles for <strong>{user.email}</strong>
      </div>

      <UserRoleList
        roles={currentRoles}
        onRemoveRole={handleRemoveRole}
        onRefreshRoles={refreshRoles}
        isRefreshing={isRefreshing}
      />
      
      {unusedRoles.length > 0 && (
        <>
          <Separator className="my-4" />
          <UserRoleForm
            availableRoles={unusedRoles}
            onAddRole={handleAddRole}
            onClose={onClose}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};

export default UserRoleManager;
