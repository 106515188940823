
import React from 'react';
import { Clock, Plus, CheckCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Habit, TimeOfDay } from '@/types/habit';
import { HabitListItem } from './HabitListItem';
import { getTimeRangeLabel } from '@/utils/time-of-day-utils';
import { wasCompletedToday } from '@/utils/time-of-day-utils';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

interface HabitTimeOfDaySectionProps {
  timeOfDay: TimeOfDay;
  habitsForTime: Habit[];
  currentTimeOfDay: TimeOfDay;
  onAddHabit: (timeOfDay: TimeOfDay) => void;
  onToggleComplete: (habitId: string, completed: boolean) => Promise<void>;
  onEdit: (habit: Habit) => void;
  onDelete: (habitId: string) => Promise<void>;
  onUpdateField?: (habitId: string, field: string, value: any) => Promise<void>;
}

export function HabitTimeOfDaySection({
  timeOfDay,
  habitsForTime,
  currentTimeOfDay,
  onAddHabit,
  onToggleComplete,
  onEdit,
  onDelete,
  onUpdateField
}: HabitTimeOfDaySectionProps) {
  const timeOfDayLabels = {
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    night: 'Night'
  };

  // Calculate completed habits statistics
  const totalHabits = habitsForTime.length;
  const completedHabits = habitsForTime.filter(habit => wasCompletedToday(habit.last_completed)).length;
  const completionPercentage = totalHabits > 0 ? Math.round((completedHabits / totalHabits) * 100) : 0;
  
  // Sort habits - completed ones at the bottom
  const sortedHabits = [...habitsForTime].sort((a, b) => {
    const aCompleted = wasCompletedToday(a.last_completed);
    const bCompleted = wasCompletedToday(b.last_completed);
    
    if (aCompleted === bCompleted) return 0;
    return aCompleted ? 1 : -1; // Completed habits go to the bottom
  });

  // Get motivational message based on completion percentage
  const getMotivationalMessage = () => {
    if (totalHabits === 0) return "";
    if (completionPercentage === 100) return "Great job! You've completed everything!";
    if (completionPercentage >= 75) return "Almost done! Keep going!";
    if (completionPercentage >= 50) return "You're halfway there! Keep it up!";
    if (completionPercentage >= 25) return "Good start! Keep completing your goals!";
    if (completedHabits > 0) return "You've started! Keep going!";
    return "Start completing your goals!";
  };

  return (
    <div 
      className={`rounded-lg p-4 transition-colors ${
        timeOfDay === currentTimeOfDay 
          ? 'bg-primary/10 border border-primary/20' 
          : 'bg-card/30'
      }`}
    >
      <div className="flex items-center mb-3 border-b pb-2">
        <h2 className={`text-xl font-medium flex items-center ${
          timeOfDay === currentTimeOfDay ? 'text-primary' : ''
        }`}>
          <Clock className={`h-5 w-5 mr-2 ${
            timeOfDay === currentTimeOfDay 
              ? 'text-primary' 
              : 'text-muted-foreground'
          }`} />
          <span>{timeOfDayLabels[timeOfDay]}</span>
          <span className={`text-xs ml-2 ${
            timeOfDay === currentTimeOfDay 
              ? 'text-primary/70' 
              : 'text-muted-foreground'
          }`}>
            ({getTimeRangeLabel(timeOfDay)})
            {timeOfDay === currentTimeOfDay && ' - Current time'}
          </span>
        </h2>
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={() => onAddHabit(timeOfDay)}
          className="ml-auto"
        >
          <Plus className="h-4 w-4 mr-1" />
          <span className="text-sm">Add</span>
        </Button>
      </div>
      
      {/* Stats section */}
      <div className="mb-4">
        <div className="flex justify-between items-center mb-1 text-sm">
          <div className="flex items-center">
            <CheckCircle className={cn(
              "h-4 w-4 mr-1",
              completedHabits > 0 ? "text-primary" : "text-muted-foreground"
            )} />
            <span>{completedHabits} / {totalHabits} completed</span>
          </div>
          <span className="font-medium">{completionPercentage}%</span>
        </div>
        <Progress 
          value={completionPercentage} 
          className={cn(
            "h-2", 
            completionPercentage === 100 ? "bg-green-200" : "",
            timeOfDay === currentTimeOfDay ? "bg-primary/20" : ""
          )}
        />
        
        {/* Motivational message */}
        {totalHabits > 0 && (
          <div className={cn(
            "text-xs mt-1 italic",
            completionPercentage === 100 ? "text-green-600 dark:text-green-400" : "text-muted-foreground",
            timeOfDay === currentTimeOfDay ? "font-medium" : ""
          )}>
            {getMotivationalMessage()}
          </div>
        )}
      </div>
      
      {sortedHabits.length === 0 ? (
        <div className="text-center py-4 text-muted-foreground text-sm">
          No habits for this time period.
        </div>
      ) : (
        <div className="grid gap-3">
          {sortedHabits.map(habit => (
            <HabitListItem
              key={habit.id}
              habit={habit}
              onToggleComplete={onToggleComplete}
              onEdit={() => onEdit(habit)}
              onDelete={onDelete}
              onUpdateField={onUpdateField}
            />
          ))}
        </div>
      )}
    </div>
  );
}
