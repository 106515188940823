
import React from 'react';
import { CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Clock, Search } from 'lucide-react';
import { formatTotalTime } from '../utils/time-utils';

interface TaskHeaderProps {
  totalEstimatedMinutes: number;
  searchTerm: string;
  onSearchChange: (value: string) => void;
}

/**
 * Header component for the task management section
 */
const TaskHeader: React.FC<TaskHeaderProps> = ({
  totalEstimatedMinutes,
  searchTerm,
  onSearchChange
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        <CardTitle>Tasks</CardTitle>
        {totalEstimatedMinutes > 0 && (
          <div className="text-sm text-muted-foreground flex items-center gap-1.5">
            <Clock className="h-4 w-4" />
            <span>Estimated: {formatTotalTime(totalEstimatedMinutes)}</span>
          </div>
        )}
      </div>
      <div className="relative w-full sm:w-64">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search tasks..."
          className="pl-8"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TaskHeader;
