
// Helper utilities for working with dates and timezones

/**
 * Format a date string using the browser's default locale
 */
export function formatDate(dateString: string, options?: Intl.DateTimeFormatOptions, timezone?: string): string {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  
  // Use provided options or default to a standard date format
  const formatOptions: Intl.DateTimeFormatOptions = options || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  
  // Add timezone if provided
  if (timezone) {
    formatOptions.timeZone = timezone;
  }
  
  return new Intl.DateTimeFormat('default', formatOptions).format(date);
}

/**
 * Format a date with time using the browser's default locale
 */
export function formatDateTime(dateString: string, options?: Intl.DateTimeFormatOptions, timezone?: string): string {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  
  // Use provided options or default to a standard datetime format
  const formatOptions: Intl.DateTimeFormatOptions = options || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  
  // Add timezone if provided
  if (timezone) {
    formatOptions.timeZone = timezone;
  }
  
  return new Intl.DateTimeFormat('default', formatOptions).format(date);
}

/**
 * Format time only using the browser's default locale
 */
export function formatTime(dateString: string, options?: Intl.DateTimeFormatOptions, timezone?: string): string {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  
  // Use provided options or default to a standard time format
  const formatOptions: Intl.DateTimeFormatOptions = options || {
    hour: '2-digit',
    minute: '2-digit',
  };
  
  // Add timezone if provided
  if (timezone) {
    formatOptions.timeZone = timezone;
  }
  
  return new Intl.DateTimeFormat('default', formatOptions).format(date);
}

/**
 * Get the current timezone from the browser
 */
export function getBrowserTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Convert a date to a specific timezone
 */
export function convertToTimezone(date: Date, timezone: string): Date {
  // This is a bit tricky as JS Date objects are always in the local timezone
  // We need to take the UTC time and adjust it to the target timezone
  const targetDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  return targetDate;
}

/**
 * Format a relative time (e.g. "2 hours ago", "yesterday", etc.)
 */
export function formatRelativeTime(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  if (diffInSeconds < 60) {
    return 'just now';
  }
  
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  }
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  }
  
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) {
    if (diffInDays === 1) {
      return 'yesterday';
    }
    return `${diffInDays} days ago`;
  }
  
  // For older dates, return the formatted date
  return formatDate(dateString);
}
