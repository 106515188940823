
import React, { useState } from 'react';
import { Task, CreateTaskPayload } from '@/types/task';
import TaskCreator from './tasks/TaskCreator';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Tabs } from "@/components/ui/tabs";
import { calculateTotalEstimatedTime } from './tasks/utils/time-utils';
import { filterTasks, countTasksByStatus, getStatusTabs } from './tasks/filters/TaskFilter';
import TaskHeader from './tasks/header/TaskHeader';
import TaskStatusTabs from './tasks/tabs/TaskStatusTabs';
import TaskStatusTabContent from './tasks/tabs/TaskStatusTabContent';

interface TaskManagementProps {
  projectId: string;
  tasks: Task[];
  onCreateTask: (task: CreateTaskPayload) => Promise<Task | null>;
  onUpdateTask: (task: any) => Promise<boolean>;
  onDeleteTask: (taskId: string) => Promise<boolean>;
  onToggleExpansion: (taskId: string) => void;
  onRefreshTasks: () => Promise<void>;
}

const TaskManagement: React.FC<TaskManagementProps> = ({
  projectId,
  tasks,
  onCreateTask,
  onUpdateTask,
  onDeleteTask,
  onToggleExpansion,
  onRefreshTasks
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState('in_progress');
  const [isCreatingTask, setIsCreatingTask] = useState(false);

  const filteredTasks = filterTasks(tasks, searchTerm, selectedTab);
  
  // Count tasks by status
  const taskCounts = countTasksByStatus(tasks);

  // Calculate total estimated time for all tasks
  const totalEstimatedMinutes = tasks.reduce((total, task) => {
    return total + calculateTotalEstimatedTime(task);
  }, 0);
  
  // Status tab configuration
  const statusTabs = getStatusTabs(taskCounts);

  const handleCreateTask = async (task: CreateTaskPayload) => {
    setIsCreatingTask(true);
    try {
      const result = await onCreateTask(task);
      return result;
    } finally {
      // Add a small delay before turning off the creating state to allow for UI updates
      setTimeout(() => setIsCreatingTask(false), 200);
    }
  };

  return (
    <Card>
      <CardHeader className="pb-3">
        <TaskHeader 
          totalEstimatedMinutes={totalEstimatedMinutes}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
        />
      </CardHeader>
      <CardContent>
        <TaskCreator 
          projectId={projectId} 
          onCreateTask={handleCreateTask} 
          className="transition-all duration-300"
        />

        <Tabs 
          defaultValue="in_progress"
          value={selectedTab}
          onValueChange={setSelectedTab}
          className="mt-6"
        >
          <TaskStatusTabs 
            statusTabs={statusTabs}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
          />
          
          {statusTabs.map(tab => (
            <TaskStatusTabContent
              key={tab.value}
              tabValue={tab.value}
              filteredTasks={filteredTasks}
              onUpdateTask={onUpdateTask}
              onDeleteTask={onDeleteTask}
              onToggleExpansion={onToggleExpansion}
              onCreateSubtask={handleCreateTask}
              projectId={projectId}
            />
          ))}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default TaskManagement;
