
import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ChevronDown, ChevronRight, FileText } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';

interface TaskItemContentProps {
  title: string;
  description?: string | null;
  isCompleted: boolean;
  hasSubtasks: boolean;
  isExpanded: boolean;
  onToggleExpansion: () => void;
  onUpdateTitle?: (newTitle: string) => void;
}

const TaskItemContent: React.FC<TaskItemContentProps> = ({
  title,
  description,
  isCompleted,
  hasSubtasks,
  isExpanded,
  onToggleExpansion,
  onUpdateTitle,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleTitleClick = (e: React.MouseEvent) => {
    if (!hasSubtasks && onUpdateTitle) {
      e.stopPropagation();
      setIsEditing(true);
    }
  };

  const handleTitleSubmit = () => {
    if (editedTitle.trim() && editedTitle !== title && onUpdateTitle) {
      onUpdateTitle(editedTitle);
    } else {
      setEditedTitle(title);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleTitleSubmit();
    } else if (e.key === 'Escape') {
      setEditedTitle(title);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex-grow">
      <div 
        className={cn(
          "flex items-center gap-2 cursor-pointer",
          isCompleted && "text-muted-foreground line-through"
        )}
        onClick={() => hasSubtasks && onToggleExpansion()}
      >
        {hasSubtasks && (
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-5 w-5 p-0"
            onClick={(e) => {
              e.stopPropagation();
              onToggleExpansion();
            }}
          >
            {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
          </Button>
        )}
        
        <div 
          className="flex-grow font-medium"
          onClick={handleTitleClick}
        >
          {isEditing ? (
            <Input
              ref={inputRef}
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              onBlur={handleTitleSubmit}
              onKeyDown={handleKeyDown}
              className="h-6 py-0 px-1"
            />
          ) : (
            title
          )}
        </div>

        {description && (
          <Dialog>
            <DialogTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm" 
                className="h-6 w-6 p-0 ml-2"
                onClick={(e) => e.stopPropagation()}
              >
                <FileText className="h-4 w-4" />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Description</DialogTitle>
              </DialogHeader>
              <Textarea
                value={description}
                readOnly
                className="min-h-[100px] mt-2"
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default TaskItemContent;
