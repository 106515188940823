
import React, { useState } from 'react';
import { Task, UpdateTaskPayload } from '@/types/task';
import { Button } from '@/components/ui/button';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { taskFormSchema, TaskFormValues } from './task-editor-schema';
import TaskFormFields from './TaskFormFields';
import { LinkItem } from '@/components/projects/form-fields/links/types';

interface TaskEditorProps {
  task: Task;
  onUpdate: (task: UpdateTaskPayload) => Promise<boolean>;
  onCancel: () => void;
}

const TaskEditor: React.FC<TaskEditorProps> = ({ task, onUpdate, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm<TaskFormValues>({
    resolver: zodResolver(taskFormSchema),
    defaultValues: {
      title: task.title,
      description: task.description || '',
      status: task.status,
      priority: task.priority,
      estimated_minutes: task.estimated_minutes || undefined,
      links: task.links || [],
      due_date: task.due_date ? new Date(task.due_date) : null,
      recurrence: task.recurrence as TaskFormValues['recurrence'],
      custom_recurrence: task.custom_recurrence ? {
        every: task.custom_recurrence.every,
        period: task.custom_recurrence.period as 'days' | 'weeks' | 'months' | 'years',
        day_of_week: task.custom_recurrence.day_of_week as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" | undefined
      } : null
    },
  });

  const handleSubmit = async (data: TaskFormValues) => {
    setIsSubmitting(true);
    
    try {
      const formattedLinks = (data.links || []).map(link => ({
        url: link.url,
        title: link.title
      })) as LinkItem[];
      
      // Format due_date to ISO string if it exists
      const dueDate = data.due_date ? data.due_date.toISOString() : null;
      
      // Format custom_recurrence to match the Task type structure
      const customRecurrence = data.custom_recurrence ? {
        every: data.custom_recurrence.every,
        period: data.custom_recurrence.period,
        day_of_week: data.custom_recurrence.day_of_week
      } : null;
      
      const success = await onUpdate({
        id: task.id,
        title: data.title,
        description: data.description,
        status: data.status,
        priority: data.priority,
        estimated_minutes: data.estimated_minutes,
        links: formattedLinks,
        due_date: dueDate,
        recurrence: data.recurrence,
        custom_recurrence: customRecurrence
      });
      
      if (success) {
        // Solo chiudiamo l'editor, senza spostamenti nella pagina
        onCancel();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form 
        onSubmit={form.handleSubmit(handleSubmit)} 
        className="space-y-4 p-3 border rounded-md bg-muted/20"
      >
        <TaskFormFields />
        
        <div className="flex justify-end gap-2">
          <Button 
            type="button" 
            variant="ghost" 
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            type="submit" 
            disabled={isSubmitting || !form.formState.isDirty}
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default TaskEditor;
