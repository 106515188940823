
export const htmlSamples = {
  headers: `<h1>H1 Heading</h1>\n<h2>H2 Heading</h2>\n<h3>H3 Heading</h3>`,
  emphasis: `<strong>Bold text</strong>\n<em>Italic text</em>\n<s>Strikethrough</s>`,
  lists: `<ul>\n  <li>Item 1</li>\n  <li>Item 2</li>\n  <li>\n    <ul>\n      <li>Nested item</li>\n    </ul>\n  </li>\n</ul>\n\n<ol>\n  <li>Ordered item 1</li>\n  <li>Ordered item 2</li>\n</ol>`,
  links: `<a href="https://example.com">Regular link</a>\n<a href="https://example.com" rel="dofollow">DoFollow link</a>`,
  tables: `<table>\n  <thead>\n    <tr>\n      <th>Header 1</th>\n      <th>Header 2</th>\n    </tr>\n  </thead>\n  <tbody>\n    <tr>\n      <td>Cell 1</td>\n      <td>Cell 2</td>\n    </tr>\n  </tbody>\n</table>`,
  howTo: `<section class="how-to">\n  <h2>How To Section</h2>\n\n  <div class="supplies">\n    <h3>What You'll Need</h3>\n    <ul>\n      <li>Item 1</li>\n      <li>Item 2</li>\n    </ul>\n  </div>\n\n  <div class="steps">\n    <h3>Steps</h3>\n    <ol>\n      <li>First step</li>\n      <li>Second step</li>\n      <li>Third step</li>\n    </ol>\n  </div>\n\n  <div class="results">\n    <h3>Results</h3>\n    <p>Description of the expected results.</p>\n  </div>\n</section>`,
  faq: `<section class="faq">\n  <h2>FAQ</h2>\n\n  <div class="faq-item">\n    <h3>Question 1?</h3>\n    <p>Answer to question 1.</p>\n  </div>\n\n  <div class="faq-item">\n    <h3>Question 2?</h3>\n    <p>Answer to question 2.</p>\n  </div>\n</section>`,
  custom: `<div class="custom-block">\n  <h3>Custom HTML Block</h3>\n  <p>This is a <strong>custom HTML</strong> block with styling.</p>\n  <ul>\n    <li>HTML Item 1</li>\n    <li>HTML Item 2</li>\n  </ul>\n</div>`
};
