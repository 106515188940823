
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Task } from '@/types/task';
import { getAllSubtaskIds } from '../task-utils';

export async function deleteTaskOperation(
  taskId: string,
  tasks: Task[],
  userId: string | undefined,
  projectId: string | undefined,
): Promise<boolean> {
  if (!userId || !projectId) {
    toast.error('You must be logged in to delete a task');
    return false;
  }

  try {
    // Get all subtasks recursively to show the correct count
    const subtaskIds = getAllSubtaskIds(tasks, taskId);
    const totalToDelete = 1 + subtaskIds.length;

    // Supabase will cascade delete subtasks due to the foreign key constraint
    const { error } = await supabase
      .from('tasks')
      .delete()
      .eq('id', taskId)
      .eq('user_id', userId);

    if (error) throw error;

    toast.success(`Deleted task${totalToDelete > 1 ? ` and ${subtaskIds.length} subtask${subtaskIds.length !== 1 ? 's' : ''}` : ''}`);
    
    return true;
  } catch (err: any) {
    console.error('Error deleting task:', err);
    toast.error(err.message || 'Failed to delete task');
    return false;
  }
}
