
import React from 'react';
import { FormItem, FormLabel } from '@/components/ui/form';
import { LinksFormProps } from './links/types';
import LinkList from './links/LinkList';
import LinkInputForm from './links/LinkInputForm';

const LinksField: React.FC<LinksFormProps> = ({ form }) => {
  return (
    <FormItem>
      <FormLabel>Links</FormLabel>
      <LinkList form={form} />
      <LinkInputForm form={form} />
    </FormItem>
  );
};

export default LinksField;
