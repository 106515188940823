
import React from 'react';
import { LinkItem } from '@/components/projects/form-fields/links/types';
import { TaskPriority } from '@/types/task';
import PrioritySelector from './PrioritySelector';
import EstimatedTimeInput from './EstimatedTimeInput';
import TaskLinks from './TaskLinks';
import { formatDate } from '@/utils/date-utils';
import { CalendarIcon, Link, RepeatIcon } from 'lucide-react';
import TaskRecurrenceDropdown from '../recurrence/TaskRecurrenceDropdown';
import { Task } from '@/types/task';

interface TaskItemMetadataProps {
  task: Task;
  priority: TaskPriority;
  estimatedMinutes: number | null;
  links: LinkItem[];
  due_date?: string | null;
  onUpdatePriority: (priority: TaskPriority) => Promise<void>;
  onUpdateEstimatedTime: (minutes: number | null) => Promise<void>;
  onTaskUpdated: (task: Partial<Task>) => Promise<boolean>;
}

const TaskItemMetadata: React.FC<TaskItemMetadataProps> = ({
  task,
  priority,
  estimatedMinutes,
  links,
  due_date,
  onUpdatePriority,
  onUpdateEstimatedTime,
  onTaskUpdated
}) => {
  return (
    <div className="mt-1 flex flex-wrap gap-2 text-xs text-muted-foreground">
      {/* Priority selector */}
      <PrioritySelector 
        priority={priority} 
        onUpdatePriority={onUpdatePriority} 
      />
      
      {/* Estimated time input */}
      <EstimatedTimeInput 
        minutes={estimatedMinutes} 
        onUpdate={onUpdateEstimatedTime}
        autoIncrement={task.auto_increment_time}
      />
      
      {/* Due date indicator */}
      {due_date ? (
        <div className="flex items-center gap-1 px-2 py-1 rounded-full border bg-background/50">
          <CalendarIcon className="h-3 w-3" />
          <span>{formatDate(due_date, { day: 'numeric', month: 'short', year: 'numeric' })}</span>
        </div>
      ) : (
        <div className="flex items-center gap-1 text-muted-foreground cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors"
             title="Add due date">
          <CalendarIcon className="h-3 w-3" />
        </div>
      )}
      
      {/* Recurrence dropdown */}
      <TaskRecurrenceDropdown 
        task={task} 
        onTaskUpdated={onTaskUpdated} 
      />
      
      {/* Links */}
      {links.length > 0 ? (
        <TaskLinks links={links} />
      ) : (
        <div className="flex items-center gap-1 text-muted-foreground cursor-pointer hover:bg-muted/50 p-0.5 rounded transition-colors"
             title="Add link">
          <Link className="h-3 w-3" />
        </div>
      )}
    </div>
  );
};

export default TaskItemMetadata;
