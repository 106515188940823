
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { Loader2 } from 'lucide-react';
import { useProjectCollection } from '@/hooks/use-project-collection';
import { findCollectionById } from '@/components/collections/project-collections-data';
import CollectionHeader from '@/components/collections/CollectionHeader';
import EmptyCollection from '@/components/collections/EmptyCollection';
import ProjectsGrid from '@/components/projects/ProjectsGrid';
import CollectionNotFound from '@/components/collections/CollectionNotFound';
import NewProjectButton from '@/components/NewProjectButton';
import { useDocumentTitle } from '@/hooks/use-document-title';
import QuickFilters from '@/components/projects/filters/QuickFilters';
import { useProjectFilters } from '@/hooks/projects/use-project-filters';

const ProjectCollection = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const collection = findCollectionById(collectionId);
  const { projects, loading, fetchProjects } = useProjectCollection(collectionId);
  
  const {
    searchQuery,
    setSearchQuery,
    selectedTags,
    setSelectedTags,
    showDueToday,
    setShowDueToday,
    showOverdue,
    setShowOverdue,
    showHighPriority,
    setShowHighPriority,
    showFavorites,
    setShowFavorites,
    clearAllFilters,
    hasActiveFilters,
    filteredProjects
  } = useProjectFilters(projects);
  
  useDocumentTitle(collection ? `${collection.name} Projects` : 'Collection');
  
  useEffect(() => {
    // Forza un aggiornamento dei progetti quando la collezione cambia
    if (collectionId) {
      fetchProjects();
    }
  }, [collectionId, fetchProjects]);

  // Se collection non è definito, visualizza il messaggio di errore
  if (!collection) {
    return (
      <ProtectedRoute>
        <MainLayout>
          <div className="max-w-4xl mx-auto w-full px-4">
            <CollectionNotFound />
          </div>
        </MainLayout>
      </ProtectedRoute>
    );
  }

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="max-w-4xl mx-auto w-full px-4">
          <CollectionHeader 
            icon={collection.icon}
            name={collection.name}
            description={collection.description}
          />
          
          {loading ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : projects.length === 0 ? (
            <EmptyCollection 
              collectionId={collectionId as string} 
              onSuccess={fetchProjects}
            />
          ) : (
            <>
              <QuickFilters
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                showDueToday={showDueToday}
                setShowDueToday={setShowDueToday}
                showOverdue={showOverdue}
                setShowOverdue={setShowOverdue}
                showHighPriority={showHighPriority}
                setShowHighPriority={setShowHighPriority}
                showFavorites={showFavorites}
                setShowFavorites={setShowFavorites}
                clearAllFilters={clearAllFilters}
                hasActiveFilters={hasActiveFilters}
              />
              
              <ProjectsGrid 
                projects={filteredProjects}
                onSuccess={fetchProjects}
              />
              
              {filteredProjects.length === 0 && hasActiveFilters && (
                <div className="text-center py-10">
                  <h3 className="text-lg font-medium mb-2">No projects found</h3>
                  <p className="text-muted-foreground mb-4">
                    No projects match the selected filters.
                  </p>
                  <button 
                    onClick={clearAllFilters}
                    className="text-primary hover:underline"
                  >
                    Clear all filters
                  </button>
                </div>
              )}
              
              {collectionId && (
                <div className="mt-8 max-w-3xl mx-auto">
                  <NewProjectButton 
                    collectionId={collectionId} 
                    onSuccess={fetchProjects}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default ProjectCollection;
