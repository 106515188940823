
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { 
  Task, 
  CreateTaskPayload,
  convertTaskToSupabase, 
  convertSupabaseToTask 
} from '@/types/task';

export async function createTaskOperation(
  taskData: CreateTaskPayload,
  tasks: Task[],
  userId: string | undefined,
  projectId: string | undefined,
): Promise<Task | null> {
  if (!userId || !projectId) {
    toast.error('You must be logged in to create a task');
    return null;
  }

  try {
    // Calculate position (add to end by default)
    let position = 0;
    if (!taskData.position) {
      const tasksAtSameLevel = tasks.filter(t => 
        taskData.parent_task_id 
          ? t.parent_task_id === taskData.parent_task_id 
          : !t.parent_task_id
      );
      position = tasksAtSameLevel.length > 0 
        ? Math.max(...tasksAtSameLevel.map(t => t.position)) + 1 
        : 0;
    }

    const newTask = {
      ...taskData,
      position,
      project_id: projectId,
      user_id: userId
    };

    const { data, error } = await supabase
      .from('tasks')
      .insert(convertTaskToSupabase(newTask))
      .select()
      .single();

    if (error) throw error;
    
    return convertSupabaseToTask(data) as Task;
  } catch (err: any) {
    console.error('Error creating task:', err);
    toast.error(err.message || 'Failed to create task');
    return null;
  }
}
