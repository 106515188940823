
import React from 'react';

interface ResourceContentProps {
  content: string;
}

const ResourceContent: React.FC<ResourceContentProps> = ({ content }) => {
  if (!content) {
    return null;
  }
  
  return (
    <div className="prose prose-lg prose-neutral dark:prose-invert max-w-none">
      <div 
        dangerouslySetInnerHTML={{ __html: content }} 
        className="space-y-6"
      />
    </div>
  );
};

export default ResourceContent;
