
import { useEffect } from 'react';

interface MetadataOptions {
  title: string;
  description?: string;
  withPrefix?: boolean;
  image?: string;
  ogType?: string; // Added ogType property
}

/**
 * Hook to set document metadata including title, description and Open Graph tags
 */
export const useMetadata = ({
  title,
  description,
  withPrefix = true,
  image = '/og-image.png',
  ogType = 'website' // Default to 'website' if not provided
}: MetadataOptions) => {
  useEffect(() => {
    // Set the document title
    const prefix = withPrefix ? 'MyHacks | ' : '';
    document.title = `${prefix}${title}`;
    
    // Set meta description if provided
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription && description) {
      metaDescription.setAttribute('content', description);
    }
    
    // Set Open Graph title
    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content', `${prefix}${title}`);
    } else {
      const newOgTitle = document.createElement('meta');
      newOgTitle.setAttribute('property', 'og:title');
      newOgTitle.setAttribute('content', `${prefix}${title}`);
      document.head.appendChild(newOgTitle);
    }
    
    // Set Open Graph description
    if (description) {
      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute('content', description);
      } else {
        const newOgDescription = document.createElement('meta');
        newOgDescription.setAttribute('property', 'og:description');
        newOgDescription.setAttribute('content', description);
        document.head.appendChild(newOgDescription);
      }
    }
    
    // Set Open Graph image
    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', image);
    }
    
    // Set Open Graph type
    const ogTypeElement = document.querySelector('meta[property="og:type"]');
    if (ogTypeElement) {
      ogTypeElement.setAttribute('content', ogType);
    } else {
      const newOgType = document.createElement('meta');
      newOgType.setAttribute('property', 'og:type');
      newOgType.setAttribute('content', ogType);
      document.head.appendChild(newOgType);
    }
    
    return () => {
      // Reset to default on unmount if needed
      if (!withPrefix) {
        document.title = 'MyHacks';
      }
    };
  }, [title, description, withPrefix, image, ogType]);
};
