
import { useQuery } from '@tanstack/react-query';
import { ResourceStatus, ResourcePreview } from '@/types/resource';
import { getResources, getResourceBySlug } from '@/services/resources';

export const useResourcesQuery = (status?: ResourceStatus, categoryId?: string, language = 'en') => {
  return useQuery({
    queryKey: ['resources', status, categoryId, language],
    queryFn: () => getResources(status, categoryId, language),
  });
};

export const useResourceBySlugQuery = (slug: string, language = 'en') => {
  return useQuery({
    queryKey: ['resource', slug, language],
    queryFn: () => getResourceBySlug(slug),
    enabled: !!slug,
  });
};
