
import { CustomRecurrenceSettings, DayOfWeekOption, PeriodOption } from '../recurrence-types';
import { Json } from '@/integrations/supabase/types';
import { UseTranslationResponse } from 'react-i18next';

// Define a type for the translation function based on what react-i18next actually exposes
export type TranslationFunction = UseTranslationResponse<'translation', undefined>['t'];

// Helper to check if an object has the required properties to be treated as CustomRecurrenceSettings
export const isValidCustomRecurrence = (obj: any): obj is CustomRecurrenceSettings => {
  return obj && 
    typeof obj === 'object' && 
    'every' in obj && 
    'period' in obj;
};
