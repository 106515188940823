
import React from 'react';
import { FileText, ChevronDown, ChevronRight, Star, Sparkles } from 'lucide-react';
import { 
  SidebarMenuItem, SidebarMenuButton, SidebarMenuSub, 
  SidebarMenuSubItem, SidebarMenuSubButton 
} from '@/components/ui/sidebar';
import { useSidebarResources } from '../hooks/use-sidebar-resources';
import { useSidebar } from '@/components/ui/sidebar';
import { useLocation } from 'react-router-dom';

export function ResourcesMenu() {
  const { state } = useSidebar();
  const location = useLocation();
  const { 
    isResourcesOpen, isResourcesActive, isAdmin,
    toggleResourcesOpen, navigateToResources, navigateToCategories, 
    navigateToPublicResources, navigateToKeywords, navigateToResourcesGenerator
  } = useSidebarResources();

  return (
    <SidebarMenuItem>
      <SidebarMenuButton 
        onClick={navigateToPublicResources} 
        className="w-full justify-between"
        isActive={isResourcesActive}
      >
        <div className="flex items-center gap-2">
          <FileText className="h-4 w-4" />
          <span className="transition-opacity duration-200 group-data-[collapsible=icon]:opacity-0">Resources</span>
        </div>
        {state !== 'collapsed' && isAdmin && (
          isResourcesOpen ? (
            <ChevronDown className="h-3 w-3" onClick={(e) => toggleResourcesOpen(e)} />
          ) : (
            <ChevronRight className="h-3 w-3" onClick={(e) => toggleResourcesOpen(e)} />
          )
        )}
      </SidebarMenuButton>
      
      {isResourcesOpen && state !== 'collapsed' && isAdmin && (
        <SidebarMenuSub>
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToPublicResources}
              isActive={location.pathname === '/resources'}
            >
              <span>Public Resources</span>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToResources}
              isActive={location.pathname === '/admin/resources'}
            >
              <div className="flex items-center gap-2">
                <span>Manage Resources</span>
                <Star className="h-2 w-2 text-amber-400 fill-amber-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToCategories}
              isActive={location.pathname === '/admin/resources/categories'}
            >
              <div className="flex items-center gap-2">
                <span>Categories</span>
                <Star className="h-2 w-2 text-amber-400 fill-amber-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToKeywords}
              isActive={location.pathname === '/admin/resources/keywords'}
            >
              <div className="flex items-center gap-2">
                <span>Keywords</span>
                <Star className="h-2 w-2 text-amber-400 fill-amber-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToResourcesGenerator}
              isActive={location.pathname === '/admin/resources/resources-generator'}
            >
              <div className="flex items-center gap-2">
                <span>Resource Generator</span>
                <Sparkles className="h-2 w-2 text-blue-400 fill-blue-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
        </SidebarMenuSub>
      )}
    </SidebarMenuItem>
  );
}
