
import React from 'react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertTriangle, Search } from 'lucide-react';

const ProjectNotFound = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col items-center justify-center py-16 px-4 text-center">
      <div className="rounded-full bg-amber-100 p-6 mb-6">
        <AlertTriangle className="h-12 w-12 text-amber-600" />
      </div>
      <h1 className="text-3xl font-bold mb-3">Project Not Found</h1>
      <p className="text-muted-foreground max-w-md mb-8">
        The project you're looking for doesn't exist, has been deleted, or you don't have permission to access it.
      </p>
      <div className="flex flex-col sm:flex-row gap-4">
        <Button 
          variant="outline" 
          onClick={() => navigate(-1)}
          className="flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Go Back
        </Button>
        <Button onClick={() => navigate('/projects')}>
          Browse Projects
        </Button>
      </div>
    </div>
  );
};

export default ProjectNotFound;
