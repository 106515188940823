
import React, { useState } from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useNavigate } from 'react-router-dom';
import ResourceList from '@/components/resources/ResourcesList';
import CategoriesList from '@/components/resources/CategoriesList';
import AdminPageHeader from '@/components/admin/common/AdminPageHeader';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useMetadata } from '@/hooks/use-metadata';
import { useResources } from '@/hooks/use-resources';

const AdminResources: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('resources');
  const navigate = useNavigate();
  
  // Get the current language from user settings or default to 'en'
  const language = 'en'; // This would typically come from a language context or user preferences
  
  // Fetch resources data
  const { useResourcesQuery, useCategoriesQuery } = useResources(language);
  const { data: resources = [], isLoading: resourcesLoading } = useResourcesQuery();
  const { data: categories = [], isLoading: categoriesLoading } = useCategoriesQuery();
  
  useMetadata({
    title: 'Resource Management',
    description: 'Manage resource content and categories for your platform.',
  });

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    
    // Update URL without causing a full page reload
    const url = new URL(window.location.href);
    if (value === 'categories') {
      url.pathname = '/admin/resources/categories';
    } else {
      url.pathname = '/admin/resources';
    }
    window.history.pushState({}, '', url.toString());
  };
  
  // Set initial tab based on URL
  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('/categories')) {
      setActiveTab('categories');
    } else {
      setActiveTab('resources');
    }
  }, []);

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <AdminPageHeader 
            title="Resource Management"
            description="Manage resources and categories for your knowledge base."
            createButtonText={activeTab === 'resources' ? "Create Resource" : "Create Category"}
            onCreateClick={() => {
              if (activeTab === 'resources') {
                navigate('/admin/resources/create');
              } else {
                navigate('/admin/resources/categories/create');
              }
            }}
            backRoute="/admin"
          />

          <div className="mt-6">
            <Tabs value={activeTab} onValueChange={handleTabChange}>
              <TabsList className="grid w-full grid-cols-2 mb-6">
                <TabsTrigger value="resources">Resources</TabsTrigger>
                <TabsTrigger value="categories">Categories</TabsTrigger>
              </TabsList>
              
              <TabsContent value="resources">
                <ResourceList 
                  resources={resources} 
                  isLoading={resourcesLoading} 
                  language={language} 
                />
              </TabsContent>
              
              <TabsContent value="categories">
                <CategoriesList 
                  categories={categories} 
                  isLoading={categoriesLoading} 
                  language={language} 
                />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminResources;
