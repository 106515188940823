
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useReminders } from '@/hooks/reminders/use-reminders';
import { ReminderForm } from '@/components/reminders/ReminderForm';
import { CreateReminderPayload } from '@/types/reminder';
import { addDays } from 'date-fns';
import { toast } from 'sonner';

interface HabitReminderDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  habitTitle: string;
}

export function HabitReminderDialog({ open, onOpenChange, habitTitle }: HabitReminderDialogProps) {
  const { createReminder } = useReminders();

  const handleCreateReminder = async (data: CreateReminderPayload) => {
    try {
      await createReminder(data);
      onOpenChange(false);
      toast.success('Reminder created successfully', {
        description: `You'll be reminded about "${habitTitle}" at the scheduled time`
      });
    } catch (error) {
      console.error('Failed to create reminder:', error);
      toast.error('Failed to create reminder');
    }
  };

  // Set default values for the reminder
  const defaultValues = {
    title: `Remember to: ${habitTitle}`,
    description: `Complete your habit: ${habitTitle}`,
    due_at: addDays(new Date(), 1).toISOString(), // Default to tomorrow
    notify_before: 15, // Default to 15 minutes before
    recurring: 'daily' as const // Default to daily recurrence
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Create Reminder for Habit</DialogTitle>
        </DialogHeader>
        
        <div className="mt-4">
          <ReminderForm 
            onSubmit={handleCreateReminder} 
            defaultValues={defaultValues} 
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
