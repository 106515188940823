
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../project-form-schema';

interface CollectionFieldProps {
  form: UseFormReturn<ProjectFormValues>;
}

const CollectionField: React.FC<CollectionFieldProps> = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="collection_id"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Collection</FormLabel>
          <Select 
            onValueChange={field.onChange} 
            defaultValue={field.value}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Select a collection" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectItem value="career">Career</SelectItem>
              <SelectItem value="family">Family</SelectItem>
              <SelectItem value="friends-social">Friends & Social</SelectItem>
              <SelectItem value="finances">Finances</SelectItem>
              <SelectItem value="love-relationship">Love & Relationship</SelectItem>
              <SelectItem value="health-fitness">Health & Fitness</SelectItem>
              <SelectItem value="personal-development">Personal Development</SelectItem>
              <SelectItem value="fun-recreation">Fun & Recreation</SelectItem>
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CollectionField;
