
import React from 'react';
import { format } from 'date-fns';
import { Bell, Calendar } from 'lucide-react';
import { Reminder } from '@/types/reminder';

interface ReminderMetadataProps {
  reminder: Reminder;
}

export function ReminderMetadata({ reminder }: ReminderMetadataProps) {
  const formatDueDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    
    if (date.toDateString() === today.toDateString()) {
      return `Today, ${format(date, 'HH:mm')}`;
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return `Tomorrow, ${format(date, 'HH:mm')}`;
    } else {
      return format(date, 'PPP, HH:mm');
    }
  };

  return (
    <div className="flex items-center gap-4 mt-3 text-sm text-muted-foreground">
      <div className="flex items-center gap-1">
        <Calendar className="h-4 w-4" />
        <span>{formatDueDate(reminder.due_at)}</span>
      </div>
      
      {reminder.notify_before > 0 && (
        <div className="flex items-center gap-1">
          <Bell className="h-4 w-4" />
          <span>{reminder.notify_before} min before</span>
        </div>
      )}
    </div>
  );
}
