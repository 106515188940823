
import React from 'react';
import EditableContent from './EditableContent';

interface EditableImportantInfoProps {
  projectId: string;
  importantInfo: string | null | undefined;
  onImportantInfoUpdated: () => void;
}

const EditableImportantInfo: React.FC<EditableImportantInfoProps> = ({
  projectId,
  importantInfo,
  onImportantInfoUpdated
}) => {
  return (
    <div className="mt-4">
      <h3 className="text-sm font-medium text-foreground">Important Information</h3>
      <EditableContent
        content={importantInfo}
        projectId={projectId}
        fieldName="important_info"
        tableName="projects"
        placeholderText="Add important information..."
        emptyText="Add important information..."
        onContentUpdated={onImportantInfoUpdated}
      />
    </div>
  );
};

export default EditableImportantInfo;
