
import React from 'react';
import { 
  FormField, 
  FormItem, 
  FormLabel, 
  FormControl, 
  FormDescription, 
  FormMessage 
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { HelpCircle } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { UseFormReturn } from 'react-hook-form';
import { OpenAIConfig } from '@/services/resources/openai-config-service';

interface ResourceTemplateTabProps {
  form: UseFormReturn<OpenAIConfig>;
  defaultTemplate: string;
}

const ResourceTemplateTab: React.FC<ResourceTemplateTabProps> = ({ form, defaultTemplate }) => {
  return (
    <FormField
      control={form.control}
      name="resource_template"
      render={({ field }) => (
        <FormItem>
          <div className="flex items-center gap-2">
            <FormLabel>Template Instructions</FormLabel>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent className="max-w-sm">
                  <p>Your template MUST instruct the AI to output HTML content with valid JSON containing the required fields: title, content, excerpt, meta_title, and meta_description.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <FormControl>
            <Textarea
              placeholder="Provide instructions for how resources should be structured..."
              className="min-h-[300px] font-mono text-sm"
              {...field}
              value={field.value || defaultTemplate}
            />
          </FormControl>
          <FormDescription>
            <p className="mb-2">Use <code>{'{keyword}'}</code> as a placeholder for the keyword value.</p>
            <div className="p-3 bg-amber-50 border border-amber-200 rounded-md mt-2">
              <h4 className="font-medium text-sm mb-1 text-amber-800">⚠️ Important</h4>
              <p className="text-xs mb-1 text-amber-700">Your template MUST instruct the AI to output content in this exact JSON format with HTML:</p>
              <pre className="text-xs p-2 bg-white rounded mt-1 whitespace-pre-wrap overflow-auto border border-amber-100">
{`{
  "title": "Title Here", 
  "content": "<h2>HTML Content Here</h2><p>Write content using HTML tags...</p>",
  "excerpt": "Short excerpt here...",
  "meta_title": "SEO title here",
  "meta_description": "SEO description here"
}`}
              </pre>
              <p className="text-xs mt-2 text-amber-700">All fields are required. The content field must contain HTML, not Markdown.</p>
            </div>
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ResourceTemplateTab;
