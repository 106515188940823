
import { useState } from 'react';
import { 
  Task, 
  CreateTaskPayload, 
  UpdateTaskPayload
} from '@/types/task';
import { createTaskOperation } from './operations/create-task';
import { updateTaskOperation } from './operations/update-task';
import { deleteTaskOperation } from './operations/delete-task';

interface TaskOperationsProps {
  projectId: string | undefined;
  userId: string | undefined;
  tasks: Task[];
  refreshTasks: () => Promise<void>;
}

export function useTaskOperations({ 
  projectId, 
  userId, 
  tasks, 
  refreshTasks 
}: TaskOperationsProps) {
  const [isProcessing, setIsProcessing] = useState(false);

  const createTask = async (taskData: CreateTaskPayload): Promise<Task | null> => {
    try {
      setIsProcessing(true);
      
      // Save scroll position before update
      const scrollPosition = window.scrollY;
      
      const newTask = await createTaskOperation(taskData, tasks, userId, projectId);
      
      // Update without moving the scroll
      await refreshTasks();
      window.scrollTo(0, scrollPosition);
      
      return newTask;
    } finally {
      setIsProcessing(false);
    }
  };

  const updateTask = async (taskData: UpdateTaskPayload): Promise<boolean> => {
    try {
      setIsProcessing(true);
      
      // Save scroll position before update
      const scrollPosition = window.scrollY;
      
      const success = await updateTaskOperation(taskData, tasks, userId, projectId);
      
      // Update and restore scroll position
      await refreshTasks();
      window.scrollTo(0, scrollPosition);
      
      return success;
    } finally {
      setIsProcessing(false);
    }
  };

  const deleteTask = async (taskId: string): Promise<boolean> => {
    try {
      setIsProcessing(true);
      
      // Save scroll position before deletion
      const scrollPosition = window.scrollY;
      
      const success = await deleteTaskOperation(taskId, tasks, userId, projectId);
      
      // Update and restore scroll position
      await refreshTasks();
      window.scrollTo(0, scrollPosition);
      
      return success;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    createTask,
    updateTask,
    deleteTask,
    isProcessing
  };
}
