
import React from 'react';
import { Goal, UpdateGoalPayload } from '@/types/goal';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import GoalForm from './GoalForm';

interface EditGoalDialogProps {
  isOpen: boolean;
  onClose: () => void;
  goal: Goal;
  projectId: string;
  onUpdateGoal: (goalData: UpdateGoalPayload) => Promise<boolean>;
}

const EditGoalDialog: React.FC<EditGoalDialogProps> = ({
  isOpen,
  onClose,
  goal,
  projectId,
  onUpdateGoal
}) => {
  const handleUpdateGoal = async (formData: any) => {
    const updatedGoalData: UpdateGoalPayload = {
      id: goal.id,
      ...formData
    };

    const success = await onUpdateGoal(updatedGoalData);
    if (success) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Edit Goal</DialogTitle>
          <DialogDescription>
            Update goal details for this project
          </DialogDescription>
        </DialogHeader>

        <GoalForm
          projectId={projectId}
          goal={goal}
          onSubmit={handleUpdateGoal}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditGoalDialog;
