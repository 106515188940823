
import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, Save } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { UseFormReturn } from 'react-hook-form';
import { OpenAIConfig } from '@/services/resources/openai-config-service';
import ResourceTemplateTab from './ResourceTemplateTab';
import AuthorProfileTab from './AuthorProfileTab';

interface ContentConfigCardProps {
  form: UseFormReturn<OpenAIConfig>;
  defaultTemplate: string;
  defaultAuthorProfile: string;
  isSubmitting: boolean;
}

const ContentConfigCard: React.FC<ContentConfigCardProps> = ({ 
  form, 
  defaultTemplate, 
  defaultAuthorProfile,
  isSubmitting 
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Content Configuration</CardTitle>
        <CardDescription>
          Customize how resources are generated from keywords
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Alert variant="default" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Using the OpenAI API key configured in Supabase Edge Function Secrets
          </AlertDescription>
        </Alert>

        <Tabs defaultValue="template" className="w-full">
          <TabsList className="mb-4 w-full justify-start">
            <TabsTrigger value="template">Content Template</TabsTrigger>
            <TabsTrigger value="author">Author Profile</TabsTrigger>
          </TabsList>
          
          <TabsContent value="template">
            <ResourceTemplateTab 
              form={form} 
              defaultTemplate={defaultTemplate} 
            />
          </TabsContent>
          
          <TabsContent value="author">
            <AuthorProfileTab 
              form={form} 
              defaultAuthorProfile={defaultAuthorProfile} 
            />
          </TabsContent>
        </Tabs>
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button type="submit" disabled={isSubmitting}>
          <Save className="mr-2 h-4 w-4" />
          Save Configuration
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ContentConfigCard;
