
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { UseFormReturn } from 'react-hook-form';
import { ResourceFormValues } from '@/hooks/resources/resource-form-types';
import HtmlEditor from './html-editor/HtmlEditor';

interface ContentFieldProps {
  form: UseFormReturn<ResourceFormValues>;
}

const ContentField: React.FC<ContentFieldProps> = ({ form }) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="content"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Content (HTML)</FormLabel>
            <FormControl>
              <HtmlEditor
                value={field.value || ''}
                onChange={(value) => form.setValue('content', value, { shouldDirty: true })}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default ContentField;
