
import React from 'react';
import { Button } from '@/components/ui/button';
import { LinkIcon, X } from 'lucide-react';
import { LinksFormProps } from './types';

const LinkList: React.FC<LinksFormProps> = ({ form }) => {
  const handleRemoveLink = (urlToRemove: string) => {
    const currentLinks = form.getValues('links') || [];
    form.setValue('links', currentLinks.filter(link => link.url !== urlToRemove));
  };

  return (
    <div className="space-y-2 mb-2">
      {form.watch('links')?.map((link) => (
        <div 
          key={link.url} 
          className="flex items-center gap-2 p-2 rounded-md border border-border"
        >
          <LinkIcon className="h-4 w-4 text-muted-foreground" />
          <div className="flex-1 truncate">
            <p className="text-sm font-medium">{link.title}</p>
            <p className="text-xs text-muted-foreground">{link.url}</p>
          </div>
          <Button 
            type="button" 
            variant="ghost" 
            size="sm" 
            onClick={() => handleRemoveLink(link.url)}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default LinkList;
