
import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

const RoleManagementSkeleton: React.FC = () => {
  return (
    <div className="space-y-4 py-2">
      <Skeleton className="h-4 w-3/4" />
      
      <div className="space-y-2">
        <Skeleton className="h-5 w-1/4" />
        <div className="flex gap-2">
          <Skeleton className="h-8 w-20" />
          <Skeleton className="h-8 w-24" />
        </div>
      </div>
      
      <Skeleton className="h-px w-full my-4" />
      
      <div className="space-y-2">
        <Skeleton className="h-5 w-1/4" />
        <Skeleton className="h-10 w-full" />
        <div className="flex justify-end gap-2 mt-4">
          <Skeleton className="h-10 w-24" />
          <Skeleton className="h-10 w-24" />
        </div>
      </div>
    </div>
  );
};

export default RoleManagementSkeleton;
