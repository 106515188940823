
import { useState } from 'react';
import { Habit } from '@/types/habit';
import { format } from 'date-fns';

export function useHabitDayFilter() {
  const [showAllDays, setShowAllDays] = useState(false);
  
  // Get current day of week
  const today = new Date();
  const currentDayOfWeek = format(today, 'EEEE').toLowerCase();

  // Filter habits by day of week - only show habits for today if toggle is off
  const filterHabitsByDay = (habitsList: Habit[]) => {
    if (showAllDays) {
      return habitsList;
    }
    
    return habitsList.filter(habit => 
      habit.days_of_week && 
      habit.days_of_week.includes(currentDayOfWeek)
    );
  };

  return {
    showAllDays,
    setShowAllDays,
    filterHabitsByDay,
    currentDayOfWeek
  };
}
