
import React from 'react';
import { Habit, TimeOfDay } from '@/types/habit';
import { HabitTimeOfDaySection } from '@/components/habits/HabitTimeOfDaySection';

interface HabitsSectionsProps {
  habitsByTimeOfDay: Record<TimeOfDay, Habit[]>;
  timeOfDaySectionRefs: React.MutableRefObject<Record<TimeOfDay, React.RefObject<HTMLDivElement>>>;
  currentTimeOfDay: TimeOfDay;
  onAddHabit: (timeOfDay: TimeOfDay) => void;
  onToggleComplete: (habitId: string, completed: boolean) => Promise<void>;
  onEdit: (habit: Habit) => void;
  onDelete: (habitId: string) => Promise<void>;
  onUpdateField: (habitId: string, field: string, value: any) => Promise<void>;
}

export const HabitsSections: React.FC<HabitsSectionsProps> = ({
  habitsByTimeOfDay,
  timeOfDaySectionRefs,
  currentTimeOfDay,
  onAddHabit,
  onToggleComplete,
  onEdit,
  onDelete,
  onUpdateField
}) => {
  return (
    <div className="space-y-8">
      {(Object.entries(habitsByTimeOfDay) as [TimeOfDay, Habit[]][]).map(([timeOfDay, habitsForTime]) => (
        <div 
          key={timeOfDay} 
          ref={timeOfDaySectionRefs.current[timeOfDay]}
        >
          <HabitTimeOfDaySection
            timeOfDay={timeOfDay}
            habitsForTime={habitsForTime}
            currentTimeOfDay={currentTimeOfDay}
            onAddHabit={onAddHabit}
            onToggleComplete={onToggleComplete}
            onEdit={onEdit}
            onDelete={onDelete}
            onUpdateField={onUpdateField}
          />
        </div>
      ))}
    </div>
  );
};
