
import React from 'react';
import { Bell } from 'lucide-react';
import { 
  SidebarMenuItem, 
  SidebarMenuButton 
} from '@/components/ui/sidebar';
import { useSidebarReminders } from '../hooks/use-sidebar-reminders';

export function RemindersMenu() {
  const { navigateToReminders, isRemindersActive } = useSidebarReminders();

  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        onClick={navigateToReminders}
        tooltip="Reminders"
        isActive={isRemindersActive}
      >
        <Bell className="h-4 w-4" />
        <span>Reminders</span>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
