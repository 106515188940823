
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Edit, Plus } from 'lucide-react';
import UserRoleBadge from '../UserRoleBadge';
import UserActions from '../UserActions';
import { AppRole, UserProfile } from '@/contexts/auth/types';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UsersTableContentProps {
  users: UserWithRoles[];
  onOpenRoleDialog: (user: UserWithRoles) => void;
  onOpenProfileDialog: (user: UserWithRoles) => void;
  onRemoveRole: (userId: string, role: AppRole) => Promise<void>;
  onDeleteUser: (userId: string) => Promise<void>;
}

const UsersTableContent: React.FC<UsersTableContentProps> = ({
  users,
  onOpenRoleDialog,
  onOpenProfileDialog,
  onRemoveRole,
  onDeleteUser
}) => {
  return (
    <div className="border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[250px]">User</TableHead>
            <TableHead>Roles</TableHead>
            <TableHead>Name</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell className="font-medium">{user.email}</TableCell>
              <TableCell>
                <div className="flex flex-wrap gap-1">
                  {user.roles.map((role) => (
                    <UserRoleBadge
                      key={`${user.id}-${role}`}
                      role={role}
                      onRemove={
                        // Only allow role removal if user has more than one role
                        // (users should always have at least one role)
                        user.roles.length > 1
                          ? () => onRemoveRole(user.id, role)
                          : undefined
                      }
                    />
                  ))}
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-6 w-6 rounded-full"
                    onClick={() => onOpenRoleDialog(user)}
                  >
                    <Plus className="h-3 w-3" />
                    <span className="sr-only">Add role</span>
                  </Button>
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  <span>
                    {user.profile?.first_name || user.profile?.last_name
                      ? `${user.profile.first_name || ''} ${
                          user.profile.last_name || ''
                        }`
                      : 'No name set'}
                  </span>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-6 w-6"
                    onClick={() => onOpenProfileDialog(user)}
                  >
                    <Edit className="h-3 w-3" />
                    <span className="sr-only">Edit profile</span>
                  </Button>
                </div>
              </TableCell>
              <TableCell className="text-right">
                <UserActions 
                  user={user} 
                  onDeleteUser={onDeleteUser}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UsersTableContent;
