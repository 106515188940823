
import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Clock } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import PriorityDropdown from './dropdowns/PriorityDropdown';
import StatusDropdown from './dropdowns/StatusDropdown';
import DueDateDropdown from './dropdowns/DueDateDropdown';
import RecurrenceDropdown from './dropdowns/RecurrenceDropdown';
import TagsDropdown from './dropdowns/TagsDropdown';

interface ProjectBadgesProps {
  project: {
    id: string;
    priority?: string | null;
    status?: string | null;
    due_date?: string | null;
    recurrence?: string | null;
    tags?: string[] | null;
  };
  tasksCount: number;
  onProjectUpdated: () => void;
}

const ProjectBadges: React.FC<ProjectBadgesProps> = ({ 
  project, 
  tasksCount,
  onProjectUpdated 
}) => {
  const { t } = useTranslation();
  
  return (
    <div className="flex flex-wrap gap-2 mt-4">
      {project.priority && (
        <PriorityDropdown 
          priority={project.priority} 
          projectId={project.id} 
          onProjectUpdated={onProjectUpdated} 
        />
      )}
      
      {project.status && (
        <StatusDropdown 
          status={project.status} 
          projectId={project.id} 
          onProjectUpdated={onProjectUpdated} 
        />
      )}
      
      <DueDateDropdown
        dueDate={project.due_date || null}
        projectId={project.id}
        onProjectUpdated={onProjectUpdated}
      />
      
      <RecurrenceDropdown
        recurrence={project.recurrence || null}
        projectId={project.id}
        onProjectUpdated={onProjectUpdated}
      />
      
      <TagsDropdown
        tags={project.tags || null}
        projectId={project.id}
        onProjectUpdated={onProjectUpdated}
      />
      
      <Badge variant="secondary" className="flex items-center gap-1">
        <Clock className="h-3 w-3" />
        {tasksCount} {t('projects.tasks', 'task', { count: tasksCount })}
      </Badge>
    </div>
  );
};

export default ProjectBadges;
