
import React, { useState } from 'react';
import { useHabits } from '@/hooks/habits/use-habits';
import { getCurrentTimeOfDay } from '@/utils/time-of-day-utils';
import { Habit } from '@/types/habit';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useHabitDayFilter } from '@/hooks/habits/use-habit-day-filter';
import { HabitListItem } from '@/components/habits/HabitListItem';
import { Button } from '@/components/ui/button';
import { ArrowRight, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { HabitsEmptyState } from '@/components/habits/HabitsEmptyState';

const TodayHabitsSection = () => {
  const { habits, isLoading, toggleHabitCompletion, refreshHabits, celebration } = useHabits();
  const { filterHabitsByDay, currentDayOfWeek } = useHabitDayFilter();
  const currentTimeOfDay = getCurrentTimeOfDay();
  
  // Filter habits for today and current time of day
  const filteredHabits = filterHabitsByDay(habits).filter(
    habit => habit.time_of_day === currentTimeOfDay
  );
  
  // Function stubs for habit operations (will navigate to habits page)
  const handleEdit = () => {}; // Fixed: removed the habit parameter since it's not used
  const handleDelete = async () => { return Promise.resolve(); };
  const handleUpdateField = async () => { return Promise.resolve(); };

  if (isLoading) {
    return (
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle className="flex items-center">
            <Clock className="mr-2 h-5 w-5" />
            <span>Current Time Habits</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-center h-40">
            <div className="animate-pulse text-muted-foreground">Loading habits...</div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="shadow-md h-full">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="flex items-center text-xl">
          <Clock className="mr-2 h-5 w-5" />
          <span>Current Time Habits</span>
        </CardTitle>
        <Link to="/habits">
          <Button variant="ghost" size="sm" className="text-xs">
            View All <ArrowRight className="ml-1 h-3 w-3" />
          </Button>
        </Link>
      </CardHeader>
      <CardContent>
        {filteredHabits.length === 0 ? (
          <div className="text-center p-6">
            <p className="text-muted-foreground mb-2">No habits for this time period.</p>
            <Link to="/habits">
              <Button variant="outline" size="sm">Create a habit</Button>
            </Link>
          </div>
        ) : (
          <div className="space-y-3">
            {filteredHabits.map(habit => (
              <HabitListItem
                key={habit.id}
                habit={habit}
                onToggleComplete={async (id, completed) => {
                  await toggleHabitCompletion(id, completed);
                }}
                onEdit={handleEdit} // This now matches the expected type
                onDelete={handleDelete}
                onUpdateField={handleUpdateField}
              />
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TodayHabitsSection;
