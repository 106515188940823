
import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const FaqSection: React.FC = () => {
  const faqs = [
    {
      question: "What makes this platform different from other productivity tools?",
      answer: "Our platform integrates project management, task tracking, habit building, and AI assistance in one seamless solution. Most competitors offer only one or two of these features, requiring you to juggle multiple tools."
    },
    {
      question: "Is there a free plan available?",
      answer: "Yes, we offer a generous free tier that includes essential features for personal productivity. Premium plans with advanced features are available for power users and teams."
    },
    {
      question: "Can I use this platform for team collaboration?",
      answer: "Absolutely! Our platform is designed for both individual and team use. You can easily share projects, assign tasks, and collaborate with team members in real-time."
    },
    {
      question: "How secure is my data on your platform?",
      answer: "We take security very seriously. All data is encrypted both in transit and at rest. We use industry-standard security practices and regular audits to ensure your information remains private and protected."
    },
    {
      question: "Can I integrate this with other tools I already use?",
      answer: "Yes, we offer integrations with popular productivity and communication tools. Our API also allows for custom integrations if you have specific requirements."
    },
    {
      question: "Is there a mobile app available?",
      answer: "Yes, we have native mobile apps for iOS and Android, allowing you to stay productive on the go. Your data syncs seamlessly across all your devices."
    }
  ];

  return (
    <div className="py-20">
      <div className="max-w-4xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Find answers to common questions about our platform
          </p>
        </div>
        
        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger className="text-lg font-medium text-left">{faq.question}</AccordionTrigger>
              <AccordionContent className="text-muted-foreground">{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqSection;
