
import React, { useState, useEffect, useRef } from 'react';
import { Clock, Pause, Play, Volume2, VolumeX } from 'lucide-react';
import AudioFiles from './AudioFiles';

interface FocusTimerProps {
  estimatedMinutes: number;
  autoIncrement?: boolean;
}

const FocusTimer: React.FC<FocusTimerProps> = ({ estimatedMinutes, autoIncrement = false }) => {
  const [timeLeft, setTimeLeft] = useState(Math.ceil(estimatedMinutes * 60));
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Create audio element for the gong sound
    audioRef.current = new Audio(AudioFiles.gong);
    
    return () => {
      // Cleanup audio
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isPaused || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = Math.max(0, prev - 1);
        
        // Play the gong sound when timer reaches zero
        if (newTime === 0 && prev > 0 && !isMuted) {
          console.log("Timer reached zero, playing sound");
          if (audioRef.current) {
            // Ensure the audio is ready to play
            audioRef.current.currentTime = 0;
            audioRef.current.play().catch(error => {
              console.error("Error playing sound:", error);
            });
          } else {
            console.error("Audio reference is null");
          }
        }
        
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPaused, timeLeft, isMuted]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  // For testing - add a button to force play the sound
  const testSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch(error => {
        console.error("Error playing test sound:", error);
      });
    }
  };

  const formatEstimatedTime = () => {
    if (!autoIncrement) return `${Math.round(estimatedMinutes)} min`;
    
    const mins = Math.floor(estimatedMinutes);
    const secs = Math.round((estimatedMinutes - mins) * 60);
    return `${mins}:${secs.toString().padStart(2, '0')} min`;
  };

  return (
    <div className="text-center space-y-4">
      <div className="flex items-center justify-center gap-3 text-5xl font-mono font-bold">
        <Clock className="h-10 w-10" />
        <span>
          {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
        </span>
      </div>
      {autoIncrement && (
        <div className="text-sm text-muted-foreground">
          Estimated: {formatEstimatedTime()}
        </div>
      )}
      <div className="flex items-center justify-center gap-4">
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="flex items-center gap-2 hover:text-primary text-lg"
        >
          {isPaused ? (
            <Play className="h-6 w-6" />
          ) : (
            <Pause className="h-6 w-6" />
          )}
          {isPaused ? 'Resume' : 'Pause'}
        </button>
        
        <button
          onClick={toggleMute}
          className="flex items-center gap-2 hover:text-primary"
          title={isMuted ? "Unmute" : "Mute"}
        >
          {isMuted ? (
            <VolumeX className="h-6 w-6" />
          ) : (
            <Volume2 className="h-6 w-6" />
          )}
        </button>
        
        {/* Test button for development */}
        <button
          onClick={testSound}
          className="text-xs bg-gray-200 dark:bg-gray-700 px-2 py-1 rounded"
        >
          Test Sound
        </button>
      </div>
    </div>
  );
};

export default FocusTimer;
