
import { supabase } from "@/integrations/supabase/client";
import { Reminder } from "@/types/reminder";

/**
 * Fetch all reminders for the current user
 */
export async function getReminders(): Promise<Reminder[]> {
  const { data, error } = await supabase
    .from('reminders')
    .select('*')
    .order('due_at', { ascending: true });

  if (error) {
    console.error("Error fetching reminders:", error);
    throw error;
  }

  return data as Reminder[];
}

/**
 * Fetch upcoming reminders (pending and due in the future)
 */
export async function getUpcomingReminders(): Promise<Reminder[]> {
  const now = new Date().toISOString();
  
  const { data, error } = await supabase
    .from('reminders')
    .select('*')
    .eq('status', 'pending')
    .gt('due_at', now)
    .order('due_at', { ascending: true })
    .limit(5);

  if (error) {
    console.error("Error fetching upcoming reminders:", error);
    throw error;
  }

  return data as Reminder[];
}

/**
 * Fetch reminders that are due now or overdue
 */
export async function getDueReminders(): Promise<Reminder[]> {
  const now = new Date().toISOString();
  
  const { data, error } = await supabase
    .from('reminders')
    .select('*')
    .eq('status', 'pending')
    .lte('due_at', now)
    .is('completed_at', null)
    .order('due_at', { ascending: true });

  if (error) {
    console.error("Error fetching due reminders:", error);
    throw error;
  }

  return data as Reminder[];
}
