
import React, { useState, useRef } from 'react';
import { CustomBadge } from '../CustomBadge';
import { 
  Popover,
  PopoverContent,
  PopoverTrigger 
} from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Tag, X, Plus } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useUserTags } from '@/hooks/use-user-tags';

interface TagsDropdownProps {
  tags: string[] | null;
  projectId: string;
  onProjectUpdated: () => void;
}

const TagsDropdown: React.FC<TagsDropdownProps> = ({ 
  tags, 
  projectId,
  onProjectUpdated 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [updating, setUpdating] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { tags: userTags } = useUserTags();

  const currentTags = tags || [];

  const handleAddTag = async () => {
    if (!newTag.trim()) return;
    if (currentTags.includes(newTag.trim())) {
      setNewTag('');
      return;
    }

    await updateTags([...currentTags, newTag.trim()]);
    setNewTag('');
    
    // Focus the input again for continuous adding
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleRemoveTag = async (tagToRemove: string) => {
    await updateTags(currentTags.filter(tag => tag !== tagToRemove));
  };

  const updateTags = async (newTags: string[]) => {
    if (updating) return;
    
    setUpdating(true);
    try {
      const { error } = await supabase
        .from('projects')
        .update({ tags: newTags })
        .eq('id', projectId);

      if (error) {
        throw error;
      }

      toast.success('Project tags updated');
      onProjectUpdated();
    } catch (error: any) {
      console.error('Error updating project tags:', error);
      toast.error('Failed to update project tags');
    } finally {
      setUpdating(false);
    }
  };

  // Filter suggested tags that are not already used in the project
  const suggestedTags = userTags.filter(tag => !currentTags.includes(tag));

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <CustomBadge 
          variant="outline" 
          className="flex items-center gap-1 cursor-pointer hover:bg-accent/50 transition-colors"
        >
          <Tag className="h-3 w-3" />
          {currentTags.length > 0 ? `${currentTags.length} tags` : 'Add tags'}
        </CustomBadge>
      </PopoverTrigger>
      <PopoverContent className="w-80 z-50 bg-popover shadow-lg" align="start">
        <div className="space-y-4">
          <h4 className="font-medium">Project Tags</h4>
          
          <div className="flex flex-wrap gap-2 min-h-10">
            {currentTags.map(tag => (
              <Badge 
                key={tag} 
                variant="secondary"
                className="flex items-center gap-1 px-3 py-1"
              >
                {tag}
                <X 
                  className="h-3 w-3 cursor-pointer" 
                  onClick={() => handleRemoveTag(tag)} 
                />
              </Badge>
            ))}
            {currentTags.length === 0 && (
              <div className="text-sm text-muted-foreground">No tags added yet</div>
            )}
          </div>
          
          <div className="flex gap-2 items-center">
            <Input
              ref={inputRef}
              placeholder="Add a tag..."
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddTag();
                }
              }}
              className="flex-1"
            />
            <Button 
              type="button" 
              size="sm" 
              onClick={handleAddTag}
              disabled={!newTag.trim()}
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
          
          {suggestedTags.length > 0 && (
            <div className="space-y-2">
              <h5 className="text-sm font-medium">Suggested tags</h5>
              <div className="flex flex-wrap gap-2">
                {suggestedTags.map(tag => (
                  <Badge 
                    key={tag} 
                    variant="outline"
                    className="cursor-pointer hover:bg-accent transition-colors"
                    onClick={() => {
                      setNewTag(tag);
                      setTimeout(() => {
                        handleAddTag();
                      }, 0);
                    }}
                  >
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default TagsDropdown;
