
import { addDays, addWeeks, addMonths } from 'date-fns';

/**
 * Calculate next due date for standard recurrence patterns
 */
export function calculateStandardRecurrence(
  recurrence: 'none' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'weekdays' | 'weekends' | null,
  dueDate: Date
): Date {
  const nextDate = new Date(dueDate);
  
  switch (recurrence) {
    case 'daily':
      return addDays(nextDate, 1);
      
    case 'weekdays':
      return calculateWeekdaysRecurrence(nextDate);
      
    case 'weekends':
      return calculateWeekendsRecurrence(nextDate);
      
    case 'weekly':
      return addWeeks(nextDate, 1);
      
    case 'monthly':
      return addMonths(nextDate, 1);
      
    case 'yearly':
      return addMonths(nextDate, 12);
      
    default:
      return nextDate;
  }
}

/**
 * Calculate next weekday from the given date
 */
export function calculateWeekdaysRecurrence(date: Date): Date {
  const dayOfWeek = date.getDay(); // 0 = Sunday, 6 = Saturday
  
  if (dayOfWeek === 5) { // Friday
    return addDays(date, 3); // Skip to Monday
  } else {
    return addDays(date, 1); // Next day is a weekday
  }
}

/**
 * Calculate next weekend day from the given date
 */
export function calculateWeekendsRecurrence(date: Date): Date {
  const day = date.getDay();
  
  if (day === 0) { // Sunday
    return addDays(date, 6); // Skip to Saturday
  } else if (day === 6) { // Saturday
    return addDays(date, 1); // Go to Sunday
  } else {
    // Weekday, go to next Saturday
    return addDays(date, 6 - day);
  }
}
