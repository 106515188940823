
import React from 'react';
import { Tag } from 'lucide-react';
import { Badge } from '@/components/ui/badge';

interface HabitTagsProps {
  tags: string[] | null;
}

export function HabitTags({ tags }: HabitTagsProps) {
  if (!tags || tags.length === 0) return null;
  
  return (
    <div className="flex flex-wrap mt-2 gap-1">
      {tags.map(tag => (
        <Badge key={tag} variant="outline" className="text-xs">
          <Tag className="h-3 w-3 mr-1" />
          {tag}
        </Badge>
      ))}
    </div>
  );
}
