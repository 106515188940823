
import React from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import ProjectGrid from '@/components/projects/ProjectGrid';

interface StatusSectionProps {
  title: string;
  projects: Project[];
  onSuccess: () => void;
}

const StatusSection: React.FC<StatusSectionProps> = ({ title, projects, onSuccess }) => {
  if (projects.length === 0) return null;

  return (
    <div className="mb-8">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-xl font-semibold">{title}</h2>
        <span className="text-sm text-muted-foreground">({projects.length})</span>
      </div>
      <div className="max-w-3xl mx-auto">
        <ProjectGrid projects={projects} onSuccess={onSuccess} />
      </div>
    </div>
  );
};

export default StatusSection;
