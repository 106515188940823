
import { RecurrenceOption, DayOfWeekOption, PeriodOption, CustomRecurrenceSettings } from './recurrence-types';
import { UseTranslationResponse } from 'react-i18next';
import { Json } from '@/integrations/supabase/types';
import { 
  getRecurrenceLabel as getLabelForRecurrence,
  getCustomRecurrenceLabel as getCustomLabel,
  isValidCustomRecurrence as isValidCustomSettings,
  getRecurrenceOptions as getRecOptions,
  getPeriodOptions as getPeriods,
  getDayOfWeekOptions as getDaysOfWeek
} from './utils';

// Re-export with new names to avoid conflicts
export const getRecurrenceLabel = getLabelForRecurrence;
export const getCustomRecurrenceLabel = getCustomLabel;
export const isValidCustomRecurrence = isValidCustomSettings;
export const getRecurrenceOptions = getRecOptions;
export const getPeriodOptions = getPeriods;
export const getDayOfWeekOptions = getDaysOfWeek;
