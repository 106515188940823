
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Award, Star, Zap, Trophy } from 'lucide-react';
import { cn } from '@/lib/utils';

interface HabitCompletionCelebrationProps {
  isVisible: boolean;
  message: string;
  onClose: () => void;
}

const celebrationIcons = [
  <Award key="award" className="text-amber-500 h-6 w-6" />,
  <Star key="star" className="text-amber-500 h-6 w-6" />,
  <Zap key="zap" className="text-amber-500 h-6 w-6" />,
  <Trophy key="trophy" className="text-amber-500 h-6 w-6" />
];

export function HabitCompletionCelebration({
  isVisible,
  message,
  onClose
}: HabitCompletionCelebrationProps) {
  const [icon, setIcon] = useState(celebrationIcons[0]);
  
  // Choose a random icon when the component appears
  useEffect(() => {
    if (isVisible) {
      const randomIndex = Math.floor(Math.random() * celebrationIcons.length);
      setIcon(celebrationIcons[randomIndex]);
      
      // Auto-close the celebration after 3 seconds
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={cn(
            "fixed bottom-6 right-6 bg-white dark:bg-gray-800 p-4 rounded-lg",
            "shadow-lg border border-primary/20 z-50 max-w-sm"
          )}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 25 }}
        >
          <div className="flex items-center">
            <motion.div
              initial={{ scale: 0.5, rotate: -10 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 10 }}
              className="mr-3"
            >
              {icon}
            </motion.div>
            <div>
              <h3 className="font-bold text-base">Well done!</h3>
              <p className="text-sm text-muted-foreground">{message}</p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
