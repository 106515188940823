import { useState } from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { calculateNextDueDate, CustomRecurrence } from '@/utils/recurrence';

export const useDoneForToday = (project: Project | undefined, onSuccess?: () => void) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleDoneForToday = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!project) return;
    
    try {
      setIsUpdating(true);
      let updateData: any = {};
      
      if (project.recurrence && project.recurrence !== 'none') {
        let baseDate = project.due_date ? new Date(project.due_date) : new Date();
        
        console.log('Calculating next due date with:', {
          recurrence: project.recurrence,
          baseDate,
          customRecurrence: project.custom_recurrence
        });
        
        // If it's a custom recurrence, use the custom settings
        if (project.recurrence === 'custom' && project.custom_recurrence) {
          // Convert Json type to CustomRecurrence if needed
          const customRecurrence = project.custom_recurrence as CustomRecurrence;
          
          let nextDueDate = calculateNextDueDate(
            'none', // Use a placeholder since we're providing custom recurrence
            baseDate,
            customRecurrence
          );
          
          updateData.due_date = nextDueDate.toISOString();
          console.log('Next due date for custom recurrence:', nextDueDate);
        } else {
          // Type assertion to ensure type safety
          const recurrenceType = project.recurrence as 'none' | 'daily' | 'weekly' | 'monthly';
          let nextDueDate = calculateNextDueDate(
            recurrenceType,
            baseDate
          );
          
          updateData.due_date = nextDueDate.toISOString();
          console.log('Next due date for standard recurrence:', nextDueDate);
        }
        
        const today = new Date();
        // Make sure we don't set a due date in the past
        if (new Date(updateData.due_date) < today) {
          console.log('Calculated due date is in the past, recalculating from today');
          if (project.recurrence === 'custom' && project.custom_recurrence) {
            // Convert Json type to CustomRecurrence if needed
            const customRecurrence = project.custom_recurrence as CustomRecurrence;
            
            updateData.due_date = calculateNextDueDate(
              'none',
              today,
              customRecurrence
            ).toISOString();
          } else {
            const recurrenceType = project.recurrence as 'none' | 'daily' | 'weekly' | 'monthly';
            updateData.due_date = calculateNextDueDate(
              recurrenceType,
              today
            ).toISOString();
          }
        }
      } else {
        updateData.status = 'completed';
      }
      
      console.log('Updating project with:', updateData);
      const { error } = await supabase.from('projects').update(updateData).eq('id', project.id);
      if (error) throw error;
      
      if (project.recurrence !== 'none' && updateData.due_date) {
        const nextDueDate = new Date(updateData.due_date);
        const formattedDate = nextDueDate.toLocaleDateString();
        toast.success(`Next due date updated to ${formattedDate}`);
      } else {
        toast.success('Project marked as completed');
      }
      
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error('Failed to update project');
    } finally {
      setIsUpdating(false);
    }
  };

  return { handleDoneForToday, isUpdating };
};
