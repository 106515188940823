
import React from 'react';

interface KeywordEmptyStateProps {
  searchTerm: string;
}

const KeywordEmptyState: React.FC<KeywordEmptyStateProps> = ({ searchTerm }) => {
  return (
    <div className="rounded-md border p-12 text-center">
      <h3 className="font-semibold text-lg">No keywords found</h3>
      <p className="text-muted-foreground mt-2">
        {searchTerm ? 'Try adjusting your search term' : 'Add some keywords to get started'}
      </p>
    </div>
  );
};

export default KeywordEmptyState;
