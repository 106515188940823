
import React from 'react';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '@/components/ui/select';
import { UseFormReturn } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';
import { cn } from '@/lib/utils';

interface SelectFieldProps {
  label: string;
  name: keyof TaskFormValues;
  form: UseFormReturn<TaskFormValues>;
  options: {
    value: string;
    label: string;
  }[];
}

const priorityConfig: Record<string, {
  bg: string
}> = {
  'asap': {
    bg: 'bg-red-500 text-white dark:bg-red-600'
  },
  'high': {
    bg: 'bg-orange-500 text-white dark:bg-orange-600'
  },
  'average': {
    bg: 'bg-blue-500 text-white dark:bg-blue-600'
  },
  'low': {
    bg: 'bg-green-500 text-white dark:bg-green-600'
  }
};

const SelectField: React.FC<SelectFieldProps> = ({ 
  label, 
  name, 
  form, 
  options 
}) => {
  const isPriorityField = name === 'priority';
  const currentValue = form.watch(name) as string;

  return (
    <div>
      <label className="text-sm font-medium mb-1 block">{label}</label>
      <Select 
        value={currentValue} 
        onValueChange={(value) => form.setValue(name, value as any)}
      >
        <SelectTrigger 
          className={cn(
            "w-full",
            isPriorityField && currentValue && priorityConfig[currentValue] && 
            priorityConfig[currentValue].bg
          )}
        >
          <SelectValue placeholder={`Select ${label.toLowerCase()}`} />
        </SelectTrigger>
        <SelectContent>
          {options.map(option => (
            <SelectItem 
              key={option.value} 
              value={option.value}
              className={cn(
                isPriorityField && priorityConfig[option.value] && 
                priorityConfig[option.value].bg
              )}
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectField;
