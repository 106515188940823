
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { OpenAIConfig } from '@/services/resources/openai-config-service';
import ModelConfigCard from './config-form/ModelConfigCard';
import ContentConfigCard from './config-form/ContentConfigCard';
import { DEFAULT_TEMPLATE, DEFAULT_AUTHOR_PROFILE } from './config-form/constants';

interface OpenAIConfigFormProps {
  defaultValues: OpenAIConfig;
  onSubmit: (data: OpenAIConfig) => void;
  isSubmitting: boolean;
}

const OpenAIConfigForm: React.FC<OpenAIConfigFormProps> = ({
  defaultValues,
  onSubmit,
  isSubmitting
}) => {
  const form = useForm<OpenAIConfig>({
    defaultValues: {
      ...defaultValues,
      resource_template: defaultValues.resource_template || DEFAULT_TEMPLATE,
      author_profile: defaultValues.author_profile || DEFAULT_AUTHOR_PROFILE
    }
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <ModelConfigCard form={form} />
        <ContentConfigCard 
          form={form} 
          defaultTemplate={DEFAULT_TEMPLATE}
          defaultAuthorProfile={DEFAULT_AUTHOR_PROFILE}
          isSubmitting={isSubmitting}
        />
      </form>
    </Form>
  );
};

export default OpenAIConfigForm;
