
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.tsx'
import './index.css'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import { FontLoader } from './utils/font-loading';

// Initialize font loading optimization as early as possible
FontLoader.preloadCriticalFonts();
FontLoader.init();

// Initialize i18next with minimal config for initial render
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    // Improve initial load by setting initImmediate to defer parsing
    initImmediate: true
  });

// Create React root with optimized execution
const root = document.getElementById("root");
if (root) {
  // Function to mark performance metrics
  const markPerformanceMetric = (name: string) => {
    if (typeof performance !== 'undefined' && performance.mark) {
      performance.mark(name);
    }
  };

  const renderApp = () => {
    markPerformanceMetric('render-start');
    const rootInstance = createRoot(root);
    
    // Use React.lazy and Suspense for route-based code splitting
    rootInstance.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
    
    // Mark when hydration is complete
    window.requestAnimationFrame(() => {
      document.documentElement.classList.add('hydrated');
      markPerformanceMetric('hydration-complete');
    });
  };

  // For mobile devices, adjust rendering strategy
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
  if (isMobile) {
    // On mobile, reduce initial render complexity
    renderApp();
  } else {
    // On desktop, use requestIdleCallback for non-blocking rendering
    if ('requestIdleCallback' in window) {
      // Use a short timeout to ensure it doesn't delay too long
      window.requestIdleCallback(renderApp, { timeout: 1000 });
    } else {
      // Small timeout to let the browser breathe after parsing HTML
      setTimeout(renderApp, 1);
    }
  }
}

// Add performance markers for debugging
performance.mark('app-init-end');
