
import { supabase } from '@/integrations/supabase/client';
import { AppRole } from '../types';
import { toast } from 'sonner';

/**
 * Role service functions for fetching and managing user roles
 */
export const fetchUserRoles = async (userId: string): Promise<AppRole[]> => {
  try {
    // Call the database function to get user roles
    // Note: Database function parameter is '_user_id', not 'user_id'
    const { data, error } = await supabase.rpc('get_user_roles', { _user_id: userId });
    
    if (error) {
      console.error('Error fetching roles from RPC function:', error);
      throw error;
    }
    
    // Ensure the result is an array
    if (!Array.isArray(data)) {
      console.warn('get_user_roles did not return an array:', data);
      return ['user'];
    }
    
    // Deduplicate roles in case there are duplicates in the database
    const uniqueRoles = [...new Set(data)];
    
    // Filter to only include valid AppRoles
    const validRoles: AppRole[] = uniqueRoles.filter(role => 
      role === 'user' || role === 'admin' || role === 'premium'
    ) as AppRole[];
    
    // If no valid roles found, default to 'user'
    return validRoles.length > 0 ? validRoles : ['user'];
  } catch (error) {
    console.error('Error fetching roles:', error);
    return ['user'];
  }
};

export const removeUserRole = async (
  userId: string,
  role: AppRole
): Promise<boolean> => {
  try {
    console.log(`Attempting to remove role '${role}' from user ${userId}`);
    
    // Check if the user is an admin and this is their only admin role
    if (role === 'admin') {
      // Get admin email
      const { data: userData, error: userError } = await supabase
        .from('profiles')
        .select('email')
        .eq('id', userId)
        .single();
      
      if (userError) throw userError;
      
      // If this is davidezanon5@gmail.com, prevent removing admin role
      if (userData?.email === 'davidezanon5@gmail.com') {
        toast.error("Cannot remove admin role from the primary administrator");
        return false;
      }
    }
    
    // First verify if the role actually exists for this user using direct DB query
    // This is more reliable than relying on the local state
    const { data: rolesData, error: rolesError } = await supabase
      .from('user_roles')
      .select('role')
      .eq('user_id', userId)
      .eq('role', role);
    
    if (rolesError) {
      console.error('Error checking existing roles:', rolesError);
      throw rolesError;
    }
    
    // If the role doesn't exist in the database, return early
    if (!rolesData || rolesData.length === 0) {
      console.warn(`Role '${role}' doesn't exist in database for user ${userId}`);
      toast.warning(`Role '${role}' was not found for this user`);
      return false;
    }
    
    // Delete the role since we confirmed it exists
    const { error, count } = await supabase
      .from('user_roles')
      .delete({ count: 'exact' })
      .eq('user_id', userId)
      .eq('role', role);
    
    if (error) {
      console.error('Database error when removing role:', error);
      throw error;
    }
    
    console.log(`Role removal affected ${count} rows`);
    
    if (count === 0) {
      console.warn('No rows were affected when removing role');
      toast.warning(`Role '${role}' was not found or could not be removed`);
      return false;
    }
    
    toast.success(`Role '${role}' removed successfully`);
    return true;
  } catch (error: any) {
    console.error('Error removing role:', error);
    toast.error(`Failed to remove role: ${error.message}`);
    return false;
  }
};

export const addUserRole = async (
  userId: string,
  role: AppRole
): Promise<boolean> => {
  try {
    // First verify if the role already exists for this user
    const { data: existingRoles, error: checkError } = await supabase.rpc('get_user_roles', { 
      _user_id: userId 
    });
    
    if (checkError) {
      console.error('Error checking existing roles:', checkError);
      throw checkError;
    }
    
    // If the role already exists, return early
    if (Array.isArray(existingRoles) && existingRoles.includes(role)) {
      console.warn(`Role '${role}' already exists for user ${userId}`);
      toast.info(`User already has the role '${role}'`);
      return true;
    }
    
    // Add the role
    const { error } = await supabase
      .from('user_roles')
      .insert({ user_id: userId, role: role });
    
    if (error) {
      console.error('Database error when adding role:', error);
      throw error;
    }
    
    toast.success(`Role '${role}' added successfully`);
    return true;
  } catch (error: any) {
    console.error('Error adding role:', error);
    toast.error(`Failed to add role: ${error.message}`);
    return false;
  }
};
