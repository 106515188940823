
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Clock, Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { getCurrentTimeOfDay } from '@/utils/time-of-day-utils';
import { useIsMobile } from '@/hooks/use-mobile';

const DateDisplay: React.FC = () => {
  const { t } = useTranslation();
  const [time, setTime] = useState(new Date());
  const { user } = useAuth();
  const isMobile = useIsMobile();

  // Update time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Format the current date and time
  const formattedDate = format(time, isMobile ? 'EEE, MMM d' : 'EEEE, MMMM d');
  const formattedTime = format(time, 'h:mm a');
  
  // Get the current time of day
  const timeOfDay = getCurrentTimeOfDay();
  
  // Return icon based on time of day
  const getTimeIcon = () => {
    switch(timeOfDay) {
      case 'morning':
        return <Sunrise className="h-4 w-4 mr-2 text-amber-400" />;
      case 'afternoon':
        return <Sun className="h-4 w-4 mr-2 text-amber-500" />;
      case 'evening':
        return <Sunset className="h-4 w-4 mr-2 text-orange-500" />;
      case 'night':
        return <Moon className="h-4 w-4 mr-2 text-indigo-400" />;
      default:
        return <Clock className="h-4 w-4 mr-2 opacity-70" />;
    }
  };

  // Modified to show for all users, not just logged in ones
  return (
    <div className="flex items-center text-sm">
      {getTimeIcon()}
      <div>
        <div className="font-medium">{formattedDate}</div>
        <div className="text-xs opacity-70">{formattedTime}</div>
      </div>
    </div>
  );
};

export default DateDisplay;
