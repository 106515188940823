
import { supabase } from '@/integrations/supabase/client';
import { UserProfile } from '@/contexts/auth/types';
import { toast } from 'sonner';
import { UserWithRoles } from '../types/user-types';

export function useUsersProfiles(
  users: UserWithRoles[],
  setUsers: React.Dispatch<React.SetStateAction<UserWithRoles[]>>,
  setIsLoading: (loading: boolean) => void
) {
  const handleUpdateUserProfile = async (userId: string, data: Partial<UserProfile>) => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('profiles')
        .update(data)
        .eq('id', userId);

      if (error) throw error;

      // Update the local state after successful update
      setUsers(users.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            profile: {
              ...user.profile,
              ...data
            } as UserProfile
          };
        }
        return user;
      }));
      toast.success('User profile updated successfully');
    } catch (err: any) {
      console.error('Error updating user profile:', err);
      toast.error(`Failed to update user profile: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdateUserProfile
  };
}
