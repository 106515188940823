
import { z } from 'zod';

// Schema for custom recurrence fields
const customRecurrenceSchema = z.object({
  every: z.number().int().positive().default(1),
  period: z.enum(['days', 'weeks', 'months', 'years']).default('weeks'),
  day_of_week: z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']).optional(),
});

export const projectFormSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().optional(),
  important_info: z.string().optional(),
  ai: z.string().optional(),
  links: z.array(z.object({
    url: z.string().url({ message: 'Please enter a valid URL' }),
    title: z.string().optional(),
  })).optional(),
  tags: z.array(z.string()).optional(),
  due_date: z.date().optional(),
  recurrence: z.enum(['none', 'daily', 'weekdays', 'weekends', 'weekly', 'monthly', 'yearly', 'custom']),
  custom_recurrence: customRecurrenceSchema.optional(),
  priority: z.enum(['asap', 'high', 'average', 'low']),
  status: z.enum(['active', 'in_progress', 'queue', 'blocked', 'completed']).default('active'),
  collection_id: z.string(),
});

export type ProjectFormValues = z.infer<typeof projectFormSchema>;

export type CustomRecurrence = z.infer<typeof customRecurrenceSchema>;
