
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

interface TodoFormProps {
  onAddTodo: (text: string) => Promise<void>;
  isLoading: boolean;
}

export function TodoForm({ onAddTodo, isLoading }: TodoFormProps) {
  const [newTodoText, setNewTodoText] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onAddTodo(newTodoText);
    setNewTodoText('');
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4 flex gap-2">
      <input
        type="text"
        value={newTodoText}
        onChange={(e) => setNewTodoText(e.target.value)}
        placeholder="Add a new to-do..."
        className="flex-1 px-3 py-2 border border-yellow-300 dark:border-yellow-700 rounded-md bg-yellow-50 dark:bg-yellow-900/50 focus:outline-none focus:ring-2 focus:ring-yellow-300 dark:focus:ring-yellow-600 focus:border-transparent dark:text-yellow-100 dark:placeholder:text-yellow-400"
        disabled={isLoading}
      />
      <Button 
        type="submit" 
        size="sm"
        disabled={!newTodoText.trim() || isLoading}
        className="bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 text-white"
      >
        <Plus size={16} className="mr-1" /> Add
      </Button>
    </form>
  );
}
