
import { useAuth } from '@/contexts/auth';
import { useState, useMemo, useRef } from 'react';
import { Task, CreateTaskPayload, UpdateTaskPayload } from '@/types/task';
import { useTaskData } from './tasks/use-task-data';
import { useTaskOperations } from './tasks/use-task-operations';
import { toggleTaskExpansion as toggleExpansion } from './tasks/task-utils';

export function useProjectTasks(projectId: string | undefined) {
  const { user } = useAuth();
  const [expandedState, setExpandedState] = useState<Record<string, boolean>>({});
  const initialLoadAttemptedRef = useRef(false);
  
  // Use useMemo to stabilize the projectId dependency
  const stableProjectId = useMemo(() => projectId, [projectId]);
  const stableUserId = useMemo(() => user?.id, [user?.id]);
  
  // Get task data
  const { 
    tasks, 
    isLoading, 
    error, 
    refreshTasks 
  } = useTaskData({ 
    projectId: stableProjectId, 
    userId: stableUserId 
  });

  // Get task operations
  const { 
    createTask, 
    updateTask, 
    deleteTask,
    isProcessing 
  } = useTaskOperations({ 
    projectId: stableProjectId, 
    userId: stableUserId, 
    tasks, 
    refreshTasks 
  });

  // Apply expanded state to tasks
  const tasksWithExpandedState = useMemo(() => {
    return tasks.map(task => ({
      ...task,
      isExpanded: expandedState[task.id] !== undefined ? expandedState[task.id] : true,
      subtasks: task.subtasks ? task.subtasks.map(subtask => ({
        ...subtask,
        isExpanded: expandedState[subtask.id] !== undefined ? expandedState[subtask.id] : true
      })) : []
    }));
  }, [tasks, expandedState]);

  // Handle task expansion toggling
  const toggleTaskExpansion = (taskId: string) => {
    setExpandedState(prev => {
      const currentValue = prev[taskId] !== undefined ? prev[taskId] : true;
      return {
        ...prev,
        [taskId]: !currentValue
      };
    });
  };

  return {
    tasks: tasksWithExpandedState,
    isLoading: isLoading || isProcessing,
    error,
    createTask,
    updateTask,
    deleteTask,
    toggleTaskExpansion,
    refreshTasks
  };
}
