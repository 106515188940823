
import React, { useState } from 'react';
import MainLayout from '@/layouts/MainLayout';
import { useKeywords } from '@/hooks/use-keywords';
import KeywordsList from '@/components/resources/keywords/KeywordsList';
import KeywordBulkAdd from '@/components/resources/keywords/KeywordBulkAdd';
import AdminPageHeader from '@/components/admin/common/AdminPageHeader';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useMetadata } from '@/hooks/use-metadata';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const AdminKeywords: React.FC = () => {
  const [isAddSheetOpen, setIsAddSheetOpen] = useState(false);
  const [keywordToDelete, setKeywordToDelete] = useState<string | null>(null);
  
  // Get keywords data
  const { useKeywordsQuery, useDeleteKeywordMutation } = useKeywords();
  const { data: keywords = [], isLoading: keywordsLoading } = useKeywordsQuery();
  const deleteKeyword = useDeleteKeywordMutation();
  
  useMetadata({
    title: 'Keyword Management',
    description: 'Manage keywords for resource generation.',
  });

  const handleOpenAddSheet = () => {
    setIsAddSheetOpen(true);
  };

  const handleCloseAddSheet = () => {
    setIsAddSheetOpen(false);
  };
  
  const handleDeleteClick = (id: string) => {
    setKeywordToDelete(id);
  };
  
  const handleConfirmDelete = async () => {
    if (keywordToDelete) {
      await deleteKeyword.mutateAsync(keywordToDelete);
      setKeywordToDelete(null);
    }
  };
  
  const handleCancelDelete = () => {
    setKeywordToDelete(null);
  };

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <AdminPageHeader 
            title="Keyword Management"
            description="Manage keywords for resource content generation."
            createButtonText="Add Keywords"
            onCreateClick={handleOpenAddSheet}
            backRoute="/admin/resources"
          />

          <div className="mt-6">
            <KeywordsList 
              keywords={keywords} 
              isLoading={keywordsLoading} 
              onDelete={handleDeleteClick}
            />
          </div>
          
          {/* Add Keywords Sheet */}
          <Sheet open={isAddSheetOpen} onOpenChange={setIsAddSheetOpen}>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Add Keywords</SheetTitle>
                <SheetDescription>
                  Add one or multiple keywords to be used for content generation.
                </SheetDescription>
              </SheetHeader>
              <div className="py-6">
                <KeywordBulkAdd />
              </div>
            </SheetContent>
          </Sheet>
          
          {/* Delete Confirmation Dialog */}
          <AlertDialog open={!!keywordToDelete} onOpenChange={() => setKeywordToDelete(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the keyword.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={handleCancelDelete}>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleConfirmDelete} className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminKeywords;
