
import React from 'react';
import { FormField, FormItem, FormLabel } from '@/components/ui/form';
import TagsField from '@/components/projects/form-fields/TagsField';
import { UseFormReturn } from 'react-hook-form';
import { CreateHabitPayload } from '@/types/habit';

interface HabitTagsFieldProps {
  form: UseFormReturn<CreateHabitPayload>;
}

const HabitTagsField: React.FC<HabitTagsFieldProps> = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="tags"
      render={() => (
        <FormItem>
          <FormLabel>Tags</FormLabel>
          <TagsField form={form as any} />
        </FormItem>
      )}
    />
  );
};

export default HabitTagsField;
