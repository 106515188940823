
import React from 'react';
import { Star, MoreVertical, RotateCcw, Pencil, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";

interface ProjectCardHeaderProps {
  title: string;
  status: string;
  isFavorite?: boolean;
  recurrence?: string | null;
  onEditClick: (e: React.MouseEvent) => void;
  onDeleteClick: (e: React.MouseEvent) => void;
  onStatusClick?: (e: React.MouseEvent) => void;
}

const ProjectCardHeader: React.FC<ProjectCardHeaderProps> = ({
  title,
  status,
  isFavorite = false,
  recurrence,
  onEditClick,
  onDeleteClick,
  onStatusClick,
}) => {
  const statusColors = {
    active: 'text-green-500',
    in_progress: 'text-blue-500',
    queue: 'text-orange-500',
    completed: 'text-gray-500'
  };

  return (
    <div className="flex justify-between items-start">
      <div className="flex items-center gap-3">
        <div>
          <div className="flex items-center gap-2">
            <div 
              className={`w-2 h-2 rounded-full ${statusColors[status as keyof typeof statusColors]} interactive-element cursor-pointer`}
              onClick={onStatusClick}
            />
            <h3 className="font-medium text-lg">{title}</h3>
            {isFavorite && <Star className="h-4 w-4 text-yellow-500 fill-yellow-500" />}
          </div>
          {recurrence && (
            <p className="text-sm text-muted-foreground">
              <span className="flex items-center gap-1">
                <RotateCcw className="h-3 w-3" />
                {recurrence.charAt(0).toUpperCase() + recurrence.slice(1)}
              </span>
            </p>
          )}
        </div>
      </div>
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="h-8 w-8 interactive-element">
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={onEditClick} className="interactive-element">
            <Pencil className="h-4 w-4 mr-2" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem 
            onClick={onDeleteClick}
            className="text-destructive focus:text-destructive interactive-element"
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ProjectCardHeader;
