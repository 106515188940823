
import React from 'react';
import { UserPlus, FolderPlus, ListTodo, Zap } from 'lucide-react';

interface StepProps {
  number: number;
  title: string;
  description: string;
  icon: React.ReactNode;
}

const Step: React.FC<StepProps> = ({ number, title, description, icon }) => {
  return (
    <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-6">
      <div className="flex-shrink-0 w-12 h-12 rounded-full bg-primary/10 dark:bg-primary/20 flex items-center justify-center text-primary font-bold text-xl">
        {number}
      </div>
      <div className="flex-1">
        <div className="flex items-center gap-2 mb-2">
          <div className="text-primary">{icon}</div>
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
        <p className="text-muted-foreground">{description}</p>
      </div>
    </div>
  );
};

const HowItWorksSection: React.FC = () => {
  const steps = [
    {
      number: 1,
      icon: <UserPlus size={20} />,
      title: "Create Your Account",
      description: "Sign up in seconds and get instant access to all our productivity tools and features."
    },
    {
      number: 2,
      icon: <FolderPlus size={20} />,
      title: "Set Up Your First Project",
      description: "Create a new project, define its scope, and establish goals and timelines."
    },
    {
      number: 3,
      icon: <ListTodo size={20} />,
      title: "Add Tasks and Track Progress",
      description: "Break down your project into manageable tasks and monitor your progress."
    },
    {
      number: 4,
      icon: <Zap size={20} />,
      title: "Boost Your Productivity",
      description: "Leverage our analytics and AI tools to optimize your workflow and achieve more."
    }
  ];

  return (
    <div className="py-20">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Getting started is simple - follow these steps to transform your productivity
          </p>
        </div>
        
        <div className="grid grid-cols-1 gap-12 max-w-3xl mx-auto">
          {steps.map((step, index) => (
            <Step
              key={index}
              number={step.number}
              icon={step.icon}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
