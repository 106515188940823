
import React from 'react';
import { Task } from '@/types/task';
import TaskItem from '../TaskItem';

interface SubtasksListProps {
  subtasks: Task[];
  level: number;
  onUpdateTask: (task: any) => Promise<boolean>;
  onDeleteTask: (taskId: string) => Promise<boolean>;
  onToggleExpansion: (taskId: string) => void;
  onCreateSubtask: (task: any) => Promise<Task | null>;
  projectId: string;
}

const SubtasksList: React.FC<SubtasksListProps> = ({
  subtasks,
  level,
  onUpdateTask,
  onDeleteTask,
  onToggleExpansion,
  onCreateSubtask,
  projectId
}) => {
  if (!subtasks || subtasks.length === 0) {
    return null;
  }

  return (
    <div className="mt-2">
      {subtasks.map(subtask => (
        <TaskItem
          key={subtask.id}
          task={subtask}
          level={level + 1}
          onUpdateTask={onUpdateTask}
          onDeleteTask={onDeleteTask}
          onToggleExpansion={onToggleExpansion}
          onCreateSubtask={onCreateSubtask}
          projectId={projectId}
        />
      ))}
    </div>
  );
};

export default SubtasksList;
