
import React from 'react';
import { Save } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface ResourceFormActionsProps {
  isSubmitting: boolean;
  handleGenerateSlug: () => void;
}

const ResourceFormActions: React.FC<ResourceFormActionsProps> = ({
  isSubmitting,
  handleGenerateSlug,
}) => {
  return (
    <div className="flex justify-between items-center">
      <Button 
        type="button" 
        variant="outline"
        onClick={handleGenerateSlug}
      >
        Generate Slug
      </Button>
      
      <Button 
        type="submit"
        disabled={isSubmitting}
      >
        <Save className="h-4 w-4 mr-2" />
        Save
      </Button>
    </div>
  );
};

export default ResourceFormActions;
