
import React from 'react';
import { format, differenceInDays } from 'date-fns';
import { Goal } from '@/lib/utils';
import { Calendar } from 'lucide-react';

interface ProjectGoalInfoProps {
  goals: Goal[];
}

const ProjectGoalInfo: React.FC<ProjectGoalInfoProps> = ({ goals }) => {
  if (!goals || goals.length === 0) return null;
  
  // Find the closest upcoming goal, or the most recent one if all are past
  const sortedGoals = [...goals].sort((a, b) => {
    if (!a.due_date) return 1;
    if (!b.due_date) return -1;
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
  });
  
  const closestGoal = sortedGoals[0];
  
  if (!closestGoal.due_date) return null;
  
  const dueDate = new Date(closestGoal.due_date);
  const today = new Date();
  const daysRemaining = differenceInDays(dueDate, today);
  
  let statusClass = 'text-blue-500';
  let daysText = '';
  
  if (daysRemaining < 0) {
    statusClass = 'text-red-500';
    daysText = `${Math.abs(daysRemaining)} days overdue`;
  } else if (daysRemaining === 0) {
    statusClass = 'text-orange-500';
    daysText = 'Due today';
  } else if (daysRemaining === 1) {
    statusClass = 'text-orange-500';
    daysText = 'Due tomorrow';
  } else {
    daysText = `${daysRemaining} days remaining`;
  }
  
  return (
    <div className="mt-3 pt-3 border-t border-white/10 interactive-element">
      <div className="flex items-center gap-2">
        <Calendar className="h-4 w-4 text-foreground/60" />
        <div>
          <div className="text-sm font-medium">Goal: {closestGoal.title}</div>
          <div className={`text-xs ${statusClass}`}>
            {format(dueDate, 'MMM d, yyyy')} · {daysText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectGoalInfo;
