
import React from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '@/layouts/MainLayout';
import ResourceList from '@/components/resources/public/ResourceList';
import { usePublicResources } from '@/hooks/resources/use-public-resources';
import { useMetadata } from '@/hooks/use-metadata';

const Resources: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { usePublicResourcesQuery } = usePublicResources(language);
  
  const { data: resources, isLoading } = usePublicResourcesQuery();

  // Set metadata for the page for SEO
  useMetadata({
    title: t('resources.title'),
    description: t('resources.description'),
  });

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8 max-w-6xl">
        <div className="mb-8">
          <h1 className="text-3xl font-bold">{t('resources.title')}</h1>
          <p className="text-muted-foreground mt-2">
            {t('resources.description')}
          </p>
        </div>

        <ResourceList 
          resources={resources || []} 
          isLoading={isLoading} 
        />
      </div>
    </MainLayout>
  );
};

export default Resources;
