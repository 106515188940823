
import { useMutation } from '@tanstack/react-query';
import { previewContentFromKeyword } from '@/services/resources/resource-preview-service';
import { GeneratedContentPreview } from '@/components/resources/keywords/components/PreviewGeneratedContent';

export interface ResourcePreviewResult {
  success: boolean;
  preview?: GeneratedContentPreview;
  error?: string;
  details?: any;
}

/**
 * Hook for previewing AI-generated content from keywords without saving
 */
export const useResourcePreviewMutation = () => {
  return useMutation({
    mutationFn: async (keywordId: string): Promise<ResourcePreviewResult> => {
      const result = await previewContentFromKeyword(keywordId);
      if (!result.success) {
        throw new Error(result.error || 'Failed to generate content preview');
      }
      return result;
    }
  });
};
