
import React from 'react';
import { ResourceDetail } from '@/types/resource';
import ResourceHeader from './components/ResourceHeader';
import ResourceContent from './components/ResourceContent';
import ResourceLoading from './components/ResourceLoading';
import ResourceNotFound from './components/ResourceNotFound';
import SchemaScripts from './components/SchemaScripts';

interface ResourceDetailsProps {
  resource?: ResourceDetail;
  isLoading: boolean;
  onRatingUpdated?: () => void;
}

const ResourceDetails: React.FC<ResourceDetailsProps> = ({ resource, isLoading, onRatingUpdated }) => {
  if (isLoading) {
    return <ResourceLoading />;
  }

  if (!resource) {
    return <ResourceNotFound />;
  }

  return (
    <>
      <SchemaScripts resource={resource} />
      <article className="max-w-3xl mx-auto">
        <ResourceHeader 
          resource={resource} 
          onRatingUpdated={onRatingUpdated} 
        />
        {resource.content && <ResourceContent content={resource.content} />}
      </article>
    </>
  );
};

export default ResourceDetails;
