
import React from 'react';
import { TaskStatus } from '@/types/task';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface StatusActionsProps {
  status: TaskStatus;
  onStatusChange: (newStatus: TaskStatus) => Promise<void>;
}

const StatusActions: React.FC<StatusActionsProps> = ({
  status,
  onStatusChange,
}) => {
  return (
    <Select
      value={status}
      onValueChange={onStatusChange}
    >
      <SelectTrigger className="h-7 border-none shadow-none w-auto p-1 bg-transparent flex justify-center text-xs">
        <SelectValue>
          {status === 'active' && 'Active'}
          {status === 'in_progress' && 'In Progress'}
          {status === 'queue' && 'Queue'}
          {status === 'completed' && 'Completed'}
          {status === 'blocked' && 'Blocked'}
        </SelectValue>
      </SelectTrigger>
      <SelectContent align="end">
        <SelectItem value="active">Active</SelectItem>
        <SelectItem value="in_progress">In Progress</SelectItem>
        <SelectItem value="queue">Queue</SelectItem>
        <SelectItem value="blocked">Blocked</SelectItem>
        <SelectItem value="completed">Completed</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default StatusActions;
