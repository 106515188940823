
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, Trash } from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Category } from '@/types/resource';
import { useResources } from '@/hooks/use-resources';

interface CategoriesListProps {
  categories: Category[];
  isLoading: boolean;
  language: string;
}

const CategoriesList: React.FC<CategoriesListProps> = ({
  categories,
  isLoading,
  language
}) => {
  const navigate = useNavigate();
  const { useDeleteCategoryMutation } = useResources();
  const deleteCategoryMutation = useDeleteCategoryMutation();

  const handleDeleteCategory = (id: string) => {
    if (window.confirm('Delete category?')) {
      deleteCategoryMutation.mutate(id);
    }
  };

  if (isLoading) {
    return (
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {[...Array(6)].map((_, i) => (
          <Card key={i}>
            <CardHeader>
              <Skeleton className="h-5 w-1/2 mb-2" />
              <Skeleton className="h-4 w-3/4" />
            </CardHeader>
            <CardFooter>
              <Skeleton className="h-9 w-full" />
            </CardFooter>
          </Card>
        ))}
      </div>
    );
  }

  if (categories.length === 0) {
    return (
      <div className="text-center p-8">
        <h3 className="text-lg font-medium">Categories</h3>
        <p className="text-muted-foreground mt-2">
          No categories found. Create your first category.
        </p>
        <Button
          className="mt-4"
          onClick={() => navigate('/admin/resources/categories/create')}
        >
          Create Category
        </Button>
      </div>
    );
  }

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {categories.map((category) => (
        <Card key={category.id}>
          <CardHeader>
            <CardTitle>{category.name[language] || category.name['en'] || '(no name)'}</CardTitle>
            <CardDescription>/{category.slug}</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground line-clamp-2">
              {category.description?.[language] || category.description?.['en'] || ''}
            </p>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button 
              variant="outline"
              onClick={() => navigate(`/admin/resources/categories/edit/${category.id}`)}
            >
              <Edit className="h-4 w-4 mr-2" />
              Edit
            </Button>
            <Button
              variant="outline"
              onClick={() => handleDeleteCategory(category.id)}
            >
              <Trash className="h-4 w-4 mr-2" />
              Delete
            </Button>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default CategoriesList;
