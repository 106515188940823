
import React from 'react';
import { Badge } from '@/components/ui/badge';

interface ResourceStatusBadgeProps {
  status: string;
}

const ResourceStatusBadge: React.FC<ResourceStatusBadgeProps> = ({ status }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'published':
        return 'bg-green-500/10 text-green-600 border-green-200';
      case 'draft':
        return 'bg-yellow-500/10 text-yellow-600 border-yellow-200';
      case 'archived':
        return 'bg-gray-500/10 text-gray-600 border-gray-200';
      default:
        return 'bg-blue-500/10 text-blue-600 border-blue-200';
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'published':
        return 'Published';
      case 'draft':
        return 'Draft';
      case 'archived':
        return 'Archived';
      default:
        return status;
    }
  };

  return (
    <Badge className={getStatusColor(status)}>
      {getStatusLabel(status)}
    </Badge>
  );
};

export default ResourceStatusBadge;
