
import React, { memo } from 'react';
import { Badge } from '@/components/ui/badge';
import { X, Shield } from 'lucide-react';
import { AppRole } from '@/contexts/auth/types';
import { useTranslation } from 'react-i18next';

interface UserRoleBadgeProps {
  role: AppRole;
  onRemove?: () => void;
}

// Optimize with memo to prevent unnecessary re-renders
const UserRoleBadge: React.FC<UserRoleBadgeProps> = memo(({ role, onRemove }) => {
  const { t } = useTranslation();
  
  const getBadgeVariant = () => {
    switch (role) {
      case 'admin':
        return 'destructive';
      case 'premium':
        return 'default';
      default:
        return 'secondary';
    }
  };
  
  const handleRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    if (role === 'admin') {
      // Add a confirmation for removing admin role
      if (!confirm('Are you sure you want to remove admin privileges from this user?')) {
        return;
      }
    }
    
    onRemove?.();
  };

  return (
    <Badge 
      variant={getBadgeVariant()} 
      className="flex items-center gap-1"
      title={`${role} role`}
    >
      <Shield className="h-3 w-3 mr-1" />
      {t(`roles.${role}`)}
      {onRemove && (
        <X 
          className="h-3 w-3 cursor-pointer hover:text-white ml-1" 
          onClick={handleRemoveClick}
          aria-label={`Remove ${role} role`}
        />
      )}
    </Badge>
  );
});

// Add display name for better debugging
UserRoleBadge.displayName = 'UserRoleBadge';

export default UserRoleBadge;
