
import { useKeywordsQuery } from './resources/use-keywords-query';
import { useKeywordMutations } from './resources/use-keyword-mutations';
import { useResourceGeneratorMutation, useResourcePreviewMutation } from './resources/resource-generator';
import { useOpenAIConfigQuery, useOpenAIConfigMutation } from './resources/use-openai-config';

export const useKeywords = () => {
  const { 
    useAddKeywordMutation,
    useAddBulkKeywordsMutation,
    useDeleteKeywordMutation,
    useLinkKeywordToResourceMutation 
  } = useKeywordMutations();

  return {
    useKeywordsQuery,
    useAddKeywordMutation,
    useAddBulkKeywordsMutation,
    useDeleteKeywordMutation,
    useLinkKeywordToResourceMutation,
    useResourceGeneratorMutation,
    useResourcePreviewMutation,
    useOpenAIConfigQuery,
    useOpenAIConfigMutation
  };
};
