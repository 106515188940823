
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Reminder } from '@/types/reminder';
import { cn } from '@/lib/utils';
import { ReminderContent } from './reminder-item/ReminderContent';
import { ReminderActions } from './reminder-item/ReminderActions';

interface ReminderItemProps {
  reminder: Reminder;
  onEdit: (reminder: Reminder) => void;
  onComplete: (id: string) => void;
  onDelete: (id: string) => void;
  onSnooze: (id: string, minutes: number) => void;
}

export function ReminderItem({ 
  reminder, 
  onEdit, 
  onComplete, 
  onDelete, 
  onSnooze 
}: ReminderItemProps) {
  const isDue = new Date(reminder.due_at) <= new Date();
  const isOverdue = reminder.status === 'pending' && isDue;
  
  return (
    <Card className={cn(
      "transition-all",
      reminder.status === 'completed' ? "opacity-70" : "",
      isOverdue ? "border-red-300" : ""
    )}>
      <CardContent className="p-4">
        <div className="flex justify-between items-start">
          <ReminderContent 
            reminder={reminder} 
            isOverdue={isOverdue} 
          />
          
          <ReminderActions
            reminder={reminder}
            onEdit={onEdit}
            onComplete={onComplete}
            onDelete={onDelete}
            onSnooze={onSnooze}
          />
        </div>
      </CardContent>
    </Card>
  );
}
