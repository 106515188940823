
import { useEffect } from 'react';

/**
 * Hook to set the document title with an optional prefix
 * @param title The title to set
 * @param withPrefix Whether to add the app name prefix
 */
export const useDocumentTitle = (title: string, withPrefix: boolean = true) => {
  useEffect(() => {
    const prefix = withPrefix ? 'MyHacks | ' : '';
    document.title = `${prefix}${title}`;
    
    return () => {
      // Reset to default on unmount if needed
      if (!withPrefix) {
        document.title = 'MyHacks';
      }
    };
  }, [title, withPrefix]);
};
