
import React from 'react';
import { Button } from '@/components/ui/button';
import { Keyword } from '@/types/keyword';
import { Loader2, RotateCw, RefreshCw } from 'lucide-react';
import { useKeywords } from '@/hooks/use-keywords';
import { toast } from 'sonner';

interface GenerateResourceButtonProps {
  keyword: Keyword;
  disabled?: boolean;
}

const GenerateResourceButton: React.FC<GenerateResourceButtonProps> = ({ 
  keyword, 
  disabled = false
}) => {
  const { useResourceGeneratorMutation } = useKeywords();
  const generateResource = useResourceGeneratorMutation();
  
  const handleGenerate = async () => {
    try {
      await generateResource.mutateAsync(keyword.id);
      toast.success('Resource generation started');
    } catch (error: any) {
      toast.error(`Failed to generate resource: ${error.message}`);
    }
  };
  
  // Check if we should show the button based on keyword status
  if (keyword.status !== 'pending' && keyword.generation_status !== 'failed') {
    return null;
  }

  return (
    <Button 
      variant="outline" 
      size="sm" 
      onClick={handleGenerate} 
      disabled={disabled || generateResource.isPending}
      className="flex items-center gap-1"
    >
      {generateResource.isPending && generateResource.variables === keyword.id ? (
        <Loader2 className="h-3 w-3 animate-spin" />
      ) : keyword.generation_status === 'failed' ? (
        <RefreshCw className="h-3 w-3" />
      ) : (
        <RotateCw className="h-3 w-3" />
      )}
      {keyword.generation_status === 'failed' ? 'Retry' : 'Generate'}
    </Button>
  );
};

export default GenerateResourceButton;
