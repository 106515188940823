
import React, { useEffect } from 'react';
import { CustomBadge } from '../CustomBadge';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from '@/components/ui/dropdown-menu';
import { RepeatIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { DAY_OF_WEEK_INDICES } from '@/utils/recurrence';
import { useRecurrenceUpdate, useCustomRecurrenceSettings } from './recurrence/useRecurrenceUpdate';
import RecurrenceOptions from './recurrence/RecurrenceOptions';
import CustomRecurrenceForm from './recurrence/CustomRecurrenceForm';
import { 
  getRecurrenceLabel, 
  getRecurrenceOptions, 
  getPeriodOptions, 
  getDayOfWeekOptions, 
  isValidCustomRecurrence 
} from './recurrence/RecurrenceUtils';
import { RecurrenceDropdownProps } from './recurrence/recurrence-types';
import { Json } from '@/integrations/supabase/types';

const RecurrenceDropdown: React.FC<RecurrenceDropdownProps> = ({ 
  recurrence, 
  projectId,
  onProjectUpdated,
  customRecurrence = null
}) => {
  const { t } = useTranslation();
  const { updating, updateRecurrence } = useRecurrenceUpdate(projectId, onProjectUpdated);
  
  // Initialize customSettings with type-checked defaults if needed
  const initialCustomSettings = isValidCustomRecurrence(customRecurrence) 
    ? {
        every: customRecurrence.every as number,
        period: customRecurrence.period as string,
        day_of_week: customRecurrence.day_of_week as string | undefined
      }
    : null;
    
  const { customSettings, updateCustomSettings } = useCustomRecurrenceSettings(initialCustomSettings);

  // Effect to log custom recurrence settings on mount and when they change
  useEffect(() => {
    console.log('RecurrenceDropdown: Current customRecurrence:', JSON.stringify(customRecurrence));
    console.log('RecurrenceDropdown: Current customSettings:', JSON.stringify(customSettings));
  }, [customRecurrence, customSettings]);

  const recurrenceOptions = getRecurrenceOptions(t);
  const periodOptions = getPeriodOptions(t);
  const dayOfWeekOptions = getDayOfWeekOptions(t);
  
  const applyCustomSettings = () => {
    console.log('Applying custom settings in RecurrenceDropdown:', JSON.stringify(customSettings));
    updateRecurrence('custom', customSettings);
  };

  // Don't show anything if recurrence is not set
  if (!recurrence) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <CustomBadge 
          variant="outline" 
          className="flex items-center gap-1 cursor-pointer hover:bg-accent/50 transition-colors"
        >
          <RepeatIcon className="h-3 w-3" />
          {getRecurrenceLabel(recurrence, customSettings, recurrenceOptions, dayOfWeekOptions, periodOptions, t)}
        </CustomBadge>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-60 z-50 bg-popover shadow-lg">
        <RecurrenceOptions 
          recurrence={recurrence} 
          updateRecurrence={updateRecurrence} 
        />
        
        <DropdownMenuSeparator />
        
        <DropdownMenuSub>
          <DropdownMenuSubTrigger
            className={recurrence === 'custom' ? "bg-accent/50" : ""}
          >
            {t('projects.recurrence.custom', 'Custom')}
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent className="w-60 bg-popover p-4">
              <CustomRecurrenceForm 
                customSettings={customSettings}
                updateCustomSettings={updateCustomSettings}
                applyCustomSettings={applyCustomSettings}
              />
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RecurrenceDropdown;
