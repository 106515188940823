
import React from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Clipboard, Pencil, Trash2 } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Prompt } from '@/types/prompt';

interface PromptCardProps {
  prompt: Prompt;
  onEdit: (prompt: Prompt) => void;
  onDelete: (id: string) => void;
  onCopy: (content: string) => void;
}

export function PromptCard({ 
  prompt, 
  onEdit, 
  onDelete, 
  onCopy 
}: PromptCardProps) {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date);
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg font-semibold">{prompt.title}</CardTitle>
      </CardHeader>
      <CardContent className="flex-1">
        <div className="overflow-hidden text-sm text-muted-foreground line-clamp-4 whitespace-pre-line">
          {prompt.content}
        </div>
        
        {prompt.tags && prompt.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4">
            {prompt.tags.map(tag => (
              <Badge key={tag} variant="outline" className="text-xs">
                {tag}
              </Badge>
            ))}
          </div>
        )}
      </CardContent>
      <CardFooter className="pt-2 flex justify-between border-t">
        <div className="text-xs text-muted-foreground">
          {formatDate(prompt.updated_at)}
        </div>
        <div className="flex gap-2">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => onCopy(prompt.content)}
            title="Copy to clipboard"
          >
            <Clipboard className="h-4 w-4" />
            <span className="sr-only">Copy</span>
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => onEdit(prompt)}
            title="Edit prompt"
          >
            <Pencil className="h-4 w-4" />
            <span className="sr-only">Edit</span>
          </Button>
          <Button
            size="sm"
            variant="ghost"
            className="text-destructive hover:text-destructive"
            onClick={() => onDelete(prompt.id)}
            title="Delete prompt"
          >
            <Trash2 className="h-4 w-4" />
            <span className="sr-only">Delete</span>
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
