
import { useQuery } from '@tanstack/react-query';
import { getResources, getResourceBySlug } from '@/services/resources';
import { ResourceStatus } from '@/types/resource';

export const usePublicResources = (language = 'en') => {
  const usePublicResourcesQuery = (categoryId?: string) => {
    return useQuery({
      queryKey: ['resources', 'published', categoryId, language],
      queryFn: () => getResources('published', categoryId, language),
    });
  };

  const usePublicResourceBySlugQuery = (slug: string) => {
    return useQuery({
      queryKey: ['resource', slug, language],
      queryFn: () => getResourceBySlug(slug, language),
      enabled: !!slug,
    });
  };

  return {
    usePublicResourcesQuery,
    usePublicResourceBySlugQuery,
    usePublishedResourcesQuery: usePublicResourcesQuery,
    useResourceBySlugQuery: usePublicResourceBySlugQuery,
  };
};
