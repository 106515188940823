
import React from 'react';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { useTranslation } from 'react-i18next';
import ResourceCard from './resources/ResourceCard';
import ResourceCarouselHeader from './resources/ResourceCarouselHeader';
import ResourceCarouselMobileCta from './resources/ResourceCarouselMobileCta';
import { useResourceCarouselData } from '@/hooks/resources/use-resource-carousel-data';

/**
 * Carousel component that displays popular resources
 * Implements optimized loading and LCP improvements
 */
const ResourceCarousel: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  
  const { 
    displayData, 
    isClientSide, 
    fetchResources 
  } = useResourceCarouselData(language);

  return (
    <section className="py-8 md:py-12" id="popular-resources">
      <div className="max-w-7xl mx-auto px-4 md:px-6">
        <ResourceCarouselHeader 
          isClientSide={isClientSide}
          onVisible={fetchResources}
        />

        <Carousel className="w-full">
          <CarouselContent className="-ml-4">
            {displayData.map((resource) => (
              <CarouselItem key={resource.id} className="pl-4 md:basis-1/2 lg:basis-1/3">
                <ResourceCard resource={resource} />
              </CarouselItem>
            ))}
          </CarouselContent>
          {isClientSide && (
            <>
              <CarouselPrevious className="left-0 lg:-left-12 hidden md:flex" />
              <CarouselNext className="right-0 lg:-right-12 hidden md:flex" />
            </>
          )}
        </Carousel>

        <ResourceCarouselMobileCta isClientSide={isClientSide} />
      </div>
    </section>
  );
};

export default ResourceCarousel;
