
import { useAuth } from '@/contexts/auth';
import { toast } from 'sonner';
import { AppRole } from '@/contexts/auth/types';
import { addUserRole, removeUserRole } from '@/contexts/auth/api';
import { UserWithRoles } from '../types/user-types';

export function useUsersRoles(
  users: UserWithRoles[],
  setUsers: React.Dispatch<React.SetStateAction<UserWithRoles[]>>,
  setIsLoading: (loading: boolean) => void,
  fetchUsers: () => Promise<void>
) {
  const handleRemoveRole = async (userId: string, role: AppRole) => {
    try {
      setIsLoading(true);
      
      // Use the API function to remove role
      const success = await removeUserRole(userId, role);
      
      if (success) {
        // Update the local state immediately for responsive UI
        setUsers(prevUsers => prevUsers.map(user => {
          if (user.id === userId) {
            // Filter out the removed role
            const updatedRoles = user.roles.filter(r => r !== role);
            
            return {
              ...user,
              roles: updatedRoles // Don't add default 'user' role here - let DB handle that
            };
          }
          return user;
        }));
        
        // Always fetch fresh data to ensure UI matches database state
        await fetchUsers();
      }
    } catch (err: any) {
      console.error('Error removing role:', err);
      toast.error(`Failed to remove role: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddRole = async (userId: string, role: AppRole) => {
    try {
      setIsLoading(true);
      const success = await addUserRole(userId, role);

      if (success) {
        // Update the local state after successful addition
        setUsers(prevUsers => prevUsers.map(user => {
          if (user.id === userId) {
            // Make sure we don't add duplicates
            if (!user.roles.includes(role)) {
              return {
                ...user,
                roles: [...user.roles, role]
              };
            }
          }
          return user;
        }));
        
        // Refresh the users list to synchronize with the database
        await fetchUsers();
      }
    } catch (err: any) {
      console.error('Error adding role:', err);
      toast.error(`Failed to add role: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleRemoveRole,
    handleAddRole
  };
}
