
import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus, MessageSquare } from 'lucide-react';

interface EmptyStateProps {
  onCreatePrompt: () => void;
}

export function EmptyState({ onCreatePrompt }: EmptyStateProps) {
  return (
    <div className="flex flex-col items-center justify-center py-12 text-center">
      <div className="h-24 w-24 rounded-full bg-muted flex items-center justify-center mb-6">
        <MessageSquare className="h-12 w-12 text-muted-foreground" />
      </div>
      <h2 className="text-xl font-semibold mb-2">No prompts yet</h2>
      <p className="text-muted-foreground max-w-md mb-6">
        Create your first AI prompt template to quickly reuse in your conversations with AI assistants.
      </p>
      <Button onClick={onCreatePrompt}>
        <Plus className="h-4 w-4 mr-2" />
        Create your first prompt
      </Button>
    </div>
  );
}
