
import { useState, useEffect, useMemo } from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import { getDaysRemaining } from '@/hooks/goals/use-goal-formatting';

export function useProjectFilters(projects: Project[]) {
  // Filter states
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showDueToday, setShowDueToday] = useState(false);
  const [showOverdue, setShowOverdue] = useState(false);
  const [showHighPriority, setShowHighPriority] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  
  // Derived state - has any active filter
  const hasActiveFilters = useMemo(() => {
    return searchQuery !== '' || 
      selectedTags.length > 0 || 
      showDueToday || 
      showOverdue || 
      showHighPriority ||
      showFavorites;
  }, [searchQuery, selectedTags, showDueToday, showOverdue, showHighPriority, showFavorites]);
  
  // Filter function
  const filteredProjects = useMemo(() => {
    return projects.filter(project => {
      // Filter by search query (case insensitive)
      if (searchQuery && !project.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }
      
      // Filter by selected tags
      if (selectedTags.length > 0) {
        const projectTags = project.tags || [];
        if (!selectedTags.some(tag => projectTags.includes(tag))) {
          return false;
        }
      }
      
      // Filter by due date - today
      if (showDueToday) {
        if (!project.due_date) return false;
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const dueDate = new Date(project.due_date);
        dueDate.setHours(0, 0, 0, 0);
        
        if (dueDate.getTime() !== today.getTime()) {
          return false;
        }
      }
      
      // Filter by due date - overdue
      if (showOverdue) {
        if (!project.due_date) return false;
        
        const daysRemaining = getDaysRemaining(project.due_date);
        if (daysRemaining === null || daysRemaining >= 0) {
          return false;
        }
      }
      
      // Filter by priority - high or asap
      if (showHighPriority) {
        if (project.priority !== 'high' && project.priority !== 'asap') {
          return false;
        }
      }
      
      // Filter by favorites
      if (showFavorites) {
        if (!project.is_favorite) {
          return false;
        }
      }
      
      return true;
    });
  }, [
    projects, 
    searchQuery, 
    selectedTags, 
    showDueToday, 
    showOverdue, 
    showHighPriority, 
    showFavorites
  ]);
  
  // Reset all filters
  const clearAllFilters = () => {
    setSearchQuery('');
    setSelectedTags([]);
    setShowDueToday(false);
    setShowOverdue(false);
    setShowHighPriority(false);
    setShowFavorites(false);
  };
  
  return {
    // Filter states
    searchQuery,
    setSearchQuery,
    selectedTags,
    setSelectedTags,
    showDueToday,
    setShowDueToday,
    showOverdue,
    setShowOverdue,
    showHighPriority,
    setShowHighPriority,
    showFavorites,
    setShowFavorites,
    // Utility
    clearAllFilters,
    hasActiveFilters,
    // Filtered data
    filteredProjects
  };
}
