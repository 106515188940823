
import React from 'react';
import { Reminder } from '@/types/reminder';
import { ReminderHeader } from './ReminderHeader';
import { ReminderMetadata } from './ReminderMetadata';

interface ReminderContentProps {
  reminder: Reminder;
  isOverdue: boolean;
}

export function ReminderContent({ reminder, isOverdue }: ReminderContentProps) {
  return (
    <div className="flex-1">
      <ReminderHeader reminder={reminder} isOverdue={isOverdue} />
      
      {reminder.description && (
        <p className="text-muted-foreground mt-1">{reminder.description}</p>
      )}
      
      <ReminderMetadata reminder={reminder} />
    </div>
  );
}
