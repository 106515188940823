
import React from 'react';
import { TabsList, TabsTrigger } from "@/components/ui/tabs";

interface TaskStatusTabsProps {
  statusTabs: Array<{
    value: string;
    label: string;
    icon: string;
  }>;
  selectedTab: string;
  onTabChange: (value: string) => void;
  tasksCountByStatus?: Record<string, number>;
}

/**
 * Component for rendering the task status tabs
 */
const TaskStatusTabs: React.FC<TaskStatusTabsProps> = ({
  statusTabs,
  selectedTab,
  onTabChange,
  tasksCountByStatus = {}
}) => {
  return (
    <TabsList className="grid w-full grid-cols-6">
      {statusTabs.map(tab => (
        <TabsTrigger 
          key={tab.value} 
          value={tab.value} 
          onClick={() => onTabChange(tab.value)}
        >
          {tab.label}
          {tasksCountByStatus && tasksCountByStatus[tab.value] !== undefined && (
            <span className="ml-1.5 inline-flex h-5 w-5 items-center justify-center rounded-full bg-primary/10 text-xs font-medium">
              {tasksCountByStatus[tab.value]}
            </span>
          )}
        </TabsTrigger>
      ))}
    </TabsList>
  );
};

export default TaskStatusTabs;
