
// Import the Goal type from utils
import { Goal } from '@/lib/utils';
import { CustomRecurrence } from '@/utils/recurrence-utils';
import { Json } from '@/integrations/supabase/types';

// Project collections data
export const projectCollections = [
  { 
    id: 'career', 
    name: 'Career', 
    path: '/collections/career',
    description: 'Work and professional goals'
  },
  { 
    id: 'family', 
    name: 'Family', 
    path: '/collections/family',
    description: 'Family-related projects'
  },
  { 
    id: 'friends-social', 
    name: 'Friends & Social Life', 
    path: '/collections/friends-social',
    description: 'Social activities and relationships'
  },
  { 
    id: 'finances', 
    name: 'Finances', 
    path: '/collections/finances',
    description: 'Financial planning and goals'
  },
  { 
    id: 'love-relationship', 
    name: 'Love & Relationship', 
    path: '/collections/love-relationship',
    description: 'Romantic relationship plans'
  },
  { 
    id: 'health-fitness', 
    name: 'Health & Fitness', 
    path: '/collections/health-fitness',
    description: 'Health and wellness goals'
  },
  { 
    id: 'personal-development', 
    name: 'Personal Development', 
    path: '/collections/personal-development',
    description: 'Self-improvement and learning'
  },
  { 
    id: 'fun-recreation', 
    name: 'Fun & Recreation', 
    path: '/collections/fun-recreation',
    description: 'Entertainment and leisure'
  }
];

// Define project type
export interface Project {
  id: string;
  title: string;
  description?: string | null;
  important_info?: string | null;
  ai?: string | null;
  collection_id: string;
  status?: string;
  priority?: 'asap' | 'high' | 'average' | 'low' | string;
  due_date?: string | null;
  recurrence?: string;
  custom_recurrence?: CustomRecurrence | Json | null;
  tags?: string[] | null;
  links?: { url: string; title?: string }[] | null;
  user_id: string;
  created_at?: string;
  updated_at?: string;
  is_favorite?: boolean;
  goals?: Goal[];
}
