
import { useState, useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export type TodoItem = {
  id: string;
  title: string;
  is_completed: boolean;
  user_id: string;
  created_at: string;
};

export function useQuickTodos() {
  const { user } = useAuth();
  const [todos, setTodos] = useState<TodoItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editText, setEditText] = useState('');

  // Fetch todos on component mount
  useEffect(() => {
    if (user) {
      fetchTodos();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const fetchTodos = async () => {
    try {
      setIsLoading(true);
      // Use custom query since the table doesn't exist in the types
      const { data, error } = await supabase
        .from('quick_todos')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Cast the data to our TodoItem type to satisfy TypeScript
      setTodos(data as TodoItem[] || []);
    } catch (error) {
      console.error('Error fetching todos:', error);
      toast.error('Failed to load todo items');
    } finally {
      setIsLoading(false);
    }
  };

  const addTodo = async (newTodoText: string) => {
    if (!newTodoText.trim() || !user) return;
    
    try {
      const newTodo = {
        title: newTodoText.trim(),
        is_completed: false,
        user_id: user.id
      };
      
      const { data, error } = await supabase
        .from('quick_todos')
        .insert([newTodo])
        .select();
        
      if (error) throw error;
      
      // Cast the data to our TodoItem type
      setTodos([data[0] as TodoItem, ...todos]);
      toast.success('Todo added');
    } catch (error) {
      console.error('Error adding todo:', error);
      toast.error('Failed to add todo');
    }
  };

  const toggleTodoStatus = async (id: string, currentStatus: boolean) => {
    try {
      const { error } = await supabase
        .from('quick_todos')
        .update({ is_completed: !currentStatus })
        .eq('id', id);
        
      if (error) throw error;
      
      setTodos(todos.map(todo => 
        todo.id === id ? { ...todo, is_completed: !currentStatus } : todo
      ));
    } catch (error) {
      console.error('Error updating todo:', error);
      toast.error('Failed to update todo status');
    }
  };

  const startEditing = (todo: TodoItem) => {
    setEditingId(todo.id);
    setEditText(todo.title);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditText('');
  };

  const saveTodoEdit = async (id: string) => {
    try {
      if (!editText.trim()) {
        cancelEditing();
        return;
      }
      
      const { error } = await supabase
        .from('quick_todos')
        .update({ title: editText.trim() })
        .eq('id', id);
        
      if (error) throw error;
      
      setTodos(todos.map(todo => 
        todo.id === id ? { ...todo, title: editText.trim() } : todo
      ));
      setEditingId(null);
      setEditText('');
    } catch (error) {
      console.error('Error updating todo:', error);
      toast.error('Failed to update todo');
    }
  };

  const deleteTodo = async (id: string) => {
    try {
      const { error } = await supabase
        .from('quick_todos')
        .delete()
        .eq('id', id);
        
      if (error) throw error;
      
      setTodos(todos.filter(todo => todo.id !== id));
      toast.success('Todo removed');
    } catch (error) {
      console.error('Error deleting todo:', error);
      toast.error('Failed to remove todo');
    }
  };

  return {
    todos,
    isLoading,
    editingId,
    editText,
    setEditText,
    addTodo,
    toggleTodoStatus,
    startEditing,
    cancelEditing,
    saveTodoEdit,
    deleteTodo
  };
}
