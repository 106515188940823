
import React from 'react';
import { Card } from '@/components/ui/card';
import { useQuickTodos } from './useQuickTodos';
import { TodoForm } from './TodoForm';
import { TodoItem } from './TodoItem';
import { ScrollArea } from '@/components/ui/scroll-area';

const QuickTodo: React.FC = () => {
  const {
    todos,
    isLoading,
    editingId,
    editText,
    setEditText,
    addTodo,
    toggleTodoStatus,
    startEditing,
    cancelEditing,
    saveTodoEdit,
    deleteTodo
  } = useQuickTodos();

  return (
    <Card className="bg-[#FEF7CD] dark:bg-yellow-900/40 mb-8 p-4 shadow-sm border border-yellow-300 dark:border-yellow-700">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium text-yellow-900 dark:text-yellow-50">Quick To-Do List</h2>
      </div>
      
      <TodoForm 
        onAddTodo={addTodo} 
        isLoading={isLoading} 
      />
      
      <ScrollArea className="h-[300px] w-full pr-4">
        <div className="space-y-2">
          {isLoading ? (
            <div className="text-center py-4 text-sm text-yellow-800 dark:text-yellow-200">Loading...</div>
          ) : todos.length === 0 ? (
            <div className="text-center py-4 text-sm text-yellow-800 dark:text-yellow-200">No to-dos yet. Add one above!</div>
          ) : (
            todos.map((todo) => (
              <TodoItem
                key={todo.id}
                todo={todo}
                editingId={editingId}
                editText={editText}
                onEditTextChange={setEditText}
                onToggleStatus={toggleTodoStatus}
                onStartEditing={startEditing}
                onCancelEditing={cancelEditing}
                onSaveEdit={saveTodoEdit}
                onDelete={deleteTodo}
              />
            ))
          )}
        </div>
      </ScrollArea>
    </Card>
  );
};

export default QuickTodo;
