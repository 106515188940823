
import * as z from 'zod';

export const taskFormSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().optional(),
  status: z.enum(['active', 'in_progress', 'queue', 'blocked', 'completed']),
  priority: z.enum(['asap', 'high', 'average', 'low']),
  estimated_minutes: z.union([
    z.number().min(0, 'Estimated time cannot be negative'),
    z.string().transform((val) => {
      const num = parseInt(val);
      return isNaN(num) ? undefined : num;
    }),
    z.null()
  ]).optional(),
  links: z.array(z.object({
    url: z.string().url({ message: 'Please enter a valid URL' }),
    title: z.string(),
  })).optional(),
  due_date: z.union([
    z.date(),
    z.null()
  ]).optional(),
  recurrence: z.union([
    z.enum(['daily', 'weekdays', 'weekends', 'weekly', 'monthly', 'yearly', 'custom']),
    z.null()
  ]).optional(),
  custom_recurrence: z.object({
    every: z.number().min(1, 'Value must be at least 1'),
    period: z.enum(['days', 'weeks', 'months', 'years']),
    day_of_week: z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']).optional()
  }).optional().nullable()
});

export type TaskFormValues = z.infer<typeof taskFormSchema>;
