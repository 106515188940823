
import { addDays, addWeeks, addMonths } from 'date-fns';
import { CustomRecurrence, DAY_OF_WEEK_INDICES, isValidCustomRecurrence } from './types';
import { Json } from '@/integrations/supabase/types';
import { calculateStandardRecurrence } from './standard-patterns';

/**
 * Calculate the next due date based on recurrence pattern
 */
export function calculateNextDueDate(
  recurrence: 'none' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'weekdays' | 'weekends' | null, 
  dueDate: Date,
  customRecurrence?: CustomRecurrence | Json | null
): Date {
  // Handle custom recurrence first
  if (customRecurrence && isValidCustomRecurrence(customRecurrence)) {
    return calculateCustomRecurrence(customRecurrence, dueDate);
  }
  
  // Handle standard recurrence patterns
  return calculateStandardRecurrence(recurrence, dueDate);
}

/**
 * Calculate next due date for custom recurrence patterns
 */
function calculateCustomRecurrence(
  customRecurrence: CustomRecurrence,
  dueDate: Date
): Date {
  const nextDate = new Date(dueDate);
  const { every, period, day_of_week } = customRecurrence;
  
  switch (period) {
    case 'days':
      return addDays(nextDate, every);
      
    case 'weeks':
      return calculateWeeklyRecurrence(nextDate, every, day_of_week);
      
    case 'months':
      return addMonths(nextDate, every);
      
    case 'years':
      return addMonths(nextDate, every * 12);
      
    default:
      console.warn('Unknown period in custom recurrence:', period);
      return nextDate;
  }
}

/**
 * Calculate weekly recurrence with specific day handling
 */
function calculateWeeklyRecurrence(
  baseDate: Date,
  everyNWeeks: number,
  dayOfWeek?: string
): Date {
  // For weekly recurrence with specific day
  if (dayOfWeek && DAY_OF_WEEK_INDICES[dayOfWeek] !== undefined) {
    const result = addWeeks(baseDate, everyNWeeks);
    
    // Set to the specified day of week
    const currentDayIndex = result.getDay(); // 0 = Sunday, 1 = Monday, ...
    const targetDayIndex = DAY_OF_WEEK_INDICES[dayOfWeek];
    
    // Calculate days to add (can be negative if we need to go backwards)
    let daysToAdd = targetDayIndex - currentDayIndex;
    
    // If we'd go backwards in time, add a week
    if (daysToAdd < 0) {
      daysToAdd += 7;
    }
    
    // Adjust the date to the correct day of week
    return addDays(result, daysToAdd);
  }
  
  // Standard weekly recurrence (same day each week)
  return addWeeks(baseDate, everyNWeeks);
}
