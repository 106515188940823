
import React from 'react';
import { 
  Table, 
  TableBody, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '@/components/ui/table';
import { ResourcePreview } from '@/types/resource';
import { useResources } from '@/hooks/use-resources';
import { toast } from 'sonner';
import ResourceListRow from './list/ResourceListRow';
import ResourcesLoadingState from './list/ResourcesLoadingState';
import ResourcesEmptyState from './list/ResourcesEmptyState';

interface ResourcesListProps {
  resources: ResourcePreview[];
  isLoading: boolean;
  language: string;
}

const ResourcesList: React.FC<ResourcesListProps> = ({ 
  resources, 
  isLoading,
  language 
}) => {
  // Get the delete mutation from useResources hook
  const { useDeleteResourceMutation } = useResources();
  const deleteResourceMutation = useDeleteResourceMutation();

  const handleDeleteResource = (id: string) => {
    deleteResourceMutation.mutate(id, {
      onSuccess: () => {
        toast.success('Resource deleted successfully');
      },
      onError: (error: Error) => {
        toast.error(`Error deleting resource: ${error.message}`);
      }
    });
  };

  if (isLoading) {
    return <ResourcesLoadingState />;
  }

  if (resources.length === 0) {
    return <ResourcesEmptyState />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Title</TableHead>
          <TableHead>Slug</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Categories</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {resources.map((resource) => (
          <ResourceListRow 
            key={resource.id} 
            resource={resource} 
            onDelete={handleDeleteResource} 
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ResourcesList;
