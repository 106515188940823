
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { UseFormReturn } from 'react-hook-form';
import { ResourceFormValues } from '@/hooks/resources/resource-form-types';

interface ExcerptFieldProps {
  form: UseFormReturn<ResourceFormValues>;
}

const ExcerptField: React.FC<ExcerptFieldProps> = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="excerpt"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Excerpt</FormLabel>
          <FormControl>
            <Textarea {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ExcerptField;
