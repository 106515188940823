
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResources } from '@/hooks/use-resources';
import { ResourceFormValues } from './resource-form-types';
import { useResourceFormData } from './use-resource-form-data';
import { useResourceSubmissions } from './use-resource-submissions';

export const useResourceForm = () => {
  const { i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isEditing = !!id;
  const language = i18n.language || 'en';
  const [availableLanguages] = useState(['en', 'it']);
  
  const { useCategoriesQuery } = useResources();
  const { data: categories = [] } = useCategoriesQuery();
  
  // Use the extracted hooks
  const { 
    selectedCategories, 
    setSelectedCategories,
    defaultValues,
    loading,
    error
  } = useResourceFormData(id, language);
  
  const {
    handleSubmit,
    handleCategoryChange,
    handleGenerateSlug,
    isSubmitting
  } = useResourceSubmissions(id, language, selectedCategories, setSelectedCategories);

  return {
    isEditing,
    defaultValues,
    handleSubmit,
    handleCategoryChange,
    handleGenerateSlug,
    selectedCategories,
    categories,
    isSubmitting,
    availableLanguages,
    language,
    loading,
    error
  };
};
