
import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, X } from 'lucide-react';

interface KeywordSearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const KeywordSearchBar: React.FC<KeywordSearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="relative flex-1">
      <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        placeholder="Search keywords..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="pl-10 w-full"
      />
      {searchTerm && (
        <Button
          variant="ghost"
          size="sm"
          className="absolute right-0 top-0 h-full"
          onClick={handleClearSearch}
        >
          <X className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default KeywordSearchBar;
