
import React from 'react';
import LinkInputForm from '@/components/projects/form-fields/links/LinkInputForm';
import LinkList from '@/components/projects/form-fields/links/LinkList';
import { UseFormReturn } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';

interface LinksFieldProps {
  form: UseFormReturn<TaskFormValues>;
}

const LinksField: React.FC<LinksFieldProps> = ({ form }) => {
  return (
    <div>
      <label className="text-sm font-medium mb-1 block">Links (Optional)</label>
      <LinkList form={form as any} />
      <LinkInputForm form={form as any} />
    </div>
  );
};

export default LinksField;
