
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ResourcePreview } from '@/types/resource';
import { useTheme } from '@/components/theme-provider';

interface ResourceCardProps {
  resource: ResourcePreview;
}

/**
 * Card component to display a single resource preview
 */
const ResourceCard: React.FC<ResourceCardProps> = ({ resource }) => {
  const { theme } = useTheme();
  const isLightMode = theme === 'light';

  return (
    <Link to={`/resources/${resource.slug}`} aria-label={`Read article: ${resource.title}`}>
      <Card className="h-full hover:shadow-md transition-all border border-border/40 dark:bg-card/50">
        <CardContent className="p-4 md:p-6">
          {resource.categories && resource.categories[0] && (
            <div className={`text-xs font-medium mb-2 ${isLightMode ? 'text-blue-700' : 'text-blue-300'}`}>
              {resource.categories[0].name}
            </div>
          )}
          <h3 className="text-xl font-semibold mb-2 line-clamp-2">{resource.title}</h3>
          <p className="text-muted-foreground line-clamp-2 mb-4 text-sm md:text-base">{resource.excerpt || ''}</p>
          <div className={`flex items-center text-sm font-medium group ${isLightMode ? 'text-blue-700' : 'text-blue-300'}`}>
            Read article
            <ArrowRight className="ml-1 h-3 w-3 transition-transform group-hover:translate-x-1" />
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ResourceCard;
