
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Project, projectCollections } from './sidebar-data';
import { useAuth } from '@/contexts/auth';
import { Json } from '@/integrations/supabase/types';

export function useSidebarProjects() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isCollectionsOpen, setIsCollectionsOpen] = useState(false);
  const [isCollectionListOpen, setIsCollectionListOpen] = useState(false);
  const [expandedCollections, setExpandedCollections] = useState<Record<string, boolean>>({});
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch projects from Supabase
  useEffect(() => {
    async function fetchProjects() {
      if (!user) return;
      
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('projects')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (error) {
          console.error('Error fetching projects:', error);
        } else {
          // Properly transform data to match Project interface
          const transformedProjects = data?.map(project => {
            // Safely cast the priority to the required type
            const priority = ['asap', 'high', 'average', 'low'].includes(project.priority) 
              ? project.priority as 'asap' | 'high' | 'average' | 'low'
              : 'average';
            
            return {
              ...project,
              priority,
              links: Array.isArray(project.links) 
                ? project.links as { url: string; title: string }[]
                : null,
              tags: Array.isArray(project.tags) ? project.tags : null,
            } as Project;
          }) || [];
          
          setProjects(transformedProjects);
        }
      } catch (error) {
        console.error('Error in fetchProjects:', error);
      } finally {
        setLoading(false);
      }
    }
    
    fetchProjects();
  }, [user]);
  
  // Auto-expand collection based on current route
  useEffect(() => {
    const currentPath = location.pathname;
    
    // Check if we're on a collection page
    projectCollections.forEach(collection => {
      if (currentPath === collection.path) {
        // Auto-expand this collection
        setExpandedCollections(prev => ({
          ...prev,
          [collection.id]: true
        }));
      }
    });
  }, [location.pathname]);

  // Toggle collection expand/collapse
  const toggleCollection = (collectionId: string, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    
    setExpandedCollections(prev => ({
      ...prev,
      [collectionId]: !prev[collectionId]
    }));
  };

  const isCollectionActive = (path: string) => {
    return location.pathname === path;
  };
  
  const isProjectsActive = location.pathname.startsWith('/projects');
  
  // Filter projects by collection
  const getProjectsByCollection = (collectionId: string) => {
    return projects.filter(project => project.collection_id === collectionId);
  };
  
  const navigateToProject = (projectId: string, projectTitle: string) => {
    // Navigate to project detail page
    navigate(`/projects/${projectId}`);
  };

  return {
    isCollectionsOpen,
    setIsCollectionsOpen,
    isCollectionListOpen,
    setIsCollectionListOpen,
    expandedCollections,
    projects,
    loading,
    toggleCollection,
    isCollectionActive,
    isProjectsActive,
    getProjectsByCollection,
    navigateToProject
  };
}
