
import React, { useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ResourceCarouselHeaderProps {
  isClientSide: boolean;
  onVisible: () => void;
}

/**
 * Header component for the resource carousel section
 */
const ResourceCarouselHeader: React.FC<ResourceCarouselHeaderProps> = ({ 
  isClientSide,
  onVisible 
}) => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const subheadingRef = useRef<HTMLParagraphElement>(null);

  // Observe LCP element for faster loading
  useEffect(() => {
    // Mark the critical heading elements for LCP
    if (headingRef.current) {
      headingRef.current.setAttribute('fetchpriority', 'high');
    }
    if (subheadingRef.current) {
      subheadingRef.current.setAttribute('fetchpriority', 'high');
      subheadingRef.current.classList.add('lcp-element');
    }

    // Create intersection observer to monitor visibility
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // When visible, trigger the callback
            onVisible();
            // Disconnect after first observation
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => observer.disconnect();
  }, [onVisible]);

  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-5 md:mb-8">
      <div>
        <h2 
          className="text-2xl md:text-3xl font-bold mb-1 md:mb-2" 
          id="popular-resources-heading"
          ref={headingRef}
        >
          Popular Resources
        </h2>
        <p 
          className="text-muted-foreground text-sm md:text-base"
          ref={subheadingRef}
        >
          Discover articles and guides to boost your productivity
        </p>
      </div>
      {isClientSide && (
        <Link to="/resources" className="hidden md:block">
          <Button variant="outline" className="group mt-3 md:mt-0">
            Browse All Resources 
            <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
          </Button>
        </Link>
      )}
    </div>
  );
};

export default ResourceCarouselHeader;
