
import React from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '@/layouts/MainLayout';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import AdminPageHeader from '@/components/admin/common/AdminPageHeader';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useMetadata } from '@/hooks/use-metadata';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import KeywordsList from '@/components/resources/keywords/KeywordsList';
import OpenAIConfigForm from '@/components/resources/openai/OpenAIConfigForm';
import { useKeywords } from '@/hooks/use-keywords';

const AdminResourcesGenerator: React.FC = () => {
  const { t } = useTranslation();
  const { 
    useOpenAIConfigQuery, 
    useOpenAIConfigMutation,
    useKeywordsQuery,
    useDeleteKeywordMutation
  } = useKeywords();
  
  const openAIConfigQuery = useOpenAIConfigQuery();
  const saveOpenAIConfig = useOpenAIConfigMutation();
  const keywordsQuery = useKeywordsQuery();
  const deleteKeyword = useDeleteKeywordMutation();
  
  useMetadata({
    title: 'Resources Generator',
    description: 'Configure AI settings and generate resources from keywords',
  });
  
  const handleSaveConfig = async (data: any) => {
    await saveOpenAIConfig.mutateAsync(data);
  };
  
  const handleDeleteKeyword = async (id: string) => {
    await deleteKeyword.mutateAsync(id);
  };
  
  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-7xl">
          <AdminPageHeader 
            title={t('Resource Generator')}
            description={t('Configure AI settings and generate resources from keywords')}
            backRoute="/admin/resources"
          />
          
          <Tabs defaultValue="keywords" className="mt-6">
            <TabsList className="mb-4">
              <TabsTrigger value="keywords">Keywords</TabsTrigger>
              <TabsTrigger value="config">AI Configuration</TabsTrigger>
            </TabsList>
            
            <TabsContent value="keywords">
              <Card>
                <CardHeader>
                  <CardTitle>Keywords</CardTitle>
                  <CardDescription>
                    Generate resources from keywords using AI
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <KeywordsList 
                    keywords={keywordsQuery.data || []}
                    isLoading={keywordsQuery.isLoading}
                    onDelete={handleDeleteKeyword}
                  />
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="config">
              <Card>
                <CardHeader>
                  <CardTitle>OpenAI Configuration</CardTitle>
                  <CardDescription>
                    Configure OpenAI settings for resource generation
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  {openAIConfigQuery.isLoading ? (
                    <div className="py-8 text-center">Loading configuration...</div>
                  ) : openAIConfigQuery.error ? (
                    <div className="py-8 text-center text-destructive">
                      Error loading configuration: {openAIConfigQuery.error.message}
                    </div>
                  ) : (
                    <OpenAIConfigForm 
                      defaultValues={openAIConfigQuery.data || {
                        model: 'gpt-4o',
                        temperature: 0.7,
                        max_tokens: 2048,
                        resource_template: null,
                        author_profile: null
                      }}
                      onSubmit={handleSaveConfig}
                      isSubmitting={saveOpenAIConfig.isPending}
                    />
                  )}
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminResourcesGenerator;
