
import React from 'react';
import { AlertTriangle, ArrowUp, ArrowDown, Minus } from 'lucide-react';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { TaskPriority } from '@/types/task';

interface PrioritySelectorProps {
  priority: string;
  onUpdatePriority?: (priority: TaskPriority) => Promise<void>;
}

export const priorityConfig = {
  asap: {
    icon: <AlertTriangle className="h-3 w-3" />,
    label: 'ASAP',
    bg: 'bg-red-500 text-white dark:bg-red-600',
    dropdownBg: 'bg-red-500 dark:bg-red-600',
    dropdownText: 'text-white'
  },
  high: {
    icon: <ArrowUp className="h-3 w-3" />,
    label: 'High',
    bg: 'bg-orange-500 text-white dark:bg-orange-600',
    dropdownBg: 'bg-orange-500 dark:bg-orange-600',
    dropdownText: 'text-white'
  },
  average: {
    icon: <Minus className="h-3 w-3" />,
    label: 'Average',
    bg: 'bg-blue-500 text-white dark:bg-blue-600',
    dropdownBg: 'bg-blue-500 dark:bg-blue-600',
    dropdownText: 'text-white'
  },
  low: {
    icon: <ArrowDown className="h-3 w-3" />,
    label: 'Low',
    bg: 'bg-green-500 text-white dark:bg-green-600',
    dropdownBg: 'bg-green-500 dark:bg-green-600',
    dropdownText: 'text-white'
  }
};

const PrioritySelector: React.FC<PrioritySelectorProps> = ({
  priority,
  onUpdatePriority
}) => {
  const currentPriority = priorityConfig[priority as keyof typeof priorityConfig] || priorityConfig.average;
  
  const handlePriorityChange = async (newPriority: TaskPriority) => {
    if (onUpdatePriority) {
      await onUpdatePriority(newPriority);
    }
  };

  if (onUpdatePriority) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div 
            className={cn(
              "flex items-center gap-1 px-2 py-0.5 rounded cursor-pointer hover:opacity-90 transition-opacity",
              currentPriority.bg
            )} 
            title={`Priority: ${currentPriority.label} (click to change)`}
          >
            {React.cloneElement(currentPriority.icon, { 
              className: "h-3 w-3 text-current"
            })}
            <span>{currentPriority.label}</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-32">
          {Object.entries(priorityConfig).map(([key, config]) => (
            <DropdownMenuItem 
              key={key}
              className={cn(
                "flex items-center gap-1.5 my-1 rounded",
                config.dropdownBg,
                config.dropdownText
              )}
              onClick={() => handlePriorityChange(key as TaskPriority)}
            >
              {React.cloneElement(config.icon, { className: config.dropdownText })}
              <span>{config.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
  
  return (
    <div 
      className={cn(
        "flex items-center gap-1 px-2 py-0.5 rounded",
        currentPriority.bg
      )} 
      title={`Priority: ${currentPriority.label}`}
    >
      {React.cloneElement(currentPriority.icon, { 
        className: "h-3 w-3 text-current"
      })}
      <span>{currentPriority.label}</span>
    </div>
  );
};

export default PrioritySelector;
