
import React from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import PriorityDropdown from '@/components/project-details/dropdowns/PriorityDropdown';
import StatusDropdown from '@/components/project-details/dropdowns/StatusDropdown';
import RecurrenceDropdown from '@/components/project-details/dropdowns/RecurrenceDropdown';
import DueDateDropdown from '@/components/project-details/dropdowns/DueDateDropdown';
import TagsDropdown from '@/components/project-details/dropdowns/TagsDropdown';

interface ProjectCardActionsProps {
  project: Project;
  onProjectUpdated: () => void;
}

const ProjectCardActions: React.FC<ProjectCardActionsProps> = ({
  project,
  onProjectUpdated
}) => {
  return (
    <div className="flex flex-wrap gap-2 mt-2 interactive-element">
      <PriorityDropdown 
        priority={project.priority || 'average'} 
        projectId={project.id} 
        onProjectUpdated={onProjectUpdated}
      />
      
      <StatusDropdown 
        status={project.status || 'active'} 
        projectId={project.id} 
        onProjectUpdated={onProjectUpdated}
      />
      
      <RecurrenceDropdown 
        recurrence={project.recurrence} 
        projectId={project.id} 
        onProjectUpdated={onProjectUpdated}
      />
      
      <DueDateDropdown 
        dueDate={project.due_date} 
        projectId={project.id} 
        onProjectUpdated={onProjectUpdated}
      />
      
      <TagsDropdown 
        tags={project.tags || null}
        projectId={project.id} 
        onProjectUpdated={onProjectUpdated}
      />
    </div>
  );
};

export default ProjectCardActions;
