
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface AdminPageHeaderProps {
  title: string;
  description?: string;
  backRoute?: string;
  createButtonText?: string;
  onCreateClick?: () => void;
  children?: ReactNode;
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = ({
  title,
  description,
  backRoute = '/admin',
  createButtonText,
  onCreateClick,
  children
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between mb-8">
      <div className="flex items-center gap-4">
        <Button 
          variant="outline" 
          size="icon"
          onClick={() => navigate(backRoute)}
        >
          <ArrowLeft className="h-4 w-4" />
        </Button>
        <div>
          <h1 className="text-3xl font-bold">{title}</h1>
          {description && <p className="text-muted-foreground mt-2">{description}</p>}
        </div>
      </div>
      {createButtonText && onCreateClick && (
        <Button onClick={onCreateClick}>
          {createButtonText}
        </Button>
      )}
      {children}
    </div>
  );
};

export default AdminPageHeader;
