
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'sonner';
import { 
  fetchPrompts, 
  fetchPromptsByTag, 
  fetchUserPromptTags,
  createPrompt,
  updatePrompt,
  deletePrompt
} from '@/services/prompts';
import { Prompt, CreatePromptPayload, UpdatePromptPayload } from '@/types/prompt';

export const usePrompts = () => {
  const { user } = useAuth();
  const userId = user?.id;
  const queryClient = useQueryClient();
  const [activeTag, setActiveTag] = useState<string | null>(null);

  // Fetch all prompts
  const { 
    data: prompts = [], 
    isLoading,
    error 
  } = useQuery({
    queryKey: ['prompts', userId, activeTag],
    queryFn: () => {
      if (!userId) return Promise.resolve([]);
      return activeTag 
        ? fetchPromptsByTag(userId, activeTag)
        : fetchPrompts(userId);
    },
    enabled: !!userId
  });

  // Fetch all unique tags used by the user
  const { 
    data: tags = [],
    isLoading: isLoadingTags 
  } = useQuery({
    queryKey: ['promptTags', userId],
    queryFn: () => {
      if (!userId) return Promise.resolve([]);
      return fetchUserPromptTags(userId);
    },
    enabled: !!userId
  });

  // Create prompt mutation
  const createPromptMutation = useMutation({
    mutationFn: (newPrompt: Omit<CreatePromptPayload, 'user_id'>) => {
      if (!userId) {
        throw new Error('User not authenticated');
      }
      // Explicitly add the user_id to the payload
      return createPrompt({ ...newPrompt, user_id: userId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['promptTags'] });
      toast.success('Prompt created successfully');
    },
    onError: (err: any) => {
      toast.error('Failed to create prompt', {
        description: err.message
      });
    }
  });

  // Update prompt mutation
  const updatePromptMutation = useMutation({
    mutationFn: updatePrompt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['promptTags'] });
      toast.success('Prompt updated successfully');
    },
    onError: (err: any) => {
      toast.error('Failed to update prompt', {
        description: err.message
      });
    }
  });

  // Delete prompt mutation
  const deletePromptMutation = useMutation({
    mutationFn: deletePrompt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['promptTags'] });
      toast.success('Prompt deleted successfully');
    },
    onError: (err: any) => {
      toast.error('Failed to delete prompt', {
        description: err.message
      });
    }
  });

  const handleCreatePrompt = async (promptData: Omit<CreatePromptPayload, 'user_id'>) => {
    await createPromptMutation.mutate(promptData);
  };

  const handleUpdatePrompt = async (promptData: UpdatePromptPayload) => {
    await updatePromptMutation.mutate(promptData);
  };

  const handleDeletePrompt = async (promptId: string) => {
    await deletePromptMutation.mutate(promptId);
  };

  const copyPromptToClipboard = (content: string) => {
    navigator.clipboard.writeText(content)
      .then(() => toast.success('Copied to clipboard'))
      .catch(err => toast.error('Failed to copy to clipboard'));
  };

  return {
    prompts,
    tags,
    isLoading,
    isLoadingTags,
    error,
    activeTag,
    setActiveTag,
    handleCreatePrompt,
    handleUpdatePrompt,
    handleDeletePrompt,
    copyPromptToClipboard,
    isCreating: createPromptMutation.isPending,
    isUpdating: updatePromptMutation.isPending,
    isDeleting: deletePromptMutation.isPending
  };
};
