
import { supabase } from '@/integrations/supabase/client';
import { Resource, convertToResource } from '@/types/resource';

export const createResource = async (resource: Record<string, any>): Promise<Resource> => {
  // Ensure resource has required fields for insert
  if (!resource.slug) {
    throw new Error('Resource must have a slug');
  }
  
  if (!resource.author_id) {
    throw new Error('Resource must have an author_id');
  }

  // Make sure resource is correctly typed before inserting
  const { data, error } = await supabase
    .from('resources')
    .insert({
      title: resource.title || {},
      slug: resource.slug,
      content: resource.content || {},
      excerpt: resource.excerpt || {},
      meta_title: resource.meta_title || {},
      meta_description: resource.meta_description || {},
      status: resource.status || 'draft',
      author_id: resource.author_id,
      published_at: resource.published_at
    })
    .select()
    .single();

  if (error) throw new Error(error.message);
  return convertToResource(data);
};

export const updateResource = async (id: string, resource: Record<string, any>): Promise<Resource> => {
  const { data, error } = await supabase
    .from('resources')
    .update(resource)
    .eq('id', id)
    .select()
    .single();

  if (error) throw new Error(error.message);
  return convertToResource(data);
};

export const deleteResource = async (id: string): Promise<void> => {
  // First get any related keywords for this resource
  const { data: mappings, error: mappingsError } = await supabase
    .from('resource_keyword_mappings')
    .select('keyword_id')
    .eq('resource_id', id);
    
  if (mappingsError) throw new Error(mappingsError.message);
  
  // Delete the resource
  const { error } = await supabase
    .from('resources')
    .delete()
    .eq('id', id);

  if (error) throw new Error(error.message);
  
  // If we have related keywords, reset their status so they can be regenerated
  if (mappings && mappings.length > 0) {
    const keywordIds = mappings.map(m => m.keyword_id);
    
    // Update each keyword to reset its status
    for (const keywordId of keywordIds) {
      const { error: resetError } = await supabase
        .from('resource_keywords')
        .update({
          status: 'pending',
          generation_status: 'pending',
          generation_metadata: {}
        })
        .eq('id', keywordId);
      
      if (resetError) {
        console.error(`Error resetting keyword ${keywordId}:`, resetError);
        // Continue with other keywords even if one fails
      }
    }
  }
};
