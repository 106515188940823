
import React, { useState } from 'react';
import EditableContent from './EditableContent';
import { PlusCircle, Copy, Trash } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { Input } from '@/components/ui/input';

interface AINote {
  id: string;
  content: string;
  title: string;
}

interface EditableAIProps {
  projectId: string;
  aiContent: string | null;
  onAIUpdated: () => void;
}

const EditableAI: React.FC<EditableAIProps> = ({ 
  projectId, 
  aiContent, 
  onAIUpdated 
}) => {
  const [aiNotes, setAiNotes] = useState<AINote[]>(() => {
    try {
      if (!aiContent) return [];
      const parsed = JSON.parse(aiContent);
      return Array.isArray(parsed) ? parsed : [];
    } catch (e) {
      // If the content is not JSON, treat it as a single note
      return aiContent ? [{ id: '1', content: aiContent, title: 'Note 1' }] : [];
    }
  });
  
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [isAddingNote, setIsAddingNote] = useState(false);

  const handleAddNote = async () => {
    if (isAddingNote) {
      // Save the new note
      const newNote = {
        id: Date.now().toString(),
        content: '',
        title: newNoteTitle || `Note ${aiNotes.length + 1}`
      };
      
      const updatedNotes = [...aiNotes, newNote];
      await saveNotesToDb(updatedNotes);
      setAiNotes(updatedNotes);
      setNewNoteTitle('');
      setIsAddingNote(false);
    } else {
      // Show the add note input
      setIsAddingNote(true);
    }
  };

  const handleDeleteNote = async (id: string) => {
    const updatedNotes = aiNotes.filter(note => note.id !== id);
    await saveNotesToDb(updatedNotes);
    setAiNotes(updatedNotes);
    toast.success('AI note deleted');
  };

  const handleNoteContentUpdate = async (id: string, newContent: string) => {
    const updatedNotes = aiNotes.map(note => 
      note.id === id ? { ...note, content: newContent } : note
    );
    await saveNotesToDb(updatedNotes);
    setAiNotes(updatedNotes);
  };

  const saveNotesToDb = async (notes: AINote[]) => {
    try {
      const { error } = await supabase
        .from('projects')
        .update({ ai: JSON.stringify(notes) })
        .eq('id', projectId);
        
      if (error) throw error;
      onAIUpdated();
    } catch (error) {
      console.error('Failed to update AI notes:', error);
      toast.error('Failed to update AI notes');
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success('Copied to clipboard'))
      .catch(() => toast.error('Failed to copy'));
  };

  const cancelAddingNote = () => {
    setIsAddingNote(false);
    setNewNoteTitle('');
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium">AI Notes</h3>
        <Button 
          variant="ghost" 
          size="sm"
          onClick={handleAddNote}
          className="h-8 px-2 text-xs"
        >
          <PlusCircle className="h-4 w-4 mr-1" />
          {isAddingNote ? 'Save' : 'Add Note'}
        </Button>
      </div>

      {isAddingNote && (
        <div className="mb-3 flex gap-2">
          <Input
            value={newNoteTitle}
            onChange={(e) => setNewNoteTitle(e.target.value)}
            placeholder="Note title"
            className="text-sm"
          />
          <Button 
            variant="outline" 
            size="sm" 
            onClick={cancelAddingNote}
            className="h-10"
          >
            Cancel
          </Button>
        </div>
      )}

      {aiNotes.length === 0 && !isAddingNote ? (
        <div className="bg-[#FEF7CD] rounded-md p-3 text-sm text-muted-foreground italic">
          No AI notes yet. Click the "Add Note" button to create one.
        </div>
      ) : (
        <div className="space-y-3">
          {aiNotes.map((note) => (
            <div key={note.id} className="bg-[#FEF7CD] rounded-md overflow-hidden">
              <div className="flex items-center justify-between bg-[#F8EEA8] px-3 py-1.5">
                <h4 className="text-sm font-medium">{note.title}</h4>
                <div className="flex gap-1">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => copyToClipboard(note.content)}
                    className="h-7 w-7 p-0"
                    title="Copy to clipboard"
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteNote(note.id)}
                    className="h-7 w-7 p-0 text-destructive hover:text-destructive"
                    title="Delete note"
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                </div>
              </div>
              <div className="p-3">
                <EditableContent
                  content={note.content}
                  onUpdate={(newContent) => handleNoteContentUpdate(note.id, newContent)}
                  placeholder="Add AI notes for this project..."
                  emptyText="Click to add content to this note."
                  type="textarea"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditableAI;
