
import React from 'react';
import { ArrowLeft, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { ResourceDetail } from '@/types/resource';
import { formatDate } from '@/utils/date-utils';
import ResourceRating from './ResourceRating';

interface ResourceHeaderProps {
  resource: ResourceDetail;
  onRatingUpdated?: () => void;
}

const ResourceHeader: React.FC<ResourceHeaderProps> = ({ resource, onRatingUpdated }) => {
  return (
    <div className="mb-8">
      <div className="flex items-center mb-6">
        <Button variant="ghost" size="sm" asChild className="mr-2">
          <Link to="/resources">
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to resources
          </Link>
        </Button>
      </div>

      <h1 className="text-3xl font-bold mb-4">{resource.title}</h1>
      
      {resource.rating && (
        <div className="mb-4 text-yellow-500">
          <ResourceRating 
            resourceId={resource.id}
            averageRating={resource.rating.value} 
            ratingCount={resource.rating.count}
            onRatingUpdated={onRatingUpdated}
          />
        </div>
      )}
      
      {resource.published_at && (
        <div className="flex items-center text-sm text-muted-foreground mb-4">
          <Calendar className="h-4 w-4 mr-1" />
          {formatDate(resource.published_at)}
        </div>
      )}
      
      <div className="flex flex-wrap gap-2 mb-6">
        {resource.categories.map((category) => (
          <Badge key={category.id}>
            {category.name}
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default ResourceHeader;
