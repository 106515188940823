
import React from 'react';
import NewProjectButton from '@/components/NewProjectButton';

interface EmptyCollectionProps {
  collectionId: string;
  onSuccess: () => void;
}

const EmptyCollection: React.FC<EmptyCollectionProps> = ({ collectionId, onSuccess }) => {
  return (
    <div className="text-center py-12 bg-white/5 rounded-lg border border-white/10">
      <h3 className="text-xl font-medium mb-2">No projects in this collection yet</h3>
      <p className="text-muted-foreground mb-6">Start by creating your first project</p>
      <div className="max-w-md mx-auto">
        <NewProjectButton 
          collectionId={collectionId} 
          onSuccess={onSuccess}
        />
      </div>
    </div>
  );
};

export default EmptyCollection;
