
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { Prompt, CreatePromptPayload, UpdatePromptPayload } from '@/types/prompt';
import { PromptForm } from './PromptForm';

interface PromptsDialogsProps {
  isCreateOpen: boolean;
  setIsCreateOpen: (open: boolean) => void;
  editingPrompt: Prompt | null;
  setEditingPrompt: (prompt: Prompt | null) => void;
  deletingPromptId: string | null;
  setDeletingPromptId: (id: string | null) => void;
  handleCreate: (data: CreatePromptPayload) => Promise<void>;
  handleUpdate: (data: UpdatePromptPayload) => Promise<void>;
  handleDelete: (id: string) => void;
  isCreating: boolean;
  isUpdating: boolean;
}

export function PromptsDialogs({
  isCreateOpen,
  setIsCreateOpen,
  editingPrompt,
  setEditingPrompt,
  deletingPromptId,
  setDeletingPromptId,
  handleCreate,
  handleUpdate,
  handleDelete,
  isCreating,
  isUpdating
}: PromptsDialogsProps) {
  return (
    <>
      {/* Create Prompt Dialog */}
      <Dialog open={isCreateOpen} onOpenChange={setIsCreateOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Create New Prompt</DialogTitle>
          </DialogHeader>
          <PromptForm 
            onSubmit={handleCreate} 
            isSubmitting={isCreating}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Prompt Dialog */}
      <Dialog 
        open={!!editingPrompt} 
        onOpenChange={(open) => !open && setEditingPrompt(null)}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Prompt</DialogTitle>
          </DialogHeader>
          {editingPrompt && (
            <PromptForm
              onSubmit={(data) => handleUpdate({ ...data, id: editingPrompt.id })}
              defaultValues={editingPrompt}
              isSubmitting={isUpdating}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog 
        open={!!deletingPromptId} 
        onOpenChange={(open) => !open && setDeletingPromptId(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Prompt</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this prompt? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={() => {
                if (deletingPromptId) {
                  handleDelete(deletingPromptId);
                  setDeletingPromptId(null);
                }
              }}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
