
import React, { useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useProjectDetails } from '@/hooks/use-project-details';
import { useProjectTasks } from '@/hooks/use-project-tasks';
import ProjectHeader from '@/components/project-details/ProjectHeader';
import TaskManagement from '@/components/project-details/TaskManagement';
import GoalsManagement from '@/components/project-details/goals/GoalsManagement';
import { Loader2 } from 'lucide-react';
import ProjectNotFound from '@/components/project-details/ProjectNotFound';
import { useDocumentTitle } from '@/hooks/use-document-title';
import ProjectDoneForToday from '@/components/project-details/ProjectDoneForToday';
import { useAuth } from '@/contexts/auth';

const ProjectDetails = () => {
  // Fix: Access the 'id' parameter instead of 'projectId'
  const { id: projectId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const initialLoadAttemptedRef = useRef(false);
  
  // Use stable project ID
  const stableProjectId = useMemo(() => projectId, [projectId]);
  const stableUserId = useMemo(() => user?.id, [user?.id]);
  
  const { 
    project, 
    isLoading: projectLoading, 
    error: projectError, 
    refreshProject 
  } = useProjectDetails(stableProjectId);
  
  const { 
    tasks, 
    isLoading: tasksLoading, 
    createTask, 
    updateTask, 
    deleteTask, 
    toggleTaskExpansion,
    refreshTasks
  } = useProjectTasks(stableProjectId);

  useDocumentTitle(project ? `Project: ${project.title}` : 'Project Details');
  
  const isLoading = projectLoading || tasksLoading;

  console.log('ProjectDetails - projectId:', stableProjectId);
  console.log('ProjectDetails - user:', stableUserId);
  console.log('ProjectDetails - project:', project);
  console.log('ProjectDetails - projectError:', projectError);
  console.log('ProjectDetails - isLoading:', isLoading);

  // Refresh data only once when component mounts
  useEffect(() => {
    // Only attempt to load once on mount
    if (!initialLoadAttemptedRef.current && stableUserId && stableProjectId) {
      console.log('Initial project data load...');
      initialLoadAttemptedRef.current = true;
    }
    
    // No need to call refreshProject or refreshTasks here as they'll be called by their respective hooks
  }, [stableProjectId, stableUserId]);

  // Project not found or error loading project
  if (!isLoading && (!project || projectError)) {
    console.log('Project not found or error, rendering ProjectNotFound component');
    return (
      <ProtectedRoute>
        <MainLayout>
          <ProjectNotFound />
        </MainLayout>
      </ProtectedRoute>
    );
  }

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="max-w-5xl mx-auto w-full px-4 pb-12">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : (
            <>
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
                <ProjectHeader 
                  project={project!} 
                  tasksCount={tasks.length}
                  onProjectUpdated={refreshProject} 
                />
                
                {project && (
                  <ProjectDoneForToday 
                    project={project} 
                    onSuccess={refreshProject} 
                  />
                )}
              </div>

              <div className="mt-6 space-y-6">
                <GoalsManagement projectId={stableProjectId!} />
                
                <TaskManagement
                  projectId={stableProjectId!}
                  tasks={tasks}
                  onCreateTask={createTask}
                  onUpdateTask={updateTask}
                  onDeleteTask={deleteTask}
                  onToggleExpansion={toggleTaskExpansion}
                  onRefreshTasks={refreshTasks}
                />
              </div>
            </>
          )}
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default ProjectDetails;
