import { LinkItem } from '@/components/projects/form-fields/links/types';
import { Json } from '@/integrations/supabase/types';

export type TaskStatus = 'active' | 'in_progress' | 'queue' | 'completed' | 'blocked';
export type TaskPriority = 'asap' | 'high' | 'average' | 'low';

export interface Task {
  id: string;
  project_id: string;
  parent_task_id: string | null;
  title: string;
  description: string | null;
  notes: string | null;
  status: TaskStatus;
  priority: TaskPriority;
  estimated_minutes: number | null;
  links: LinkItem[];
  position: number;
  created_at: string;
  updated_at: string;
  user_id: string;
  due_date: string | null;
  recurrence: string | null;
  custom_recurrence?: {
    every: number;
    period: string;
    day_of_week?: string;
  } | null;
  subtasks?: Task[];
  isExpanded?: boolean;
  isLoading?: boolean; // Added isLoading flag for UI state tracking
  auto_increment_time?: boolean; // Added auto_increment_time flag
}

export interface CreateTaskPayload {
  title: string;
  project_id: string;
  parent_task_id?: string | null;
  description?: string | null;
  notes?: string | null;
  status?: TaskStatus;
  priority?: TaskPriority;
  estimated_minutes?: number | null;
  links?: LinkItem[];
  position?: number;
  due_date?: string | null;
  recurrence?: string | null;
  custom_recurrence?: {
    every: number;
    period: string;
    day_of_week?: string;
  } | null;
}

export interface UpdateTaskPayload {
  id: string;
  title?: string;
  description?: string | null;
  notes?: string | null;
  status?: TaskStatus;
  priority?: TaskPriority;
  estimated_minutes?: number | null;
  links?: LinkItem[];
  position?: number;
  parent_task_id?: string | null;
  due_date?: string | null;
  recurrence?: string | null;
  custom_recurrence?: {
    every: number;
    period: string;
    day_of_week?: string;
  } | null;
}

// Helper functions to convert between Task types and Supabase JSON
export const convertTaskToSupabase = (task: Partial<Task> | CreateTaskPayload | UpdateTaskPayload): any => {
  if (!task) return task;
  
  // If links exist, ensure they're properly formatted for storage
  if (task.links) {
    return {
      ...task,
      links: task.links as unknown as Json,
      custom_recurrence: task.custom_recurrence ? task.custom_recurrence as unknown as Json : null
    };
  }
  
  return task;
};

export const convertSupabaseToTask = (dbTask: any): Task => {
  // Ensure links is transformed to the expected LinkItem[] format
  return {
    ...dbTask,
    links: Array.isArray(dbTask.links) 
      ? dbTask.links 
      : (dbTask.links ? JSON.parse(JSON.stringify(dbTask.links)) : []),
    custom_recurrence: dbTask.custom_recurrence 
      ? JSON.parse(JSON.stringify(dbTask.custom_recurrence))
      : null,
    isExpanded: true,  // default to expanded
    isLoading: false   // default to not loading
  } as Task;
};
