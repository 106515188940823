
import { supabase } from '@/integrations/supabase/client';

export const addCategoryToResource = async (resourceId: string, categoryId: string): Promise<void> => {
  const { error } = await supabase
    .from('resource_category_mappings')
    .insert({ resource_id: resourceId, category_id: categoryId });

  if (error) throw new Error(error.message);
};

export const removeCategoryFromResource = async (resourceId: string, categoryId: string): Promise<void> => {
  const { error } = await supabase
    .from('resource_category_mappings')
    .delete()
    .eq('resource_id', resourceId)
    .eq('category_id', categoryId);

  if (error) throw new Error(error.message);
};
