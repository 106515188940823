
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { CustomRecurrenceSettings } from './recurrence-types';

export const useRecurrenceUpdate = (projectId: string, onProjectUpdated: () => void) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  const updateRecurrence = async (value: string, customSettings?: CustomRecurrenceSettings) => {
    if (updating) return;
    
    setUpdating(true);
    try {
      // Prepare the update data
      const updateData: Record<string, any> = { recurrence: value };
      
      // For custom recurrence, include the custom_recurrence object
      if (value === 'custom' && customSettings) {
        // Ensure day_of_week is included when using weeks as period
        if (customSettings.period === 'weeks' && !customSettings.day_of_week) {
          // Default to current day if not specified
          const today = new Date().getDay();
          const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
          customSettings.day_of_week = dayMap[today];
        }
        
        // Deep clone the settings to avoid reference issues
        updateData.custom_recurrence = JSON.parse(JSON.stringify(customSettings));
        console.log('Updating project with custom settings:', JSON.stringify(updateData.custom_recurrence));
      } else {
        // When not using custom recurrence, set it to null
        updateData.custom_recurrence = null;
      }
      
      console.log('Updating project recurrence:', projectId, JSON.stringify(updateData));
      
      const { data, error } = await supabase
        .from('projects')
        .update(updateData)
        .eq('id', projectId)
        .select();

      if (error) {
        console.error('Error details:', error);
        throw error;
      }

      console.log('Project updated successfully:', data);
      toast.success(t('projects.frequency.updated', 'Project frequency updated'));
      onProjectUpdated();
    } catch (error: any) {
      console.error('Error updating project frequency:', error);
      toast.error(t('projects.frequency.error', 'Failed to update project frequency'));
    } finally {
      setUpdating(false);
    }
  };

  return { updating, updateRecurrence };
};

export const useCustomRecurrenceSettings = (initialSettings?: {
  every?: number;
  period?: string;
  day_of_week?: string;
} | null) => {
  const defaultDayOfWeek = () => {
    const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, etc.
    const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return dayMap[today];
  };

  const [customSettings, setCustomSettings] = useState<CustomRecurrenceSettings>({
    every: initialSettings?.every || 1,
    period: initialSettings?.period || 'weeks',
    day_of_week: initialSettings?.day_of_week || defaultDayOfWeek()
  });

  console.log('Initial custom settings:', customSettings);

  const updateCustomSettings = (key: string, value: any) => {
    setCustomSettings(prev => {
      const newSettings = { ...prev, [key]: value };
      console.log('Updated custom settings:', key, value, newSettings);
      return newSettings;
    });
  };

  return { customSettings, updateCustomSettings };
};
