
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { AppRole, UserProfile } from '@/contexts/auth/types';
import UserRoleManager from '../UserRoleManager';
import { Shield } from 'lucide-react';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UserRoleDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedUser: UserWithRoles | null;
  onAddRole: (userId: string, role: AppRole) => Promise<void>;
  onRemoveRole: (userId: string, role: AppRole) => Promise<void>;
}

const UserRoleDialog: React.FC<UserRoleDialogProps> = ({
  isOpen,
  onOpenChange,
  selectedUser,
  onAddRole,
  onRemoveRole
}) => {
  // When the dialog closes, we want to make sure data is refreshed
  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Shield className="h-4 w-4" />
            Manage User Roles
          </DialogTitle>
        </DialogHeader>
        {selectedUser && (
          <UserRoleManager 
            user={selectedUser}
            onAddRole={onAddRole}
            onRemoveRole={onRemoveRole}
            onClose={() => onOpenChange(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserRoleDialog;
