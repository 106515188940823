
import React, { useState } from 'react';
import { AppRole } from '@/contexts/auth/types';
import { useForm } from 'react-hook-form';
import { UserPlus, Shield } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage 
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';

interface RoleFormValues {
  role: AppRole;
}

interface UserRoleFormProps {
  availableRoles: AppRole[];
  onAddRole: (role: AppRole) => Promise<void>;
  onClose: () => void;
  isLoading: boolean;
}

const UserRoleForm: React.FC<UserRoleFormProps> = ({ 
  availableRoles, 
  onAddRole, 
  onClose, 
  isLoading 
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm<RoleFormValues>({
    defaultValues: {
      // Use the first available role as default
      role: availableRoles[0] || 'user',
    },
  });

  const handleSubmit = async (data: RoleFormValues) => {
    setIsSubmitting(true);
    try {
      await onAddRole(data.role);
      form.reset();
    } finally {
      setIsSubmitting(false);
    }
  };

  // Combine both loading states
  const isFormDisabled = isLoading || isSubmitting;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-1">
                <Shield className="h-4 w-4" />
                Add Role
              </FormLabel>
              <FormControl>
                <Select
                  disabled={isFormDisabled}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableRoles.map(role => (
                      <SelectItem key={role} value={role}>
                        {role.charAt(0).toUpperCase() + role.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            type="submit" 
            disabled={isFormDisabled || availableRoles.length === 0}
          >
            <UserPlus className="h-4 w-4 mr-2" />
            Add Role
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UserRoleForm;
