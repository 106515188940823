
import React, { useState } from 'react';
import { AppRole, UserProfile } from '@/contexts/auth/types';
import { 
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage 
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { Check } from 'lucide-react';
import TimezoneSelect from '@/components/profile/TimezoneSelect';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UserProfileEditorProps {
  user: UserWithRoles;
  onUpdateProfile: (userId: string, data: Partial<UserProfile>) => Promise<void>;
  onClose: () => void;
}

interface ProfileFormValues {
  first_name: string;
  last_name: string;
  timezone: string;
}

const UserProfileEditor: React.FC<UserProfileEditorProps> = ({ 
  user, 
  onUpdateProfile, 
  onClose 
}) => {
  const [isLoading, setIsLoading] = useState(false);
  
  const form = useForm<ProfileFormValues>({
    defaultValues: {
      first_name: user.profile?.first_name || '',
      last_name: user.profile?.last_name || '',
      timezone: user.profile?.timezone || 'UTC',
    },
  });

  const handleUpdateProfile = async (data: ProfileFormValues) => {
    setIsLoading(true);
    try {
      await onUpdateProfile(user.id, data);
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4 py-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleUpdateProfile)} className="space-y-4">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input 
                    placeholder="First name" 
                    {...field}
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input 
                    placeholder="Last name" 
                    {...field}
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Timezone</FormLabel>
                <FormControl>
                  <TimezoneSelect
                    currentTimezone={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <div className="flex justify-end gap-2">
            <Button type="button" variant="outline" onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>
              <Check className="h-4 w-4 mr-2" />
              Save Changes
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default UserProfileEditor;
