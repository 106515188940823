
import React from 'react';
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel';
import { Card, CardContent } from '@/components/ui/card';
import { Star } from 'lucide-react';

interface TestimonialProps {
  quote: string;
  author: string;
  role: string;
  rating: number;
}

const TestimonialCard: React.FC<TestimonialProps> = ({ quote, author, role, rating }) => {
  return (
    <Card className="border border-border/40 h-full dark:bg-card/50">
      <CardContent className="p-8">
        <div className="flex mb-4">
          {Array.from({ length: 5 }).map((_, i) => (
            <Star
              key={i}
              size={16}
              className={`${i < rating ? 'fill-yellow-400 text-yellow-400' : 'text-gray-300'}`}
            />
          ))}
        </div>
        <blockquote className="text-lg mb-6">"{quote}"</blockquote>
        <div>
          <div className="font-semibold">{author}</div>
          <div className="text-sm text-muted-foreground">{role}</div>
        </div>
      </CardContent>
    </Card>
  );
};

const TestimonialsSection: React.FC = () => {
  const testimonials = [
    {
      quote: "This platform completely transformed how I manage my projects. The interface is intuitive and the analytics provide valuable insights into my productivity.",
      author: "Alessandro Rossi",
      role: "Product Manager",
      rating: 5
    },
    {
      quote: "I've tried many productivity tools, but this one stands out. The habit tracking feature helped me build consistent routines and achieve my goals faster.",
      author: "Sofia Bianchi",
      role: "Freelance Designer",
      rating: 5
    },
    {
      quote: "As a team leader, I needed a solution to keep track of multiple projects simultaneously. This platform makes it effortless to monitor progress and delegate tasks.",
      author: "Marco Verdi",
      role: "Team Lead",
      rating: 4
    },
    {
      quote: "The reminders and notification system ensures I never miss important deadlines. It's been a game-changer for managing my busy schedule.",
      author: "Giulia Marino",
      role: "Marketing Consultant",
      rating: 5
    },
    {
      quote: "The AI suggestions have significantly improved my workflow efficiency. I'm accomplishing more in less time than ever before.",
      author: "Roberto Ferro",
      role: "Software Developer",
      rating: 4
    }
  ];

  return (
    <div className="py-20 bg-secondary/30 dark:bg-secondary/10">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">What Our Users Say</h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Join thousands of satisfied users who have transformed their productivity
          </p>
        </div>
        
        <Carousel className="w-full">
          <CarouselContent className="-ml-4">
            {testimonials.map((testimonial, index) => (
              <CarouselItem key={index} className="pl-4 md:basis-1/2 lg:basis-1/3">
                <TestimonialCard
                  quote={testimonial.quote}
                  author={testimonial.author}
                  role={testimonial.role}
                  rating={testimonial.rating}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
};

export default TestimonialsSection;
