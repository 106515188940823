
import React from 'react';

const ProjectsHeader: React.FC = () => {
  return (
    <div className="mb-6 md:mb-8">
      <h1 className="text-2xl md:text-3xl font-bold">
        My Projects
      </h1>
      <p className="text-gray-600 dark:text-gray-300">
        Manage all your projects across different areas of your life
      </p>
    </div>
  );
};

export default ProjectsHeader;
