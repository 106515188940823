
import { useState, useEffect } from 'react';
import { Goal } from '@/types/goal';
import { useGoalOperations } from './goals/use-goal-operations';
import { formatDueDate, getStatusColor, getDaysRemaining } from './goals/use-goal-formatting';

export function useProjectGoals(projectId: string | undefined) {
  const [goals, setGoals] = useState<Goal[]>([]);
  const {
    isLoading,
    error,
    fetchGoals,
    createGoal,
    updateGoal,
    deleteGoal,
    toggleGoalCompletion
  } = useGoalOperations(projectId);

  // Load goals initially and refresh as needed
  const refreshGoals = async () => {
    const fetchedGoals = await fetchGoals();
    setGoals(fetchedGoals);
  };

  // Set up initial fetch
  useEffect(() => {
    refreshGoals();
  }, [projectId]);

  return {
    goals,
    isLoading,
    error,
    createGoal,
    updateGoal,
    deleteGoal,
    toggleGoalCompletion,
    refreshGoals,
    getDaysRemaining,
    formatDueDate,
    getStatusColor
  };
}
