
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { CreateReminderPayload, Reminder } from '@/types/reminder';
import { FormBasicFields } from './form/FormBasicFields';
import { FormDateTimeFields } from './form/FormDateTimeFields';
import { FormSettingsFields } from './form/FormSettingsFields';

interface ReminderFormProps {
  onSubmit: (data: CreateReminderPayload) => Promise<void>;
  defaultValues?: Partial<Reminder>;
}

export function ReminderForm({ onSubmit, defaultValues }: ReminderFormProps) {
  const form = useForm<CreateReminderPayload>({
    defaultValues: {
      title: defaultValues?.title || '',
      description: defaultValues?.description || '',
      due_at: defaultValues?.due_at || new Date().toISOString(),
      notify_before: defaultValues?.notify_before || 15,
      recurring: defaultValues?.recurring || 'none',
    }
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormBasicFields form={form} />
        <FormDateTimeFields form={form} />
        <FormSettingsFields form={form} />

        <Button type="submit" className="w-full">
          Save Reminder
        </Button>
      </form>
    </Form>
  );
}
