
import React from 'react';
import { CustomBadge } from '../../CustomBadge';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from '@/components/ui/dropdown-menu';
import { RepeatIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { DAY_OF_WEEK_INDICES } from '@/utils/recurrence';
import { useRecurrenceUpdate, useCustomRecurrenceSettings } from '../../dropdowns/recurrence/useRecurrenceUpdate';
import RecurrenceOptions from '../../dropdowns/recurrence/RecurrenceOptions';
import CustomRecurrenceForm from '../../dropdowns/recurrence/CustomRecurrenceForm';
import { 
  getRecurrenceLabel, 
  getRecurrenceOptions, 
  getPeriodOptions, 
  getDayOfWeekOptions 
} from '../../dropdowns/recurrence/RecurrenceUtils';
import { Task } from '@/types/task';

interface TaskRecurrenceDropdownProps {
  task: Task;
  onTaskUpdated: (task: Partial<Task>) => Promise<boolean>;
}

const TaskRecurrenceDropdown: React.FC<TaskRecurrenceDropdownProps> = ({ 
  task,
  onTaskUpdated
}) => {
  const { t } = useTranslation();
  const { customSettings, updateCustomSettings } = useCustomRecurrenceSettings(task.custom_recurrence);

  const recurrenceOptions = getRecurrenceOptions(t);
  const periodOptions = getPeriodOptions(t);
  const dayOfWeekOptions = getDayOfWeekOptions(t);

  const updateRecurrence = async (value: string) => {
    if (value === 'none') {
      return onTaskUpdated({ 
        id: task.id, 
        recurrence: null, 
        custom_recurrence: null 
      });
    }
    
    // For non-custom values, just update the recurrence field
    if (value !== 'custom') {
      return onTaskUpdated({ 
        id: task.id, 
        recurrence: value,
        custom_recurrence: null
      });
    }
    
    // Don't proceed if we're already on custom recurrence
    if (task.recurrence === 'custom') return true;
    
    // For custom recurrence, apply with default settings
    const defaultSettings = {
      every: 1,
      period: 'weeks',
      day_of_week: Object.keys(DAY_OF_WEEK_INDICES)[new Date().getDay()] || 'monday'
    };
    
    return onTaskUpdated({
      id: task.id,
      recurrence: 'custom',
      custom_recurrence: defaultSettings
    });
  };
  
  const applyCustomSettings = () => {
    onTaskUpdated({
      id: task.id,
      recurrence: 'custom',
      custom_recurrence: customSettings
    });
  };

  // Don't show if recurrence is not set
  const recurrenceValue = task.recurrence || 'none';
  
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <CustomBadge 
          variant={recurrenceValue === 'none' ? "outline" : "secondary"}
          className="flex items-center gap-1 cursor-pointer hover:bg-accent/50 transition-colors"
        >
          <RepeatIcon className="h-3 w-3" />
          {recurrenceValue === 'none' ? 
            t('projects.recurrence.repeat', 'Repeat') : 
            getRecurrenceLabel(task.recurrence, customSettings, recurrenceOptions, dayOfWeekOptions, periodOptions, t)
          }
        </CustomBadge>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-60 z-50 bg-popover shadow-lg">
        <RecurrenceOptions 
          recurrence={recurrenceValue} 
          updateRecurrence={updateRecurrence} 
        />
        
        <DropdownMenuSeparator />
        
        <DropdownMenuSub>
          <DropdownMenuSubTrigger
            className={recurrenceValue === 'custom' ? "bg-accent/50" : ""}
          >
            {t('projects.recurrence.custom', 'Custom')}
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent className="w-60 bg-popover p-4">
              <CustomRecurrenceForm 
                customSettings={customSettings}
                updateCustomSettings={updateCustomSettings}
                applyCustomSettings={applyCustomSettings}
              />
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TaskRecurrenceDropdown;
