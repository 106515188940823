
/**
 * Font loading optimization utilities
 * Helps manage web font loading to avoid FOIT (Flash of Invisible Text)
 */

// Stores already loaded fonts to avoid redundant checks
const loadedFonts = new Set<string>();

// Check if the font has loaded
export const checkFontLoaded = (fontFamily: string): Promise<boolean> => {
  // Return immediately if we've already confirmed this font is loaded
  if (loadedFonts.has(fontFamily.toLowerCase())) {
    return Promise.resolve(true);
  }
  
  // Make sure we're in a browser environment
  if (typeof window === 'undefined' || typeof document === 'undefined' || !('fonts' in document)) {
    return Promise.resolve(false);
  }
  
  return document.fonts.ready.then(() => {
    // Check if the specified font is loaded
    const isLoaded = Array.from(document.fonts).some(font => 
      font.family.toLowerCase().includes(fontFamily.toLowerCase()) && 
      font.status === 'loaded'
    );
    
    // If loaded, add to our loaded fonts cache
    if (isLoaded) {
      loadedFonts.add(fontFamily.toLowerCase());
    }
    
    return isLoaded;
  });
};

// Class to manage font loading states
export class FontLoader {
  private static fontLoadedClass = 'fonts-loaded';
  private static fontTimeoutMs = 1000; // Reduced to 1 second timeout for font loading
  private static criticalFontLoadedClass = 'critical-fonts-loaded';

  // Initialize font loading classes
  public static init() {
    // Check for font loading support and ensure we're in a browser environment
    if (typeof window !== 'undefined' && typeof document !== 'undefined' && 'fonts' in document) {
      // Add the class immediately when fonts load
      document.fonts.ready.then(() => {
        const docElement = document.documentElement;
        if (docElement) {
          docElement.classList.add(this.fontLoadedClass);
        }
      });
      
      // Check critical fonts specifically
      this.checkCriticalFonts();
    } else if (typeof document !== 'undefined') {
      // Fallback for browsers that don't support the Font Loading API but have document
      const docElement = document.documentElement;
      if (docElement) {
        docElement.classList.add(this.fontLoadedClass);
      }
    }
    
    // Set a timeout to add the font-loaded class regardless to handle slow connections
    // Only run this in browser environment
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const docElement = document.documentElement;
      if (docElement) {
        setTimeout(() => {
          docElement.classList.add(this.fontLoadedClass);
          docElement.classList.add(this.criticalFontLoadedClass);
        }, this.fontTimeoutMs);
      }
    }
  }

  // Check if critical fonts are loaded
  public static checkCriticalFonts() {
    if (typeof document === 'undefined' || !document.documentElement) return;
    
    // Use Promise.race to proceed as soon as first font loads
    Promise.race([
      checkFontLoaded('Inter'),
      checkFontLoaded('system-ui')  // Fallback system font
    ]).then(result => {
      const docElement = document.documentElement;
      if (result && docElement) {
        docElement.classList.add(this.criticalFontLoadedClass);
      }
    });
  }
  
  // Preload critical fonts - call this as early as possible
  public static preloadCriticalFonts() {
    if (typeof document === 'undefined') return;
    
    // Prioritize only the most essential font weight for initial render
    const fontUrls = [
      'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap'
    ];
    
    fontUrls.forEach(url => {
      const link = document.createElement('link');
      link.href = url;
      link.rel = 'preload';
      link.as = 'style';
      link.onload = () => { 
        link.rel = 'stylesheet'; 
        // Immediately check if the font is loaded
        this.checkCriticalFonts();
      };
      document.head.appendChild(link);
    });

    // Load non-critical font weights after a delay
    setTimeout(() => {
      const nonCriticalFonts = document.createElement('link');
      nonCriticalFonts.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=JetBrains+Mono:wght@400;500;600&display=swap';
      nonCriticalFonts.rel = 'stylesheet';
      document.head.appendChild(nonCriticalFonts);
    }, 1000);
  }
}

