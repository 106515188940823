
import { useEffect } from 'react';
import { useUsersData } from './users/use-users-data';
import { useUsersRoles } from './users/use-users-roles';
import { useUsersProfiles } from './users/use-users-profiles';
import { deleteUser } from '@/contexts/auth/api/admin-service';
import { UseUsersResult } from './types/user-types';

export function useUsers(): UseUsersResult {
  const {
    users,
    setUsers,
    isLoading,
    setIsLoading,
    error,
    fetchUsers
  } = useUsersData();

  const {
    handleRemoveRole,
    handleAddRole
  } = useUsersRoles(users, setUsers, setIsLoading, fetchUsers);

  const {
    handleUpdateUserProfile
  } = useUsersProfiles(users, setUsers, setIsLoading);

  const handleDeleteUser = async (userId: string) => {
    setIsLoading(true);
    try {
      const success = await deleteUser(userId);
      if (success) {
        // If successful, remove user from local state
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { 
    users, 
    isLoading, 
    error, 
    fetchUsers, 
    handleRemoveRole,
    handleAddRole,
    handleUpdateUserProfile,
    handleDeleteUser,
    refreshUsers: fetchUsers // Added alias for better semantic
  };
}
