
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Eye } from 'lucide-react';
import { useKeywords } from '@/hooks/use-keywords';
import { Keyword } from '@/types/keyword';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Loader2 } from 'lucide-react';
import { GeneratedContentPreview } from './PreviewGeneratedContent';

interface PreviewResourceButtonProps {
  keyword: Keyword;
  disabled?: boolean;
}

const PreviewResourceButton: React.FC<PreviewResourceButtonProps> = ({ 
  keyword, 
  disabled = false 
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState<GeneratedContentPreview | null>(null);
  
  const { useResourcePreviewMutation } = useKeywords();
  const previewMutation = useResourcePreviewMutation();
  
  const handlePreview = async () => {
    setIsDialogOpen(true);
    
    try {
      const result = await previewMutation.mutateAsync(keyword.id);
      if (result.success && result.preview) {
        setPreviewContent(result.preview);
      }
    } catch (error) {
      console.error('Failed to generate preview:', error);
    }
  };
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setPreviewContent(null);
  };
  
  return (
    <>
      <Button 
        variant="outline" 
        size="sm"
        onClick={handlePreview}
        disabled={disabled || previewMutation.isPending}
        className="flex items-center gap-1"
      >
        {previewMutation.isPending ? (
          <Loader2 className="h-3 w-3 animate-spin" />
        ) : (
          <Eye className="h-3 w-3" />
        )}
        Preview
      </Button>
      
      <Dialog open={isDialogOpen} onOpenChange={handleCloseDialog}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Content Preview for "{keyword.keyword}"</DialogTitle>
            <DialogDescription>
              This is how your content will look when generated
            </DialogDescription>
          </DialogHeader>
          
          {previewMutation.isPending ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <span className="ml-2">Generating preview...</span>
            </div>
          ) : previewMutation.isError ? (
            <div className="p-6 text-center text-destructive">
              <p className="font-semibold">Error generating preview</p>
              <p className="text-sm mt-2">{previewMutation.error?.message || 'Unknown error'}</p>
            </div>
          ) : previewContent ? (
            <div className="space-y-6 p-2">
              {/* Render the preview content properties instead of the object itself */}
              <div className="prose prose-neutral dark:prose-invert max-w-none">
                <h1>{previewContent.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: previewContent.content }} />
              </div>
            </div>
          ) : (
            <div className="p-6 text-center text-muted-foreground">
              No preview data available
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewResourceButton;
