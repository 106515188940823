import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSidebarAdmin() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  
  const isAdminActive = location.pathname.startsWith('/admin');
  
  const toggleAdminOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsAdminOpen(!isAdminOpen);
  };

  const navigateToAdmin = () => {
    navigate('/admin');
    setIsAdminOpen(true);
  };

  const navigateToUsers = () => {
    navigate('/admin/users');
  };

  return {
    isAdminOpen,
    setIsAdminOpen,
    isAdminActive,
    toggleAdminOpen,
    navigateToAdmin,
    navigateToUsers
  };
}
