
import React from 'react';
import { FormItem, FormLabel } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Category } from '@/types/resource';

interface CategoriesFieldProps {
  selectedCategories: string[];
  onCategoryChange: (categoryId: string) => void;
  categories: Category[];
  language: string;
}

const CategoriesField: React.FC<CategoriesFieldProps> = ({
  selectedCategories,
  onCategoryChange,
  categories,
  language
}) => {
  return (
    <FormItem>
      <FormLabel>Categories</FormLabel>
      <div className="flex flex-wrap gap-2 mt-2">
        {categories.map((category) => (
          <Button
            key={category.id}
            type="button"
            variant={selectedCategories.includes(category.id) ? "default" : "outline"}
            size="sm"
            onClick={() => onCategoryChange(category.id)}
          >
            {category.name['en'] || category.slug}
          </Button>
        ))}
        {categories.length === 0 && (
          <p className="text-sm text-muted-foreground">
            No categories available. Create some first.
          </p>
        )}
      </div>
    </FormItem>
  );
};

export default CategoriesField;
