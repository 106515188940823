
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CalendarIcon, Clock } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { UseFormReturn } from 'react-hook-form';
import { CreateReminderPayload } from '@/types/reminder';

interface FormDateTimeFieldsProps {
  form: UseFormReturn<CreateReminderPayload>;
}

export function FormDateTimeFields({ form }: FormDateTimeFieldsProps) {
  // Helper function to combine date and time
  const combineDateAndTime = (date: Date, timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours, minutes);
    return newDate.toISOString();
  };

  // Format time for display
  const formatTimeForInput = (dateString?: string) => {
    if (!dateString) return '12:00';
    const date = new Date(dateString);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  };
  
  return (
    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
      <FormField
        control={form.control}
        name="due_at"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormLabel>Due Date</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full pl-3 text-left font-normal",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value ? (
                      format(new Date(field.value), "PPP")
                    ) : (
                      <span>Pick a date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={new Date(field.value)}
                  onSelect={(date) => {
                    if (date) {
                      const timeString = formatTimeForInput(field.value);
                      field.onChange(combineDateAndTime(date, timeString));
                    }
                  }}
                  disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                  initialFocus
                  className="p-3 pointer-events-auto"
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="due_at"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormLabel>Time</FormLabel>
            <div className="flex items-center">
              <FormControl>
                <div className="relative w-full">
                  <Input
                    type="time"
                    value={formatTimeForInput(field.value)}
                    onChange={(e) => {
                      const newDateTime = combineDateAndTime(
                        new Date(field.value),
                        e.target.value
                      );
                      field.onChange(newDateTime);
                    }}
                    className="pl-9"
                  />
                  <Clock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                </div>
              </FormControl>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
