
import React from 'react';
import { Habit } from '@/types/habit';
import { Checkbox } from '@/components/ui/checkbox';
import { wasCompletedToday } from '@/utils/time-of-day-utils';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { HabitContentSection } from './habit-list-item/HabitContentSection';
import { HabitActionsSection } from './habit-list-item/HabitActionsSection';
import { useIsMobile } from '@/hooks/use-mobile';

interface HabitListItemProps {
  habit: Habit;
  onToggleComplete: (habitId: string, completed: boolean) => Promise<void>;
  onEdit: () => void;
  onDelete: (habitId: string) => Promise<void>;
  onUpdateField?: (habitId: string, field: string, value: any) => Promise<void>;
}

export function HabitListItem({
  habit,
  onToggleComplete,
  onEdit,
  onDelete,
  onUpdateField
}: HabitListItemProps) {
  const isCompleted = wasCompletedToday(habit.last_completed);
  const isMobile = useIsMobile();
  
  const handleToggle = async (checked: boolean) => {
    await onToggleComplete(habit.id, checked);
  };

  const handleTitleEdit = async (newTitle: string) => {
    if (onUpdateField) {
      await onUpdateField(habit.id, 'title', newTitle);
    } else {
      // In a real implementation, we would update the title via API call here
      console.log(`Title changed from "${habit.title}" to "${newTitle}"`);
    }
  };
  
  const formatIncrementDisplay = () => {
    if (!habit.estimated_minutes || !habit.auto_increment_time) return null;
    
    // If decimal part is present, show it with one decimal place
    const hasDecimal = habit.estimated_minutes % 1 !== 0;
    const mins = Math.floor(habit.estimated_minutes);
    const secs = Math.round((habit.estimated_minutes - mins) * 60);
    
    if (hasDecimal) {
      return (
        <div 
          className="text-xs text-green-600 dark:text-green-400 font-semibold ml-auto tooltip-wrapper"
          title="When you complete this habit, the estimated time will increase by 1%"
        >
          <span className="inline-block">{mins}:{secs.toString().padStart(2, '0')}m +1%</span>
        </div>
      );
    }
    
    // Default display
    return (
      <div 
        className="text-xs text-green-600 dark:text-green-400 font-semibold ml-auto tooltip-wrapper"
        title="When you complete this habit, the estimated time will increase by 1%"
      >
        <span className="inline-block">{habit.estimated_minutes}m +1%</span>
      </div>
    );
  };
  
  return (
    <motion.div 
      className={cn(
        "flex items-start gap-3 p-3 rounded-lg border transition-colors",
        isCompleted 
          ? "bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-900" 
          : "bg-card border-border"
      )}
      initial={isCompleted ? { opacity: 0.8 } : { opacity: 1 }}
      animate={isCompleted ? { opacity: 1 } : {}}
      transition={{ duration: 0.5 }}
    >
      <Checkbox 
        id={`habit-${habit.id}`}
        checked={isCompleted}
        onCheckedChange={handleToggle} 
        className={cn(
          isCompleted ? "border-green-500" : "",
          "mt-1", // Align with the first line for better touch targets
          isMobile && "h-5 w-5" // Larger checkbox on mobile
        )}
      />
      
      <HabitContentSection 
        habit={habit} 
        isCompleted={isCompleted} 
        onEditTitle={handleTitleEdit} 
      />

      <div className="flex items-start">
        <HabitActionsSection 
          habit={habit} 
          onEdit={onEdit} 
          onDelete={onDelete} 
          onUpdateField={onUpdateField} 
        />
        
        {formatIncrementDisplay()}
      </div>
    </motion.div>
  );
};
