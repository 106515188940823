
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getOpenAIConfig, saveOpenAIConfig, OpenAIConfig } from '@/services/resources/openai-config-service';
import { toast } from 'sonner';

export const useOpenAIConfigQuery = () => {
  return useQuery({
    queryKey: ['openai-config'],
    queryFn: getOpenAIConfig,
  });
};

export const useOpenAIConfigMutation = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (config: OpenAIConfig) => saveOpenAIConfig(config),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['openai-config'] });
      toast.success('OpenAI configuration saved successfully');
    },
    onError: (error: Error) => {
      toast.error(`Failed to save OpenAI configuration: ${error.message}`);
    }
  });
};
