
import React from 'react';
import { FolderKanban, ChevronDown, ChevronRight } from 'lucide-react';
import { 
  SidebarMenuItem, SidebarMenuButton, SidebarMenuSub, 
  SidebarMenuSubItem, SidebarMenuSubButton 
} from '@/components/ui/sidebar';
import { useSidebar } from '@/components/ui/sidebar';
import { useSidebarProjects } from '../use-sidebar-projects';
import { projectCollections } from '../sidebar-data';
import { SidebarCollectionItem } from '../SidebarCollectionItem';
import { useLocation, useNavigate } from 'react-router-dom';

export function ProjectsMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useSidebar();
  const { 
    isCollectionsOpen, setIsCollectionsOpen,
    isCollectionListOpen, setIsCollectionListOpen,
    expandedCollections, isProjectsActive,
    toggleCollection, isCollectionActive,
    getProjectsByCollection, navigateToProject
  } = useSidebarProjects();

  return (
    <SidebarMenuItem>
      <SidebarMenuButton 
        onClick={() => {
          navigate('/projects');
          setIsCollectionsOpen(true);
        }} 
        className="w-full justify-between"
        isActive={isProjectsActive}
      >
        <div className="flex items-center gap-2">
          <FolderKanban className="h-4 w-4" />
          <span className="transition-opacity duration-200 group-data-[collapsible=icon]:opacity-0">Projects</span>
        </div>
        {state !== 'collapsed' && (
          isCollectionsOpen ? (
            <ChevronDown className="h-3 w-3" onClick={(e) => {
              e.stopPropagation();
              setIsCollectionsOpen(!isCollectionsOpen);
            }} />
          ) : (
            <ChevronRight className="h-3 w-3" onClick={(e) => {
              e.stopPropagation();
              setIsCollectionsOpen(!isCollectionsOpen);
            }} />
          )
        )}
      </SidebarMenuButton>
      
      {isCollectionsOpen && state !== 'collapsed' && (
        <SidebarMenuSub>
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={() => navigate('/projects')}
              isActive={location.pathname === '/projects'}
            >
              <span>All Projects</span>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              className="font-medium w-full justify-between"
              onClick={() => setIsCollectionListOpen(!isCollectionListOpen)}
            >
              <span>Collections</span>
              {isCollectionListOpen ? (
                <ChevronDown className="h-3 w-3" />
              ) : (
                <ChevronRight className="h-3 w-3" />
              )}
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          {isCollectionListOpen && projectCollections.map((collection) => (
            <SidebarCollectionItem
              key={collection.id}
              id={collection.id}
              name={collection.name}
              path={`/collections/${collection.id}`}
              isActive={isCollectionActive(`/collections/${collection.id}`)}
              isExpanded={expandedCollections[collection.id]}
              toggleCollection={toggleCollection}
              projects={getProjectsByCollection(collection.id)}
              navigateToProject={navigateToProject}
            />
          ))}
        </SidebarMenuSub>
      )}
    </SidebarMenuItem>
  );
}
