
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import AddGoalButton from './AddGoalButton';
import GoalsList from './GoalsList';
import { useProjectGoals } from '@/hooks/use-project-goals';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Flag, List, BarChart } from 'lucide-react';
import GoalsTimeline from './GoalsTimeline';

interface GoalsManagementProps {
  projectId: string;
}

const GoalsManagement: React.FC<GoalsManagementProps> = ({ projectId }) => {
  const {
    goals,
    isLoading,
    createGoal,
    updateGoal,
    deleteGoal,
    toggleGoalCompletion,
    formatDueDate,
    getStatusColor
  } = useProjectGoals(projectId);

  return (
    <Card>
      <CardHeader className="pb-3">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
          <CardTitle className="flex items-center gap-2">
            <Flag className="h-5 w-5" />
            <span>Project Goals</span>
          </CardTitle>
          <AddGoalButton projectId={projectId} onCreateGoal={createGoal} />
        </div>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="list">
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="list" className="flex items-center gap-2">
              <List className="h-4 w-4" />
              <span>List</span>
            </TabsTrigger>
            <TabsTrigger value="timeline" className="flex items-center gap-2">
              <BarChart className="h-4 w-4" />
              <span>Timeline</span>
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="list">
            <GoalsList
              goals={goals}
              isLoading={isLoading}
              projectId={projectId}
              onUpdateGoal={updateGoal}
              onDeleteGoal={deleteGoal}
              onToggleCompletion={toggleGoalCompletion}
              formatDueDate={formatDueDate}
              getStatusColor={getStatusColor}
            />
          </TabsContent>
          
          <TabsContent value="timeline">
            <GoalsTimeline
              goals={goals}
              isLoading={isLoading}
              formatDueDate={formatDueDate}
              getStatusColor={getStatusColor}
            />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default GoalsManagement;
