
import React from 'react';
import { Badge } from "@/components/ui/badge";
import { BadgeProps } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

export interface CustomBadgeProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  children: React.ReactNode;
  variant?: "default" | "secondary" | "destructive" | "outline" | "custom";
}

export const CustomBadge: React.FC<CustomBadgeProps> = ({
  className,
  variant = "default",
  children,
  ...props
}) => {
  return (
    <div {...props} className={cn("inline-block", className)}>
      <Badge variant={variant === "custom" ? "default" : variant as BadgeProps["variant"]} className={cn(
        "h-6 text-xs font-medium",
        variant === "custom" && className
      )}>
        {children}
      </Badge>
    </div>
  );
};
