
import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import HtmlTemplateAccordion from './HtmlTemplateAccordion';
import SeoBlocksAccordion from './SeoBlocksAccordion';
import ContentPreview from './ContentPreview';
import { htmlSamples } from './HtmlSamples';

interface HtmlEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({ value, onChange }) => {
  const [activeTab, setActiveTab] = useState<string>('edit');

  const insertHtmlTemplate = (template: string) => {
    onChange(value + '\n\n' + template);
  };

  return (
    <Tabs defaultValue="edit" className="w-full" onValueChange={setActiveTab}>
      <TabsList className="mb-2">
        <TabsTrigger value="edit">Edit</TabsTrigger>
        <TabsTrigger value="preview">Preview</TabsTrigger>
      </TabsList>
      <TabsContent value="edit" className="space-y-4">
        <Textarea 
          value={value} 
          onChange={(e) => onChange(e.target.value)} 
          className="min-h-[300px] font-mono text-sm" 
        />
        
        <HtmlTemplateAccordion 
          htmlSamples={htmlSamples} 
          onInsertTemplate={insertHtmlTemplate} 
        />
        
        <SeoBlocksAccordion 
          htmlSamples={htmlSamples}
          onInsertTemplate={insertHtmlTemplate}
        />
      </TabsContent>
      <TabsContent value="preview">
        <ContentPreview content={value} />
      </TabsContent>
    </Tabs>
  );
};

export default HtmlEditor;
