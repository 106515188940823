
import React, { useState } from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../project-form-schema';
import { Input } from '@/components/ui/input';
import { DAY_OF_WEEK_INDICES } from '@/utils/recurrence';

interface RecurrenceFieldProps {
  form: UseFormReturn<ProjectFormValues>;
}

const RecurrenceField: React.FC<RecurrenceFieldProps> = ({ form }) => {
  const [showCustomOptions, setShowCustomOptions] = useState(form.watch('recurrence') === 'custom');

  const daysOfWeek = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' }
  ];

  const handleRecurrenceChange = (value: string) => {
    form.setValue('recurrence', value as ProjectFormValues['recurrence'], { shouldValidate: true });
    setShowCustomOptions(value === 'custom');
    
    // Initialize custom recurrence if not already set
    if (value === 'custom' && !form.getValues('custom_recurrence')) {
      // Get the current day of week as a key from DAY_OF_WEEK_INDICES
      const currentDayIndex = new Date().getDay();
      const dayNames = Object.keys(DAY_OF_WEEK_INDICES);
      // Map from JS day index (0=Sunday) to our day name
      // Sunday (0) should map to 'sunday', which is dayNames[6]
      const currentDayName = currentDayIndex === 0 ? 'sunday' : dayNames[currentDayIndex - 1];
      
      form.setValue('custom_recurrence', {
        every: 1,
        period: 'weeks',
        day_of_week: currentDayName as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
      }, { shouldValidate: true });
    }
  };

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="recurrence"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Frequency</FormLabel>
            <Select 
              onValueChange={handleRecurrenceChange} 
              defaultValue={field.value}
              value={field.value}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select frequency" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="none">None</SelectItem>
                <SelectItem value="daily">Daily</SelectItem>
                <SelectItem value="weekdays">Weekdays</SelectItem>
                <SelectItem value="weekends">Weekends</SelectItem>
                <SelectItem value="weekly">Weekly</SelectItem>
                <SelectItem value="monthly">Monthly</SelectItem>
                <SelectItem value="yearly">Yearly</SelectItem>
                <SelectItem value="custom">Custom</SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      {showCustomOptions && (
        <div className="bg-muted/50 p-4 rounded-md space-y-4">
          <div className="flex items-center gap-2">
            <span>Every</span>
            <FormField
              control={form.control}
              name="custom_recurrence.every"
              render={({ field }) => (
                <FormItem className="w-20">
                  <FormControl>
                    <Input
                      type="number"
                      min="1"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value) || 1)}
                      value={field.value || 1}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="custom_recurrence.period"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <Select
                    onValueChange={field.onChange}
                    value={field.value || 'weeks'}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="days">Days</SelectItem>
                      <SelectItem value="weeks">Weeks</SelectItem>
                      <SelectItem value="months">Months</SelectItem>
                      <SelectItem value="years">Years</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>

          {form.watch('custom_recurrence.period') === 'weeks' && (
            <FormField
              control={form.control}
              name="custom_recurrence.day_of_week"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Day of week</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      form.trigger('custom_recurrence.day_of_week'); 
                    }}
                    value={field.value || 'monday'}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {daysOfWeek.map(day => (
                        <SelectItem key={day.value} value={day.value}>
                          {day.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RecurrenceField;
