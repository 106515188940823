
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { TaskFormValues } from '../task-editor-schema';
import { FormField, FormItem, FormLabel, FormControl } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { DAY_OF_WEEK_INDICES } from '@/utils/recurrence-utils';
import { useTranslation } from 'react-i18next';

const RecurrenceField = () => {
  const { t } = useTranslation();
  const form = useFormContext<TaskFormValues>();
  const recurrenceValue = form.watch('recurrence');
  const [showCustomOptions, setShowCustomOptions] = useState(recurrenceValue === 'custom');

  useEffect(() => {
    setShowCustomOptions(recurrenceValue === 'custom');
  }, [recurrenceValue]);

  const handleRecurrenceChange = (value: string) => {
    if (value === 'none') {
      form.setValue('recurrence', null, { shouldValidate: true });
      form.setValue('custom_recurrence', null, { shouldValidate: true });
      return;
    }
    
    form.setValue('recurrence', value as TaskFormValues['recurrence'], { shouldValidate: true });
    
    // Initialize custom recurrence if not already set
    if (value === 'custom' && !form.getValues('custom_recurrence')) {
      // Get the current day of week as a key from DAY_OF_WEEK_INDICES
      const currentDayIndex = new Date().getDay();
      const dayNames = Object.keys(DAY_OF_WEEK_INDICES);
      // Map from JS day index (0=Sunday) to our day name
      const currentDayName = currentDayIndex === 0 ? 'sunday' : dayNames[currentDayIndex - 1];
      
      form.setValue('custom_recurrence', {
        every: 1,
        period: 'weeks',
        day_of_week: currentDayName as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday"
      }, { shouldValidate: true });
    }
  };

  const daysOfWeek = [
    { value: 'monday', label: t('days.monday', 'Monday') },
    { value: 'tuesday', label: t('days.tuesday', 'Tuesday') },
    { value: 'wednesday', label: t('days.wednesday', 'Wednesday') },
    { value: 'thursday', label: t('days.thursday', 'Thursday') },
    { value: 'friday', label: t('days.friday', 'Friday') },
    { value: 'saturday', label: t('days.saturday', 'Saturday') },
    { value: 'sunday', label: t('days.sunday', 'Sunday') }
  ];

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="recurrence"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('tasks.recurrence.label', 'Repeat')}</FormLabel>
            <Select 
              onValueChange={handleRecurrenceChange} 
              value={field.value || 'none'}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={t('tasks.recurrence.selectFrequency', 'Select frequency')} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="none">{t('projects.recurrence.none', 'None')}</SelectItem>
                <SelectItem value="daily">{t('projects.recurrence.daily', 'Daily')}</SelectItem>
                <SelectItem value="weekdays">{t('projects.recurrence.weekdays', 'Weekdays')}</SelectItem>
                <SelectItem value="weekends">{t('projects.recurrence.weekends', 'Weekends')}</SelectItem>
                <SelectItem value="weekly">{t('projects.recurrence.weekly', 'Weekly')}</SelectItem>
                <SelectItem value="monthly">{t('projects.recurrence.monthly', 'Monthly')}</SelectItem>
                <SelectItem value="yearly">{t('projects.recurrence.yearly', 'Yearly')}</SelectItem>
                <SelectItem value="custom">{t('projects.recurrence.custom', 'Custom')}</SelectItem>
              </SelectContent>
            </Select>
          </FormItem>
        )}
      />

      {showCustomOptions && (
        <div className="bg-muted/50 p-4 rounded-md space-y-4">
          <div className="flex items-center gap-2">
            <span>{t('projects.recurrence.customEvery', 'Every')}</span>
            <FormField
              control={form.control}
              name="custom_recurrence.every"
              render={({ field }) => (
                <FormItem className="w-20">
                  <FormControl>
                    <Input
                      type="number"
                      min="1"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value) || 1)}
                      value={field.value || 1}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="custom_recurrence.period"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      form.trigger('custom_recurrence.period');
                    }}
                    value={field.value || 'weeks'}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="days">{t('projects.recurrence.days', 'Days')}</SelectItem>
                      <SelectItem value="weeks">{t('projects.recurrence.periodWeeks', 'Weeks')}</SelectItem>
                      <SelectItem value="months">{t('projects.recurrence.periodMonths', 'Months')}</SelectItem>
                      <SelectItem value="years">{t('projects.recurrence.periodYears', 'Years')}</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>

          {form.watch('custom_recurrence.period') === 'weeks' && (
            <FormField
              control={form.control}
              name="custom_recurrence.day_of_week"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('projects.recurrence.dayOfWeek', 'Day of week')}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      form.trigger('custom_recurrence.day_of_week');
                    }}
                    value={field.value || 'monday'}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {daysOfWeek.map(day => (
                        <SelectItem key={day.value} value={day.value}>
                          {day.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RecurrenceField;
