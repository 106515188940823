
import React, { useState } from 'react';
import { FileText, PenLine } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle,
  DialogFooter
} from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';

interface HabitDescriptionProps {
  title: string;
  description: string | null;
  onUpdateDescription?: (newDescription: string) => Promise<void>;
}

export function HabitDescription({ 
  title, 
  description, 
  onUpdateDescription 
}: HabitDescriptionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description || '');
  const [showFullDescription, setShowFullDescription] = useState(false);
  
  const handleOpen = () => {
    setEditMode(false);
    setIsOpen(true);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    if (onUpdateDescription) {
      await onUpdateDescription(editedDescription);
    }
    setEditMode(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditMode(false);
    setEditedDescription(description || '');
    setShowFullDescription(false);
  };

  const toggleFullDescription = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFullDescription(!showFullDescription);
  };
  
  // Function to render text with line breaks preserved
  const renderWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  // Function to determine if text needs a "Show more" button
  const needsShowMore = (text: string) => {
    return text.split('\n').length > 2 || text.length > 100;
  };
  
  // Function to get truncated preview text
  const getTruncatedText = (text: string) => {
    const lines = text.split('\n');
    if (lines.length > 2) {
      return lines.slice(0, 2).join('\n');
    }
    return text.length > 100 ? text.substring(0, 100) + '...' : text;
  };
  
  return (
    <>
      <Button 
        variant="ghost" 
        size="sm" 
        className="h-8 w-8 p-0"
        onClick={handleOpen}
        title={description ? "View Description" : "Add Description"}
      >
        <FileText className="h-4 w-4" />
      </Button>
      
      <Dialog open={isOpen} onOpenChange={handleClose}>
        <DialogContent className="max-w-md w-full">
          <DialogHeader>
            <DialogTitle>{editMode ? `Edit ${title} Description` : title}</DialogTitle>
          </DialogHeader>
          
          {editMode ? (
            <>
              <Textarea
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                className="min-h-[100px] mt-2"
                placeholder="Enter a description for this habit..."
              />
              <DialogFooter className="mt-4">
                <Button variant="outline" onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>
                  Save
                </Button>
              </DialogFooter>
            </>
          ) : (
            <>
              <div className="mt-2 max-h-[50vh] overflow-y-auto">
                {description ? (
                  <div className="bg-muted p-3 rounded-md whitespace-pre-line break-words">
                    {renderWithLineBreaks(description)}
                  </div>
                ) : (
                  <div className="text-muted-foreground text-center p-4">
                    No description available.
                  </div>
                )}
              </div>
              <DialogFooter className="mt-4">
                {onUpdateDescription && (
                  <Button onClick={handleEdit}>
                    <PenLine className="h-4 w-4 mr-2" />
                    {description ? "Edit" : "Add"} Description
                  </Button>
                )}
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
