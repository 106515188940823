
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import {
  Select, SelectContent, SelectItem, SelectTrigger, SelectValue
} from '@/components/ui/select';
import { Repeat } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';
import { CreateReminderPayload } from '@/types/reminder';

interface FormSettingsFieldsProps {
  form: UseFormReturn<CreateReminderPayload>;
}

export function FormSettingsFields({ form }: FormSettingsFieldsProps) {
  return (
    <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
      <FormField
        control={form.control}
        name="notify_before"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormLabel>Notify Before (minutes)</FormLabel>
            <FormControl>
              <Select
                value={field.value.toString()}
                onValueChange={(value) => field.onChange(parseInt(value))}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select when to be notified" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">At the time</SelectItem>
                  <SelectItem value="5">5 minutes before</SelectItem>
                  <SelectItem value="15">15 minutes before</SelectItem>
                  <SelectItem value="30">30 minutes before</SelectItem>
                  <SelectItem value="60">1 hour before</SelectItem>
                  <SelectItem value="120">2 hours before</SelectItem>
                  <SelectItem value="1440">1 day before</SelectItem>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="recurring"
        render={({ field }) => (
          <FormItem className="flex-1">
            <FormLabel>Recurring</FormLabel>
            <FormControl>
              <div className="relative w-full">
                <Select
                  value={field.value || 'none'}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger className="pl-9">
                    <SelectValue placeholder="Set recurring schedule" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">No recurrence</SelectItem>
                    <SelectItem value="daily">Daily</SelectItem>
                    <SelectItem value="weekly">Weekly</SelectItem>
                    <SelectItem value="monthly">Monthly</SelectItem>
                  </SelectContent>
                </Select>
                <Repeat className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
