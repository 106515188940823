
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';

export function useSidebarReminders() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRemindersOpen, setIsRemindersOpen] = useState(false);
  
  const isRemindersActive = location.pathname.startsWith('/reminders');

  const toggleRemindersOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsRemindersOpen(!isRemindersOpen);
  };

  const navigateToReminders = () => {
    navigate('/reminders');
  };

  return {
    isRemindersOpen,
    setIsRemindersOpen,
    isRemindersActive,
    toggleRemindersOpen,
    navigateToReminders
  };
}
