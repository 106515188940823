
import { QueryClient } from '@tanstack/react-query';
import { ResourceGenerationResult } from './types';
import { toast } from 'sonner';

/**
 * Handler for successful resource generation
 */
export const handleResourceGenerationSuccess = (
  result: ResourceGenerationResult,
  queryClient: QueryClient
) => {
  // Invalidate keywords query to refresh the list
  queryClient.invalidateQueries({
    queryKey: ['keywords']
  });
  
  // Invalidate resources query to include the new resource
  queryClient.invalidateQueries({
    queryKey: ['resources']
  });
  
  // Show success message
  toast.success('Resource generated successfully', {
    description: `"${result.resource?.title}" has been created.`
  });
};
