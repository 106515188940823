
import React from 'react';
import { Activity } from 'lucide-react';
import { 
  SidebarMenuItem, 
  SidebarMenuButton 
} from '@/components/ui/sidebar';
import { useSidebarHabits } from '../hooks/use-sidebar-habits';

export function HabitsMenu() {
  const { navigateToHabits, isHabitsActive } = useSidebarHabits();

  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        onClick={navigateToHabits}
        tooltip="Habits"
        isActive={isHabitsActive}
      >
        <Activity className="h-4 w-4" />
        <span>Habits</span>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
