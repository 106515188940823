
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
import { 
  createCategory, 
  updateCategory, 
  deleteCategory 
} from '@/services/resources';
import { Category } from '@/types/resource';

export const useCategoryMutations = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const useCreateCategoryMutation = () => {
    return useMutation({
      mutationFn: (category: Partial<Category>) => createCategory(category as Record<string, any>),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resourceCategories'] });
        toast({
          title: 'Category created successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error creating category',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  const useUpdateCategoryMutation = () => {
    return useMutation({
      mutationFn: ({ id, category }: { id: string; category: Partial<Category> }) => 
        updateCategory(id, category as Record<string, any>),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resourceCategories'] });
        toast({
          title: 'Category updated successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error updating category',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  const useDeleteCategoryMutation = () => {
    return useMutation({
      mutationFn: deleteCategory,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resourceCategories'] });
        toast({
          title: 'Category deleted successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error deleting category',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  return {
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
  };
};
