
import React from 'react';
import { Button } from '@/components/ui/button';

interface ProjectCardFooterProps {
  dueDate?: Date;
  onDoneClick: (e: React.MouseEvent) => void;
}

const ProjectCardFooter: React.FC<ProjectCardFooterProps> = ({
  onDoneClick,
}) => {
  return (
    <>
      <div className="px-5 py-3 bg-white/[0.02] flex items-center justify-center">
        <Button 
          variant="outline" 
          size="sm" 
          className="h-7 interactive-element w-full" 
          onClick={onDoneClick}
        >
          Done for today
        </Button>
      </div>
    </>
  );
};

export default ProjectCardFooter;
