
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { UseFormReturn } from 'react-hook-form';
import { ResourceFormValues } from '@/hooks/resources/resource-form-types';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';

interface TitleSlugFieldsProps {
  form: UseFormReturn<ResourceFormValues>;
  onGenerateSlug?: () => void;
}

const TitleSlugFields: React.FC<TitleSlugFieldsProps> = ({ form, onGenerateSlug }) => {
  return (
    <div className="grid gap-4 md:grid-cols-2">
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Title</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="slug"
        render={({ field }) => (
          <FormItem className="flex-1">
            <div className="flex items-center justify-between">
              <FormLabel>Slug</FormLabel>
              {onGenerateSlug && (
                <Button 
                  type="button" 
                  variant="outline" 
                  size="sm" 
                  onClick={onGenerateSlug}
                  className="h-6 text-xs"
                >
                  <RefreshCw className="h-3 w-3 mr-1" />
                  Generate
                </Button>
              )}
            </div>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default TitleSlugFields;
