
import { useState, useEffect } from 'react';
import { Habit, CreateHabitPayload, UpdateHabitPayload } from '@/types/habit';
import { useAuth } from '@/contexts/AuthContext';
import { 
  fetchHabits, 
  createHabitInDb, 
  updateHabitInDb, 
  deleteHabitFromDb 
} from './use-habit-api';
import { toggleHabitCompletion } from './use-habit-completion';

export function useHabits() {
  const [habits, setHabits] = useState<Habit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [celebration, setCelebration] = useState({
    visible: false,
    message: '',
    habitTitle: ''
  });
  const { user } = useAuth();

  const refreshHabits = async () => {
    setIsLoading(true);
    const data = await fetchHabits(user?.id);
    setHabits(data);
    setIsLoading(false);
  };

  const createHabit = async (habit: CreateHabitPayload): Promise<boolean> => {
    const success = await createHabitInDb(habit, user?.id);
    if (success) {
      await refreshHabits();
    }
    return success;
  };

  const updateHabit = async (habit: UpdateHabitPayload): Promise<boolean> => {
    const success = await updateHabitInDb(habit, user?.id);
    if (success) {
      await refreshHabits();
    }
    return success;
  };

  const deleteHabit = async (habitId: string): Promise<boolean> => {
    const success = await deleteHabitFromDb(habitId, user?.id);
    if (success) {
      await refreshHabits();
    }
    return success;
  };
  
  const getMilestoneMessage = (streakCount: number): string => {
    if (streakCount === 1) return "You've started a new streak!";
    if (streakCount === 3) return "Three days in a row! You're building a good habit!";
    if (streakCount === 7) return "A full week! Impressive!";
    if (streakCount === 14) return "Two weeks of consistency! You're doing great!";
    if (streakCount === 21) return "21 days! You've formed a real habit!";
    if (streakCount === 30) return "A whole month! Incredible consistency!";
    if (streakCount % 30 === 0) return `${streakCount / 30} months of consistency! You're a champion!`;
    if (streakCount % 5 === 0) return `${streakCount} days in a row! Keep it up!`;
    return "Keep it up!";
  };

  const handleToggleHabitCompletion = async (habitId: string, completed: boolean): Promise<boolean> => {
    const success = await toggleHabitCompletion(habitId, completed, user?.id);
    
    if (success) {
      // Refresh habits to get updated data
      await refreshHabits();
      
      // Show celebration if habit was completed (not uncompleted)
      if (completed) {
        const completedHabit = habits.find(h => h.id === habitId);
        if (completedHabit) {
          // Get the updated habit with the new streak count
          const updatedHabits = await fetchHabits(user?.id);
          const updatedHabit = updatedHabits.find(h => h.id === habitId);
          
          if (updatedHabit) {
            const milestoneMessage = getMilestoneMessage(updatedHabit.streak_count);
            setCelebration({
              visible: true,
              message: milestoneMessage,
              habitTitle: updatedHabit.title
            });
          }
        }
      }
    }
    
    return success;
  };
  
  const hideCelebration = () => {
    setCelebration(prev => ({ ...prev, visible: false }));
  };

  useEffect(() => {
    refreshHabits();
  }, [user]);

  return {
    habits,
    isLoading,
    createHabit,
    updateHabit,
    deleteHabit,
    toggleHabitCompletion: handleToggleHabitCompletion,
    refreshHabits,
    celebration,
    hideCelebration
  };
}
