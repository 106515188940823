
import { 
  Briefcase, Home, Users, DollarSign, Heart, Dumbbell, 
  BookOpen, Gamepad
} from 'lucide-react';
import React from 'react';

// Collection data with icons
export const projectCollectionsWithIcons = [
  { 
    id: 'career', 
    name: 'Career', 
    icon: <Briefcase className="h-5 w-5" />, 
    description: 'Work and professional goals'
  },
  { 
    id: 'family', 
    name: 'Family', 
    icon: <Home className="h-5 w-5" />, 
    description: 'Family-related projects'
  },
  { 
    id: 'friends-social', 
    name: 'Friends & Social Life', 
    icon: <Users className="h-5 w-5" />, 
    description: 'Social activities and relationships'
  },
  { 
    id: 'finances', 
    name: 'Finances', 
    icon: <DollarSign className="h-5 w-5" />, 
    description: 'Financial planning and goals'
  },
  { 
    id: 'love-relationship', 
    name: 'Love & Relationship', 
    icon: <Heart className="h-5 w-5" />, 
    description: 'Romantic relationship plans'
  },
  { 
    id: 'health-fitness', 
    name: 'Health & Fitness', 
    icon: <Dumbbell className="h-5 w-5" />, 
    description: 'Health and wellness goals'
  },
  { 
    id: 'personal-development', 
    name: 'Personal Development', 
    icon: <BookOpen className="h-5 w-5" />, 
    description: 'Self-improvement and learning'
  },
  { 
    id: 'fun-recreation', 
    name: 'Fun & Recreation', 
    icon: <Gamepad className="h-5 w-5" />, 
    description: 'Entertainment and leisure'
  }
];

// Helper function to find a collection by ID
export const findCollectionById = (id: string | undefined) => {
  if (!id) return null;
  return projectCollectionsWithIcons.find(collection => collection.id === id) || null;
};
