
import React from 'react';
import { TableCell, TableRow } from '@/components/ui/table';
import { ResourcePreview } from '@/types/resource';
import ResourceStatusBadge from './ResourceStatusBadge';
import ResourceCategoriesList from './ResourceCategoriesList';
import ResourceActions from './ResourceActions';
import ResourceDate from './ResourceDate';

interface ResourceListRowProps {
  resource: ResourcePreview;
  onDelete: (id: string) => void;
}

const ResourceListRow: React.FC<ResourceListRowProps> = ({ resource, onDelete }) => {
  return (
    <TableRow>
      <TableCell className="font-medium">{resource.title}</TableCell>
      <TableCell className="text-muted-foreground">{resource.slug}</TableCell>
      <TableCell>
        <ResourceStatusBadge status={resource.status} />
      </TableCell>
      <TableCell>
        <ResourceDate 
          publishedDate={resource.published_at} 
          createdDate={resource.created_at} 
        />
      </TableCell>
      <TableCell>
        <ResourceCategoriesList categories={resource.categories} />
      </TableCell>
      <TableCell className="text-right">
        <ResourceActions 
          resourceId={resource.id} 
          resourceSlug={resource.slug} 
          onDelete={onDelete} 
        />
      </TableCell>
    </TableRow>
  );
};

export default ResourceListRow;
