
import { supabase } from '@/integrations/supabase/client';
import { UserProfile } from '../types';
import { toast } from 'sonner';

/**
 * User profile service functions for fetching and updating user profiles
 */
export const fetchUserProfile = async (userId: string): Promise<UserProfile | null> => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('email, timezone, first_name, last_name')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data as UserProfile;
  } catch (error) {
    console.error('Error fetching profile:', error);
    return null;
  }
};

export const updateUserTimezone = async (
  userId: string, 
  timezone: string
): Promise<void> => {
  try {
    const { error } = await supabase
      .from('profiles')
      .update({ timezone })
      .eq('id', userId);

    if (error) throw error;
  } catch (error: any) {
    console.error('Error updating timezone:', error);
    throw error;
  }
};

export const updateUserProfile = async (
  userId: string,
  data: Partial<UserProfile>
): Promise<void> => {
  try {
    const { error } = await supabase
      .from('profiles')
      .update(data)
      .eq('id', userId);

    if (error) throw error;
    toast.success('Profile updated successfully');
  } catch (error: any) {
    console.error('Error updating profile:', error);
    toast.error('Failed to update profile');
    throw error;
  }
};
