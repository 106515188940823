
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useAuth } from '@/contexts/AuthContext';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import TimezoneSelect from '@/components/profile/TimezoneSelect';
import { UserProfile } from '@/contexts/auth/types';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { user, userRoles, userProfile, updateProfile, isLoading, removeRole } = useAuth();
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState<Partial<UserProfile>>({
    first_name: userProfile?.first_name || '',
    last_name: userProfile?.last_name || ''
  });
  const [userIdByEmail, setUserIdByEmail] = useState<string | null>(null);

  // Fetch the user ID for a specific email
  useEffect(() => {
    const fetchUserIdByEmail = async () => {
      if (user?.email === 'davidezanon.art@gmail.com') {
        try {
          const { data, error } = await supabase
            .from('profiles')
            .select('id')
            .eq('email', 'davidezanon.art@gmail.com')
            .single();
          
          if (error) throw error;
          if (data) {
            setUserIdByEmail(data.id);
          }
        } catch (error) {
          console.error('Error fetching user ID:', error);
        }
      }
    };

    fetchUserIdByEmail();
  }, [user]);

  const getBadgeVariant = (role: string) => {
    switch (role) {
      case 'admin':
        return 'destructive';
      case 'premium':
        return 'default';
      default:
        return 'secondary';
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateProfile(formData);
    setEditing(false);
  };

  const toggleEdit = () => {
    if (editing) {
      // Reset form data if canceling
      setFormData({
        first_name: userProfile?.first_name || '',
        last_name: userProfile?.last_name || ''
      });
    }
    setEditing(!editing);
  };

  const handleRemoveAdminRole = async () => {
    if (!userIdByEmail) {
      toast.error('Could not find user ID');
      return;
    }

    try {
      await removeRole(userIdByEmail, 'admin');
      toast.success('Admin role removed successfully');
    } catch (error) {
      console.error('Error removing admin role:', error);
      toast.error('Failed to remove admin role');
    }
  };

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="container max-w-4xl mx-auto pt-32 pb-16 px-4">
          <Card className="shadow-md">
            <CardHeader className="bg-muted/30 pb-4">
              <div className="flex justify-between items-center">
                <div>
                  <CardTitle className="text-2xl md:text-3xl">{t('profile.title')}</CardTitle>
                  <CardDescription className="text-base mt-1">
                    {t('profile.email')}: {user?.email}
                  </CardDescription>
                </div>
                <Button 
                  variant={editing ? "outline" : "default"} 
                  onClick={toggleEdit}
                  disabled={isLoading}
                >
                  {editing ? t('common.cancel') : t('common.edit')}
                </Button>
              </div>
            </CardHeader>
            <CardContent className="pt-6 space-y-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                {editing ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div className="space-y-2">
                      <Label htmlFor="first_name">{t('profile.firstName')}</Label>
                      <Input
                        id="first_name"
                        name="first_name"
                        value={formData.first_name || ''}
                        onChange={handleInputChange}
                        placeholder={t('profile.firstNamePlaceholder')}
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="last_name">{t('profile.lastName')}</Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        value={formData.last_name || ''}
                        onChange={handleInputChange}
                        placeholder={t('profile.lastNamePlaceholder')}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mb-6">
                    <h3 className="text-lg font-medium mb-3">{t('profile.personalInfo')}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <div className="text-sm text-muted-foreground mb-1">{t('profile.firstName')}</div>
                        <div className="text-lg">
                          {userProfile?.first_name || (
                            <span className="text-muted-foreground italic">
                              {t('profile.noFirstName')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-muted-foreground mb-1">{t('profile.lastName')}</div>
                        <div className="text-lg">
                          {userProfile?.last_name || (
                            <span className="text-muted-foreground italic">
                              {t('profile.noLastName')}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <h3 className="text-lg font-medium mb-2">{t('profile.roles')}</h3>
                  <div className="flex flex-wrap gap-2">
                    {userRoles.map((role) => (
                      <Badge key={role} variant={getBadgeVariant(role) as any}>
                        {t(`roles.${role}`)}
                      </Badge>
                    ))}
                  </div>
                  
                  {/* Special button for removing admin role from davidezanon.art@gmail.com */}
                  {user?.email === 'davidezanon.art@gmail.com' && userRoles.includes('admin') && (
                    <Button 
                      variant="destructive" 
                      className="mt-4" 
                      onClick={handleRemoveAdminRole}
                      disabled={isLoading}
                    >
                      Remove Admin Role
                    </Button>
                  )}
                </div>
                
                <div>
                  <h3 className="text-lg font-medium mb-2">{t('profile.timezone')}</h3>
                  <div className="max-w-md">
                    <TimezoneSelect currentTimezone={userProfile?.timezone} />
                    <p className="text-sm text-muted-foreground mt-2">
                      {t('profile.timezoneHelp')}
                    </p>
                  </div>
                </div>

                {editing && (
                  <div className="pt-4">
                    <Button type="submit" disabled={isLoading}>
                      {isLoading ? t('common.saving') : t('common.save')}
                    </Button>
                  </div>
                )}
              </form>
            </CardContent>
          </Card>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default Profile;
