
import { useState, useEffect } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { AppRole } from '@/contexts/auth/types';
import { fetchUserRoles } from '@/contexts/auth/api';

export function useAuthSession() {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<AppRole[]>(['user']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Initialize user session and set up auth state change listener
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserRoles(session.user.id).then(roles => setUserRoles(roles));
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (_event, session) => {
        setSession(session);
        setUser(session?.user ?? null);
        if (session?.user) {
          fetchUserRoles(session.user.id).then(roles => setUserRoles(roles));
        } else {
          setUserRoles(['user']);
        }
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  return {
    session,
    user,
    userRoles,
    isLoading,
    error,
    setIsLoading,
    setError,
    setUserRoles
  };
}
