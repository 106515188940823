
import { User } from '@supabase/supabase-js';
import { useTranslation } from 'react-i18next';
import { 
  signInUser, 
  signOutUser, 
  signUpUser 
} from '@/contexts/auth/api';
import { AppRole } from '@/contexts/auth/types';

export function useAuthActions(
  user: User | null,
  userRoles: AppRole[],
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: string | null) => void
) {
  const { t } = useTranslation();

  const signUp = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await signUpUser(email, password, t);
    } catch (error: any) {
      setError(error.message);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await signInUser(email, password, t);
    } catch (error: any) {
      setError(error.message);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      await signOutUser(t);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const hasRole = (role: AppRole | AppRole[]): boolean => {
    if (Array.isArray(role)) {
      return role.some(r => userRoles.includes(r));
    }
    return userRoles.includes(role);
  };

  return {
    signUp,
    signIn,
    signOut,
    hasRole
  };
}
