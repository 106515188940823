
import React from 'react';
import { 
  TableRow, 
  TableCell 
} from '@/components/ui/table';
import { Keyword } from '@/types/keyword';
import { formatDate } from '@/utils/date-utils';
import KeywordActions from '../KeywordActions';

interface KeywordTableRowProps {
  keyword: Keyword;
  onDelete: (id: string) => void;
}

const KeywordTableRow: React.FC<KeywordTableRowProps> = ({ keyword, onDelete }) => {
  return (
    <TableRow key={keyword.id}>
      <TableCell className="font-medium">{keyword.keyword}</TableCell>
      <TableCell>{formatDate(keyword.created_at)}</TableCell>
      <TableCell className="text-right">
        <KeywordActions 
          keyword={keyword}
          onDelete={onDelete}
        />
      </TableCell>
    </TableRow>
  );
};

export default KeywordTableRow;
