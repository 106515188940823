
import React from 'react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';

interface TagsFilterProps {
  tags: string[];
  activeTag: string | null;
  onSelectTag: (tag: string | null) => void;
}

export function TagsFilter({ tags, activeTag, onSelectTag }: TagsFilterProps) {
  if (tags.length === 0) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="flex items-center mb-2">
        <h2 className="text-sm font-medium mr-2">Filter by tag:</h2>
        {activeTag && (
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-7 px-2 text-xs"
            onClick={() => onSelectTag(null)}
          >
            <X className="h-3 w-3 mr-1" />
            Clear filter
          </Button>
        )}
      </div>
      <div className="flex flex-wrap gap-2">
        {tags.map(tag => (
          <Badge
            key={tag}
            variant={activeTag === tag ? "default" : "outline"}
            className="cursor-pointer hover:bg-primary/90 transition-colors"
            onClick={() => onSelectTag(activeTag === tag ? null : tag)}
          >
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );
}
