
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth';
import MobileNavLink from './MobileNavLink';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onSignOut: () => Promise<void>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, onSignOut }) => {
  const { user, hasRole } = useAuth();
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="md:hidden neo-blur fixed inset-0 top-16 z-40 animate-fade-in">
      <div 
        className="absolute inset-0 bg-black/20 backdrop-blur-sm" 
        onClick={onClose}
        aria-hidden="true"
      />
      <nav className="relative z-10 flex flex-col p-6 space-y-6 bg-background/95 dark:bg-black/90 border-t dark:border-white/10 min-h-screen">
        {user ? (
          <MobileNavLink to="/dashboard" label="Your Dashboard" onClick={onClose} />
        ) : null}
        <MobileNavLink to="/resources" label="Productivity Resources" onClick={onClose} />
        
        {user && (
          <>
            <MobileNavLink to="/profile" label="User Profile Settings" onClick={onClose} />
            {hasRole('admin') && (
              <MobileNavLink to="/admin" label="Admin Dashboard" onClick={onClose} />
            )}
            <MobileNavLink to="/habits" label="Habit Tracker" onClick={onClose} />
            <MobileNavLink to="/reminders" label="Personal Reminders" onClick={onClose} />
          </>
        )}
        
        <div className="pt-4 border-t border-white/10">
          {user ? (
            <Button 
              className="w-full py-3 text-base glass hover:bg-white/10 rounded-md font-medium transition-all"
              onClick={() => {
                onSignOut();
                onClose();
              }}
            >
              Logout from Account
            </Button>
          ) : (
            <Button 
              className="w-full py-3 text-base glass hover:bg-white/10 rounded-md font-medium transition-all"
              onClick={() => {
                navigate('/auth');
                onClose();
              }}
            >
              Sign In to Your Account
            </Button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;
