
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';

import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

import TitleField from './form-fields/TitleField';
import DescriptionField from './form-fields/DescriptionField';
import ImportantInfoField from './form-fields/ImportantInfoField';
import AIField from './form-fields/AIField';
import CollectionField from './form-fields/CollectionField';
import TagsField from './form-fields/TagsField';
import LinksField from './form-fields/LinksField';
import DueDateField from './form-fields/DueDateField';
import RecurrenceField from './form-fields/RecurrenceField';
import PriorityField from './form-fields/PriorityField';
import StatusField from './form-fields/StatusField';

import { projectFormSchema, type ProjectFormValues } from './project-form-schema';
import { Project } from '@/components/sidebar/sidebar-data';

interface ProjectFormProps {
  collectionId?: string;
  project?: Project;
  isEditing?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ 
  collectionId, 
  project,
  isEditing = false,
  onSuccess, 
  onCancel 
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const form = useForm<ProjectFormValues>({
    resolver: zodResolver(projectFormSchema),
    defaultValues: {
      title: project?.title || '',
      description: project?.description || '',
      important_info: project?.important_info || '',
      ai: project?.ai || '',
      links: project?.links || [],
      tags: project?.tags || [],
      due_date: project?.due_date ? new Date(project.due_date) : undefined,
      recurrence: project?.recurrence as ProjectFormValues["recurrence"] || 'none',
      priority: project?.priority as ProjectFormValues["priority"] || 'average',
      status: project?.status as ProjectFormValues["status"] || 'active',
      collection_id: project?.collection_id || collectionId || '',
    },
  });

  const onSubmit = async (data: ProjectFormValues) => {
    if (!user) {
      toast.error('You must be logged in to create a project');
      return;
    }

    setIsSubmitting(true);

    try {
      const projectData = {
        title: data.title,
        description: data.description || '',
        important_info: data.important_info || '',
        ai: data.ai || '',
        links: data.links || [],
        tags: data.tags || [],
        due_date: data.due_date ? data.due_date.toISOString() : null,
        recurrence: data.recurrence,
        priority: data.priority,
        status: data.status,
        collection_id: data.collection_id,
        user_id: user.id,
      };

      if (isEditing && project) {
        // Update existing project
        const { error } = await supabase
          .from('projects')
          .update(projectData)
          .eq('id', project.id);

        if (error) throw error;

        toast.success('Project updated successfully');
      } else {
        // Create new project
        const { error } = await supabase
          .from('projects')
          .insert(projectData);

        if (error) throw error;

        toast.success('Project created successfully');
      }
      
      if (onSuccess) {
        onSuccess();
      } else {
        navigate(`/projects/collections/${data.collection_id}`);
      }
    } catch (error: any) {
      console.error(isEditing ? 'Error updating project:' : 'Error creating project:', error);
      toast.error(error.message || (isEditing ? 'Error updating project' : 'Error creating project'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>{isEditing ? 'Edit Project' : 'Create New Project'}</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <TitleField form={form} />
            <DescriptionField form={form} />
            <ImportantInfoField form={form} />
            <AIField form={form} />
            <CollectionField form={form} />
            <TagsField form={form} />
            <LinksField form={form} />
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <DueDateField form={form} />
              <RecurrenceField form={form} />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <PriorityField form={form} />
              <StatusField form={form} />
            </div>

            <div className="flex justify-end gap-2 pt-4">
              {onCancel && (
                <Button 
                  type="button" 
                  variant="ghost" 
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
              <Button 
                type="submit" 
                disabled={isSubmitting}
              >
                {isSubmitting 
                  ? (isEditing ? 'Updating...' : 'Creating...') 
                  : (isEditing ? 'Update Project' : 'Create Project')}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ProjectForm;
