
import { supabase } from '@/integrations/supabase/client';

export interface ResourceRating {
  average_rating: number;
  rating_count: number;
}

export const getResourceRating = async (resourceId: string): Promise<ResourceRating | null> => {
  const { data, error } = await supabase
    .rpc('get_resource_rating', { resource_id: resourceId });
  
  if (error) {
    console.error('Error fetching resource rating:', error);
    return null;
  }
  
  return data[0] || { average_rating: 0, rating_count: 0 };
};

export const addResourceReview = async (resourceId: string, rating: number): Promise<boolean> => {
  try {
    // Get IP address for anonymous users (using a service)
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;
    
    // Get current user if authenticated
    const { data: { user } } = await supabase.auth.getUser();
    
    // First check if this user/IP has already reviewed this specific resource
    const hasReviewed = await hasUserReviewed(resourceId);
    if (hasReviewed) {
      console.log('User has already reviewed this resource');
      return false;
    }
    
    const { error } = await supabase
      .from('resource_reviews')
      .insert({
        resource_id: resourceId,
        user_id: user?.id || null,
        ip_address: ipAddress,
        rating: rating
      });
    
    if (error) {
      console.error('Error adding resource review:', error);
      // Check if it's a unique constraint violation (user already reviewed)
      if (error.code === '23505') {
        return false;
      }
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error in addResourceReview:', error);
    return false;
  }
};

export const hasUserReviewed = async (resourceId: string): Promise<boolean> => {
  try {
    // Get IP address for anonymous users
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;
    
    // Get current user if authenticated
    const { data: { user } } = await supabase.auth.getUser();
    
    // Check if user has already reviewed THIS SPECIFIC RESOURCE (by user_id or IP)
    let query = supabase
      .from('resource_reviews')
      .select('id')
      .eq('resource_id', resourceId);
      
    // Add filter based on whether user is logged in or anonymous
    if (user?.id) {
      query = query.eq('user_id', user.id);
    } else {
      query = query.eq('ip_address', ipAddress);
    }
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Error checking if user reviewed:', error);
      return false;
    }
    
    return data.length > 0;
  } catch (error) {
    console.error('Error in hasUserReviewed:', error);
    return false;
  }
};
