
import React, { useState } from 'react';
import { CustomBadge } from '../CustomBadge';
import { Calendar, Clock } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { Button } from '@/components/ui/button';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { formatDistanceToNow, isPast, isToday, format, addDays } from 'date-fns';
import { cn } from '@/lib/utils';

interface DueDateDropdownProps {
  dueDate: string | null;
  projectId: string;
  onProjectUpdated: () => void;
}

const DueDateDropdown: React.FC<DueDateDropdownProps> = ({
  dueDate,
  projectId,
  onProjectUpdated
}) => {
  const [updating, setUpdating] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(dueDate ? new Date(dueDate) : undefined);

  const updateDueDate = async (newDate: Date | undefined) => {
    if (updating) return;
    
    setUpdating(true);
    try {
      const { error } = await supabase
        .from('projects')
        .update({ due_date: newDate ? newDate.toISOString() : null })
        .eq('id', projectId);

      if (error) {
        throw error;
      }

      toast.success('Due date updated');
      onProjectUpdated();
      setIsOpen(false);
    } catch (error: any) {
      console.error(`Error updating project due date:`, error);
      toast.error('Failed to update due date');
    } finally {
      setUpdating(false);
    }
  };

  const removeDueDate = () => {
    setDate(undefined);
    updateDueDate(undefined);
  };

  // Format the due date status with color coding
  const getDueDateStatus = () => {
    if (!dueDate) return { text: 'Add due date', color: 'text-muted-foreground' };
    
    const dueDateObj = new Date(dueDate);
    
    if (isPast(dueDateObj) && !isToday(dueDateObj)) {
      return { 
        text: `Overdue: ${formatDistanceToNow(dueDateObj, { addSuffix: true })}`,
        color: 'text-red-500 dark:text-red-400'
      };
    }
    
    if (isToday(dueDateObj)) {
      return { 
        text: 'Due today', 
        color: 'text-amber-500 dark:text-amber-400'
      };
    }
    
    // Check if due date is within the next week
    if (dueDateObj < addDays(new Date(), 7)) {
      return { 
        text: `Due ${formatDistanceToNow(dueDateObj, { addSuffix: true })}`,
        color: 'text-blue-500 dark:text-blue-400'
      };
    }
    
    return { 
      text: format(dueDateObj, 'MMM d, yyyy'),
      color: 'text-foreground/70'
    };
  };

  const status = getDueDateStatus();

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <CustomBadge 
          variant="secondary" 
          className={cn(
            "flex items-center gap-1 cursor-pointer hover:bg-accent/50 transition-colors",
            status.color
          )}
        >
          <Calendar className="h-3 w-3" />
          {status.text}
        </CustomBadge>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-auto z-50 bg-popover shadow-lg" align="start">
        <div className="p-3">
          <CalendarComponent
            mode="single"
            selected={date}
            onSelect={setDate}
            initialFocus
            className="p-3 pointer-events-auto"
          />
          <div className="flex justify-between mt-4 px-1">
            {dueDate && (
              <Button 
                variant="outline" 
                size="sm" 
                onClick={removeDueDate}
                className="text-destructive hover:text-destructive"
              >
                Remove date
              </Button>
            )}
            <Button 
              size="sm" 
              className="ml-auto"
              onClick={() => updateDueDate(date)}
              disabled={updating}
            >
              {updating ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default DueDateDropdown;
