
import { supabase } from '@/integrations/supabase/client';

/**
 * Generates a content preview from a keyword using OpenAI
 * This doesn't save the resource or update the keyword status
 */
export const previewContentFromKeyword = async (keywordId: string): Promise<{ 
  success: boolean; 
  preview?: any; 
  error?: string; 
  details?: any 
}> => {
  try {
    console.log(`Calling preview-content function with keywordId: ${keywordId}`);
    
    const { data, error } = await supabase.functions.invoke('generate-resource', {
      body: { 
        keywordId,
        mode: 'preview' // Tell the edge function to only generate content without saving
      }
    });
    
    if (error) {
      console.error('Error from Supabase function:', error);
      return {
        success: false,
        error: `Function error: ${error.message}`,
        details: error
      };
    }
    
    if (!data || !data.success) {
      const errorMessage = data?.error || 'Unknown error in preview-content function';
      console.error('Function returned error:', errorMessage);
      
      if (data?.details) {
        console.error('Error details:', data.details);
      }
      
      return {
        success: false,
        error: errorMessage,
        details: data?.details || undefined
      };
    }
    
    console.log('Content preview generated successfully:', data.preview);
    return data;
  } catch (error: any) {
    console.error('Error generating content preview:', error);
    return { 
      success: false, 
      error: error.message || 'An unknown error occurred while generating the content preview',
      details: error.details || error
    };
  }
};
