
import { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { Task, convertSupabaseToTask } from '@/types/task';
import { organizeTasksHierarchy } from './task-utils';
import { toast } from 'sonner';

interface TaskDataProps {
  projectId: string | undefined;
  userId: string | undefined;
}

export function useTaskData({ projectId, userId }: TaskDataProps) {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const channelRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const isMountedRef = useRef(true);
  const fetchInProgressRef = useRef(false);
  const initialFetchCompletedRef = useRef(false);

  const fetchTasks = useCallback(async () => {
    if (!userId || !projectId) {
      console.log('Cannot fetch tasks: missing userId or projectId', { userId, projectId });
      if (isMountedRef.current) {
        setIsLoading(false);
      }
      return;
    }

    // Prevent concurrent fetches
    if (fetchInProgressRef.current) {
      console.log('Tasks fetch already in progress, skipping...');
      return;
    }

    try {
      fetchInProgressRef.current = true;
      
      if (isMountedRef.current) {
        setIsLoading(true);
        setError(null);
      }

      console.log('Fetching tasks for project:', projectId);
      console.log('User ID for tasks:', userId);

      const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('project_id', projectId)
        .eq('user_id', userId)
        .order('position', { ascending: true });

      if (error) {
        console.error('Error in supabase query:', error);
        throw error;
      }

      console.log('Fetched tasks data:', data);

      // Transform data to match Task interface and organize in hierarchy
      const transformedTasks = data.map(task => convertSupabaseToTask(task)) as Task[];
      
      const organizedTasks = organizeTasksHierarchy(transformedTasks);
      
      if (isMountedRef.current) {
        setTasks(organizedTasks);
        setIsLoading(false);
        initialFetchCompletedRef.current = true;
      }
    } catch (err: any) {
      console.error('Error fetching tasks:', err);
      if (isMountedRef.current) {
        setError(err.message || 'Failed to fetch tasks');
        setIsLoading(false);
        toast.error('Failed to load tasks');
      }
    } finally {
      fetchInProgressRef.current = false;
    }
  }, [projectId, userId]);

  // Set up real-time subscription
  useEffect(() => {
    isMountedRef.current = true;
    initialFetchCompletedRef.current = false;
    
    if (!projectId || !userId) {
      console.log('Not setting up tasks subscription: missing userId or projectId', { userId, projectId });
      if (isMountedRef.current) {
        setIsLoading(false);
      }
      return;
    }

    console.log('Setting up tasks subscription for project', projectId);
    
    // Clean up any existing channel first
    if (channelRef.current) {
      supabase.removeChannel(channelRef.current);
      channelRef.current = null;
    }
    
    // Initial fetch
    if (isMountedRef.current && !initialFetchCompletedRef.current) {
      fetchTasks();
    }
    
    // Create a new channel
    const channel = supabase
      .channel('tasks-changes-' + projectId)
      .on('postgres_changes', 
        { 
          event: '*', 
          schema: 'public', 
          table: 'tasks',
          filter: `project_id=eq.${projectId}`
        }, 
        () => {
          console.log('Tasks change detected, refreshing...');
          // Only refresh if mounted and not already fetching
          if (isMountedRef.current && !fetchInProgressRef.current) {
            fetchTasks();
          }
        }
      )
      .subscribe();

    // Store the channel reference
    channelRef.current = channel;

    return () => {
      isMountedRef.current = false;
      console.log('Cleaning up tasks subscription for project', projectId);
      if (channelRef.current) {
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
    };
  }, [fetchTasks, projectId, userId]);

  return {
    tasks,
    isLoading,
    error,
    refreshTasks: fetchTasks
  };
}
