
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { ResourceFormValues } from '@/hooks/resources/resource-form-types';
import { Category } from '@/types/resource';
import ResourceFormActions from './ResourceFormActions';
import TitleSlugFields from './form-fields/TitleSlugFields';
import ContentField from './form-fields/ContentField';
import ExcerptField from './form-fields/ExcerptField';
import MetaFields from './form-fields/MetaFields';
import StatusField from './form-fields/StatusField';
import CategoriesField from './form-fields/CategoriesField';

interface ResourceFormProps {
  defaultValues: ResourceFormValues;
  onSubmit: (data: ResourceFormValues) => void;
  isSubmitting: boolean;
  onGenerateSlug: (title: string) => string;
  selectedCategories: string[];
  onCategoryChange: (categoryId: string) => void;
  categories: Category[];
  language: string;
}

const ResourceForm: React.FC<ResourceFormProps> = ({
  defaultValues,
  onSubmit,
  isSubmitting,
  onGenerateSlug,
  selectedCategories,
  onCategoryChange,
  categories,
  language
}) => {
  const form = useForm<ResourceFormValues>({
    defaultValues,
    // No validation rules here - we'll allow empty fields for drafts
  });

  const handleGenerateSlug = () => {
    const title = form.getValues('title');
    if (!title) return;
    
    const slug = onGenerateSlug(title);
    form.setValue('slug', slug);
  };

  const handleSubmit = (data: ResourceFormValues) => {
    // For published resources, we could validate here if needed
    // But we're allowing all empty fields for drafts
    onSubmit(data);
  };

  return (
    <Form {...form}>
      <form className="space-y-6" onSubmit={form.handleSubmit(handleSubmit)}>
        <TitleSlugFields form={form} onGenerateSlug={handleGenerateSlug} />
        <ContentField form={form} />
        <ExcerptField form={form} />
        <MetaFields form={form} />
        
        <div className="grid gap-4 md:grid-cols-2">
          <StatusField form={form} />
          <CategoriesField 
            selectedCategories={selectedCategories}
            onCategoryChange={onCategoryChange}
            categories={categories}
            language={language}
          />
        </div>

        <ResourceFormActions 
          isSubmitting={isSubmitting} 
          handleGenerateSlug={handleGenerateSlug}
        />
      </form>
    </Form>
  );
};

export default ResourceForm;
