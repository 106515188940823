
import React from 'react';
import { Shield, ChevronDown, ChevronRight, LayoutDashboard, Users, Star } from 'lucide-react';
import { 
  SidebarMenuItem, SidebarMenuButton, SidebarMenuSub, 
  SidebarMenuSubItem, SidebarMenuSubButton 
} from '@/components/ui/sidebar';
import { useSidebarAdmin } from '../hooks/use-sidebar-admin';
import { useSidebar } from '@/components/ui/sidebar';
import { useLocation } from 'react-router-dom';

interface AdminMenuProps {
  hasAdminRole: boolean;
}

export function AdminMenu({ hasAdminRole }: AdminMenuProps) {
  const { state } = useSidebar();
  const location = useLocation();
  const { 
    isAdminOpen, isAdminActive, 
    toggleAdminOpen, navigateToAdmin, navigateToUsers 
  } = useSidebarAdmin();

  if (!hasAdminRole) return null;

  return (
    <SidebarMenuItem>
      <SidebarMenuButton 
        onClick={navigateToAdmin} 
        className="w-full justify-between"
        isActive={isAdminActive}
      >
        <div className="flex items-center gap-2">
          <Shield className="h-4 w-4" />
          <span className="transition-opacity duration-200 group-data-[collapsible=icon]:opacity-0">Admin</span>
          <Star className="h-3 w-3 text-amber-400 fill-amber-400" />
        </div>
        {state !== 'collapsed' && (
          isAdminOpen ? (
            <ChevronDown className="h-3 w-3" onClick={(e) => toggleAdminOpen(e)} />
          ) : (
            <ChevronRight className="h-3 w-3" onClick={(e) => toggleAdminOpen(e)} />
          )
        )}
      </SidebarMenuButton>
      
      {isAdminOpen && state !== 'collapsed' && (
        <SidebarMenuSub>
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToAdmin}
              isActive={location.pathname === '/admin'}
            >
              <div className="flex items-center gap-2">
                <LayoutDashboard className="h-3 w-3" />
                <span>Dashboard</span>
                <Star className="h-2 w-2 text-amber-400 fill-amber-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
          
          <SidebarMenuSubItem>
            <SidebarMenuSubButton
              onClick={navigateToUsers}
              isActive={location.pathname === '/admin/users'}
            >
              <div className="flex items-center gap-2">
                <Users className="h-3 w-3" />
                <span>User Management</span>
                <Star className="h-2 w-2 text-amber-400 fill-amber-400" />
              </div>
            </SidebarMenuSubButton>
          </SidebarMenuSubItem>
        </SidebarMenuSub>
      )}
    </SidebarMenuItem>
  );
}
