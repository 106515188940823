
import React, { useEffect, useState } from 'react';
import { X, Bell, Clock, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { useReminders } from '@/hooks/reminders/use-reminders';
import { Reminder } from '@/types/reminder';
import { format } from 'date-fns';
import { toast } from 'sonner';

export function ReminderNotification() {
  const { user } = useAuth();
  const { dueReminders, completeReminder, snoozeReminder } = useReminders();
  
  useEffect(() => {
    if (!user || dueReminders.length === 0) return;
    
    // Show toast for each due reminder
    dueReminders.forEach(reminder => {
      // Create a unique ID for the toast based on reminder ID and due time
      // This helps prevent duplicate toasts for the same reminder
      const toastId = `reminder-${reminder.id}-${reminder.due_at}`;
      
      // Check if this specific toast is already showing
      const toastElement = document.getElementById(toastId);
      if (toastElement) return;
      
      toast(
        <ReminderToastContent 
          reminder={reminder} 
          onComplete={completeReminder} 
          onSnooze={snoozeReminder} 
        />,
        {
          id: toastId,
          duration: Infinity, // Keep showing until user interacts
          className: "reminder-toast",
          icon: <Bell className="h-5 w-5 text-yellow-500" />,
          position: "top-right",
        }
      );
    });
  }, [dueReminders, user, completeReminder, snoozeReminder]);

  return null; // No UI to render in the component itself
}

interface ReminderToastContentProps {
  reminder: Reminder;
  onComplete: (id: string) => void;
  onSnooze: ({ id, minutes }: { id: string; minutes: number }) => void;
}

function ReminderToastContent({ 
  reminder, 
  onComplete, 
  onSnooze 
}: ReminderToastContentProps) {
  const handleComplete = () => {
    onComplete(reminder.id);
    toast.dismiss(`reminder-${reminder.id}-${reminder.due_at}`);
  };

  const handleSnooze = (minutes: number) => {
    onSnooze({ id: reminder.id, minutes });
    toast.dismiss(`reminder-${reminder.id}-${reminder.due_at}`);
  };

  return (
    <div className="w-full max-w-md px-1 py-2" id={`reminder-${reminder.id}-${reminder.due_at}`}>
      <h3 className="text-base font-semibold">{reminder.title}</h3>
      
      {reminder.description && (
        <p className="text-sm text-muted-foreground mt-1">{reminder.description}</p>
      )}
      
      <div className="flex items-center gap-2 mt-2 text-xs text-muted-foreground">
        <Clock className="h-3.5 w-3.5" />
        <span>{format(new Date(reminder.due_at), 'PPP, HH:mm')}</span>
      </div>

      <div className="flex flex-row gap-2 mt-3">
        <Button onClick={handleComplete} size="sm" className="flex-1 gap-1">
          <Check className="h-3.5 w-3.5" />
          Complete
        </Button>
        <Button onClick={() => handleSnooze(15)} variant="outline" size="sm" className="flex-1">
          15m
        </Button>
        <Button onClick={() => handleSnooze(60)} variant="outline" size="sm" className="flex-1">
          1h
        </Button>
      </div>
    </div>
  );
}
