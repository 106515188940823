
import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { 
  Search, 
  Calendar, 
  Tag, 
  Clock, 
  Flag, 
  Star, 
  X, 
  Filter,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { useUserTags } from '@/hooks/use-user-tags';
import { cn } from '@/lib/utils';

interface QuickFiltersProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  showDueToday: boolean;
  setShowDueToday: (show: boolean) => void;
  showOverdue: boolean;
  setShowOverdue: (show: boolean) => void;
  showHighPriority: boolean;
  setShowHighPriority: (show: boolean) => void;
  showFavorites: boolean;
  setShowFavorites: (show: boolean) => void;
  clearAllFilters: () => void;
  hasActiveFilters: boolean;
}

const QuickFilters: React.FC<QuickFiltersProps> = ({
  searchQuery,
  setSearchQuery,
  selectedTags,
  setSelectedTags,
  showDueToday,
  setShowDueToday,
  showOverdue,
  setShowOverdue,
  showHighPriority,
  setShowHighPriority,
  showFavorites,
  setShowFavorites,
  clearAllFilters,
  hasActiveFilters
}) => {
  const { tags: userTags } = useUserTags();
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  
  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="mb-6 space-y-3">
      <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder="Search projects..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="pl-9"
          />
          {searchQuery && (
            <button 
              className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
              onClick={() => setSearchQuery('')}
            >
              <X className="h-4 w-4" />
            </button>
          )}
        </div>
        
        <div className="flex gap-2 flex-wrap">
          <Button 
            variant={showDueToday ? "default" : "outline"} 
            size="sm"
            onClick={() => setShowDueToday(!showDueToday)}
            className="flex gap-1.5 items-center"
          >
            <Calendar className="h-4 w-4" />
            <span>Today</span>
          </Button>
          <Button 
            variant={showOverdue ? "default" : "outline"} 
            size="sm"
            onClick={() => setShowOverdue(!showOverdue)}
            className="flex gap-1.5 items-center"
          >
            <Clock className="h-4 w-4" />
            <span>Overdue</span>
          </Button>
          
          <div className="relative">
            <Button 
              variant={selectedTags.length > 0 ? "default" : "outline"} 
              size="sm"
              onClick={() => setShowTagsDropdown(!showTagsDropdown)}
              className="flex gap-1.5 items-center"
            >
              <Tag className="h-4 w-4" />
              <span>Tags{selectedTags.length > 0 ? ` (${selectedTags.length})` : ''}</span>
              {showTagsDropdown ? (
                <ChevronUp className="h-3 w-3 ml-1" />
              ) : (
                <ChevronDown className="h-3 w-3 ml-1" />
              )}
            </Button>
            
            {showTagsDropdown && (
              <div className="absolute z-10 top-full left-0 mt-1 w-64 bg-card border rounded-md shadow-lg p-3">
                <div className="mb-2 font-medium">Available Tags</div>
                {userTags.length === 0 ? (
                  <div className="text-sm text-muted-foreground">No tags available</div>
                ) : (
                  <div className="flex flex-wrap gap-2 max-h-48 overflow-y-auto">
                    {userTags.map(tag => (
                      <Badge 
                        key={tag}
                        variant={selectedTags.includes(tag) ? "default" : "outline"}
                        className="cursor-pointer"
                        onClick={() => toggleTag(tag)}
                      >
                        {tag}
                        {selectedTags.includes(tag) && (
                          <X className="ml-1 h-3 w-3" />
                        )}
                      </Badge>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          
          <Button 
            variant="outline" 
            size="sm"
            onClick={() => setShowMoreFilters(!showMoreFilters)}
            className={cn(
              "flex gap-1.5 items-center",
              (showHighPriority || showFavorites) && "bg-primary text-primary-foreground hover:bg-primary/90"
            )}
          >
            <Filter className="h-4 w-4" />
            <span>More filters</span>
            {showMoreFilters ? (
              <ChevronUp className="h-3 w-3 ml-1" />
            ) : (
              <ChevronDown className="h-3 w-3 ml-1" />
            )}
          </Button>
          
          {hasActiveFilters && (
            <Button 
              variant="ghost" 
              size="sm"
              onClick={clearAllFilters}
              className="flex gap-1.5 items-center text-muted-foreground"
            >
              <X className="h-4 w-4" />
              <span>Clear all</span>
            </Button>
          )}
        </div>
      </div>
      
      {/* Additional filters dropdown */}
      {showMoreFilters && (
        <div className="bg-card/50 border rounded-md p-3 flex flex-wrap gap-3">
          <Button 
            variant={showHighPriority ? "default" : "outline"} 
            size="sm"
            onClick={() => setShowHighPriority(!showHighPriority)}
            className="flex gap-1.5 items-center"
          >
            <Flag className="h-4 w-4" />
            <span>High Priority</span>
          </Button>
          <Button 
            variant={showFavorites ? "default" : "outline"} 
            size="sm"
            onClick={() => setShowFavorites(!showFavorites)}
            className="flex gap-1.5 items-center"
          >
            <Star className="h-4 w-4" />
            <span>Favorites</span>
          </Button>
        </div>
      )}
      
      {/* Show active filter count */}
      {hasActiveFilters && (
        <div className="text-sm text-muted-foreground">
          Active filters
        </div>
      )}
    </div>
  );
};

export default QuickFilters;
