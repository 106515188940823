
import { supabase } from "@/integrations/supabase/client";
import { Reminder } from "@/types/reminder";
import { calculateNextDueDate } from "@/utils/recurrence-utils";
import { createReminder, updateReminder } from "./reminder-mutations";

/**
 * Mark a reminder as completed
 * If the reminder is recurring, updates it with the next occurrence date instead of creating a new one
 */
export async function completeReminder(id: string): Promise<Reminder> {
  // First get the reminder to check if it's recurring
  const { data: reminder, error: fetchError } = await supabase
    .from('reminders')
    .select('*')
    .eq('id', id)
    .single();
    
  if (fetchError) {
    console.error("Error fetching reminder:", fetchError);
    throw fetchError;
  }
  
  const typedReminder = reminder as Reminder;
    
  if (typedReminder && typedReminder.recurring && typedReminder.recurring !== 'none') {
    // Calculate next due date based on recurrence pattern
    const nextDueDate = calculateNextDueDate(typedReminder.recurring, new Date(typedReminder.due_at));
    
    // Update the existing reminder with the new due date
    return updateReminder({
      id,
      due_at: nextDueDate.toISOString(),
      status: 'pending', // Reset status to pending
      completed_at: null, // Clear completed_at
      snoozed_until: null // Clear any snooze settings
    });
  }
  
  // For non-recurring reminders, just mark as completed
  return updateReminder({
    id,
    status: 'completed',
    completed_at: new Date().toISOString()
  });
}

/**
 * Snooze a reminder for the specified number of minutes
 */
export async function snoozeReminder(id: string, minutes: number = 15): Promise<Reminder> {
  const snoozeUntil = new Date();
  snoozeUntil.setMinutes(snoozeUntil.getMinutes() + minutes);
  
  return updateReminder({
    id,
    status: 'snoozed',
    snoozed_until: snoozeUntil.toISOString()
  });
}

/**
 * Delete a reminder
 */
export async function deleteReminder(id: string): Promise<void> {
  const { error } = await supabase
    .from('reminders')
    .delete()
    .eq('id', id);

  if (error) {
    console.error("Error deleting reminder:", error);
    throw error;
  }
}
