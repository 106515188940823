
import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const CtaSection: React.FC = () => {
  return (
    <div className="py-24 bg-primary/10 dark:bg-primary/15">
      <div className="max-w-5xl mx-auto px-6 text-center">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
          Ready to Transform Your Productivity?
        </h2>
        <p className="text-xl text-gray-700 dark:text-gray-200 mb-8 max-w-3xl mx-auto">
          Join thousands of users who have already improved their workflow, built better habits, and achieved their goals with our platform.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Link to="/auth">
            <Button size="lg" className="text-base font-medium px-8 group">
              Get Started Free
              <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
            </Button>
          </Link>
          <Link to="/resources">
            <Button size="lg" variant="outline" className="text-base font-medium px-8">
              Explore Productivity Resources
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
