
import React from 'react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Pencil, Trash2, X, Save } from 'lucide-react';
import { TodoItem as TodoItemType } from './useQuickTodos';

interface TodoItemProps {
  todo: TodoItemType;
  editingId: string | null;
  editText: string;
  onEditTextChange: (text: string) => void;
  onToggleStatus: (id: string, status: boolean) => Promise<void>;
  onStartEditing: (todo: TodoItemType) => void;
  onCancelEditing: () => void;
  onSaveEdit: (id: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

export function TodoItem({
  todo,
  editingId,
  editText,
  onEditTextChange,
  onToggleStatus,
  onStartEditing,
  onCancelEditing,
  onSaveEdit,
  onDelete
}: TodoItemProps) {
  const isEditing = editingId === todo.id;

  return (
    <div 
      className={`flex items-center justify-between p-2 rounded-md ${
        todo.is_completed 
          ? 'bg-yellow-100 dark:bg-yellow-800/40' 
          : 'bg-yellow-50 dark:bg-yellow-900/30'
      }`}
    >
      <div className="flex items-center gap-2 flex-1">
        <Checkbox 
          id={`todo-${todo.id}`}
          checked={todo.is_completed}
          onCheckedChange={() => onToggleStatus(todo.id, todo.is_completed)}
          className="border-yellow-500 dark:border-yellow-600 data-[state=checked]:bg-yellow-500 dark:data-[state=checked]:bg-yellow-600"
        />
        
        {isEditing ? (
          <input
            type="text"
            value={editText}
            onChange={(e) => onEditTextChange(e.target.value)}
            className="flex-1 px-2 py-1 border border-yellow-300 dark:border-yellow-700 rounded-md bg-white dark:bg-yellow-900/60 focus:outline-none focus:ring-1 focus:ring-yellow-300 dark:focus:ring-yellow-600 dark:text-yellow-100"
            autoFocus
          />
        ) : (
          <label 
            htmlFor={`todo-${todo.id}`}
            className={`flex-1 dark:text-yellow-100 ${todo.is_completed ? 'line-through text-yellow-700 dark:text-yellow-400 opacity-70' : ''}`}
          >
            {todo.title}
          </label>
        )}
      </div>
      
      <div className="flex items-center gap-1">
        {isEditing ? (
          <>
            <Button 
              size="icon" 
              variant="ghost" 
              className="h-7 w-7 text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-300 hover:bg-yellow-100 dark:hover:bg-yellow-800/50"
              onClick={() => onSaveEdit(todo.id)}
            >
              <Save size={16} />
            </Button>
            <Button 
              size="icon" 
              variant="ghost" 
              className="h-7 w-7 text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 hover:bg-yellow-100 dark:hover:bg-yellow-800/50"
              onClick={onCancelEditing}
            >
              <X size={16} />
            </Button>
          </>
        ) : (
          <>
            <Button 
              size="icon" 
              variant="ghost" 
              className="h-7 w-7 text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 hover:bg-yellow-100 dark:hover:bg-yellow-800/50"
              onClick={() => onStartEditing(todo)}
              disabled={todo.is_completed}
            >
              <Pencil size={16} />
            </Button>
            <Button 
              size="icon" 
              variant="ghost" 
              className="h-7 w-7 text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 hover:bg-yellow-100 dark:hover:bg-yellow-800/50"
              onClick={() => onDelete(todo.id)}
            >
              <Trash2 size={16} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
