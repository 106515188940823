
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateResourceFromKeyword } from '@/services/resources/resource-generator-service';
import { handleResourceGenerationSuccess } from './success-handler';
import { handleResourceGenerationError } from './error-handler';
import { ResourceGenerationError, ResourceGenerationResult } from './types';

/**
 * Hook for generating resources from keywords using AI
 */
export const useResourceGeneratorMutation = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (keywordId: string): Promise<ResourceGenerationResult> => {
      const result = await generateResourceFromKeyword(keywordId);
      if (!result.success) {
        // Create an error object that includes the details
        const error = new Error(result.error || 'Failed to generate resource') as ResourceGenerationError;
        if (result.details) {
          error.details = result.details;
        }
        throw error;
      }
      return result;
    },
    onSuccess: (data) => handleResourceGenerationSuccess(data, queryClient),
    onError: (error: Error) => handleResourceGenerationError(error, queryClient)
  });
};
