
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import CategoryForm from '@/components/admin/categories/CategoryForm';
import { useCategoryForm } from '@/hooks/categories/use-category-form';
import { toast } from 'sonner';
import { useMetadata } from '@/hooks/use-metadata';

const AdminCategoryForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(id);
  
  const title = isEditing ? 'Edit Category' : 'Create Category';
  useMetadata({
    title,
    description: isEditing 
      ? 'Edit an existing resource category.' 
      : 'Create a new resource category for your platform.',
  });
  
  const {
    defaultValues,
    handleSubmit,
    isSubmitting
  } = useCategoryForm();

  const handleCancel = () => {
    navigate('/admin/resources/categories');
  };

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <Card>
            <CardHeader>
              <CardTitle>{isEditing ? 'Edit Category' : 'Create Category'}</CardTitle>
            </CardHeader>
            <CardContent>
              <CategoryForm
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            </CardContent>
          </Card>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminCategoryForm;
