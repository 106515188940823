
import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { convertJsonToStringRecord } from '@/utils/json-utils';
import { ResourceFormValues } from './resource-form-types';

export const useResourceFormData = (id: string | undefined, language: string) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [defaultValues, setDefaultValues] = useState<ResourceFormValues>({
    title: '',
    slug: '',
    content: '',
    excerpt: '',
    meta_title: '',
    meta_description: '',
    status: 'draft',
    categories: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchResource = async () => {
      if (!id) return;
      
      setLoading(true);
      setError(null);
      
      try {
        // Fetch resource data
        const { data: resource, error } = await supabase
          .from('resources')
          .select('*, resource_category_mappings(category_id)')
          .eq('id', id)
          .single();

        if (error) throw error;

        console.log('Fetched resource:', resource);

        // Get string value or empty string for each field using the helper function
        const titleRecord = convertJsonToStringRecord(resource.title);
        const contentRecord = convertJsonToStringRecord(resource.content);
        const excerptRecord = convertJsonToStringRecord(resource.excerpt);
        const metaTitleRecord = convertJsonToStringRecord(resource.meta_title);
        const metaDescriptionRecord = convertJsonToStringRecord(resource.meta_description);

        // Check if the language exists in the records, and if not, try to get the first available language data
        // This helps when a resource has been generated but doesn't have content in the current language
        const getContentForLanguage = (record: Record<string, string>) => {
          if (record[language]) return record[language];
          
          // If there's no content for the current language but there's content in any language, use that
          const keys = Object.keys(record);
          if (keys.length > 0) return record[keys[0]];
          
          return '';
        };

        // Set form values with fallback to other languages if the current language doesn't have content
        setDefaultValues({
          title: getContentForLanguage(titleRecord),
          slug: resource.slug,
          content: getContentForLanguage(contentRecord),
          excerpt: getContentForLanguage(excerptRecord),
          meta_title: getContentForLanguage(metaTitleRecord),
          meta_description: getContentForLanguage(metaDescriptionRecord),
          status: resource.status,
          categories: [],
        });

        // Set selected categories
        const categoryIds = resource.resource_category_mappings.map(
          (mapping: any) => mapping.category_id
        );
        setSelectedCategories(categoryIds);
      } catch (error: any) {
        console.error('Error fetching resource:', error);
        setError(error.message || 'Failed to load resource data');
      } finally {
        setLoading(false);
      }
    };

    fetchResource();
  }, [id, language]);

  return {
    selectedCategories,
    setSelectedCategories,
    defaultValues,
    setDefaultValues,
    loading,
    error
  };
};
