
import { supabase } from '@/integrations/supabase/client';
import { ResourceDetail } from '@/types/resource';
import { Json } from '@/integrations/supabase/types';

export const getResourceBySlug = async (slug: string, language = 'en'): Promise<ResourceDetail> => {
  console.log(`Fetching resource with slug: ${slug}, language: ${language}`);
  
  try {
    // First try to use the RPC function which has proper language support
    const { data: rpcData, error: rpcError } = await supabase
      .rpc('get_resource_by_slug', { 
        resource_slug: slug,
        lang_code: language
      });
      
    if (!rpcError && rpcData && rpcData.length > 0) {
      console.log('Successfully fetched resource using RPC function');
      // Fix: Extract the first item from the array since we expect a single resource
      return rpcData[0] as ResourceDetail;
    }
    
    // If the RPC approach fails, fall back to manual query
    console.log('Falling back to manual resource query');
    const { data, error } = await supabase
      .from('resources')
      .select(`
        id, title, slug, content, excerpt, meta_title, meta_description,
        status, published_at, created_at,
        resource_category_mappings (
          resource_categories (id, name, slug)
        )
      `)
      .eq('slug', slug)
      .eq('status', 'published')
      .single();

    if (error) {
      console.error('Error fetching resource by slug:', error);
      throw new Error(error.message);
    }

    if (!data) {
      console.error('No resource found with slug:', slug);
      throw new Error(`Resource not found: ${slug}`);
    }
    
    // Transform the data to match ResourceDetail format
    const categories = data.resource_category_mappings 
      ? data.resource_category_mappings.map((mapping: any) => ({
          id: mapping.resource_categories.id,
          name: mapping.resource_categories.name,
          slug: mapping.resource_categories.slug
        }))
      : [];

    // Extract the content string from the JSON field based on language
    const content = typeof data.content === 'object' && data.content !== null
      ? (data.content[language] || data.content['en'] || '') 
      : data.content || '';
    
    // Extract title string from JSON field
    const title = typeof data.title === 'object' && data.title !== null
      ? (data.title[language] || data.title['en'] || '') 
      : data.title || '';
      
    // Extract excerpt string from JSON field
    const excerpt = typeof data.excerpt === 'object' && data.excerpt !== null
      ? (data.excerpt[language] || data.excerpt['en'] || '') 
      : data.excerpt || '';
    
    // Extract meta fields from JSON  
    const meta_title = typeof data.meta_title === 'object' && data.meta_title !== null
      ? (data.meta_title[language] || data.meta_title['en'] || '') 
      : data.meta_title || '';
      
    const meta_description = typeof data.meta_description === 'object' && data.meta_description !== null
      ? (data.meta_description[language] || data.meta_description['en'] || '') 
      : data.meta_description || '';

    console.log('Resource data processed successfully');

    return {
      id: data.id,
      title,
      slug: data.slug,
      content,
      excerpt,
      meta_title,
      meta_description,
      published_at: data.published_at,
      categories
    };
  } catch (error) {
    console.error('Error in getResourceBySlug:', error);
    throw error;
  }
};
