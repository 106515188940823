
import React from 'react';
import { Task, CreateTaskPayload } from '@/types/task';
import { TabsContent } from "@/components/ui/tabs";
import TaskList from '../TaskList';

interface TaskStatusTabContentProps {
  tabValue: string;
  filteredTasks: Task[];
  onUpdateTask: (task: any) => Promise<boolean>;
  onDeleteTask: (taskId: string) => Promise<boolean>;
  onToggleExpansion: (taskId: string) => void;
  onCreateSubtask: (task: CreateTaskPayload) => Promise<Task | null>;
  projectId: string;
}

/**
 * Component for rendering the content of a task status tab
 */
const TaskStatusTabContent: React.FC<TaskStatusTabContentProps> = ({
  tabValue,
  filteredTasks,
  onUpdateTask,
  onDeleteTask,
  onToggleExpansion,
  onCreateSubtask,
  projectId
}) => {
  return (
    <TabsContent value={tabValue} className="mt-4">
      <TaskList
        tasks={filteredTasks}
        onUpdateTask={onUpdateTask}
        onDeleteTask={onDeleteTask}
        onToggleExpansion={onToggleExpansion}
        onCreateSubtask={onCreateSubtask}
        projectId={projectId}
      />
    </TabsContent>
  );
};

export default TaskStatusTabContent;
