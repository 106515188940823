
import React from 'react';
import { Goal } from '@/types/goal';
import GoalItem from './GoalItem';
import { Loader2 } from 'lucide-react';

interface GoalsListProps {
  goals: Goal[];
  isLoading: boolean;
  projectId: string;
  onUpdateGoal: any;
  onDeleteGoal: any;
  onToggleCompletion: any;
  formatDueDate: any;
  getStatusColor: any;
}

const GoalsList: React.FC<GoalsListProps> = ({
  goals,
  isLoading,
  projectId,
  onUpdateGoal,
  onDeleteGoal,
  onToggleCompletion,
  formatDueDate,
  getStatusColor
}) => {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  if (goals.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        <p>No goals defined for this project.</p>
        <p className="text-sm">Add the first goal to start creating the project timeline.</p>
      </div>
    );
  }

  // Separate completed and incomplete goals
  const incompleteGoals = goals.filter(goal => !goal.completed);
  const completedGoals = goals.filter(goal => goal.completed);

  return (
    <div className="space-y-6">
      {incompleteGoals.length > 0 && (
        <div className="space-y-3">
          <h3 className="text-sm font-medium text-muted-foreground">To Complete</h3>
          <div className="space-y-2">
            {incompleteGoals.map(goal => (
              <GoalItem
                key={goal.id}
                goal={goal}
                projectId={projectId}
                onUpdateGoal={onUpdateGoal}
                onDeleteGoal={onDeleteGoal}
                onToggleCompletion={onToggleCompletion}
                formatDueDate={formatDueDate}
                getStatusColor={getStatusColor}
              />
            ))}
          </div>
        </div>
      )}

      {completedGoals.length > 0 && (
        <div className="space-y-3">
          <h3 className="text-sm font-medium text-muted-foreground">Completed</h3>
          <div className="space-y-2">
            {completedGoals.map(goal => (
              <GoalItem
                key={goal.id}
                goal={goal}
                projectId={projectId}
                onUpdateGoal={onUpdateGoal}
                onDeleteGoal={onDeleteGoal}
                onToggleCompletion={onToggleCompletion}
                formatDueDate={formatDueDate}
                getStatusColor={getStatusColor}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GoalsList;
