
import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, AppRole } from '@/contexts/auth';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles?: AppRole[];
}

const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-pulse text-primary">Loading...</div>
  </div>
);

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ 
  children, 
  requiredRoles = [] 
}) => {
  const { user, hasRole, isLoading } = useAuth();
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string>('');
  const authCheckedRef = useRef(false);
  
  console.log('ProtectedRoute - user:', user?.id);
  console.log('ProtectedRoute - isLoading:', isLoading);
  console.log('ProtectedRoute - location:', location.pathname);
  
  useEffect(() => {
    // Only check and update redirect state when auth is no longer loading
    // and we haven't already checked authentication
    if (!isLoading && !authCheckedRef.current) {
      authCheckedRef.current = true;
      
      if (!user) {
        console.log('User not logged in, should redirect to /auth');
        setShouldRedirect(true);
        setRedirectPath('/auth');
      } else if (requiredRoles.length > 0 && !requiredRoles.some(role => hasRole(role))) {
        console.log('User lacks required roles, should redirect to home');
        setShouldRedirect(true);
        setRedirectPath('/');
      } else {
        setShouldRedirect(false);
        setRedirectPath('');
      }
    }
  }, [user, hasRole, isLoading, requiredRoles, location.pathname]);
  
  // Show loading state while auth is being checked
  if (isLoading) {
    console.log('Auth is loading, showing LoadingFallback');
    return <LoadingFallback />;
  }

  // Perform redirection if needed
  if (shouldRedirect && redirectPath) {
    console.log(`Redirecting to ${redirectPath} from ${location.pathname}`);
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <Suspense fallback={<LoadingFallback />}>{children}</Suspense>;
};

export default ProtectedRoute;
