
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/contexts/auth';
import { Project } from '@/components/sidebar/sidebar-data';
import { toast } from 'sonner';
import { Goal } from '@/lib/utils';
import { CustomRecurrence } from '@/utils/recurrence-utils';

export function useProjectDetails(projectId: string | undefined) {
  const { user } = useAuth();
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isMountedRef = useRef(true);
  const fetchInProgressRef = useRef(false);

  // Use useMemo to stabilize the dependency
  const stableProjectId = useMemo(() => projectId, [projectId]);
  const stableUserId = useMemo(() => user?.id, [user?.id]);

  const fetchProject = useCallback(async () => {
    if (!stableProjectId) {
      if (isMountedRef.current) {
        setIsLoading(false);
        setError("Project ID is missing");
      }
      return;
    }

    if (!stableUserId) {
      console.log('User not authenticated yet, waiting...');
      return;
    }

    // Prevent concurrent fetches
    if (fetchInProgressRef.current) {
      console.log('Project fetch already in progress, skipping...');
      return;
    }

    try {
      fetchInProgressRef.current = true;
      
      if (isMountedRef.current) {
        setIsLoading(true);
        setError(null);
      }

      console.log('Fetching project with ID:', stableProjectId);
      console.log('Current user ID:', stableUserId);

      // First, get the project
      const { data: projectData, error: projectError } = await supabase
        .from('projects')
        .select('*')
        .eq('id', stableProjectId)
        .eq('user_id', stableUserId)
        .single();

      if (projectError) {
        console.error('Error fetching project:', projectError);
        throw projectError;
      }

      console.log('Fetched project data:', projectData);

      // Then, get the goals for this project
      const { data: goalsData, error: goalsError } = await supabase
        .from('project_goals')
        .select('*')
        .eq('project_id', stableProjectId)
        .eq('user_id', stableUserId)
        .order('due_date', { ascending: true });

      if (goalsError) {
        console.error('Error fetching project goals:', goalsError);
        // Continue without goals, don't throw
      }

      // Safely cast the priority to the required type
      const priority = ['asap', 'high', 'average', 'low'].includes(projectData.priority) 
        ? projectData.priority as 'asap' | 'high' | 'average' | 'low'
        : 'average';

      // Transform data to match Project interface
      const transformedProject: Project = {
        ...projectData,
        priority,
        links: Array.isArray(projectData.links) 
          ? projectData.links as { url: string; title: string }[]
          : null,
        tags: Array.isArray(projectData.tags) ? projectData.tags : null,
        goals: goalsData || [], // Add goals to the project
        custom_recurrence: projectData.custom_recurrence, // Ensure custom_recurrence is included
        ai: projectData.ai // Include the AI field
      };
      
      console.log('Transformed project with custom_recurrence:', transformedProject.custom_recurrence);
      
      if (isMountedRef.current) {
        setProject(transformedProject);
        setIsLoading(false);
        setError(null);
      }
    } catch (err: any) {
      console.error('Error fetching project:', err);
      if (isMountedRef.current) {
        setError(err.message || 'Failed to fetch project');
        setIsLoading(false);
        toast.error('Failed to load project details');
      }
    } finally {
      fetchInProgressRef.current = false;
    }
  }, [stableProjectId, stableUserId]);

  useEffect(() => {
    isMountedRef.current = true;
    
    if (stableProjectId && stableUserId) {
      console.log('Initial project fetch...');
      fetchProject();
    } else if (!stableProjectId) {
      setIsLoading(false);
    }
    
    // Set up subscription for real-time updates
    let subscription: any;
    
    if (stableProjectId) {
      const channel = supabase
        .channel(`project-updates-${stableProjectId}`)
        .on('postgres_changes', 
          { 
            event: 'UPDATE', 
            schema: 'public', 
            table: 'projects',
            filter: `id=eq.${stableProjectId}`
          }, 
          () => {
            console.log('Project updated, refreshing...');
            if (isMountedRef.current && !fetchInProgressRef.current) {
              fetchProject();
            }
          })
        .subscribe();
        
      subscription = channel;
    }
    
    return () => {
      isMountedRef.current = false;
      if (subscription) {
        supabase.removeChannel(subscription);
      }
    };
  }, [fetchProject, stableProjectId, stableUserId]);

  return {
    project,
    isLoading,
    error,
    refreshProject: fetchProject
  };
}
