
import React from 'react';
import { cn } from '@/lib/utils';
import { Reminder } from '@/types/reminder';
import { ReminderStatusBadge } from './ReminderStatusBadge';

interface ReminderHeaderProps {
  reminder: Reminder;
  isOverdue: boolean;
}

export function ReminderHeader({ reminder, isOverdue }: ReminderHeaderProps) {
  return (
    <div className="flex items-center gap-2">
      <h3 className={cn(
        "font-medium text-lg",
        reminder.status === 'completed' ? "line-through text-muted-foreground" : ""
      )}>
        {reminder.title}
      </h3>
      <ReminderStatusBadge reminder={reminder} isOverdue={isOverdue} />
    </div>
  );
}
