
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '@/components/sidebar/sidebar-data';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Edit } from 'lucide-react';
import EditProjectDialog from '@/components/projects/EditProjectDialog';
import { getCollectionName } from './utils/project-constants';
import ProjectBadges from './ProjectBadges';
import EditableDescription from './EditableDescription';
import EditableImportantInfo from './EditableImportantInfo';
import EditableAI from './EditableAI';

interface ProjectHeaderProps {
  project: Project;
  tasksCount: number;
  onProjectUpdated: () => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({ 
  project, 
  tasksCount,
  onProjectUpdated 
}) => {
  const navigate = useNavigate();
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);

  return (
    <div className="space-y-4 mt-6">
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => navigate(`/collections/${project.collection_id}`)}
        className="flex items-center gap-2 mb-2 -ml-2"
      >
        <ArrowLeft className="h-4 w-4" />
        <span>Back to {getCollectionName(project.collection_id)}</span>
      </Button>
      
      <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold">{project.title}</h1>
          <EditableDescription 
            projectId={project.id} 
            description={project.description} 
            onDescriptionUpdated={onProjectUpdated}
          />
          
          <EditableImportantInfo
            projectId={project.id}
            importantInfo={project.important_info}
            onImportantInfoUpdated={onProjectUpdated}
          />

          <EditableAI
            projectId={project.id}
            aiContent={project.ai}
            onAIUpdated={onProjectUpdated}
          />
        </div>
        
        <Button 
          variant="outline" 
          size="sm" 
          className="flex items-center gap-2 self-start"
          onClick={() => setIsEditDialogOpen(true)}
        >
          <Edit className="h-4 w-4" />
          <span>Edit Project</span>
        </Button>
      </div>
      
      <ProjectBadges 
        project={project} 
        tasksCount={tasksCount}
        onProjectUpdated={onProjectUpdated}
      />
      
      <EditProjectDialog
        project={project}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onSuccess={onProjectUpdated}
      />
    </div>
  );
};

export default ProjectHeader;
