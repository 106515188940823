
import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { Card, CardContent } from '@/components/ui/card';

const WelcomeSection = () => {
  const { user } = useAuth();
  
  // Extract first name from email if no displayName is available
  const displayName = user?.email ? user.email.split('@')[0] : '';
  const firstName = displayName.charAt(0).toUpperCase() + displayName.slice(1);

  return (
    <div className="mb-6">
      <h1 className="text-3xl font-bold mb-2 text-gradient">
        Hi, {firstName}!
      </h1>
      <p className="text-muted-foreground">
        Here's what you need to focus on.
      </p>
    </div>
  );
};

export default WelcomeSection;
