
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { CalendarIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../project-form-schema';
import { useAuth } from '@/contexts/AuthContext';
import { formatDate } from '@/utils/date-utils';

interface DueDateFieldProps {
  form: UseFormReturn<ProjectFormValues>;
}

const DueDateField: React.FC<DueDateFieldProps> = ({ form }) => {
  const { userProfile } = useAuth();
  const timezone = userProfile?.timezone || undefined;

  const formatSelectedDate = (date: Date) => {
    // Format the date using our utility with the user's timezone
    return formatDate(date.toISOString(), 
      { year: 'numeric', month: 'long', day: 'numeric' }, 
      timezone
    );
  };

  return (
    <FormField
      control={form.control}
      name="due_date"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>Due Date</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  className="w-full pl-3 text-left font-normal"
                  data-placeholder={!field.value}
                >
                  {field.value ? (
                    formatSelectedDate(field.value)
                  ) : (
                    <span className="text-muted-foreground">Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                initialFocus
                className="p-3 pointer-events-auto"
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default DueDateField;
