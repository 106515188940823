
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { MoreHorizontal, Trash } from 'lucide-react';
import { Keyword } from '@/types/keyword';

interface KeywordActionsMenuProps {
  keyword: Keyword;
  onDelete?: (id: string) => void;
}

const KeywordActionsMenu: React.FC<KeywordActionsMenuProps> = ({ 
  keyword,
  onDelete 
}) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete(keyword.id);
    }
  };

  const hasResource = !!keyword.resource_id;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {!hasResource && onDelete && (
          <DropdownMenuItem 
            className="text-destructive"
            onClick={handleDelete}
          >
            <Trash className="h-4 w-4 mr-2" />
            Delete Keyword
          </DropdownMenuItem>
        )}
        
        {hasResource && (
          <DropdownMenuItem disabled className="text-muted-foreground">
            <Trash className="h-4 w-4 mr-2" />
            Cannot delete (has resource)
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default KeywordActionsMenu;
