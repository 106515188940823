
import { supabase } from '@/integrations/supabase/client';
import { ResourceStatus, ResourcePreview, convertToResourcePreview } from '@/types/resource';

export const getResources = async (status?: ResourceStatus, categoryId?: string, language = 'en'): Promise<ResourcePreview[]> => {
  console.log(`Fetching resources with status: ${status || 'all'}, categoryId: ${categoryId || 'none'}, language: ${language}`);
  
  const { data, error } = await supabase
    .rpc('get_resources_with_categories', { 
      status_filter: status, 
      category_id_filter: categoryId, 
      lang_code: language 
    });

  if (error) {
    console.error('Error fetching resources:', error);
    throw new Error(error.message);
  }
  
  console.log(`Retrieved ${data?.length || 0} resources`);
  
  // Convert data to ResourcePreview[]
  return (data || []).map(item => convertToResourcePreview(item));
};
