
import React from 'react';
import { Button } from '@/components/ui/button';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';

interface SeoBlocksAccordionProps {
  htmlSamples: Record<string, string>;
  onInsertTemplate: (template: string) => void;
}

const SeoBlocksAccordion: React.FC<SeoBlocksAccordionProps> = ({ 
  htmlSamples, 
  onInsertTemplate 
}) => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="special-blocks">
        <AccordionTrigger>SEO Blocks</AccordionTrigger>
        <AccordionContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.howTo)}
            >
              Insert How To Block
            </Button>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onInsertTemplate(htmlSamples.faq)}
            >
              Insert FAQ Block
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default SeoBlocksAccordion;
