
import React from 'react';
import { Task } from '@/types/task';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import FocusTimer from './FocusTimer';
import FocusTaskDetails from './FocusTaskDetails';
import './AudioFiles'; // Import so it's included in the build

interface FocusModeSheetProps {
  task: Task;
  isOpen: boolean;
  onClose: () => void;
}

const FocusModeSheet: React.FC<FocusModeSheetProps> = ({
  task,
  isOpen,
  onClose,
}) => {
  if (!task.estimated_minutes) return null;

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side="bottom"
        className="h-[100dvh] flex flex-col items-center justify-center gap-8 max-w-3xl mx-auto overflow-y-auto"
      >
        <SheetHeader className="text-center">
          <SheetTitle className="text-2xl">Focus Mode</SheetTitle>
        </SheetHeader>

        <FocusTimer 
          estimatedMinutes={task.estimated_minutes} 
          autoIncrement={task.auto_increment_time}
        />
        
        <div className="w-full max-w-lg mb-8">
          <FocusTaskDetails task={task} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FocusModeSheet;
