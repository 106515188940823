
import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import { Keyword } from '@/types/keyword';

interface ResourceLinkButtonProps {
  keyword: Keyword;
}

const ResourceLinkButton: React.FC<ResourceLinkButtonProps> = ({ keyword }) => {
  if (!keyword.resource_id) return null;
  
  return (
    <Button
      variant="outline"
      size="sm"
      asChild
      className="flex items-center gap-1"
    >
      <RouterLink to={`/admin/resources/${keyword.resource_id}`}>
        <Link className="h-3 w-3" />
        Resource
      </RouterLink>
    </Button>
  );
};

export default ResourceLinkButton;
