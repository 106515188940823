
import { User } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

/**
 * Core authentication service functions for sign up, sign in, and sign out
 */
export const signUpUser = async (
  email: string, 
  password: string, 
  t: (key: string) => string
): Promise<boolean> => {
  try {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) throw error;
    
    toast.success(t('auth.signupSuccess'));
    return true;
  } catch (error: any) {
    console.error('Signup error:', error);
    toast.error(t('auth.signupError'), {
      description: error.message
    });
    return false;
  }
};

export const signInUser = async (
  email: string, 
  password: string, 
  t: (key: string) => string
): Promise<boolean> => {
  try {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;
    
    toast.success(t('auth.loginSuccess'));
    return true;
  } catch (error: any) {
    console.error('Login error:', error);
    toast.error(t('auth.loginError'), {
      description: error.message
    });
    return false;
  }
};

export const signOutUser = async (t: (key: string) => string): Promise<boolean> => {
  try {
    await supabase.auth.signOut();
    toast.success(t('auth.logoutSuccess'));
    return true;
  } catch (error: any) {
    console.error('Logout error:', error);
    toast.error(t('auth.logoutError'), {
      description: error.message
    });
    return false;
  }
};
