
import React, { useState } from 'react';
import { Goal, UpdateGoalPayload } from '@/types/goal';
import { Card, CardContent } from '@/components/ui/card';
import { Check, Clock, Edit, Trash2, Calendar } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import EditGoalDialog from './EditGoalDialog';
import { format } from 'date-fns';

interface GoalItemProps {
  goal: Goal;
  projectId: string;
  onUpdateGoal: (goalData: UpdateGoalPayload) => Promise<boolean>;
  onDeleteGoal: (goalId: string) => Promise<boolean>;
  onToggleCompletion: (goalId: string, completed: boolean) => Promise<boolean>;
  formatDueDate: (dueDate: string | null) => string;
  getStatusColor: (dueDate: string | null, completed: boolean) => string;
}

const GoalItem: React.FC<GoalItemProps> = ({
  goal,
  projectId,
  onUpdateGoal,
  onDeleteGoal,
  onToggleCompletion,
  formatDueDate,
  getStatusColor
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleToggleCompletion = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await onToggleCompletion(goal.id, goal.completed);
  };

  const statusColor = getStatusColor(goal.due_date, goal.completed);
  
  return (
    <>
      <Card className={`relative overflow-hidden ${goal.completed ? 'bg-muted/30' : ''}`}>
        {goal.completed && (
          <div className="absolute top-0 right-0 bg-green-500 text-white text-xs px-2 py-0.5 rounded-bl-md">
            Completed
          </div>
        )}
        
        <CardContent className="p-4">
          <div className="flex items-start gap-3">
            <Checkbox 
              checked={goal.completed} 
              onCheckedChange={() => onToggleCompletion(goal.id, goal.completed)}
              className="mt-1"
            />
            
            <div className="flex-1 space-y-1.5">
              <div className="flex items-start justify-between">
                <h3 className={`font-medium ${goal.completed ? 'line-through text-muted-foreground' : ''}`}>
                  {goal.title}
                </h3>
              </div>
              
              {goal.description && (
                <p className={`text-sm text-muted-foreground ${goal.completed ? 'line-through' : ''}`}>
                  {goal.description}
                </p>
              )}
              
              {goal.due_date && (
                <div className={`flex items-center gap-1.5 text-xs ${statusColor}`}>
                  <Calendar className="h-3.5 w-3.5" />
                  <span>
                    {format(new Date(goal.due_date), 'PPP')}
                    {" - "}
                    {formatDueDate(goal.due_date)}
                  </span>
                </div>
              )}
            </div>
            
            <div className="flex gap-1 items-center">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => setIsEditDialogOpen(true)}
              >
                <Edit className="h-4 w-4" />
                <span className="sr-only">Edit</span>
              </Button>
              
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-destructive hover:text-destructive/80"
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                <Trash2 className="h-4 w-4" />
                <span className="sr-only">Delete</span>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <EditGoalDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        goal={goal}
        projectId={projectId}
        onUpdateGoal={onUpdateGoal}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this goal?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. The goal will be permanently deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={async () => {
                await onDeleteGoal(goal.id);
                setIsDeleteDialogOpen(false);
              }}
              className="bg-destructive hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default GoalItem;
