
import { RefObject, useRef, useEffect, useState } from 'react';
import { TimeOfDay } from '@/types/habit';
import { getCurrentTimeOfDay } from '@/utils/time-of-day-utils';

export function useTimeOfDayNavigation(isLoading: boolean, habitsCount: number) {
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<TimeOfDay>(getCurrentTimeOfDay());
  const [currentTimeOfDay, setCurrentTimeOfDay] = useState<TimeOfDay>(getCurrentTimeOfDay());
  
  // Create refs for each time of day section
  const timeOfDaySectionRefs = useRef<Record<TimeOfDay, RefObject<HTMLDivElement>>>({
    morning: useRef<HTMLDivElement>(null),
    afternoon: useRef<HTMLDivElement>(null),
    evening: useRef<HTMLDivElement>(null),
    night: useRef<HTMLDivElement>(null)
  });

  // Update current time of day periodically and scroll to current section
  useEffect(() => {
    setSelectedTimeOfDay(getCurrentTimeOfDay());
    setCurrentTimeOfDay(getCurrentTimeOfDay());
    
    // Scroll to current time of day section once data is loaded
    if (!isLoading && habitsCount > 0) {
      const currentSection = timeOfDaySectionRefs.current[currentTimeOfDay].current;
      if (currentSection) {
        // Use a small timeout to ensure the section is rendered
        setTimeout(() => {
          currentSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
    
    // Update current time of day every minute
    const interval = setInterval(() => {
      const newTimeOfDay = getCurrentTimeOfDay();
      // Only scroll if time of day changed
      if (newTimeOfDay !== currentTimeOfDay) {
        setCurrentTimeOfDay(newTimeOfDay);
        const newSection = timeOfDaySectionRefs.current[newTimeOfDay].current;
        if (newSection) {
          newSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 60000);
    
    return () => clearInterval(interval);
  }, [isLoading, habitsCount, currentTimeOfDay]);

  return {
    timeOfDaySectionRefs,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
    currentTimeOfDay
  };
}
