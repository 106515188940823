
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { 
  Form, FormField, FormItem, FormLabel, FormControl, FormDescription
} from '@/components/ui/form';
import { CreateHabitPayload, TimeOfDay } from '@/types/habit';
import { getTimeRangeLabel } from '@/utils/time-of-day-utils';
import LinksField from '@/components/projects/form-fields/LinksField';
import HabitTagsField from './form-fields/TagsField';
import DaysOfWeekField from './form-fields/DaysOfWeekField';
import { Switch } from '@/components/ui/switch';

/**
 * Props for the HabitForm component
 */
interface HabitFormProps {
  /** Callback function when form is submitted with valid data */
  onSubmit: (data: CreateHabitPayload) => Promise<void>;
  /** Optional initial values for form fields */
  defaultValues?: Partial<CreateHabitPayload & { auto_increment_time?: boolean }>;
}

/** Available time of day options for habits */
const timeOfDayOptions: TimeOfDay[] = ['morning', 'afternoon', 'evening', 'night'];

/**
 * Form component for creating or editing habits.
 * 
 * Features:
 * - All fields needed to create or update a habit
 * - Special handling for time input (minutes/seconds for auto-increment)
 * - Support for tags, links, and days of week selection
 * - Auto-increment time toggle with visual feedback
 * 
 * @param onSubmit - Function called with form data when submitted
 * @param defaultValues - Pre-populated values for editing an existing habit
 */
export function HabitForm({ onSubmit, defaultValues }: HabitFormProps) {
  const form = useForm<CreateHabitPayload & { auto_increment_time?: boolean }>({
    defaultValues: {
      title: '',
      description: '',
      estimated_minutes: undefined,
      time_of_day: 'morning',
      tags: [],
      days_of_week: [],
      auto_increment_time: false,
      ...defaultValues
    }
  });

  const watchEstimatedMinutes = form.watch('estimated_minutes');
  const watchAutoIncrement = form.watch('auto_increment_time');
  
  // State for minutes and seconds when auto-increment is enabled
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  
  /**
   * Synchronize the minutes/seconds fields with the form value
   * when estimated_minutes changes
   */
  useEffect(() => {
    if (watchEstimatedMinutes !== undefined) {
      const mins = Math.floor(watchEstimatedMinutes);
      const secs = Math.round((watchEstimatedMinutes - mins) * 60);
      setMinutes(mins);
      setSeconds(secs);
    }
  }, [watchEstimatedMinutes]);
  
  /**
   * Updates the form's estimated_minutes value from the
   * separate minutes and seconds inputs
   */
  const updateEstimatedMinutes = () => {
    const totalMinutes = minutes + (seconds / 60);
    form.setValue('estimated_minutes', totalMinutes);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {/* Title field */}
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter habit title" />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Description field */}
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description (Optional)</FormLabel>
              <FormControl>
                <Textarea {...field} placeholder="Enter habit description" />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Time input fields - different UI based on auto-increment setting */}
        {watchAutoIncrement ? (
          <div className="space-y-4">
            <FormLabel>Estimated Time (Minutes:Seconds)</FormLabel>
            <div className="flex gap-2 items-center">
              <Input 
                type="number" 
                value={minutes}
                onChange={(e) => {
                  setMinutes(parseInt(e.target.value) || 0);
                  updateEstimatedMinutes();
                }}
                placeholder="Minutes" 
                min="0"
                className="w-full"
                aria-label="Minutes"
              />
              <span>:</span>
              <Input 
                type="number" 
                value={seconds}
                onChange={(e) => {
                  setSeconds(parseInt(e.target.value) || 0);
                  updateEstimatedMinutes();
                }}
                placeholder="Seconds" 
                min="0"
                max="59"
                className="w-full"
                aria-label="Seconds"
              />
            </div>
            <FormDescription>
              Total time: {minutes} min {seconds} sec ({(minutes + seconds/60).toFixed(2)} minutes)
            </FormDescription>
          </div>
        ) : (
          <FormField
            control={form.control}
            name="estimated_minutes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Estimated Minutes (Optional)</FormLabel>
                <FormControl>
                  <Input 
                    type="number" 
                    {...field} 
                    onChange={e => field.onChange(e.target.value ? parseInt(e.target.value) : undefined)}
                    placeholder="Enter estimated minutes" 
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        {/* Auto-increment toggle - only shown when time is set */}
        {watchEstimatedMinutes && (
          <FormField
            control={form.control}
            name="auto_increment_time"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                <div className="space-y-0.5">
                  <FormLabel>Auto-increment Time</FormLabel>
                  <FormDescription>
                    Automatically increase time by 1% each time you complete this habit
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        {/* Time of day selector */}
        <FormField
          control={form.control}
          name="time_of_day"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Time of Day</FormLabel>
              <FormControl>
                <select
                  {...field}
                  className="w-full p-2 border rounded-md bg-background"
                >
                  {timeOfDayOptions.map(time => (
                    <option key={time} value={time}>
                      {time.charAt(0).toUpperCase() + time.slice(1)} ({getTimeRangeLabel(time)})
                    </option>
                  ))}
                </select>
              </FormControl>
            </FormItem>
          )}
        />

        {/* Days of week selector */}
        <DaysOfWeekField form={form} />

        {/* Tags field */}
        <HabitTagsField form={form} />

        {/* Links field */}
        <LinksField form={form as any} />

        {/* Submit button */}
        <Button type="submit" className="w-full">
          Save Habit
        </Button>
      </form>
    </Form>
  );
}
