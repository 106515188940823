
import React from 'react';
import { Reminder, CreateReminderPayload } from '@/types/reminder';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { ReminderForm } from './ReminderForm';

interface ReminderDialogsProps {
  isCreateOpen: boolean;
  setIsCreateOpen: (open: boolean) => void;
  editingReminder: Reminder | null;
  setEditingReminder: (reminder: Reminder | null) => void;
  deletingReminderId: string | null;
  setDeletingReminderId: (id: string | null) => void;
  handleCreate: (data: CreateReminderPayload) => Promise<void>;
  handleUpdate: (data: CreateReminderPayload) => Promise<void>;
  handleDelete: (id: string) => void;
}

export function ReminderDialogs({
  isCreateOpen,
  setIsCreateOpen,
  editingReminder,
  setEditingReminder,
  deletingReminderId,
  setDeletingReminderId,
  handleCreate,
  handleUpdate,
  handleDelete
}: ReminderDialogsProps) {
  return (
    <>
      {/* Create Reminder Dialog */}
      <Dialog open={isCreateOpen} onOpenChange={setIsCreateOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Reminder</DialogTitle>
          </DialogHeader>
          <ReminderForm onSubmit={handleCreate} />
        </DialogContent>
      </Dialog>

      {/* Edit Reminder Dialog */}
      <Dialog 
        open={!!editingReminder} 
        onOpenChange={(open) => !open && setEditingReminder(null)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Reminder</DialogTitle>
          </DialogHeader>
          {editingReminder && (
            <ReminderForm
              onSubmit={handleUpdate}
              defaultValues={editingReminder}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog 
        open={!!deletingReminderId} 
        onOpenChange={(open) => !open && setDeletingReminderId(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Reminder</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this reminder? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={() => {
                if (deletingReminderId) {
                  handleDelete(deletingReminderId);
                  setDeletingReminderId(null);
                }
              }}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
