import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/integrations/supabase/client';
import { useAuth, AppRole } from '@/contexts/AuthContext';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useDocumentTitle } from '@/hooks/use-document-title';

interface UserWithRoles {
  id: string;
  email: string;
  roles: AppRole[];
}

const Admin: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserWithRoles[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  useDocumentTitle('Admin Dashboard');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // Get all profiles
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, email');
      
      if (profilesError) throw profilesError;
      
      // Get roles for each profile
      const usersWithRoles = await Promise.all(
        profiles.map(async (profile) => {
          const { data: roles, error: rolesError } = await supabase
            .from('user_roles')
            .select('role')
            .eq('user_id', profile.id);
          
          if (rolesError) throw rolesError;
          
          return {
            id: profile.id,
            email: profile.email,
            roles: roles.map(r => r.role) as AppRole[]
          };
        })
      );
      
      setUsers(usersWithRoles);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({
        title: 'Error fetching users',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleRole = async (userId: string, role: AppRole, hasRole: boolean) => {
    try {
      if (hasRole) {
        // Remove role
        const { error } = await supabase
          .from('user_roles')
          .delete()
          .eq('user_id', userId)
          .eq('role', role);
        
        if (error) throw error;
      } else {
        // Add role
        const { error } = await supabase
          .from('user_roles')
          .insert({ user_id: userId, role });
        
        if (error) throw error;
      }
      
      // Refresh users
      await fetchUsers();
      
      toast({
        title: `Role ${hasRole ? 'removed' : 'added'} successfully`,
      });
    } catch (error) {
      console.error('Error toggling role:', error);
      toast({
        title: 'Error updating role',
        variant: 'destructive',
      });
    }
  };

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container max-w-4xl mx-auto pt-32 pb-16 px-4">
          <Card className="mb-8">
            <CardHeader>
              <CardTitle>Admin Dashboard</CardTitle>
              <CardDescription>Manage users and their roles</CardDescription>
            </CardHeader>
            <CardContent>
              {loading ? (
                <div className="text-center py-4">Loading users...</div>
              ) : (
                <div className="space-y-6">
                  {users.map((user) => (
                    <div 
                      key={user.id} 
                      className="p-4 border rounded-lg bg-card/50"
                    >
                      <div className="font-medium">{user.email}</div>
                      <div className="text-sm text-muted-foreground mb-3">
                        {user.id}
                      </div>
                      <div className="flex flex-wrap gap-2 mb-3">
                        {user.roles.map((role) => (
                          <Badge key={role}>{t(`roles.${role}`)}</Badge>
                        ))}
                      </div>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {(['admin', 'premium', 'user'] as AppRole[]).map((role) => {
                          const hasRole = user.roles.includes(role);
                          return (
                            <Button
                              key={role}
                              size="sm"
                              variant={hasRole ? 'default' : 'outline'}
                              onClick={() => toggleRole(user.id, role, hasRole)}
                            >
                              {hasRole ? `Remove ${t(`roles.${role}`)}` : `Add ${t(`roles.${role}`)}`}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default Admin;
