
import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { addResourceReview, hasUserReviewed } from '@/services/resources/review-service';

interface ResourceRatingProps {
  resourceId: string;
  averageRating: number;
  ratingCount: number;
  onRatingUpdated?: () => void;
}

const ResourceRating: React.FC<ResourceRatingProps> = ({ 
  resourceId, 
  averageRating, 
  ratingCount,
  onRatingUpdated
}) => {
  const [hoverRating, setHoverRating] = useState(0);
  const [userRated, setUserRated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState(true);
  const { toast } = useToast();

  // Check if user has already reviewed this specific resource
  useEffect(() => {
    const checkUserReview = async () => {
      if (!resourceId) return;
      
      setIsCheckingStatus(true);
      try {
        const hasReviewed = await hasUserReviewed(resourceId);
        console.log('Has user reviewed this resource?', resourceId, hasReviewed);
        setUserRated(hasReviewed);
      } catch (error) {
        console.error('Error checking user review status:', error);
      } finally {
        setIsCheckingStatus(false);
      }
    };
    
    checkUserReview();
  }, [resourceId]);

  const handleRatingClick = async (rating: number) => {
    if (userRated || isSubmitting || isCheckingStatus) return;
    
    setIsSubmitting(true);
    try {
      const success = await addResourceReview(resourceId, rating);
      
      if (success) {
        setUserRated(true);
        toast({
          title: "Thank you for your rating!",
          description: "Your feedback helps others discover quality resources.",
        });
        if (onRatingUpdated) {
          onRatingUpdated();
        }
      } else {
        toast({
          title: "Rating not submitted",
          description: "You've already rated this resource or an error occurred.",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
      toast({
        title: "Error submitting rating",
        description: "Something went wrong. Please try again later.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-1 mb-1">
        {[...Array(5)].map((_, i) => (
          <Star 
            key={i} 
            className={`h-5 w-5 cursor-pointer transition-all ${!userRated && !isCheckingStatus ? 'hover:scale-110' : ''}`}
            fill={(i < Math.round(averageRating) || i < hoverRating) ? "currentColor" : "none"}
            color={(i < Math.round(averageRating) || i < hoverRating) ? "rgb(234 179 8)" : "currentColor"}
            onMouseEnter={() => !userRated && !isCheckingStatus && setHoverRating(i + 1)}
            onMouseLeave={() => !userRated && !isCheckingStatus && setHoverRating(0)}
            onClick={() => handleRatingClick(i + 1)}
          />
        ))}
        <span className="text-sm text-muted-foreground ml-2">
          ({ratingCount} {ratingCount === 1 ? 'review' : 'reviews'})
        </span>
      </div>
      {isCheckingStatus ? (
        <p className="text-xs text-muted-foreground">Checking status...</p>
      ) : !userRated ? (
        <p className="text-xs text-muted-foreground">Click to rate this resource (one rating per visitor)</p>
      ) : (
        <p className="text-xs text-muted-foreground">Thanks for your review!</p>
      )}
    </div>
  );
};

export default ResourceRating;
