
import { CustomRecurrenceSettings, DayOfWeekOption, PeriodOption, RecurrenceOption } from '../recurrence-types';
import { TranslationFunction, isValidCustomRecurrence } from './recurrence-types';
import { Json } from '@/integrations/supabase/types';

export const getCustomRecurrenceLabel = (
  customSettings: CustomRecurrenceSettings | Json | any,
  dayOfWeekOptions: DayOfWeekOption[],
  periodOptions: PeriodOption[],
  t: TranslationFunction
): string => {
  // Make sure customSettings is valid
  if (!isValidCustomRecurrence(customSettings)) {
    return t('projects.recurrence.custom', 'Custom');
  }

  const { every, period, day_of_week } = customSettings;
  console.log('Getting label for custom settings:', JSON.stringify(customSettings));
  
  if (period === 'weeks' && day_of_week) {
    const dayLabel = dayOfWeekOptions.find(d => d.value === day_of_week)?.label || day_of_week;
    return `${t('projects.recurrence.customEvery', 'Every')} ${every} ${every === 1 ? t('projects.recurrence.week', 'week') : t('projects.recurrence.weeks', 'weeks')} ${t('projects.recurrence.on', 'on')} ${dayLabel}`;
  }
  
  const periodLabel = periodOptions.find(p => p.value === period)?.label.toLowerCase() || period;
  return `${t('projects.recurrence.customEvery', 'Every')} ${every} ${periodLabel}`;
};

export const getRecurrenceLabel = (
  recurrence: string | null,
  customSettings: CustomRecurrenceSettings | Json | any,
  recurrenceOptions: RecurrenceOption[],
  dayOfWeekOptions: DayOfWeekOption[],
  periodOptions: PeriodOption[],
  t: TranslationFunction
): string => {
  if (recurrence === 'custom' && isValidCustomRecurrence(customSettings)) {
    return getCustomRecurrenceLabel(customSettings, dayOfWeekOptions, periodOptions, t);
  }
  
  return recurrenceOptions.find(option => option.value === recurrence)?.label || recurrence || '';
};
