
import { useQuery } from '@tanstack/react-query';
import { getKeywords } from '@/services/resources/keyword-service';

export const useKeywordsQuery = () => {
  return useQuery({
    queryKey: ['keywords'],
    queryFn: getKeywords,
  });
};
