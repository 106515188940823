
import React from 'react';
import { CustomBadge } from '../CustomBadge';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from '@/components/ui/dropdown-menu';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface StatusDropdownProps {
  status: string;
  projectId: string;
  onProjectUpdated: () => void;
}

const statusLabels: Record<string, string> = {
  'active': 'Active',
  'in_progress': 'In Progress',
  'queue': 'Queue',
  'blocked': 'Blocked',
  'completed': 'Completed'
};

const statusColors: Record<string, string> = {
  'active': 'bg-green-500 text-white dark:bg-green-600',
  'in_progress': 'bg-blue-500 text-white dark:bg-blue-600',
  'queue': 'bg-orange-500 text-white dark:bg-orange-600',
  'blocked': 'bg-red-700 text-white dark:bg-red-800',
  'completed': 'bg-gray-500 text-white dark:bg-gray-600'
};

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'queue', label: 'Queue' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'completed', label: 'Completed' }
];

const StatusDropdown: React.FC<StatusDropdownProps> = ({ 
  status, 
  projectId,
  onProjectUpdated 
}) => {
  const [updating, setUpdating] = React.useState(false);

  const updateStatus = async (value: string) => {
    if (updating) return;
    
    setUpdating(true);
    try {
      const { error } = await supabase
        .from('projects')
        .update({ status: value })
        .eq('id', projectId);

      if (error) {
        throw error;
      }

      toast.success(`Project status updated`);
      onProjectUpdated();
    } catch (error: any) {
      console.error(`Error updating project status:`, error);
      toast.error(`Failed to update project status`);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <CustomBadge 
          variant="custom" 
          className={`cursor-pointer hover:opacity-90 transition-colors interactive-element ${statusColors[status as keyof typeof statusColors]}`}
        >
          {statusLabels[status as keyof typeof statusLabels]}
        </CustomBadge>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="z-50 bg-popover shadow-lg">
        {statusOptions.map(option => (
          <DropdownMenuItem 
            key={option.value}
            onClick={() => updateStatus(option.value)}
            className={`${statusColors[option.value as keyof typeof statusColors]} my-1 rounded`}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatusDropdown;
