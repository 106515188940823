
import React from 'react';
import { Badge } from '@/components/ui/badge';

interface Category {
  id: string;
  name: string;
}

interface ResourceCategoriesListProps {
  categories: Category[];
}

const ResourceCategoriesList: React.FC<ResourceCategoriesListProps> = ({ categories }) => {
  if (categories.length === 0) {
    return <span className="text-muted-foreground text-xs">-</span>;
  }

  return (
    <div className="flex flex-wrap gap-1">
      {categories.map((category) => (
        <Badge key={category.id} variant="outline" className="text-xs">
          {category.name}
        </Badge>
      ))}
    </div>
  );
};

export default ResourceCategoriesList;
