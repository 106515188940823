
import React from 'react';
import { PanelLeft } from 'lucide-react';
import { 
  Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, 
  SidebarGroupLabel, SidebarMenu, SidebarTrigger, SidebarRail,
  useSidebar, SidebarFooter, SidebarSeparator
} from '@/components/ui/sidebar';
import { useAuth } from '@/contexts/AuthContext';
import { ProjectsMenu } from './menus/ProjectsMenu';
import { ResourcesMenu } from './menus/ResourcesMenu';
import { AdminMenu } from './menus/AdminMenu';
import { HabitsMenu } from './menus/HabitsMenu';
import { RemindersMenu } from './menus/RemindersMenu';
import { PromptsMenu } from './menus/PromptsMenu';
import DateDisplay from '../navbar/DateDisplay';

export function GlobalSidebar() {
  const { state } = useSidebar();
  const { hasRole, user } = useAuth();
  
  return (
    <Sidebar 
      collapsible="icon"
      className="border-r border-border/50 h-[calc(100vh-var(--navbar-height))] w-[var(--sidebar-width)] sticky top-[var(--navbar-height)]"
    >
      <SidebarContent className="pt-4 bg-sidebar dark:bg-neutral-950">
        <SidebarGroup>
          <div className="flex items-center justify-between pr-2">
            <SidebarGroupLabel>Navigation</SidebarGroupLabel>
            <SidebarTrigger 
              className="h-6 w-6 shrink-0 text-sidebar-foreground/50 hover:text-sidebar-foreground transition-colors"
            >
              <PanelLeft className="h-4 w-4" />
            </SidebarTrigger>
          </div>
          <SidebarGroupContent>
            <SidebarMenu>
              <ProjectsMenu />
              <HabitsMenu />
              <RemindersMenu />
              <PromptsMenu />
              <ResourcesMenu />
              <AdminMenu hasAdminRole={hasRole('admin')} />
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      
      {/* Global Navigation Bar Footer */}
      <SidebarFooter className="border-t border-border/30 p-4">
        <DateDisplay />
      </SidebarFooter>
      
      <SidebarRail />
    </Sidebar>
  );
}
