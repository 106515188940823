
import React, { useState, useRef, useEffect } from 'react';
import { Input } from '@/components/ui/input';

interface HabitTitleProps {
  title: string;
  isCompleted: boolean;
  habitId: string;
  onEditTitle: (newTitle: string) => void;
}

export function HabitTitle({ title, isCompleted, habitId, onEditTitle }: HabitTitleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const titleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && titleInputRef.current) {
      titleInputRef.current.focus();
      titleInputRef.current.select();
    }
  }, [isEditing]);

  const handleSubmit = () => {
    onEditTitle(editedTitle);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Escape') {
      setEditedTitle(title);
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Input
        ref={titleInputRef}
        value={editedTitle}
        onChange={(e) => setEditedTitle(e.target.value)}
        onBlur={handleSubmit}
        onKeyDown={handleKeyDown}
        className="font-medium h-7 py-0"
      />
    );
  }

  return (
    <label 
      htmlFor={`habit-${habitId}`} 
      className={`font-medium cursor-pointer ${isCompleted ? 'line-through text-muted-foreground' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        setIsEditing(true);
      }}
    >
      {title}
    </label>
  );
}
