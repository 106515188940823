
import { supabase } from '@/integrations/supabase/client';
import { CreatePromptPayload, UpdatePromptPayload } from '@/types/prompt';

export async function createPrompt(prompt: CreatePromptPayload): Promise<{ id: string }> {
  const { data, error } = await supabase
    .from('prompts')
    .insert(prompt) // Pass the single object, not wrapped in an array
    .select('id')
    .single();

  if (error) {
    console.error('Error creating prompt:', error);
    throw error;
  }

  return { id: data.id };
}

export async function updatePrompt(prompt: UpdatePromptPayload): Promise<void> {
  const { error } = await supabase
    .from('prompts')
    .update({
      title: prompt.title,
      content: prompt.content,
      tags: prompt.tags
    })
    .eq('id', prompt.id);

  if (error) {
    console.error('Error updating prompt:', error);
    throw error;
  }
}

export async function deletePrompt(promptId: string): Promise<void> {
  const { error } = await supabase
    .from('prompts')
    .delete()
    .eq('id', promptId);

  if (error) {
    console.error('Error deleting prompt:', error);
    throw error;
  }
}
