
import React, { useEffect } from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import AdminPageHeader from '@/components/admin/common/AdminPageHeader';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useMetadata } from '@/hooks/use-metadata';
import UsersTable from '@/components/admin/users/UsersTable';
import { useUsers } from '@/hooks/admin/use-users';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { toast } from 'sonner';

const AdminUsers: React.FC = () => {
  const { t } = useTranslation();
  const { 
    users, 
    isLoading, 
    error, 
    handleRemoveRole, 
    handleAddRole,
    handleUpdateUserProfile,
    handleDeleteUser,
    refreshUsers 
  } = useUsers();
  
  useMetadata({
    title: 'User Management',
    description: 'Manage users, permissions, and roles.',
  });
  
  // Handle error with toast notification
  useEffect(() => {
    if (error) {
      toast.error('Error loading users', {
        description: error
      });
    }
  }, [error]);
  
  // Initial load of users
  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);
  
  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <AdminPageHeader 
            title={t('admin.userManagement')}
            description={t('admin.userDescription')}
            backRoute="/admin"
          />
          
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>{t('admin.userManagement')}</CardTitle>
              <CardDescription>
                View and manage user roles and permissions.
              </CardDescription>
            </CardHeader>
            <CardContent>
              {error ? (
                <Alert variant="destructive" className="mb-6">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              ) : null}
              
              {isLoading && !users.length ? (
                <div className="text-center py-8">
                  <p>{t('common.loading')}</p>
                </div>
              ) : (
                <UsersTable 
                  users={users} 
                  onRemoveRole={handleRemoveRole}
                  onAddRole={handleAddRole}
                  onUpdateProfile={handleUpdateUserProfile}
                  onDeleteUser={handleDeleteUser}
                  onRefresh={refreshUsers}
                  isLoading={isLoading} 
                />
              )}
            </CardContent>
          </Card>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminUsers;
