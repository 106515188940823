
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { AppRole, UserProfile } from '@/contexts/auth/types';
import UserProfileEditor from '../UserProfileEditor';

interface UserWithRoles {
  id: string;
  email: string;
  profile: UserProfile | null;
  roles: AppRole[];
}

interface UserProfileDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedUser: UserWithRoles | null;
  onUpdateProfile: (userId: string, data: Partial<UserProfile>) => Promise<void>;
}

const UserProfileDialog: React.FC<UserProfileDialogProps> = ({
  isOpen,
  onOpenChange,
  selectedUser,
  onUpdateProfile
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit User Profile</DialogTitle>
        </DialogHeader>
        {selectedUser && (
          <UserProfileEditor
            user={selectedUser}
            onUpdateProfile={onUpdateProfile}
            onClose={() => onOpenChange(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileDialog;
