
// Constants for project collections, priorities, and statuses

export const projectCollections = [
  { id: 'career', name: 'Career' },
  { id: 'family', name: 'Family' },
  { id: 'friends-social', name: 'Friends & Social Life' },
  { id: 'finances', name: 'Finances' },
  { id: 'love-relationship', name: 'Love & Relationship' },
  { id: 'health-fitness', name: 'Health & Fitness' },
  { id: 'personal-development', name: 'Personal Development' },
  { id: 'fun-recreation', name: 'Fun & Recreation' }
];

export const getCollectionName = (collectionId: string) => {
  return projectCollections.find(c => c.id === collectionId)?.name || collectionId;
};

export const priorityColors = {
  asap: 'bg-red-500 hover:bg-red-600',
  high: 'bg-orange-500 hover:bg-orange-600',
  average: 'bg-blue-500 hover:bg-blue-600',
  low: 'bg-green-500 hover:bg-green-600'
};

export const statusLabels = {
  active: 'Active',
  in_progress: 'In Progress',
  queue: 'In Queue',
  completed: 'Completed',
  blocked: 'Blocked'
};

export const priorityOptions = [
  { value: 'asap', label: 'ASAP' },
  { value: 'high', label: 'High' },
  { value: 'average', label: 'Average' },
  { value: 'low', label: 'Low' }
];

export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'queue', label: 'In Queue' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'completed', label: 'Completed' }
];
