
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { 
  Task, 
  UpdateTaskPayload,
  convertTaskToSupabase 
} from '@/types/task';
import { 
  shouldTaskRecur, 
  calculateNextDueDate
} from '@/utils/recurrence';

// Helper function to find a task in the hierarchy
const findTaskInHierarchy = (tasks: Task[], taskId: string): Task | undefined => {
  // First check at the current level
  const taskAtCurrentLevel = tasks.find(t => t.id === taskId);
  if (taskAtCurrentLevel) return taskAtCurrentLevel;
  
  // If not found, search in subtasks recursively
  for (const task of tasks) {
    if (task.subtasks && task.subtasks.length > 0) {
      const foundInSubtasks = findTaskInHierarchy(task.subtasks, taskId);
      if (foundInSubtasks) return foundInSubtasks;
    }
  }
  
  return undefined;
};

export async function updateTaskOperation(
  taskData: UpdateTaskPayload,
  tasks: Task[],
  userId: string | undefined,
  projectId: string | undefined,
): Promise<boolean> {
  if (!userId || !projectId) {
    toast.error('You must be logged in to update a task');
    return false;
  }

  try {
    // Get the existing task for comparison using the recursive function
    const existingTask = findTaskInHierarchy(tasks, taskData.id);
    if (!existingTask) {
      throw new Error('Task not found');
    }
    
    // Check if task is being marked as completed and has recurrence
    const isBeingCompleted = 
      taskData.status === 'completed' && 
      existingTask.status !== 'completed';
    
    // Prepare update data
    let updateData = convertTaskToSupabase(taskData);
    
    // If task was completed and has recurrence settings, update its due date
    if (isBeingCompleted && existingTask.recurrence) {
      // Update task with the latest changes for recurrence calculations
      const updatedTask = {
        ...existingTask,
        ...taskData,
      };
      
      if (shouldTaskRecur(updatedTask) && updatedTask.due_date) {
        try {
          // Calculate next due date
          const currentDueDate = new Date(updatedTask.due_date);
          
          // Type assertion to handle recurrence type safety
          const recurrenceValue = updatedTask.recurrence as 'none' | 'daily' | 'weekly' | 'monthly' | null;
          
          const nextDueDate = calculateNextDueDate(
            recurrenceValue,
            currentDueDate,
            updatedTask.custom_recurrence
          );
          
          // IMPORTANT CHANGE: Instead of creating a new task, 
          // update the current one with the new due date and maintain its original status
          updateData = {
            ...updateData,
            due_date: nextDueDate.toISOString(),
            status: existingTask.status // Preserve the original status
          };
            
          toast.info('Task updated with next occurrence date', {
            description: `Due on ${nextDueDate.toLocaleDateString()}`
          });
        } catch (err: any) {
          console.error('Error updating recurring task:', err);
          toast.error('Failed to update recurring task');
        }
      }
    }
    
    // Update the task with all changes
    const { error } = await supabase
      .from('tasks')
      .update(updateData)
      .eq('id', taskData.id)
      .eq('user_id', userId);

    if (error) throw error;
    
    return true;
  } catch (err: any) {
    console.error('Error updating task:', err);
    toast.error(err.message || 'Failed to update task');
    return false;
  }
}
