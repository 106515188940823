
import { Task } from '@/types/task';

/**
 * Organizes flat tasks list into a hierarchical structure
 */
export const organizeTasksHierarchy = (flatTasks: Task[]): Task[] => {
  const taskMap = new Map<string, Task>();
  const rootTasks: Task[] = [];
  
  // First pass: Create a map of all tasks
  flatTasks.forEach(task => {
    taskMap.set(task.id, { 
      ...task, 
      subtasks: [],
      isExpanded: true 
    });
  });
  
  // Second pass: Build hierarchy
  flatTasks.forEach(task => {
    const taskWithSubtasks = taskMap.get(task.id)!;
    
    if (task.parent_task_id && taskMap.has(task.parent_task_id)) {
      // This is a subtask, add it to its parent
      const parentTask = taskMap.get(task.parent_task_id)!;
      parentTask.subtasks!.push(taskWithSubtasks);
      
      // Sort subtasks by position
      parentTask.subtasks!.sort((a, b) => a.position - b.position);
    } else {
      // This is a root task
      rootTasks.push(taskWithSubtasks);
    }
  });
  
  // Sort root tasks by position
  return rootTasks.sort((a, b) => a.position - b.position);
};

/**
 * Gets all subtask IDs for a given task ID recursively
 */
export const getAllSubtaskIds = (tasks: Task[], taskId: string): string[] => {
  const findTask = (tasks: Task[], id: string): Task | undefined => {
    for (const task of tasks) {
      if (task.id === id) return task;
      if (task.subtasks?.length) {
        const found = findTask(task.subtasks, id);
        if (found) return found;
      }
    }
    return undefined;
  };

  const collectIds = (task: Task): string[] => {
    let ids: string[] = [];
    if (task.subtasks?.length) {
      for (const subtask of task.subtasks) {
        ids.push(subtask.id);
        ids = [...ids, ...collectIds(subtask)];
      }
    }
    return ids;
  };

  const task = findTask(tasks, taskId);
  return task ? collectIds(task) : [];
};

/**
 * Toggle expansion state for a task in the task tree
 */
export const toggleTaskExpansion = (tasks: Task[], taskId: string): Task[] => {
  return tasks.map(task => {
    if (task.id === taskId) {
      return { ...task, isExpanded: !task.isExpanded };
    }
    if (task.subtasks?.length) {
      return { ...task, subtasks: toggleTaskExpansion(task.subtasks, taskId) };
    }
    return task;
  });
};
