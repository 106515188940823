
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSidebarHabits() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHabitsOpen, setIsHabitsOpen] = useState(false);
  
  const isHabitsActive = location.pathname.startsWith('/habits');
  
  const toggleHabitsOpen = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setIsHabitsOpen(!isHabitsOpen);
  };

  const navigateToHabits = () => {
    navigate('/habits');
    setIsHabitsOpen(true);
  };

  return {
    isHabitsOpen,
    setIsHabitsOpen,
    isHabitsActive,
    toggleHabitsOpen,
    navigateToHabits
  };
}
