
import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import GoalForm from './GoalForm';
import { CreateGoalPayload } from '@/types/goal';

interface AddGoalButtonProps {
  projectId: string;
  onCreateGoal: (goal: CreateGoalPayload) => Promise<any>;
}

const AddGoalButton: React.FC<AddGoalButtonProps> = ({ projectId, onCreateGoal }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCreateGoal = async (goal: CreateGoalPayload) => {
    const result = await onCreateGoal(goal);
    if (result) {
      setIsOpen(false);
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button size="sm" className="flex items-center gap-1">
          <Plus className="h-4 w-4" />
          <span>New Goal</span>
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:max-w-md">
        <SheetHeader>
          <SheetTitle>Add New Goal</SheetTitle>
          <SheetDescription>
            Goals help you define key milestones for your project and create a timeline.
          </SheetDescription>
        </SheetHeader>
        <div className="mt-6">
          <GoalForm 
            projectId={projectId} 
            onSubmit={handleCreateGoal} 
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default AddGoalButton;
