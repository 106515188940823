
import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface MobileNavLinkProps {
  to: string;
  label: string;
  onClick: () => void;
  className?: string;
}

const MobileNavLink: React.FC<MobileNavLinkProps> = ({ 
  to, 
  label, 
  onClick,
  className 
}) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={cn(
        "block text-foreground py-3 px-2 text-base font-medium hover:bg-accent/40 rounded-md transition-colors active:scale-[0.98]",
        className
      )}
    >
      {label}
    </Link>
  );
};

export default MobileNavLink;
