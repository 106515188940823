
import { Json } from '@/integrations/supabase/types';

/**
 * Custom recurrence settings interface
 */
export interface CustomRecurrence {
  every: number;
  period: string;
  day_of_week?: string;
}

/**
 * Map of day names to their numerical indices (0 = Sunday, 1 = Monday, etc.)
 */
export const DAY_OF_WEEK_INDICES: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0
};

/**
 * Type guard for custom recurrence validation
 */
export const isValidCustomRecurrence = (obj: any): obj is CustomRecurrence => {
  return obj && 
    typeof obj === 'object' && 
    'every' in obj && 
    'period' in obj;
};
