
import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

const ResourcesLoadingState: React.FC = () => {
  return (
    <div className="space-y-4">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="flex items-center space-x-4 p-4 border rounded-md">
          <div className="space-y-2 flex-1">
            <Skeleton className="h-5 w-1/4" />
            <Skeleton className="h-4 w-1/2" />
          </div>
          <Skeleton className="h-10 w-20" />
        </div>
      ))}
    </div>
  );
};

export default ResourcesLoadingState;
