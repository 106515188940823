
import React from 'react';
import { Plus } from 'lucide-react';
import NewProjectDialog from './projects/NewProjectDialog';

interface NewProjectButtonProps {
  onClick?: () => void;
  collectionId?: string;
  onSuccess?: () => void;
}

const NewProjectButton: React.FC<NewProjectButtonProps> = ({ 
  onClick, 
  collectionId,
  onSuccess
}) => {
  if (onClick) {
    // Use the old button style if we have an onClick handler
    return (
      <button
        onClick={onClick}
        className="group h-full glass rounded-lg flex flex-col items-center justify-center px-6 py-12 border border-dashed border-white/10 hover:border-blue-400/50 hover:bg-white/5 transition-all focus-ring"
      >
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-500/20 group-hover:bg-blue-500/30 transition-colors mb-4">
          <Plus className="h-6 w-6 text-blue-400" />
        </div>
        <span className="font-medium text-foreground/80 group-hover:text-foreground transition-colors">
          New Project
        </span>
      </button>
    );
  }
  
  // Use the dialog if no onClick handler is provided
  return (
    <NewProjectDialog
      collectionId={collectionId}
      onSuccess={onSuccess}
      trigger={
        <button className="group h-full glass rounded-lg flex flex-col items-center justify-center px-6 py-12 border border-dashed border-white/10 hover:border-blue-400/50 hover:bg-white/5 transition-all focus-ring">
          <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-500/20 group-hover:bg-blue-500/30 transition-colors mb-4">
            <Plus className="h-6 w-6 text-blue-400" />
          </div>
          <span className="font-medium text-foreground/80 group-hover:text-foreground transition-colors">
            New Project
          </span>
        </button>
      }
    />
  );
};

export default NewProjectButton;
