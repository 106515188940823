
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ArrowRight, Database, FileCog, FileText, Shield, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useMetadata } from '@/hooks/use-metadata';
import VersionManager from '@/components/admin/version/VersionManager';

const AdminDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  useMetadata({
    title: 'Admin Dashboard',
    description: 'Manage your application settings, users, resources and more.',
  });

  return (
    <ProtectedRoute requiredRoles={['admin']}>
      <MainLayout>
        <div className="container mx-auto px-4 py-8 max-w-6xl">
          <div className="mb-8">
            <h1 className="text-3xl font-bold">{t('admin.dashboard')}</h1>
            <p className="text-muted-foreground mt-2">{t('admin.description')}</p>
          </div>

          <div className="grid gap-6 mb-8">
            <VersionManager />
          </div>

          <Card className="mb-8">
            <CardHeader>
              <CardTitle>{t('admin.modules')}</CardTitle>
              <CardDescription>{t('admin.modulesDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                <Card className="p-4 border shadow-sm hover:shadow-md transition-shadow cursor-pointer bg-card/50"
                  onClick={() => navigate('/admin/users')}>
                  <div className="flex items-center gap-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <Users className="h-6 w-6 text-primary" />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium">{t('admin.userManagement')}</h3>
                      <p className="text-sm text-muted-foreground">{t('admin.userDescription')}</p>
                    </div>
                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                  </div>
                </Card>

                <Card className="p-4 border shadow-sm hover:shadow-md transition-shadow cursor-pointer bg-card/50"
                  onClick={() => navigate('/admin/resources')}>
                  <div className="flex items-center gap-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <FileText className="h-6 w-6 text-primary" />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium">{t('admin.resources.title')}</h3>
                      <p className="text-sm text-muted-foreground">{t('admin.resources.description')}</p>
                    </div>
                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                  </div>
                </Card>

                <Card className="p-4 border shadow-sm hover:shadow-md transition-shadow cursor-pointer bg-card/50 opacity-70">
                  <div className="flex items-center gap-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <Database className="h-6 w-6 text-primary" />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium">{t('admin.dataManagement')}</h3>
                      <p className="text-sm text-muted-foreground">{t('admin.dataDescription')}</p>
                    </div>
                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                  </div>
                </Card>

                <Card className="p-4 border shadow-sm hover:shadow-md transition-shadow cursor-pointer bg-card/50 opacity-70">
                  <div className="flex items-center gap-4">
                    <div className="p-2 rounded-full bg-primary/10">
                      <FileCog className="h-6 w-6 text-primary" />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-medium">{t('admin.settings')}</h3>
                      <p className="text-sm text-muted-foreground">{t('admin.settingsDescription')}</p>
                    </div>
                    <ArrowRight className="h-5 w-5 text-muted-foreground" />
                  </div>
                </Card>
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardHeader>
              <CardTitle>{t('admin.systemStatus')}</CardTitle>
              <CardDescription>{t('admin.systemStatusDescription')}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 rounded-lg bg-card/50 border">
                  <div className="flex items-center gap-3">
                    <Shield className="h-5 w-5 text-green-500" />
                    <div>
                      <p className="font-medium">{t('admin.securityStatus')}</p>
                      <p className="text-sm text-muted-foreground">{t('admin.securityStatusGood')}</p>
                    </div>
                  </div>
                  <button className="text-sm px-3 py-1.5 border rounded-md hover:bg-muted transition-colors">
                    {t('admin.viewDetails')}
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default AdminDashboard;
