
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
import { 
  createResource, 
  updateResource, 
  deleteResource 
} from '@/services/resources';
import { Resource } from '@/types/resource';

export const useResourceMutations = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const useCreateResourceMutation = () => {
    return useMutation({
      mutationFn: (resource: Partial<Resource>) => createResource(resource as Record<string, any>),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resources'] });
        toast({
          title: 'Resource created successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error creating resource',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  const useUpdateResourceMutation = () => {
    return useMutation({
      mutationFn: ({ id, resource }: { id: string; resource: Partial<Resource> }) => 
        updateResource(id, resource as Record<string, any>),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resources'] });
        toast({
          title: 'Resource updated successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error updating resource',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  const useDeleteResourceMutation = () => {
    return useMutation({
      mutationFn: deleteResource,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resources'] });
        toast({
          title: 'Resource deleted successfully',
        });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error deleting resource',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  return {
    useCreateResourceMutation,
    useUpdateResourceMutation,
    useDeleteResourceMutation,
  };
};
