
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';

const CollectionNotFound: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <div className="text-center py-12">
      <h2 className="text-2xl font-bold">Collection not found</h2>
      <Button 
        variant="link" 
        onClick={() => navigate('/projects')}
        className="mt-4"
      >
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Projects
      </Button>
    </div>
  );
};

export default CollectionNotFound;
