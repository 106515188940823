
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { useAuth } from "@/contexts/AuthContext";
import {
  getReminders,
  getUpcomingReminders,
  getDueReminders,
  createReminder,
  updateReminder,
  completeReminder,
  snoozeReminder,
  deleteReminder
} from "@/services/reminders"; // Updated import path
import { CreateReminderPayload, UpdateReminderPayload } from "@/types/reminder";

export function useReminders() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const remindersQuery = useQuery({
    queryKey: ["reminders"],
    queryFn: getReminders,
    enabled: !!user,
  });

  const upcomingRemindersQuery = useQuery({
    queryKey: ["reminders", "upcoming"],
    queryFn: getUpcomingReminders,
    enabled: !!user,
    refetchInterval: 60000, // Refetch every minute
  });

  const dueRemindersQuery = useQuery({
    queryKey: ["reminders", "due"],
    queryFn: getDueReminders,
    enabled: !!user,
    refetchInterval: 30000, // Refetch every 30 seconds
  });

  const createReminderMutation = useMutation({
    mutationFn: (data: CreateReminderPayload) => createReminder(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reminders"] });
      toast.success("Reminder created successfully");
    },
    onError: (error: Error) => {
      toast.error("Failed to create reminder", {
        description: error.message
      });
    },
  });

  const updateReminderMutation = useMutation({
    mutationFn: (data: UpdateReminderPayload) => updateReminder(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reminders"] });
      toast.success("Reminder updated successfully");
    },
    onError: (error: Error) => {
      toast.error("Failed to update reminder", {
        description: error.message
      });
    },
  });

  const completeReminderMutation = useMutation({
    mutationFn: (id: string) => completeReminder(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reminders"] });
      toast.success("Reminder marked as completed");
    },
    onError: (error: Error) => {
      toast.error("Failed to complete reminder", {
        description: error.message
      });
    },
  });

  const snoozeReminderMutation = useMutation({
    mutationFn: ({ id, minutes }: { id: string; minutes: number }) => 
      snoozeReminder(id, minutes),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reminders"] });
      toast.success("Reminder snoozed");
    },
    onError: (error: Error) => {
      toast.error("Failed to snooze reminder", {
        description: error.message
      });
    },
  });

  const deleteReminderMutation = useMutation({
    mutationFn: (id: string) => deleteReminder(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reminders"] });
      toast.success("Reminder deleted");
    },
    onError: (error: Error) => {
      toast.error("Failed to delete reminder", {
        description: error.message
      });
    },
  });

  return {
    reminders: remindersQuery.data || [],
    upcomingReminders: upcomingRemindersQuery.data || [],
    dueReminders: dueRemindersQuery.data || [],
    isLoading: remindersQuery.isLoading,
    isError: remindersQuery.isError,
    error: remindersQuery.error,
    createReminder: createReminderMutation.mutate,
    updateReminder: updateReminderMutation.mutate,
    completeReminder: completeReminderMutation.mutate,
    snoozeReminder: snoozeReminderMutation.mutate,
    deleteReminder: deleteReminderMutation.mutate,
  };
}
