
import React from 'react';
import EditableContent from './EditableContent';

interface EditableDescriptionProps {
  projectId: string;
  description: string | null | undefined;
  onDescriptionUpdated: () => void;
}

const EditableDescription: React.FC<EditableDescriptionProps> = ({
  projectId,
  description,
  onDescriptionUpdated
}) => {
  return (
    <EditableContent
      content={description}
      projectId={projectId}
      fieldName="description"
      tableName="projects"
      placeholderText="Add a description..."
      emptyText="Add a description..."
      onContentUpdated={onDescriptionUpdated}
    />
  );
};

export default EditableDescription;
