
import React from 'react';
import { Calendar, ListChecks, BarChart2, Sparkles, Clock, CalendarClock } from 'lucide-react';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col items-start p-6 bg-card border border-border/40 rounded-xl hover:shadow-md transition-all">
      <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-lg mb-4 text-primary">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-muted-foreground">{description}</p>
    </div>
  );
};

const FeatureSection: React.FC = () => {
  const features = [
    {
      icon: <ListChecks size={24} />,
      title: "Smart Task Management",
      description: "Create, organize, and prioritize tasks with ease. Track your progress and never miss a deadline."
    },
    {
      icon: <BarChart2 size={24} />,
      title: "Insightful Analytics",
      description: "Gain valuable insights into your productivity patterns with detailed charts and statistics."
    },
    {
      icon: <Sparkles size={24} />,
      title: "AI-Powered Assistance",
      description: "Get intelligent suggestions and automation to help streamline your workflow."
    },
    {
      icon: <Calendar size={24} />,
      title: "Project Planning",
      description: "Plan and visualize your projects with intuitive tools for better time management."
    },
    {
      icon: <Clock size={24} />,
      title: "Habit Tracking",
      description: "Build and maintain positive habits with consistent tracking and reinforcement."
    },
    {
      icon: <CalendarClock size={24} />,
      title: "Smart Reminders",
      description: "Never forget important tasks with customizable reminders and notifications."
    }
  ];

  return (
    <div id="key-features-section" className="py-20 bg-secondary/30 dark:bg-secondary/10">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Key Features</h2>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Everything you need to boost your productivity and organize your projects efficiently
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
