
import { supabase } from '@/integrations/supabase/client';
import { Category, convertToCategory } from '@/types/resource';

export const createCategory = async (category: Record<string, any>): Promise<Category> => {
  // Ensure category has required fields for insert
  if (!category.slug) {
    throw new Error('Category must have a slug');
  }
  
  if (!category.name) {
    throw new Error('Category must have a name');
  }

  // Make sure category is correctly typed before inserting
  const { data, error } = await supabase
    .from('resource_categories')
    .insert({
      name: category.name || {},
      slug: category.slug,
      description: category.description || {}
    })
    .select()
    .single();

  if (error) throw new Error(error.message);
  return convertToCategory(data);
};

export const updateCategory = async (id: string, category: Record<string, any>): Promise<Category> => {
  const { data, error } = await supabase
    .from('resource_categories')
    .update(category)
    .eq('id', id)
    .select()
    .single();

  if (error) throw new Error(error.message);
  return convertToCategory(data);
};

export const deleteCategory = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('resource_categories')
    .delete()
    .eq('id', id);

  if (error) throw new Error(error.message);
};
