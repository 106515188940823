
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
import { 
  addCategoryToResource, 
  removeCategoryFromResource 
} from '@/services/resources';

export const useResourceCategoryMappings = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const useAddCategoryToResourceMutation = () => {
    return useMutation({
      mutationFn: ({ resourceId, categoryId }: { resourceId: string; categoryId: string }) => 
        addCategoryToResource(resourceId, categoryId),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resources'] });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error adding category to resource',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  const useRemoveCategoryFromResourceMutation = () => {
    return useMutation({
      mutationFn: ({ resourceId, categoryId }: { resourceId: string; categoryId: string }) => 
        removeCategoryFromResource(resourceId, categoryId),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['resources'] });
      },
      onError: (error: Error) => {
        toast({
          title: 'Error removing category from resource',
          description: error.message,
          variant: 'destructive',
        });
      },
    });
  };

  return {
    useAddCategoryToResourceMutation,
    useRemoveCategoryFromResourceMutation,
  };
};
