
import { supabase } from "@/integrations/supabase/client";
import { Reminder, CreateReminderPayload, UpdateReminderPayload } from "@/types/reminder";

/**
 * Create a new reminder
 */
export async function createReminder(payload: CreateReminderPayload): Promise<Reminder> {
  const { data, error } = await supabase
    .from('reminders')
    .insert([
      { 
        ...payload,
        status: 'pending',
        user_id: (await supabase.auth.getUser()).data.user?.id
      }
    ])
    .select()
    .single();

  if (error) {
    console.error("Error creating reminder:", error);
    throw error;
  }

  return data as Reminder;
}

/**
 * Update an existing reminder
 */
export async function updateReminder(payload: UpdateReminderPayload): Promise<Reminder> {
  const { id, ...updateData } = payload;
  
  const { data, error } = await supabase
    .from('reminders')
    .update(updateData)
    .eq('id', id)
    .select()
    .single();

  if (error) {
    console.error("Error updating reminder:", error);
    throw error;
  }

  return data as Reminder;
}
