
/**
 * Utility functions for time calculations in tasks
 */

import { Task } from '@/types/task';

/**
 * Calculates the total estimated time including child tasks
 */
export const calculateTotalEstimatedTime = (task: Task): number => {
  let total = task.estimated_minutes || 0;
  
  if (task.subtasks && task.subtasks.length > 0) {
    total += task.subtasks.reduce((subtotal, subtask) => {
      return subtotal + calculateTotalEstimatedTime(subtask);
    }, 0);
  }
  
  return total;
};

/**
 * Formats the total time (converts to hours and minutes)
 */
export const formatTotalTime = (minutes: number): string => {
  if (minutes === 0) return "0m";
  
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours === 0) return `${remainingMinutes}m`;
  if (remainingMinutes === 0) return `${hours}h`;
  return `${hours}h ${remainingMinutes}m`;
};
