
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ResourceDetail } from '@/types/resource';
import { generateHowToSchema, generateFAQSchema, generateReviewSchema } from '../schemas/SchemaGenerator';

interface SchemaScriptsProps {
  resource: ResourceDetail;
}

const SchemaScripts: React.FC<SchemaScriptsProps> = ({ resource }) => {
  const howToSchema = resource.content ? generateHowToSchema(resource.content, resource) : null;
  const faqSchema = resource.content ? generateFAQSchema(resource.content) : null;
  const reviewSchema = generateReviewSchema(resource);

  return (
    <Helmet>
      {howToSchema && (
        <script type="application/ld+json">
          {JSON.stringify(howToSchema)}
        </script>
      )}
      {faqSchema && (
        <script type="application/ld+json">
          {JSON.stringify(faqSchema)}
        </script>
      )}
      {reviewSchema && (
        <script type="application/ld+json">
          {JSON.stringify(reviewSchema)}
        </script>
      )}
    </Helmet>
  );
};

export default SchemaScripts;
