
import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/AuthContext';
import { useDocumentTitle } from '@/hooks/use-document-title';
import WelcomeSection from '@/components/dashboard/WelcomeSection';
import TodayHabitsSection from '@/components/dashboard/TodayHabitsSection';
import ProjectsSection from '@/components/dashboard/ProjectsSection';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  useDocumentTitle('Dashboard');

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="w-full max-w-7xl mx-auto space-y-8">
          <WelcomeSection />
          
          <div className="space-y-8">
            <TodayHabitsSection />
            <ProjectsSection title="In Progress Projects" status="in_progress" />
            <ProjectsSection title="Active Projects" status="active" />
          </div>
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default Dashboard;
