
import React, { useEffect, useState } from 'react';
import MainLayout from '@/layouts/MainLayout';
import ProtectedRoute from '@/components/ProtectedRoute';
import { Loader2 } from 'lucide-react';
import { useProjects } from '@/hooks/use-projects';
import ProjectsHeader from '@/components/projects/ProjectsHeader';
import ProjectsGrid from '@/components/projects/ProjectsGrid';
import { useDocumentTitle } from '@/hooks/use-document-title';
import QuickFilters from '@/components/projects/filters/QuickFilters';
import { useProjectFilters } from '@/hooks/projects/use-project-filters';
import NewProjectDialog from '@/components/projects/NewProjectDialog';
import { useAuth } from '@/contexts/auth';

const Projects = () => {
  const { user } = useAuth();
  const [hasInitialFetch, setHasInitialFetch] = useState(false);
  const { projects, loading, fetchProjects } = useProjects();
  const {
    searchQuery,
    setSearchQuery,
    selectedTags,
    setSelectedTags,
    showDueToday,
    setShowDueToday,
    showOverdue,
    setShowOverdue,
    showHighPriority,
    setShowHighPriority,
    showFavorites,
    setShowFavorites,
    clearAllFilters,
    hasActiveFilters,
    filteredProjects
  } = useProjectFilters(projects);
  
  useDocumentTitle('Projects');
  
  // Refresh projects only once when user changes or on initial load
  useEffect(() => {
    let isMounted = true;
    
    if (user && !hasInitialFetch && isMounted) {
      console.log('Projects page - refreshing projects for user:', user.id);
      fetchProjects().then(() => {
        if (isMounted) {
          setHasInitialFetch(true);
        }
      });
    }
    
    return () => {
      isMounted = false;
    };
  }, [user, hasInitialFetch, fetchProjects]);

  return (
    <ProtectedRoute>
      <MainLayout>
        <div className="max-w-4xl mx-auto w-full px-4">
          <div className="flex justify-between items-center">
            <ProjectsHeader />
            <div className="mt-6 mb-6">
              <NewProjectDialog onSuccess={fetchProjects} />
            </div>
          </div>
          
          {loading ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : (
            <>
              <QuickFilters
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                showDueToday={showDueToday}
                setShowDueToday={setShowDueToday}
                showOverdue={showOverdue}
                setShowOverdue={setShowOverdue}
                showHighPriority={showHighPriority}
                setShowHighPriority={setShowHighPriority}
                showFavorites={showFavorites}
                setShowFavorites={setShowFavorites}
                clearAllFilters={clearAllFilters}
                hasActiveFilters={hasActiveFilters}
              />
              
              <ProjectsGrid 
                projects={filteredProjects}
                onSuccess={fetchProjects}
              />
              
              {filteredProjects.length === 0 && (
                <div className="text-center py-10">
                  {hasActiveFilters ? (
                    <>
                      <h3 className="text-lg font-medium mb-2">No projects match your filters</h3>
                      <p className="text-muted-foreground mb-4">
                        Try changing or clearing your filters.
                      </p>
                      <button 
                        onClick={clearAllFilters}
                        className="text-primary hover:underline"
                      >
                        Clear all filters
                      </button>
                    </>
                  ) : (
                    <>
                      <h3 className="text-lg font-medium mb-2">No projects yet</h3>
                      <p className="text-muted-foreground mb-4">
                        Get started by creating your first project.
                      </p>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </MainLayout>
    </ProtectedRoute>
  );
};

export default Projects;
