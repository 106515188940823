
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Goal, CreateGoalPayload, UpdateGoalPayload } from '@/types/goal';
import { useAuth } from '@/contexts/AuthContext';
import { useState } from 'react';

export function useGoalOperations(projectId: string | undefined) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch goals
  const fetchGoals = async (): Promise<Goal[]> => {
    if (!user || !projectId) {
      setIsLoading(false);
      return [];
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('project_goals')
        .select('*')
        .eq('project_id', projectId)
        .eq('user_id', user.id)
        .order('due_date', { ascending: true });

      if (error) {
        throw error;
      }

      return data as Goal[];
    } catch (err: any) {
      console.error('Error fetching goals:', err);
      setError(err.message || 'Failed to fetch goals');
      toast.error('Errore nel caricamento degli obiettivi');
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  // Create goal
  const createGoal = async (goalData: CreateGoalPayload): Promise<Goal | null> => {
    if (!user || !projectId) {
      toast.error('Devi essere loggato per creare un obiettivo');
      return null;
    }

    try {
      const newGoal = {
        ...goalData,
        project_id: projectId,
        user_id: user.id
      };

      const { data, error } = await supabase
        .from('project_goals')
        .insert(newGoal)
        .select()
        .single();

      if (error) throw error;
      
      toast.success('Obiettivo creato con successo');
      return data as Goal;
    } catch (err: any) {
      console.error('Error creating goal:', err);
      toast.error('Errore nella creazione dell\'obiettivo');
      return null;
    }
  };

  // Update goal
  const updateGoal = async (goalData: UpdateGoalPayload): Promise<boolean> => {
    if (!user || !projectId) {
      toast.error('Devi essere loggato per aggiornare un obiettivo');
      return false;
    }

    try {
      const { error } = await supabase
        .from('project_goals')
        .update(goalData)
        .eq('id', goalData.id)
        .eq('user_id', user.id);

      if (error) throw error;
      
      toast.success('Obiettivo aggiornato con successo');
      return true;
    } catch (err: any) {
      console.error('Error updating goal:', err);
      toast.error('Errore nell\'aggiornamento dell\'obiettivo');
      return false;
    }
  };

  // Delete goal
  const deleteGoal = async (goalId: string): Promise<boolean> => {
    if (!user || !projectId) {
      toast.error('Devi essere loggato per eliminare un obiettivo');
      return false;
    }

    try {
      const { error } = await supabase
        .from('project_goals')
        .delete()
        .eq('id', goalId)
        .eq('user_id', user.id);

      if (error) throw error;
      
      toast.success('Obiettivo eliminato con successo');
      return true;
    } catch (err: any) {
      console.error('Error deleting goal:', err);
      toast.error('Errore nell\'eliminazione dell\'obiettivo');
      return false;
    }
  };

  // Toggle goal completion
  const toggleGoalCompletion = async (goalId: string, completed: boolean): Promise<boolean> => {
    return await updateGoal({ id: goalId, completed: !completed });
  };

  return {
    isLoading,
    error,
    fetchGoals,
    createGoal,
    updateGoal,
    deleteGoal,
    toggleGoalCompletion
  };
}
