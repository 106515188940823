
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/ui/input';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { CustomRecurrenceSettings, PeriodOption, DayOfWeekOption } from './recurrence-types';
import { CheckIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface CustomRecurrenceFormProps {
  customSettings: CustomRecurrenceSettings;
  updateCustomSettings: (key: string, value: any) => void;
  applyCustomSettings: () => void;
}

const CustomRecurrenceForm: React.FC<CustomRecurrenceFormProps> = ({
  customSettings,
  updateCustomSettings,
  applyCustomSettings
}) => {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState<string>(customSettings.day_of_week || '');

  // Update selectedDay when customSettings changes from parent
  useEffect(() => {
    if (customSettings.day_of_week) {
      setSelectedDay(customSettings.day_of_week);
      console.log('Updated selectedDay from customSettings:', customSettings.day_of_week);
    }
  }, [customSettings.day_of_week]);

  const periodOptions: PeriodOption[] = [
    { value: 'days', label: t('projects.recurrence.days', 'Days') },
    { value: 'weeks', label: t('projects.recurrence.periodWeeks', 'Weeks') },
    { value: 'months', label: t('projects.recurrence.periodMonths', 'Months') },
    { value: 'years', label: t('projects.recurrence.periodYears', 'Years') }
  ];

  const dayOfWeekOptions: DayOfWeekOption[] = [
    { value: 'monday', label: t('days.monday', 'Monday') },
    { value: 'tuesday', label: t('days.tuesday', 'Tuesday') },
    { value: 'wednesday', label: t('days.wednesday', 'Wednesday') },
    { value: 'thursday', label: t('days.thursday', 'Thursday') },
    { value: 'friday', label: t('days.friday', 'Friday') },
    { value: 'saturday', label: t('days.saturday', 'Saturday') },
    { value: 'sunday', label: t('days.sunday', 'Sunday') }
  ];

  // Helper function to handle day of week selection
  const handleDayOfWeekSelect = (dayValue: string) => {
    setSelectedDay(dayValue);
    updateCustomSettings('day_of_week', dayValue);
    console.log('Selected day of week:', dayValue, 'Current customSettings:', JSON.stringify(customSettings));
  };

  // Helper function to handle period selection with reset of day_of_week for non-weekly periods
  const handlePeriodSelect = (periodValue: string) => {
    updateCustomSettings('period', periodValue);
    
    // If changing to a non-weekly period, remove day_of_week
    if (periodValue !== 'weeks' && customSettings.day_of_week) {
      updateCustomSettings('day_of_week', undefined);
      setSelectedDay('');
    } 
    // If changing to weeks and no day is selected, set default
    else if (periodValue === 'weeks' && !customSettings.day_of_week) {
      const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, etc.
      const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const defaultDay = dayMap[today];
      setSelectedDay(defaultDay);
      updateCustomSettings('day_of_week', defaultDay);
    }
  };

  // Helper function to handle apply with logging
  const handleApplySettings = () => {
    // Make sure day_of_week is properly set for weekly recurrences
    if (customSettings.period === 'weeks' && !customSettings.day_of_week) {
      const today = new Date().getDay();
      const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const defaultDay = dayMap[today];
      updateCustomSettings('day_of_week', defaultDay);
      console.log('Setting default day of week:', defaultDay);
    }
    
    console.log('Applying custom settings with day:', JSON.stringify(customSettings));
    // Small delay to ensure state is updated before applying
    setTimeout(() => {
      applyCustomSettings();
    }, 50);
  };

  return (
    <div className="space-y-4">
      <div className="text-sm font-medium">
        {t('projects.recurrence.customSettings', 'Custom Settings')}
      </div>
      
      <div className="flex items-center gap-2">
        <span className="text-sm">{t('projects.recurrence.customEvery', 'Every')}</span>
        <Input
          type="number"
          min="1"
          className="w-16 h-8"
          value={customSettings.every}
          onChange={(e) => updateCustomSettings('every', parseInt(e.target.value) || 1)}
        />
        
        <div className="flex-1">
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full flex items-center justify-between bg-background border rounded-md px-3 py-1 text-sm">
              {periodOptions.find(p => p.value === customSettings.period)?.label || customSettings.period}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full bg-popover">
              {periodOptions.map(period => (
                <DropdownMenuItem 
                  key={period.value}
                  onClick={() => handlePeriodSelect(period.value)}
                  className={customSettings.period === period.value ? "bg-accent/50" : ""}
                >
                  {period.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      
      {customSettings.period === 'weeks' && (
        <div>
          <div className="text-sm mb-2">{t('projects.recurrence.dayOfWeek', 'Day of week')}</div>
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full flex items-center justify-between bg-background border rounded-md px-3 py-1 text-sm">
              {dayOfWeekOptions.find(d => d.value === selectedDay)?.label || 'Select a day'}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full bg-popover">
              {dayOfWeekOptions.map(day => (
                <DropdownMenuItem 
                  key={day.value}
                  onClick={() => handleDayOfWeekSelect(day.value)}
                  className={selectedDay === day.value ? "bg-accent/50" : ""}
                >
                  {day.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
      
      <button 
        className="w-full bg-primary text-primary-foreground py-1 rounded-md text-sm hover:bg-primary/90 transition-colors"
        onClick={handleApplySettings}
      >
        {t('projects.recurrence.apply', 'Apply')}
      </button>
    </div>
  );
};

export default CustomRecurrenceForm;
