
import React from 'react';
import { Habit, CreateHabitPayload } from '@/types/habit';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { HabitForm } from '@/components/habits/HabitForm';

interface HabitDialogsProps {
  isCreateOpen: boolean;
  setIsCreateOpen: (open: boolean) => void;
  editingHabit: Habit | null;
  setEditingHabit: (habit: Habit | null) => void;
  handleCreate: (data: CreateHabitPayload) => Promise<void>;
  handleUpdate: (data: CreateHabitPayload) => Promise<void>;
  selectedTimeOfDay: string;
}

export const HabitDialogs: React.FC<HabitDialogsProps> = ({
  isCreateOpen,
  setIsCreateOpen,
  editingHabit,
  setEditingHabit,
  handleCreate,
  handleUpdate,
  selectedTimeOfDay
}) => {
  return (
    <>
      <Dialog open={isCreateOpen} onOpenChange={setIsCreateOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Habit</DialogTitle>
          </DialogHeader>
          <HabitForm 
            onSubmit={handleCreate} 
            defaultValues={{ time_of_day: selectedTimeOfDay as any }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={!!editingHabit} onOpenChange={() => setEditingHabit(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Habit</DialogTitle>
          </DialogHeader>
          {editingHabit && (
            <HabitForm
              onSubmit={handleUpdate}
              defaultValues={editingHabit}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
