
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addKeyword, addBulkKeywords, deleteKeyword, linkKeywordToResource } from '@/services/resources/keyword-service';
import { toast } from 'sonner';

export const useKeywordMutations = () => {
  const queryClient = useQueryClient();
  
  const useAddKeywordMutation = () => {
    return useMutation({
      mutationFn: (keyword: string) => addKeyword(keyword),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['keywords'] });
        toast.success('Keyword added successfully');
      },
      onError: (error: Error) => {
        toast.error(`Failed to add keyword: ${error.message}`);
      }
    });
  };
  
  const useAddBulkKeywordsMutation = () => {
    return useMutation({
      mutationFn: (keywords: string[]) => addBulkKeywords(keywords),
      onSuccess: (result) => {
        queryClient.invalidateQueries({ queryKey: ['keywords'] });
        toast.success(`Added ${result.added} keywords, ${result.duplicates} duplicates skipped`);
      },
      onError: (error: Error) => {
        toast.error(`Failed to add keywords: ${error.message}`);
      }
    });
  };
  
  const useDeleteKeywordMutation = () => {
    return useMutation({
      mutationFn: (id: string) => deleteKeyword(id),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['keywords'] });
        toast.success('Keyword deleted successfully');
      },
      onError: (error: Error) => {
        toast.error(`Failed to delete keyword: ${error.message}`);
      }
    });
  };
  
  const useLinkKeywordToResourceMutation = () => {
    return useMutation({
      mutationFn: ({ keywordId, resourceId }: { keywordId: string, resourceId: string }) => 
        linkKeywordToResource(keywordId, resourceId),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['keywords'] });
        toast.success('Keyword linked to resource successfully');
      },
      onError: (error: Error) => {
        toast.error(`Failed to link keyword to resource: ${error.message}`);
      }
    });
  };
  
  return {
    useAddKeywordMutation,
    useAddBulkKeywordsMutation,
    useDeleteKeywordMutation,
    useLinkKeywordToResourceMutation
  };
};
