
import React from 'react';
import { MessageSquare } from 'lucide-react';
import { 
  SidebarMenuItem, 
  SidebarMenuButton 
} from '@/components/ui/sidebar';
import { useSidebarPrompts } from '../hooks/use-sidebar-prompts';

export function PromptsMenu() {
  const { navigateToPrompts, isPromptsActive } = useSidebarPrompts();

  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        onClick={navigateToPrompts}
        tooltip="Prompts"
        isActive={isPromptsActive}
      >
        <MessageSquare className="h-4 w-4" />
        <span>Prompts</span>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
