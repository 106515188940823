
import React from 'react';
import { Goal } from '@/types/goal';
import { Loader2 } from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useIsMobile } from '@/hooks/use-mobile';

interface GoalsTimelineProps {
  goals: Goal[];
  isLoading: boolean;
  formatDueDate: (dueDate: string | null, short?: boolean) => string;
  getStatusColor: (dueDate: string | null, completed: boolean) => string;
}

const GoalsTimeline: React.FC<GoalsTimelineProps> = ({
  goals,
  isLoading,
  formatDueDate,
  getStatusColor
}) => {
  const isMobile = useIsMobile();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  // Filter out goals without due dates
  const goalsWithDates = goals.filter(goal => goal.due_date);
  
  if (goalsWithDates.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        <p>No goals with a defined due date.</p>
        <p className="text-sm">Add due dates to goals to view the timeline.</p>
      </div>
    );
  }

  // Sort goals by due date
  const sortedGoals = [...goalsWithDates].sort((a, b) => {
    if (!a.due_date || !b.due_date) return 0;
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
  });

  // Group goals by month
  const goalsByMonth: Record<string, Goal[]> = {};
  
  sortedGoals.forEach(goal => {
    if (!goal.due_date) return;
    
    const date = new Date(goal.due_date);
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
    
    if (!goalsByMonth[monthYear]) {
      goalsByMonth[monthYear] = [];
    }
    
    goalsByMonth[monthYear].push(goal);
  });

  // Get month name
  const getMonthName = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
  };

  return (
    <ScrollArea className={`${isMobile ? 'h-[350px]' : 'h-[400px]'} pr-4 overflow-x-hidden`}>
      <div className={`relative pl-6 sm:pl-8 border-l-2 border-muted ${isMobile ? 'text-sm' : ''} pr-2 sm:pr-4`}>
        {Object.entries(goalsByMonth).map(([monthYear, monthGoals]) => {
          const firstGoalDate = monthGoals[0].due_date;
          return (
            <div key={monthYear} className="mb-6">
              <div className="absolute left-0 w-5 h-5 sm:w-6 sm:h-6 bg-card border-2 border-primary rounded-full -ml-2.5 sm:-ml-3 flex items-center justify-center" />
              <h3 className="text-sm font-semibold mb-3 break-words">
                {getMonthName(firstGoalDate!)}
              </h3>
              <div className="space-y-3">
                {monthGoals.map(goal => {
                  const statusColor = getStatusColor(goal.due_date, goal.completed);
                  const date = new Date(goal.due_date!);
                  const day = date.getDate();
                  
                  return (
                    <div key={goal.id} className="relative pl-6 sm:pl-8">
                      <div className={`absolute left-0 w-3 h-3 sm:w-4 sm:h-4 rounded-full -ml-1.5 sm:-ml-2 ${goal.completed ? 'bg-green-500' : 'bg-card border-2 border-current'}`} style={{ color: statusColor.replace('text-', '') }} />
                      <div className="flex flex-col sm:flex-row sm:items-start gap-1 sm:gap-2">
                        <div className={`text-xs sm:text-sm font-medium ${statusColor} min-w-[20px] flex-shrink-0`}>
                          {day}
                        </div>
                        <div className="flex-1 max-w-full overflow-hidden">
                          <div className={`text-xs sm:text-sm font-medium ${goal.completed ? 'line-through text-muted-foreground' : ''} break-words`}>
                            {goal.title}
                          </div>
                          {goal.description && (
                            <div className={`text-xs text-muted-foreground ${goal.completed ? 'line-through' : ''} line-clamp-2 break-words`}>
                              {goal.description}
                            </div>
                          )}
                          <div className={`text-xs mt-1 ${statusColor}`}>
                            {formatDueDate(goal.due_date, true)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
};

export default GoalsTimeline;
