
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';

const ResourcesEmptyState: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="text-center p-8">
      <h3 className="text-lg font-medium">All Resources</h3>
      <p className="text-muted-foreground mt-2">
        No resources found. Create your first resource.
      </p>
      <Button 
        className="mt-4"
        onClick={() => navigate('/admin/resources/create')}
      >
        Create Resource
      </Button>
    </div>
  );
};

export default ResourcesEmptyState;
