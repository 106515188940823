
import React from 'react';
import { Task, UpdateTaskPayload, CreateTaskPayload } from '@/types/task';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import TaskCreator from './TaskCreator';
import TaskEditor from './editor/TaskEditor';
import TaskItemActions from './task-item/TaskItemActions';
import TaskItemContent from './task-item/TaskItemContent';
import TaskItemMetadata from './task-item/TaskItemMetadata';
import StatusActions from './task-item/StatusActions';
import SubtasksList from './task-item/SubtasksList';
import { useTaskItemHandlers } from '@/hooks/tasks/use-task-item-handlers';

interface TaskItemProps {
  task: Task;
  level: number;
  onUpdateTask: (task: UpdateTaskPayload) => Promise<boolean>;
  onDeleteTask: (taskId: string) => Promise<boolean>;
  onToggleExpansion: (taskId: string) => void;
  onCreateSubtask: (task: CreateTaskPayload) => Promise<Task | null>;
  projectId: string;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  level,
  onUpdateTask,
  onDeleteTask,
  onToggleExpansion,
  onCreateSubtask,
  projectId
}) => {
  const {
    showAddSubtask,
    isEditing,
    isCompleted,
    handleToggleStatus,
    handleStatusChange,
    handleDelete,
    handleTitleUpdate,
    handlePriorityUpdate,
    handleEstimatedTimeUpdate,
    toggleEditing,
    setShowAddSubtask
  } = useTaskItemHandlers(task, onUpdateTask, onDeleteTask, onToggleExpansion);
  
  const hasSubtasks = task.subtasks && task.subtasks.length > 0;

  if (isEditing) {
    return (
      <div className={cn("mb-1", level > 0 && "ml-6")}>
        <TaskEditor 
          task={task} 
          onUpdate={onUpdateTask}
          onCancel={() => toggleEditing()}
        />
      </div>
    );
  }

  return (
    <div className={cn("mb-1", level > 0 && "ml-6")}>
      <div className="group flex items-start gap-2 rounded-md hover:bg-muted/40 p-2">
        <div className="flex-shrink-0 mt-0.5">
          <Checkbox
            checked={isCompleted}
            onCheckedChange={handleToggleStatus}
            className="mt-1"
          />
        </div>

        <div className="flex-grow min-w-0">
          <div className="flex items-start justify-between gap-2">
            <TaskItemContent
              title={task.title}
              description={task.description}
              isCompleted={isCompleted}
              hasSubtasks={hasSubtasks}
              isExpanded={task.isExpanded || false}
              onToggleExpansion={() => onToggleExpansion(task.id)}
              onUpdateTitle={handleTitleUpdate}
            />
            
            <div className="flex flex-col sm:flex-row items-end sm:items-center gap-2">
              <StatusActions 
                status={task.status} 
                onStatusChange={handleStatusChange} 
              />
              
              <div className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity">
                <TaskItemActions
                  task={task}
                  onEdit={toggleEditing}
                  onAddSubtask={() => setShowAddSubtask(true)}
                  onDelete={handleDelete}
                />
              </div>
            </div>
          </div>
          
          <TaskItemMetadata
            task={task}
            priority={task.priority}
            estimatedMinutes={task.estimated_minutes}
            links={task.links}
            due_date={task.due_date}
            onUpdatePriority={handlePriorityUpdate}
            onUpdateEstimatedTime={handleEstimatedTimeUpdate}
            onTaskUpdated={onUpdateTask}
          />
          
          {showAddSubtask && (
            <div className="mt-2">
              <TaskCreator
                projectId={projectId}
                parentTaskId={task.id}
                onCreateTask={onCreateSubtask}
                onCancel={() => setShowAddSubtask(false)}
              />
            </div>
          )}
          
          {hasSubtasks && task.isExpanded && (
            <SubtasksList
              subtasks={task.subtasks!}
              level={level}
              onUpdateTask={onUpdateTask}
              onDeleteTask={onDeleteTask}
              onToggleExpansion={onToggleExpansion}
              onCreateSubtask={onCreateSubtask}
              projectId={projectId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskItem;
