
import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ResourceCarouselMobileCtaProps {
  isClientSide: boolean;
}

/**
 * Mobile-specific CTA button for the resource carousel
 */
const ResourceCarouselMobileCta: React.FC<ResourceCarouselMobileCtaProps> = ({ isClientSide }) => {
  if (!isClientSide) return null;
  
  return (
    <div className="mt-6 md:hidden flex justify-center">
      <Link to="/resources">
        <Button variant="outline" className="group w-full">
          Browse All Resources 
          <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
        </Button>
      </Link>
    </div>
  );
};

export default ResourceCarouselMobileCta;
