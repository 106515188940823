
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from '@/layouts/MainLayout';
import ResourceDetails from '@/components/resources/public/ResourceDetails';
import { usePublicResources } from '@/hooks/resources/use-public-resources';
import { useMetadata } from '@/hooks/use-metadata';
import { useResourceRating } from '@/hooks/resources/use-resource-rating';

const ResourceDetailsPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { i18n } = useTranslation();
  const language = i18n.language;
  
  const { usePublicResourceBySlugQuery } = usePublicResources(language);
  const { data: resource, isLoading } = usePublicResourceBySlugQuery(slug || '');
  
  // Fetch the actual rating from the database
  const { rating: ratingData, isLoading: isRatingLoading, refetchRating } = 
    useResourceRating(resource?.id || '');

  // Combine resource with real rating data
  const resourceWithRating = resource && ratingData ? {
    ...resource,
    rating: {
      value: ratingData.average_rating || 0,
      max: 5,
      count: ratingData.rating_count || 0
    }
  } : resource;

  // Set metadata for the page for SEO
  useMetadata({
    title: resource?.title || 'Resource Details',
    description: resource?.excerpt || 'Detailed information about this resource',
    ogType: 'article',
    image: '/og-image.png', // Changed from 'ogImage' to 'image' to match interface
  });

  return (
    <MainLayout>
      <div className="container mx-auto px-4 py-8">
        <ResourceDetails 
          resource={resourceWithRating} 
          isLoading={isLoading || isRatingLoading}
          onRatingUpdated={refetchRating}
        />
      </div>
    </MainLayout>
  );
};

export default ResourceDetailsPage;
