
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Edit, Plus, Trash2, Focus } from 'lucide-react';
import { Task } from '@/types/task';
import FocusModeSheet from '../focus-mode/FocusModeSheet';

interface TaskItemActionsProps {
  task: Task;
  onEdit: () => void;
  onAddSubtask: () => void;
  onDelete: () => Promise<void>;
}

const TaskItemActions: React.FC<TaskItemActionsProps> = ({
  task,
  onEdit,
  onAddSubtask,
  onDelete,
}) => {
  const [isFocusModeOpen, setIsFocusModeOpen] = useState(false);

  return (
    <>
      <div className="flex flex-wrap items-center gap-1">
        {task.estimated_minutes && (
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-7 w-7 p-0" 
            onClick={() => setIsFocusModeOpen(true)}
            title="Focus Mode"
          >
            <Focus className="h-4 w-4" />
          </Button>
        )}
        <Button 
          variant="ghost" 
          size="sm" 
          className="h-7 w-7 p-0"
          onClick={onEdit}
          title="Edit Task"
        >
          <Edit className="h-4 w-4" />
        </Button>
        <Button 
          variant="ghost" 
          size="sm" 
          className="h-7 w-7 p-0"
          onClick={onAddSubtask}
          title="Add Subtask"
        >
          <Plus className="h-4 w-4" />
        </Button>
        <Button 
          variant="ghost" 
          size="sm" 
          className="h-7 w-7 p-0 text-destructive hover:text-destructive"
          onClick={onDelete}
          title="Delete Task"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      <FocusModeSheet
        task={task}
        isOpen={isFocusModeOpen}
        onClose={() => setIsFocusModeOpen(false)}
      />
    </>
  );
};

export default TaskItemActions;
