
import React, { createContext, useContext } from 'react';
import { AuthContextType } from './types';
import { 
  useAuthSession,
  useAuthProfile,
  useAuthActions,
  useProfileActions
} from '@/hooks/auth';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    session,
    user,
    userRoles,
    isLoading,
    error,
    setIsLoading,
    setError,
    setUserRoles
  } = useAuthSession();

  const { userProfile, setUserProfile } = useAuthProfile(user);

  const {
    signUp,
    signIn,
    signOut,
    hasRole
  } = useAuthActions(user, userRoles, setIsLoading, setError);

  const {
    removeRole,
    updateTimezone,
    updateProfile
  } = useProfileActions(
    user, 
    userRoles, 
    userProfile, 
    setIsLoading, 
    setError, 
    setUserProfile, 
    setUserRoles
  );

  const value: AuthContextType = {
    user,
    session,
    isLoading,
    error,
    userRoles,
    userProfile,
    signUp,
    signIn,
    signOut,
    hasRole,
    removeRole,
    updateTimezone,
    updateProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
