
import React from 'react';
import { Project } from '@/components/sidebar/sidebar-data';
import NewProjectButton from '@/components/NewProjectButton';
import StatusSection from './StatusSection';
import QuickTodo from './quick-todo/QuickTodo';

interface ProjectsGridProps {
  projects: Project[];
  onSuccess: () => void;
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({ projects, onSuccess }) => {
  // Sort projects by priority (ASAP first) and then by due date (nearest first)
  const sortProjects = (projectsToSort: Project[]) => {
    return [...projectsToSort].sort((a, b) => {
      // First sort by priority - ASAP gets highest priority
      if (a.priority === 'asap' && b.priority !== 'asap') return -1;
      if (a.priority !== 'asap' && b.priority === 'asap') return 1;
      
      // Then sort by due date if priority is the same
      if (!a.due_date && !b.due_date) return 0;
      if (!a.due_date) return 1;
      if (!b.due_date) return -1;
      return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
    });
  };

  // Group projects by status
  const activeProjects = sortProjects(
    projects.filter(p => p.status === 'active')
  );
  
  const inProgressProjects = sortProjects(
    projects.filter(p => p.status === 'in_progress')
  );
  
  const queueProjects = sortProjects(
    projects.filter(p => p.status === 'queue')
  );
  
  const blockedProjects = sortProjects(
    projects.filter(p => p.status === 'blocked')
  );
  
  const completedProjects = sortProjects(
    projects.filter(p => p.status === 'completed')
  );

  return (
    <div className="space-y-8">
      {/* Add Quick Todo component before In Progress projects */}
      <QuickTodo />
      
      <StatusSection 
        title="In Progress" 
        projects={inProgressProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Active" 
        projects={activeProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="In Queue" 
        projects={queueProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Blocked" 
        projects={blockedProjects} 
        onSuccess={onSuccess} 
      />
      <StatusSection 
        title="Completed" 
        projects={completedProjects} 
        onSuccess={onSuccess} 
      />
    </div>
  );
};

export default ProjectsGrid;
